import request from "superagent"

//export const COUNT_DPL_SUMMEN = "dpl/COUNT_DPL_SUMMEN"
export const LOAD_CUSTOM_GROUPS = "dpl/LOAD_CUSTOM_GROUPS"
export function CountSummen() {
  return () => {}
}

// function DoCountSummen(PlanID, Datum, Records, PlaceHolders) {
//   return {
//     type: COUNT_DPL_SUMMEN,
//     PlanID,
//     Datum,
//     Records,
//     PlaceHolders
//   }
// }

export const LoadCustomGroups = (PlanID) => {
  return (dispatch) => {
    request
      .get(`/api/dpl/basis/customgroups/${PlanID}`)
      .end((err, response) => {
        if (!err) {
          dispatch({ type: LOAD_CUSTOM_GROUPS, data: response.body })
        }
      })
  }
}
