import {
  LOAD_ACTIVE_BERECHTIGUNGEN,
  LOAD_STUFEN
} from "../actions/berechtigungen"

export const Berechtigungen = (state = { active: [], stufen: [] }, action) => {
  switch (action.type) {
    case LOAD_ACTIVE_BERECHTIGUNGEN:
      return { ...state, active: action.data }
    case LOAD_STUFEN:
      return { ...state, stufen: action.data }
  }
  return state
}
