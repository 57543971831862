import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 10
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "11px",
          color: "#ddd",
          border: "1px solid #666",
          backgroundColor: "#333333dd",
          boxShadow: "2px 2px 5px #cccccccc"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        standard: {
          "&.MuiInputLabel-shrink": {
            transform: "translate(0px, 5px) scale(0.75)"
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "#999"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "11px",
          fontFamily: "'RobotoArion'"
        }
      }
    },
    MuiAutoComplete: {
      styleOverrides: {
        root: {
          ".MuiAutocomplete-noOptions": {
            fontSize: "11px"
          }
        }
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          fontSize: "11px"
        },
        fontSize: "11px"
      }
    },
    MuiTextField: {
      minHeight: "25px",
      fontSize: "11px",
      "& .MuiInput-root": {
        fontSize: "11px"
      },
      "& .MuiInputBase-root": {
        fontSize: "11px",
        paddingLeft: "10px",
        "&:after": {}
      },
      "& .MuiInputLabel-root": {
        fontSize: "11px",

        "&.MuiInputLabel-shrink": {}
      }
    }
  }
})

export default theme
