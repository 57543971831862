import request from "superagent"
import Datetime from "app/shared/dateutil"

export const LOAD_KENNZEICHNUNGEN = "dpl/LOAD_KENNZEICHNUNGEN"
export const LOAD_GEKENNZEICHNET = "dpl/LOAD_GEKENNZEICHNET"
export const REMOVE_GEKENNZEICHNET = "dpl/REMOVE_GEKENNZEICHNET"

export const LoadKennzeichnungen = () => {
  return (dispatch) => {
    request.get("/api/dpl/basis/kennzeichnungen").end((err, response) => {
      if (!err) {
        dispatch({ type: LOAD_KENNZEICHNUNGEN, data: response.body })
      }
    })
  }
}

export const LoadGekennzeichnet = (von, bis, plan_id) => {
  return (dispatch) => {
    request
      .get(
        `/api/dpl/basis/gekennzeichnet/${Datetime.fromJSDate(von).toFormat(
          "yyyy-MM-dd"
        )}/${Datetime.fromJSDate(bis).toFormat("yyyy-MM-dd")}/${plan_id}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch({ type: LOAD_GEKENNZEICHNET, data: response.body })
        }
      })
  }
}

export const SetGekennzeichnet = (int_id, datum, id) => {
  return (dispatch) => {
    request
      .post("/api/dpl/basis/gekennzeichnet/")
      .send({ int_id, datum, id })
      .end((err, response) => {
        if (!err) {
          dispatch({ type: LOAD_GEKENNZEICHNET, data: [response.body] })
        }
      })
  }
}

export const DeleteGekennzeichnet = (int_id, datum, id) => {
  return (dispatch) => {
    request
      .delete("/api/dpl/basis/gekennzeichnet/")
      .send({ int_id, datum, id })
      .end((err, response) => {
        if (!err) {
          dispatch({ type: REMOVE_GEKENNZEICHNET, int_id, datum, id })
        }
      })
  }
}
