import { LOAD_MITARBEITER_INFOS } from "./actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export function MitarbeiterInfos(state = {}, action) {
  switch (action.type) {
    case LOAD_MITARBEITER_INFOS:
      switch (action.status) {
        case SUCCESS:
          return { ...state, [action.int_id]: { data: action.infos, isFetching: false, error: null } }
        case STARTED:
          return { ...state, [action.int_id]: { data: null, isFetching: true, error: null } }
        case ERROR:
          return { ...state, [action.int_id]: { data: null, isFetching: false, error: action.error } }
      }
  }
  return state
}

export function Mitarbeiter(state = { Infos: {} }, action) {
  switch (action.type) {
    case LOAD_MITARBEITER_INFOS:
      return { ...state, Infos: MitarbeiterInfos(state.Infos, action) }
  }
  return state
}
