import { createSelector } from "reselect"

export const GetCalendar = (state, int_id, datum) => {
  const v = state.UPL.Data.kal[`${int_id}-${datum}`]
  return v
}

export const GetWunsch = (state, int_id, datum) => {
  const v = state.UPL.Data.wunsch[`${int_id}-${datum}`]
  return v
}

export const GetWork = (state, int_id, datum) => {
  const v = state.UPL.Data.work[`${int_id}-${datum}`]
  return v
}

export const GetAllWork = (state) => {
  return state.UPL.Data.work
}

export const GetAllKalender = (state) => {
  return state.UPL.Data.kal
}

export const GetAllWunsch = (state) => {
  return state.UPL.Data.wunsch
}

export const GetDirtyWuensche = createSelector(GetAllWunsch, (wunsch) => {
  let result = []
  for (let w of Object.values(wunsch)) {
    if (w.__new && w.__deleted) {
      continue
    }
    if (w.__dirty || w.__new) {
      result.push(w)
    }
  }
  return result
})

export const GetDirtyWork = createSelector(GetAllWork, (work) => {
  let result = []
  for (let w of Object.values(work)) {
    if (w.__new && w.__deleted) {
      continue
    }
    if (w.__dirty || w.__new) {
      result.push(w)
    }
  }
  return result
})

export const HasUnsavedChanges = (state) => {
  if (state.UPL.Data.saving) {
    return false
  }
  const w = GetDirtyWork(state)
  if (w.length > 0) {
    return true
  }
  const w2 = GetDirtyWuensche(state)
  if (w2.length > 0) {
    return true
  }
  return false
}

export const IsLoading = (state) => state.UPL.Data.loading
