import request from "superagent"
export const LOAD_MENU = "LOAD_MENU"
export const LOAD_GROUPS = "menu/LOAD_GROUPS"
export const TOGGLE_MENU = "TOGGLE_MENU"
export const TOGGLE_MENU_PINNED = "TOGGLE_MENU_PINNED"
export const TOGGLE_MENU_GROUP = "TOGGLE_MENU_GROUP"

export const LOAD_MENU_STATUS_STARTED = "LOAD_MENU_STATUS_STARTED"
export const LOAD_MENU_STATUS_SUCCESS = "LOAD_MENU_STATUS_SUCCESS"
export const LOAD_MENU_STATUS_ERROR = "LOAD_MENU_STATUS_ERROR"

export function ToggleMenu() {
  return {
    type: TOGGLE_MENU
  }
}

export function ToggleMenuGroup(group) {
  return {
    type: TOGGLE_MENU_GROUP,
    group
  }
}

export function ToggleMenuPinned() {
  return {
    type: TOGGLE_MENU_PINNED
  }
}

export function LoadMenuStarted() {
  return {
    type: LOAD_MENU,
    status: LOAD_MENU_STATUS_STARTED
  }
}

export function LoadMenuSuccess(menu) {
  return {
    type: LOAD_MENU,
    status: LOAD_MENU_STATUS_SUCCESS,
    menu
  }
}

export function LoadMenuError(error) {
  return {
    type: LOAD_MENU,
    status: LOAD_MENU_STATUS_ERROR,
    error
  }
}

export function LoadMenu() {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      if (getState().Menu.items.length > 0) {
        resolve(getState().Menu.items)
        return
      }
      dispatch(LoadMenuStarted())
      request("/api/groups").end((error, response) => {
        if (error) {
          reject(error)
        } else {
          dispatch({ type: LOAD_GROUPS, data: response.body })
        }
      })
      request("/api/menu").end((error, response) => {
        if (error) {
          dispatch(LoadMenuError(error))
          reject(error)
        } else {
          dispatch(LoadMenuSuccess(response.body))
          resolve(response.body)
        }
      })
    })
  }
}
