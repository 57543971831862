import {
  LOAD_PSTAMM,
  LOAD_ABDATUM,
  EDIT_PSTAMM,
  UPDATE_PSTAMM,
  LOAD_ADRSSTAMM,
  EDIT_ADRSSTAMM,
  UPDATE_ADRSSTAMM,
  LOAD_ABKOSTAMM,
  EDIT_ABKOSTAMM,
  UPDATE_ABKOSTAMM,
  LOAD_INFOSTAMM,
  EDIT_INFOSTAMM,
  UPDATE_INFOSTAMM,
  LOAD_GEKLASTAMM,
  // LOAD_GEKLASTAMMAKTUELL,
  EDIT_GEKLASTAMM,
  UPDATE_GEKLASTAMM,
  LOAD_AKTESTAMM,
  EDIT_AKTESTAMM,
  UPDATE_AKTESTAMM,
  LOAD_SBHISTAMM,
  EDIT_SBHISTAMM,
  UPDATE_SBHISTAMM,
  DELETE_SBHISTAMM_ROW,
  ADD_GEKLASTAMM_ROW,
  ADD_AKTESTAMM_ROW,
  ADD_SBHISTAMM_ROW,
  ADD_ABKOSTAMM_ROW,
  DELETE_GEKLASTAMM_ROW,
  LOAD_QUALI,
  EDIT_QUALI,
  UPDATE_QUALI,
  INSERT_QUALI,
  ADD_QUALI_ROW,
  DELETE_QUALI_ROW,
  DELETE_ABKOSTAMM_ROW,
  LOAD_ABKOKOMB,
  LOAD_PZUORD,
  LOAD_PZUORDVONBIS,
  UPDATE_PZUORD,
  UPDATE_PZUORDVONBIS,
  EDIT_PZUORD,
  EDIT_PZUORDVONBIS,
  ADD_PZUORD_ROW,
  DELETE_PZUORD_ROW,
  DELETED_PZUORD_ROW,
  CHANGE_AB_DATUM,
  CREATE_NEW_AB_DATUM,
  LOAD_PFLICHT,
  ADD_PSTAMMABDATUM,
  CREATE_PSTAMM_ABDATUM,
  ABTEILUNGS_KOMBI,
  LOAD_INFOBENUNUM,
  UPDATE_INFOBENUNUM,
  EDIT_BENUNUM,
  LOAD_INFOBENUCHA,
  UPDATE_INFOBENUCHA,
  EDIT_BENUCHA,
  LOAD_INFOSTAMMDEF,
  LOAD_INFOBENUNUMDEF,
  LOAD_INFOBENUCHADEF,
  LOAD_PZVVA,
  EDIT_PZVVA,
  EDIT_PZVVA_TGL,
  ADD_PZVVA_ROW,
  UPDATE_PZVVA,
  DELETED_PZVVA_ROW,
  DELETE_PZVVA_ROW
} from "./actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import Datetime, { Parse, ISO } from "../../../shared/dateutil/index"
import { v4 as uuidv4 } from "uuid"

export function abDatum(state = {}, action) {
  switch (action.type) {
    case LOAD_ABDATUM:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            [action.int_id]: { datum: action.datum, ende: action.data }
          }
        default:
          return {
            ...state,
            [action.int_id]: { datum: action.datum, ende: null }
          }
      }
  }
  return state
}
import { arrayToObject, arrayToObjectFn } from "app/shared/util/conversion"
// import { queryToString } from "../../../shared/util/urls"

export function Personalstamm(
  state = {
    datum: null,
    abkodatum: null,
    data: {},
    adrsdata: {},
    abkodata: {},
    infodata: {},
    gekladata: {},
    aktedata: {},
    sbhidata: {},
    qualidata: {},
    abkokombdata: [],
    pzuorddata: [],
    pzuordvonbis: [],
    isLoading: false,
    error: null,
    Pstamm: {},
    pflicht: {},
    abteilungskombi: {},
    pstammdatum: {},
    infobenunum: {},
    infobenucha: {},
    infodatadef: [],
    infobenunumdef: [],
    infobenuchadef: [],
    pzvva: []
  },
  action
) {
  switch (action.type) {
    case ADD_PZVVA_ROW:
      return {
        ...state,
        pzvva: {
          ...state.pzvva,
          [action.int_id]: [
            ...(state.pzvva[action.int_id] || []),
            {
              id: uuidv4(),
              __new: true,
              int_id: parseInt(action.int_id),
              mand_id: 1,
              firm_id: 1,
              ab_datum: Datetime.now().startOf("month").toISO(),
              arb_zt_tgl: 0,
              tage_wo: 0,
              arb_zt_wtl: 0,
              arb_zt_4wo: 0,
              arb_zt_mtl: 0,
              lst_upd: Datetime.now().toISO(),
              ...(action.values || {})
            }
            // ...(state.pzvva[action.int_id] || [])
          ]
        }
      }
    case UPDATE_PZVVA:
      if (action.status == SUCCESS) {
        return {
          ...state,
          pzvva: {
            ...state.pzvva,
            [action.int_id]: state.pzvva[action.int_id]
              .map((row) => {
                if (row.id == action.id) {
                  return action.data
                }
                return row
              })
              .filter((r) => r !== null)
          }
        }
      }
      return state
    case DELETED_PZVVA_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          pzvva: {
            ...state.pzvva,
            [action.int_id]: state.pzvva[action.int_id].filter((row) => {
              if (row.ab_datum == action.ab_datum) {
                return false
              }
              return true
            })
          }
        }
      }
      return state
    case DELETE_PZVVA_ROW:
      return {
        ...state,
        pzvva: {
          ...state.pzvva,
          [action.int_id]: state.pzvva[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
    case LOAD_PZVVA:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pzvva: {
              ...state.pzvva,
              [action.int_id]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_PZVVA:
      return {
        ...state,
        pzvva: {
          ...state.pzvva,
          [action.int_id]: state.pzvva[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              let r = { ...row, __dirty: true, ...action.changes }
              return {
                ...r,
                arb_zt_wtl: calcWo(r),
                arb_zt_4wo: calc4Wo(r),
                arb_zt_mtl: calcMtl(r),
                arb_zt_mo: calcWD(r, 1),
                arb_zt_di: calcWD(r, 2),
                arb_zt_mi: calcWD(r, 3),
                arb_zt_do: calcWD(r, 4),
                arb_zt_fr: calcWD(r, 5),
                arb_zt_sa: calcWD(r, 6),
                arb_zt_so: calcWD(r, 7)
              }
            }
            return row
          })
        }
      }
    case EDIT_PZVVA_TGL:
      return {
        ...state,
        pzvva: {
          ...state.pzvva,
          [action.int_id]: state.pzvva[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case CHANGE_AB_DATUM:
      return { ...state, datum: action.Datum }
    case CREATE_NEW_AB_DATUM:
      var prevAbDatum = state.datum
      var data = {
        ...state.data[action.IntID][prevAbDatum],
        __new: true,
        ab_datum: action.Datum
      }
      var abkodata = state.abkodata[action.IntID]
        .filter((row) => {
          return row.ab_datum == prevAbDatum
        })
        .map((r) => {
          return { ...r, __new: true, ab_datum: action.Datum }
        })

      return {
        ...state,
        datum: action.Datum,
        data: {
          ...state.data,
          [action.IntID]: { ...state.data[action.IntID], [action.Datum]: data }
        },
        abkodata: {
          ...state.abkodata,
          [action.IntID]: [...state.abkodata[action.IntID], ...abkodata]
        }
      }
    case ADD_PSTAMMABDATUM:
      return { ...state, datum: action.Datum }
    case CREATE_PSTAMM_ABDATUM:
      // var prevAbDatum = state.datum
      var data = {
        ...state.data[action.IntID][prevAbDatum],
        __new: true,
        ab_datum: action.Datum
      }
      return {
        ...state,
        datum: action.Datum,
        data: {
          ...state.data,
          [action.IntID]: { ...state.data[action.IntID], [action.Datum]: data }
        }
      }
    case ABTEILUNGS_KOMBI:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            abteilungskombi: action.data
          }
        case ERROR:
          return state
      }
      break

    case UPDATE_PSTAMM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.int_id]: {
              ...state.data[action.int_id],
              [action.datum]: action.data
            }
            /*.map(row => {
              if (row.ab_datum == action.data.ab_datum) {
                return action.data
              }
              return row
            })*/
          }
        }
      }
      return state
    case UPDATE_ABKOSTAMM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          abkodata: {
            ...state.abkodata,
            [action.int_id]: state.abkodata[action.int_id].map((row) => {
              if (
                row.l_nbr == action.data.l_nbr &&
                row.ab_datum == action.datum
              ) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    case LOAD_INFOSTAMMDEF:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infodatadef: action.data
          }
        case ERROR:
          return state
      }
      break
    case UPDATE_INFOSTAMM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          infodata: {
            ...state.infodata,
            [action.int_id]: state.infodata[action.int_id].map((row) => {
              if (row.ktkt_art == action.data.ktkt_art) {
                return action.data
              }
              return row
            })
            // [action.int_id]: [...state.infodata[action.int_id]]
          }
        }
      }
      return state
    case LOAD_INFOBENUNUM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            infobenunum: { ...state.infobenunum, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_INFOBENUNUM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          infobenunum: {
            ...state.infobenunum,
            [action.int_id]: state.infobenunum[action.int_id].map((row) => {
              if (row.benn_id == action.data.benn_id) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    // case EDIT_BENUNUM:
    //   return {
    //     ...state,
    //     infobenunum: {
    //       ...state.infobenunum,
    //       [action.int_id]: state.infobenunum[action.int_id].map((row) => {
    //         if (row.benn_id == action.benn_id) {
    //           return { ...row, ...action.changes, __dirty: true }
    //         }
    //         return row
    //       })
    //     }
    //   }
    case EDIT_BENUNUM:
      let foundbenu = true
      foundbenu = state.infobenunum[action.int_id].find(
        (r) => r.benn_id == action.benn_id
      )
      // console.log("HIER", foundbenu)
      if (!foundbenu) {
        return {
          ...state,
          infobenunum: {
            ...state.infobenunum,
            [action.int_id]: [
              ...state.infobenunum[action.int_id],
              {
                benn_id: action.benn_id,
                int_id: action.int_id,
                __new: true,
                ...action.changes
              }
            ]
          }
        }
      }
      return {
        ...state,
        infobenunum: {
          ...state.infobenunum,
          [action.int_id]: state.infobenunum[action.int_id].map((row) => {
            if (row.benn_id == action.benn_id) {
              // console.log(row.benn_id, action.benn_id, row)
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case LOAD_INFOBENUNUMDEF:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infobenunumdef: action.data
          }
        case ERROR:
          return state
      }
      break
    case LOAD_INFOBENUCHADEF:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infobenuchadef: action.data
          }
        case ERROR:
          return state
      }
      break
    case LOAD_INFOBENUCHA:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            infobenucha: { ...state.infobenucha, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_INFOBENUCHA:
      if (action.status == SUCCESS) {
        return {
          ...state,
          infobenucha: {
            ...state.infobenucha,
            [action.int_id]: state.infobenucha[action.int_id].map((row) => {
              if (row.benc_id == action.data.benc_id) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    // case EDIT_BENUCHA:
    //   return {
    //     ...state,
    //     infobenucha: {
    //       ...state.infobenucha,
    //       [action.int_id]: state.infobenucha[action.int_id].map((row) => {
    //         if (row.benc_id == action.benc_id) {
    //           return { ...row, ...action.changes, __dirty: true }
    //         }
    //         return row
    //       })
    //     }
    //   }
    case EDIT_BENUCHA:
      let foundbech = true
      foundbech = state.infobenucha[action.int_id].find(
        (r) => r.benc_id == action.benc_id
      )
      if (!foundbech) {
        return {
          ...state,
          infobenucha: {
            ...state.infobenucha,
            [action.int_id]: [
              ...state.infobenucha[action.int_id],
              {
                benc_id: action.benc_id,
                int_id: action.int_id,
                __new: true,
                ...action.changes
              }
            ]
          }
        }
      }
      return {
        ...state,
        infobenucha: {
          ...state.infobenucha,
          [action.int_id]: state.infobenucha[action.int_id].map((row) => {
            if (row.benc_id == action.benc_id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case UPDATE_GEKLASTAMM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          gekladata: {
            ...state.gekladata,
            [action.int_id]: state.gekladata[action.int_id].map((row) => {
              if (row.num_key == action.data.num_key) {
                return action.data
              }
              return row
            })
            // [action.int_id]: [...state.gekladata[action.int_id]]
          }
        }
      }
      return state
    case UPDATE_PZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          pzuorddata: {
            ...state.pzuorddata,
            [action.int_id]: state.pzuorddata[action.int_id]
              .map((row) => {
                // console.log(row.ab_datum)
                // console.log(action.ab_datum)
                // if (row.ab_datum == action.data.ab_datum) {
                if (row.ab_datum == action.ab_datum) {
                  // console.log("war hier")
                  return action.data
                }
                return row
              })
              .filter((r) => r !== null)
          }
        }
      }
      return state
    case UPDATE_PZUORDVONBIS:
      if (action.status == SUCCESS) {
        if (action.data === null) {
          let nState = { ...state.pzuordvonbis[action.int_id] }
          delete nState[Datetime.fromISO(action.ab_datum).day()]
          return {
            ...state,
            pzuordvonbis: {
              ...state.pzuordvonbis,
              [action.int_id]: nState
            }
          }
        }
        return {
          ...state,
          pzuordvonbis: {
            ...state.pzuordvonbis,
            [action.int_id]: {
              ...state.pzuordvonbis[action.int_id],
              [Datetime.fromISO(action.ab_datum).day()]: action.data
              // [action.day]: action.data
            }
          }
        }
      }
      return state
    case UPDATE_QUALI:
    case INSERT_QUALI:
      if (action.status == SUCCESS) {
        return {
          ...state,
          qualidata: {
            ...state.qualidata,
            [action.int_id]: state.qualidata[action.int_id].map((row) => {
              if (row.ab_datum == action.data.ab_datum) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    case UPDATE_SBHISTAMM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          sbhidata: {
            ...state.sbhidata,
            [action.int_id]: state.sbhidata[action.int_id].map((row) => {
              if (row.ab_datum == action.data.ab_datum) {
                return action.data
              }
              return row
            })
            // [action.int_id]: [...state.sbhidata[action.int_id]]
          }
        }
      }
      return state
    case UPDATE_AKTESTAMM:
      // if (action.status == SUCCESS) {
      return {
        ...state,
        aktedata: {
          ...state.aktedata,
          //       [action.int_id]: state.aktedata[action.int_id].map(row => {
          //         if (row.doc_id == action.data.doc_id) {
          //           return action.data
          //         }
          //         return row
          //       })
          [action.int_id]: [...state.aktedata[action.int_id]]
          //     }
        }
      }
    // return state
    case UPDATE_ADRSSTAMM:
      return {
        ...state,
        adrsdata: {
          ...state.adrsdata,
          [action.int_id]: action.data
        }
      }
    case EDIT_PSTAMM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.int_id]: {
            ...state.data[action.int_id],
            [action.ab_datum]: {
              ...state.data[action.int_id][action.ab_datum],
              ...action.changes,
              __dirty: true
            }
          }
        }
      }
    case EDIT_ABKOSTAMM:
      return {
        ...state,
        abkodata: {
          ...state.abkodata,
          [action.int_id]: state.abkodata[action.int_id].map((row) => {
            if (row.l_nbr == action.l_nbr && row.ab_datum == action.ab_datum) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case ADD_ABKOSTAMM_ROW:
      var lnbrs = state.abkodata[action.int_id].map((r) => r.l_nbr)
      var nextlnbr = Math.max(...lnbrs) + 1
      return {
        ...state,
        abkodata: {
          ...state.abkodata,
          [action.int_id]: [
            ...state.abkodata[action.int_id],
            {
              __new: true,
              int_id: action.int_id,
              ab_datum: state.datum,
              l_nbr: nextlnbr,
              prz_kk_1: 0,
              h_abt_id: "",
              abt_id: "",
              u_abt_id: "",
              kost_id: ""
            }
          ]
        }
      }
    case DELETE_ABKOSTAMM_ROW:
      return {
        ...state,
        abkodata: {
          ...state.abkodata,
          [action.int_id]: state.abkodata[action.int_id].map((row) => {
            // if (row.l_nbr == action.l_nbr) {  // 1.Zeile kann nur geändert werden
            if (row.l_nbr == action.l_nbr && action.l_nbr !== 1) {
              return { ...row, __deleted: true }
            }
            // if (row.l_nbr == action.l_nbr && action.l_nbr == 1) {
            //   return { ...row, __trydeleted: true }
            // }
            return row
          })
        }
      }

    case EDIT_INFOSTAMM:
      let found = true
      found = state.infodata[action.int_id].find(
        (r) => r.ktkt_art == action.ktkt_art
      )
      // console.log(found)
      if (!found) {
        return {
          ...state,
          infodata: {
            ...state.infodata,
            [action.int_id]: [
              ...state.infodata[action.int_id],
              {
                ktkt_art: action.ktkt_art,
                int_id: action.int_id,
                __new: true,
                ...action.changes
              }
            ]
          }
        }
      }
      return {
        ...state,
        infodata: {
          ...state.infodata,
          [action.int_id]: state.infodata[action.int_id].map((row) => {
            if (row.ktkt_art == action.ktkt_art) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case EDIT_GEKLASTAMM:
      return {
        ...state,
        gekladata: {
          ...state.gekladata,
          [action.int_id]: state.gekladata[action.int_id].map((row) => {
            if (row.num_key == action.num_key) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }

    case EDIT_PZUORD:
      return {
        ...state,
        pzuorddata: {
          ...state.pzuorddata,
          [action.int_id]: state.pzuorddata[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case EDIT_PZUORDVONBIS:
      return {
        ...state,
        pzuordvonbis: {
          ...state.pzuordvonbis,
          [action.int_id]: {
            ...state.pzuordvonbis[action.int_id],
            [action.day]: {
              ...(state.pzuordvonbis[action.int_id][action.day] || {
                int_id: parseInt(action.int_id),
                ab_datum: action.datum,
                kz_ztmod: "T",
                __new: true
              }),
              ...action.changes,
              __dirty: true
            }
          }
        }
      }

    case DELETE_GEKLASTAMM_ROW:
      return {
        ...state,
        gekladata: {
          ...state.gekladata,
          [action.int_id]: state.gekladata[action.int_id].map((row) => {
            if (row.num_key == action.num_key) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }

    case ADD_GEKLASTAMM_ROW:
      return {
        ...state,
        gekladata: {
          ...state.gekladata,
          [action.int_id]: [
            ...state.gekladata[action.int_id],
            {
              __new: true,
              int_id: parseInt(action.int_id),
              ab_datum: Datetime.now().startOf("day").toISO(),
              lst_upd: Datetime.now().toISO()
            }
          ]
        }
      }

    case ADD_PZUORD_ROW:
      return {
        ...state,
        pzuorddata: {
          ...state.pzuorddata,
          [action.int_id]: [
            // ...(state.pzuorddata[action.int_id] || []),
            {
              __new: true,
              int_id: parseInt(action.int_id),
              ab_datum: Datetime.fromISO(action.ab_datum).toISO(),
              kz_ztmod: "",
              zm_tg: "",
              lst_upd: null
            },
            ...(state.pzuorddata[action.int_id] || [])
          ]
        }
      }

    case DELETE_PZUORD_ROW:
      return {
        ...state,
        pzuorddata: {
          ...state.pzuorddata,
          [action.int_id]: state.pzuorddata[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
    case DELETED_PZUORD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          pzuorddata: {
            ...state.pzuorddata,
            [action.int_id]: state.pzuorddata[action.int_id].filter((row) => {
              if (row.ab_datum == action.ab_datum) {
                return false
              }
              return true
            })
          }
        }
      }
      return state

    case EDIT_QUALI:
      return {
        ...state,
        qualidata: {
          ...state.qualidata,
          [action.int_id]: state.qualidata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case DELETE_QUALI_ROW:
      return {
        ...state,
        qualidata: {
          ...state.qualidata,
          [action.int_id]: state.qualidata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }

    case ADD_QUALI_ROW:
      return {
        ...state,
        qualidata: {
          ...state.qualidata,
          [action.int_id]: [
            ...state.qualidata[action.int_id],
            {
              __new: true,
              id: new Date().valueOf() + "_t",
              int_id: parseInt(action.int_id),
              ab_datum: Datetime.now().startOf("day").toISO(),
              quali_id: 0,
              is_primary: false,
              lst_upd: Datetime.now().toISO()
              // aen_usr: action.user
            }
          ]
        }
      }
    case ADD_SBHISTAMM_ROW:
      return {
        ...state,
        sbhidata: {
          ...state.sbhidata,
          [action.int_id]: [
            ...state.sbhidata[action.int_id],
            {
              __new: true,
              int_id: action.int_id,
              ab_datum: Datetime.now().startOf("day").toISO(),
              sbh_grd: 0,
              sbh_glst: "",
              ausst_beh: "",
              akt_zchn: "",
              lst_upd: Datetime.now().toISO()
            }
          ]
        }
      }
    case EDIT_SBHISTAMM:
      return {
        ...state,
        sbhidata: {
          ...state.sbhidata,
          [action.int_id]: state.sbhidata[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case DELETE_SBHISTAMM_ROW:
      return {
        ...state,
        sbhidata: {
          ...state.sbhidata,
          [action.int_id]: state.sbhidata[action.int_id].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }

    case EDIT_AKTESTAMM:
      return {
        ...state,
        aktedata: {
          ...state.aktedata,
          [action.int_id]: state.aktedata[action.int_id].map((row) => {
            if (row.doc_id == action.doc_id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case ADD_AKTESTAMM_ROW:
      return {
        ...state,
        aktedata: {
          ...state.aktedata,
          [action.int_id]: [
            ...state.aktedata[action.int_id],
            {
              __new: true,
              int_id: action.int_id,
              doc_dat: new Date(),
              //lst_upd: new Date(),
              doc_bez: ""
              // doc_id: "_" + new Date().getTime()
            }
          ]
        }
      }
    case EDIT_ADRSSTAMM:
      return {
        ...state,
        adrsdata: {
          ...state.adrsdata,
          [action.int_id]: {
            ...state.adrsdata[action.int_id],
            ...action.changes,
            __dirty: true
          }
        }
      }
    case LOAD_PSTAMM:
      switch (action.status) {
        case STARTED:
          return { ...state, datum: action.datum, isLoading: true, error: null }
        case SUCCESS:
          var pstamm = arrayToObject(action.data, "ab_datum")
          var abDatums = Object.keys(pstamm)
          var currentDatum = abDatums[abDatums.length - 1]
          return {
            ...state,
            datum: currentDatum,
            data: { ...state.data, [action.int_id]: pstamm },
            isLoading: false,
            error: null
          }
        case ERROR:
          return {
            ...state,
            datum: action.datum,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_ABKOSTAMM:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            abkodatum: action.abkodatum,
            isLoading: true,
            error: null
          }
        case SUCCESS:
          let prevData = state.abkodata[action.int_id] || []
          let newRows = prevData.filter((r) => {
            return r.__new === true
          })
          return {
            ...state,
            abkodatum: currentDatum,
            abkodata: {
              ...state.abkodata,
              [action.int_id]: [...action.data, ...newRows]
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return {
            ...state,
            abkodatum: action.abkodatum,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_ABKOKOMB:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            abkomandid: action.abkomandid,
            abkofirmid: action.abkofirmid,
            isLoading: true,
            error: null
          }
        case SUCCESS:
          return {
            ...state,
            abkomandid: 1,
            abkofirmid: 1,
            abkokombdata: action.data,
            isLoading: false,
            error: null
          }
        case ERROR:
          return {
            ...state,
            abkomandid: action.abkomandid,
            abkofirmid: action.abkofirmid,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_INFOSTAMM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            infodata: { ...state.infodata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_PZUORD:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pzuorddata: {
              ...state.pzuorddata,
              [action.int_id]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_PZUORDVONBIS:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pzuordvonbis: {
              ...state.pzuordvonbis,
              [action.int_id]: arrayToObjectFn(action.data || [], (o) =>
                parseInt(o.ab_datum.slice(8, 10))
              )
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_ADRSSTAMM:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            adrsdata: { ...state.adrsdata, [action.int_id]: action.data }
          }
        case ERROR:
          return state
      }
      break
    case LOAD_GEKLASTAMM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            gekladata: { ...state.gekladata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_QUALI:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            qualidata: { ...state.qualidata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_SBHISTAMM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            sbhidata: { ...state.sbhidata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_AKTESTAMM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            aktedata: { ...state.aktedata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_PFLICHT:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            pflicht: action.data
          }
        case ERROR:
          return state
      }
      break
  }

  return state
}
const calcWo = (row) => {
  return row.arb_zt_tgl * row.tage_wo
}
const calc4Wo = (row) => {
  return row.arb_zt_tgl * row.tage_wo * 4.0
}
const calcMtl = (row) => {
  return row.arb_zt_tgl * row.tage_wo * 4.348
}
const calcMo = (row) => {
  if (row.tage_wo >= 1) {
    return row.arb_zt_tgl
  }
  return null
}

const calcWD = (row, wd) => {
  if (row.tage_wo >= wd) {
    return row.arb_zt_tgl
  }
  return null
}
