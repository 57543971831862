export const GetVorgaben = (state) => {
  return state.UPL.Vorgaben
}

export const DefaultVorgabe = (u_abt_id, year, quali_id) => ({
  sonderbesetzung: [],
  besetzung: [],
  planstellen: 0,
  year: year,
  abt_id: u_abt_id,
  sektion_id: 0,
  quali_id: parseInt(quali_id),
  vk_wert: 0,
  ausfall_quote: 0,
  calc_vk: 0,
  daymode: false
})

export const GetVorgabe = (state, u_abt_id, year, quali_id) => {
  if (!quali_id || !u_abt_id || !year) {
    return null
  }
  const vorgaben = GetVorgaben(state)
  const vorgabeUAbtID = vorgaben[u_abt_id]
  if (!vorgabeUAbtID) {
    return DefaultVorgabe(u_abt_id, year, quali_id)
  }
  const vorgabeYear = vorgabeUAbtID[year]
  if (!vorgabeYear) {
    return DefaultVorgabe(u_abt_id, year, quali_id)
  }
  const vorgabeQuali = vorgabeYear[quali_id]
  return vorgabeQuali || DefaultVorgabe(u_abt_id, year, quali_id)
}

export const AvailableQualis = (state, u_abt_id, year) => {
  const vorgaben = GetVorgaben(state)
  const vorgabeUAbtID = vorgaben[u_abt_id]
  if (!vorgabeUAbtID) {
    return []
  }
  const vorgabeYear = vorgabeUAbtID[year]
  if (!vorgabeYear) {
    return []
  }
  return Object.keys(vorgabeYear)
}

export const DirtyVorgaben = (state) => {
  const vorgaben = GetVorgaben(state)
  let result = []
  for (let byAbtID of Object.values(vorgaben)) {
    for (let byYear of Object.values(byAbtID)) {
      for (let vorgabe of Object.values(byYear)) {
        if (vorgabe.__dirty) {
          result.push(vorgabe)
        }
      }
    }
  }
  return result
}

export const HasUnSavedChanges = (state) => {
  const dirty = DirtyVorgaben(state)
  if (dirty.length > 0) {
    return true
  }
  return false
}

export const GetCopiedVorgabe = (state) => {
  return state.UPL.VorgabenCopyPaste
}
