import { LOAD_VERSION, LOAD_VERSION_STARTED, LOAD_VERSION_SUCCESS, LOAD_VERSION_ERROR } from "./version_actions"
export function Version(state = {}, action) {
  switch (action.type) {
    case LOAD_VERSION:
      switch (action.status) {
        case LOAD_VERSION_STARTED:
          return {}
        case LOAD_VERSION_ERROR:
          return {}
        case LOAD_VERSION_SUCCESS:
          return action.version
      }
  }
  return state
}
