import request from "superagent"
export const SET_YEAR = "dpl/SET_YEAR"
export const SET_MONTH = "dpl/SET_MONTH"
export const SET_DATE = "dpl/SET_DATE"
export const SET_LOADED = "dpl/SET_LOADED"
export const LOADED_FEIERTAGE = "dpl/LOADED_FEIERTAGE"
export const REQUEST_DATE = "dpl/REQUEST_DATE"
import { SUCCESS, ERROR } from "app/state/constants"
import { LoadAllData, LoadFzManualRed } from "./records"
import { LoadSollZeit, LoadVVa } from "./fehlzeiten"

import { LoadVorgaben } from "./taetigkeiten"
import { LoadRbFolgeTag } from "./macros"
import { LoadAllPers, LoadUnverplant } from "./pers"
import { LoadGenehmigungStatus } from "./genehmigung"
import { LoadSalden } from "./salden"
import { LoadPlaceHolders } from "./placeholder"
import Datetime from "app/shared/dateutil"

export function SetDate(date) {
  return (dispatch) => {
    dispatch({
      type: SET_MONTH,
      year: Datetime.fromISO(date).year(),
      month: Datetime.fromISO(date).month(),
      silent: true
    })
    dispatch({
      type: SET_YEAR,
      year: Datetime.fromISO(date).year(),
      month: Datetime.fromISO(date).month(),
      silent: true
    })
    dispatch({ type: SET_DATE, date })
  }
}

var loadAllTimeOut = null

export function RequestDate(date) {
  return (dispatch) => {
    dispatch({ type: REQUEST_DATE, date })
    clearTimeout(loadAllTimeOut)
    loadAllTimeOut = setTimeout(() => {
      dispatch(LoadAll())
    }, 300)
  }
}

export function SetMonth(month) {
  return (dispatch, getState) => {
    let year = getState().Dpl.Datum.year
    dispatch({
      type: SET_MONTH,
      month,
      year
    })
    dispatch(LoadFeiertage())
  }
}

export function SetYear(year) {
  return (dispatch, getState) => {
    let month = getState().Dpl.Datum.month
    dispatch({
      type: SET_YEAR,
      year,
      month
    })
    dispatch(LoadFeiertage())
  }
}

export function LoadFeiertageSuccess(date, bis, ftg) {
  return {
    type: LOADED_FEIERTAGE,
    status: SUCCESS,
    ftg,
    date,
    bis
  }
}

export function LoadFeiertageError(date, bis, err) {
  return {
    type: LOADED_FEIERTAGE,
    status: ERROR,
    err,
    date,
    bis
  }
}

export const GetVonBis = (state) => {
  const start = Datetime.fromISO(state.Dpl.Datum.date).startOf("month")
  const end = Datetime.fromISO(state.Dpl.Datum.bis)
    .endOf("month")
    .startOf("day") //.toISODate()
  const loadedVon = Datetime.fromISO(state.Dpl.Datum.loadedVon)
  const loadedBis = Datetime.fromISO(state.Dpl.Datum.loadedBis)
  let loadVon = start
  let loadBis = end
  let setLoadedVon = start
  let setLoadedBis = end
  if (loadedVon.isValid() && loadedBis.isValid()) {
    if (start >= loadedVon && end <= loadedBis) {
      return { loadVon, loadBis, setLoadedVon, setLoadedBis, count: 0 }
    }
    if (start < loadedVon) {
      if (end > loadedVon) {
        loadBis = loadedVon
      }
    }
    if (end > loadedBis) {
      if (start < loadedBis) {
        loadVon = loadedBis
      }
    }
  }
  const count = loadVon.diffDays(loadBis)
  return { loadVon, loadBis, setLoadedVon, setLoadedBis, count }
}

export function getVonBis(datum) {
  let dispVon = Datetime.fromISO(datum.date).startOf("month")
  let dispBis = Datetime.fromISO(datum.bis).endOf("month")
  let loadedVon = Datetime.fromISO(datum.loadedVon)
  let loadedBis = Datetime.fromISO(datum.loadedBis)
  if (!loadedVon.isValid() || !loadedBis.isValid()) {
    return [dispVon, dispBis]
  }

  let von, bis
  if (dispBis > loadedBis) {
    bis = dispBis
    von = loadedVon //loadedBis.plus({ days: 1 })
  } else {
    bis = loadedVon
    von = dispVon
  }
  if (bis > dispBis) {
    bis = dispBis
  }
  if (von < dispVon) {
    von = dispVon
  }

  return [von, bis]
}

var loadAllTimeOut = null

export function LoadAll() {
  return (dispatch, getState) => {
    clearTimeout(loadAllTimeOut)
    setTimeout(() => {
      const { loadVon, loadBis, setLoadedVon, setLoadedBis, count } = GetVonBis(
        getState()
      )
      const datum = getState().Dpl.Datum

      const PlanID = getState().Dpl.Plaene.selected
      if (PlanID == null) {
        return
      }
      if (count == 0) {
        return
      }
      if (
        loadVon.valueOf() == Datetime.fromISO(datum.loadedVon).valueOf() &&
        loadBis.valueOf() ==
          Datetime.fromISO(datum.loadedBis).endOf("day").valueOf()
      ) {
        return
      }
      dispatch(LoadAllPers(PlanID, loadVon.toISO(), loadBis.toISO()))
      dispatch(
        LoadSalden(
          PlanID,
          Datetime.fromISO(datum.date).year(),
          Datetime.fromISO(datum.date).month()
        )
      )
      dispatch(
        LoadSollZeit(
          PlanID,
          loadVon.toFormat("yyyy-MM-dd"),
          loadBis.toFormat("yyyy-MM-dd")
        )
      )
      dispatch(
        LoadVVa(
          PlanID,
          loadVon.toFormat("yyyy-MM-dd"),
          loadBis.toFormat("yyyy-MM-dd")
        )
      )
      dispatch(LoadFzManualRed(loadVon.toISO(), loadBis.toISO()))
      dispatch(LoadRbFolgeTag(PlanID, loadVon.toISO()))
      let sVon = loadVon
      while (sVon < loadBis) {
        let year = sVon.year()
        let month = sVon.month() //- 1
        dispatch(LoadGenehmigungStatus(PlanID, year, month))
        dispatch(LoadUnverplant(PlanID, sVon.toISO()))
        sVon = sVon.plus({ months: 1 })
      }

      Promise.all([
        dispatch(LoadVorgaben(PlanID, loadVon.toISO(), loadBis.toISO())),
        dispatch(LoadPlaceHolders(loadVon.toISO(), loadBis.toISO())),
        dispatch(LoadAllData(loadVon.toISO(), loadBis.toISO())),
        dispatch(LoadFeiertage(loadVon.toISO(), loadBis.toISO()))
      ]).then(() => {
        dispatch({
          type: SET_LOADED,
          von: setLoadedVon.toISO(),
          bis: setLoadedBis.toISO()
        })
      })
    }, 500)
  }
}

export function LoadFeiertage(von, bis) {
  return (dispatch) => {
    return request
      .get(
        `/api/dpl/basis/feiertage/${Datetime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${Datetime.fromISO(bis).toFormat("yyyy-MM-dd")}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch(LoadFeiertageSuccess(von, bis, response.body))
        } else {
          dispatch(LoadFeiertageError(von, bis, err))
        }
      })
  }
}
