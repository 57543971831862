export const SELECT_DPL_CELL = "dpl/SELECT_DPL_CELL"
export const CLEAR_SELECTION = "dpl/CLEAR_SELECTION"
export const SELECT_TPL_CELL = "dpl/SELECT_TPL_CELL"
export const CLEAR_TPL_SELECTION = "dpl/CLEAR_TPL_SELECTION"
export const SWITCH_DPL_NUM = "dpl/SWITCH_DPL_NUM"
import { RecordsByIntIDDatum } from "../selectors/records"
import { UTCNow } from "app/shared/dates"
import Datetime from "../../shared/dateutil/index"

export function SwitchDplNum(row, column, index) {
  return {
    type: SWITCH_DPL_NUM,
    row,
    column,
    index
  }
}

export function SelectCell(row, column, kz, extend) {
  return (dispatch, getState) => {
    var PlanID = getState().Dpl.Plaene.selected
    if (PlanID != 0 && kz != "W") {
      var allowed = getState().Permissions[`dpl/plan/plan_id/${PlanID}`]
      if (allowed < 2) {
        return
      }
    }
    if (!canSelect(getState(), row, column, kz)) {
      return
    }
    if (extend) {
      dispatch(SelectRange(row, column, kz))
    }

    dispatch({
      type: SELECT_DPL_CELL,
      row,
      column,
      kz,
      extend
    })
  }
}

export function SelectTplCell(row, column, nbr, extend, clear) {
  return (dispatch) => {
    if (clear) {
      dispatch({
        type: CLEAR_TPL_SELECTION
      })
    }

    dispatch({
      type: SELECT_TPL_CELL,
      row,
      column,
      nbr,
      extend
    })
  }
}

function SelectRange(row, column, kz) {
  return (dispatch, getState) => {
    let state = getState().Dpl.Selection
    let closest = 9999
    let ncolumn = null
    const dateFromColumn = Datetime.fromISO(column).toJSDate()
    for (let key of Object.keys(state)) {
      let [krow, year, month, day, kkz] = key.split("-")
      let diff = 9999
      krow = parseInt(krow) || krow
      year = parseInt(year)
      month = parseInt(month)
      day = parseInt(day)

      let d = new Date(Date.UTC(year, month - 1, day))
      if (row == krow && kkz == kz) {
        diff = Math.abs(
          Datetime.fromJSDate(d).diffDays(Datetime.fromJSDate(dateFromColumn))
        )
        if (diff < closest) {
          ncolumn = d
          closest = diff
        }
      }
    }
    if (ncolumn != null) {
      let lower, upper
      if (ncolumn < dateFromColumn) {
        lower = ncolumn
        upper = dateFromColumn
      } else {
        lower = dateFromColumn
        upper = ncolumn
      }
      let c = lower
      while (c < upper) {
        if (
          !canSelect(getState(), row, Datetime.fromJSDate(c).toISODate(), kz)
        ) {
          c = Datetime.fromJSDate(c).plus({ days: 1 }).toJSDate()
          continue
        }
        dispatch({
          type: SELECT_DPL_CELL,
          row,
          column: Datetime.fromJSDate(c).toISODate(),
          kz,
          extend: true
        })
        c = Datetime.fromJSDate(c).plus({ days: 1 }).toJSDate()
      }
    }
  }
}

export function ClearSelection() {
  return {
    type: CLEAR_SELECTION
  }
}

export function isReadOnly(state) {
  let planID = state.Dpl.Plaene.selected
  if (planID == 0) {
    return false
  }
  let permissionKey = `dpl/plan/plan_id/${planID}`
  let permission = state.Permissions[permissionKey]
  if (permission < 2) {
    return true
  }
  return false
}

export function CheckWunschPermission(state) {
  let planID = state.Dpl.Plaene.selected
  let permissionKey = `dpl/wuensche/plan_id/${planID}`
  let permission = state.Permissions[permissionKey]
  if (permission !== undefined && permission >= 2) {
    return true
  }
  return false
}

export function CheckWunschSperre(state, column) {
  let sperren = state.Dpl.UI.sperren[0]
  if (sperren) {
    if (Datetime.fromISO(column) < Datetime.fromISO(sperren)) {
      return true
    }
  }
}

export function IsOwnRow(state, row) {
  let intid = state.Dpl.AllPers.pers[row].int_id
  let ownIntID = state.currentUser.user.int_id
  if (intid != ownIntID) {
    return false
  } else {
    return true
  }
}

export function IsInfoUser(state) {
  return state.currentUser.user.user_type == "info"
}

export function IsLockByPassed(state) {
  return state.Dpl.Lock.byPass
}

export function IsIstZeile(kz) {
  return kz == "I"
}

export function IsSollZeile(kz) {
  return kz == "S"
}

export function IsPlanZeile(kz) {
  return kz == "P"
}

export function IsBereitschaftsZeile(kz) {
  return kz == "B"
}

export function HasSatz(state, row, column, dprb, kz) {
  const satz = getSatzForRowColumnKz(state, row, column, dprb, kz)
  return (satz && satz.dpl_symb && true) || false
}

export function HasSollSatz(state, row, column) {
  return HasSatz(state, row, column, "dp", "S")
}

export function HasPlanSatz(state, row, column) {
  return HasSatz(state, row, column, "dp", "P")
}

export function HasIstSatz(state, row, column) {
  return HasSatz(state, row, column, "dp", "I")
}

export function HasVorbelegung(state, row, column) {
  return getPlaceHoldersForIntIDColumn(state, row, column) != ""
}

export function CheckGenehmigungBegonnen(state, column) {
  let [year, month] = column.slice(0, 7).split("-")
  year = parseInt(year)
  month = parseInt(month)
  let key = `${year}-${month}`
  if (!state.Dpl.Genehmigung[key] || state.Dpl.Genehmigung[key].current_stufe) {
    return true
  }
  return false
}

export function IsSatzLocked(state, row, column) {
  let satz = getSatzForRowColumnKz(state, row, column, "dp", "S")
  if (satz && satz.locked) {
    return true
  }
  return false
}

export function canSelect(state, row, column, kz) {
  if (isReadOnly(state)) {
    return false
  }
  if (kz == "W") {
    if (CheckWunschPermission(state)) {
      return true
    }
    if (CheckWunschSperre(state, column)) {
      return false
    }
    return IsOwnRow(state, row)
  } else {
    if (IsInfoUser(state)) {
      return false
    }
  }

  if (IsLockByPassed(state)) {
    return true
  }

  if (IsIstZeile(kz) || IsBereitschaftsZeile(kz)) {
    if (
      !HasSollSatz(state, row, column) &&
      !HasVorbelegung(state, row, column)
    ) {
      return false
    }
  } else if (IsPlanZeile(kz)) {
    if (
      !HasSollSatz(state, row, column) &&
      !HasVorbelegung(state, row, column)
    ) {
      return false
    }
    if (!isInFuture(state, column)) {
      return false
    }
  } else if (kz == "S") {
    if (isPeriodeBegonnen(state, column)) {
      return false
    }
    if (CheckGenehmigungBegonnen(state, column)) {
      return false
    }
    if (IsSatzLocked(state, row, column)) {
      return false
    }
  }
  return true
}

function isInFuture(state, column) {
  let planZeileBack = (parseInt(state.Dpl.UI.planZeileBack) || 1) - 1
  let now = UTCNow()
  let today = Datetime.fromJSDate(now).plus({ days: -planZeileBack })
  let ret = Datetime.fromISO(column) >= today
  return ret
}

/*
 * Ermittelt den Satz für Row/Column/Kz
 *
 */
function getSatzForRowColumnKz(state, row, column, dprb, kz) {
  let Records = RecordsByIntIDDatum(state)[`${row}-${column}-${dprb}-${kz}`]
  if (!Records || Records.length == 0) {
    return null
  }
  return Records[0]
}

/*
 * ermittelt vorbelegung für int_id/column
 */
export function getPlaceHoldersForIntIDColumn(state, IntID, Column) {
  return state.Dpl.PlaceHolders[`${IntID}-${Column}`] || ""
}

/*
 * ermittelt Fehlzeit für row/column
 */
function getFehlzIDForRowColumn(state, row, column) {
  let Record = state.Dpl.Records.kalender[`${row}-${column}`]
  if (!Record) {
    return ""
  }
  return Record.v || Record.n
}

/*
 * Prüft ob aktuelle Periode begonnen ist
 */
function isPeriodeBegonnen(state, datum) {
  datum = Datetime.fromISO(datum).startOf("month").toJSDate()
  return datum <= new Date()
}
