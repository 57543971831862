import request from "superagent"

export const LOADED_NO_COUNT_FZ = "dpl/LOADED_NO_COUNT_FZ"

export function LoadNoCountFz() {
  return dispatch => {
    request.get("/api/dpl/group/nocount/fz").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOADED_NO_COUNT_FZ,
          value: response.body
        })
      }
    })
  }
}
