import { FETCH_DROPDOWN, CLEAR_DROPDOWNS } from "./actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

import { arrayToObject } from "app/shared/util/conversion"
export function DropDown(state = {}, action) {
  switch (action.type) {
    case CLEAR_DROPDOWNS:
      return {}
    case FETCH_DROPDOWN:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            [action.key]: {
              data: action.data || [],
              byKey: arrayToObject(action.data, "value"),
              isFetching: false,
              error: false
            }
          }
        case ERROR:
          return {
            ...state,
            [action.key]: {
              data: [],
              byKey: {},
              isFetching: false,
              error: state.error
            }
          }
        case STARTED:
          return {
            ...state,
            [action.key]: state[action.key] || {
              data: [],
              byKey: {},
              isFetching: true,
              error: false
            }
          }
      }
  }
  return state
}
