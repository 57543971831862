import { LOAD_ZEITRAUM, LOAD_WUNSCH_ZEITRAUM } from "../actions/zeitraum"

export const Zeitraum = (state = [], action) => {
  switch (action.type) {
    case LOAD_ZEITRAUM:
      return action.data
  }
  return state
}

export const Wunschzeitraum = (state = [], action) => {
  switch (action.type) {
    case LOAD_WUNSCH_ZEITRAUM:
      return action.data
  }
  return state
}
