import Datetime from "app/shared/dateutil/index"
import { Datum } from "./datum"

export const GetZeitraum = (state, datum) => {
  const zraum = GetZeitraeume(state)
  const d = Datetime.fromISO(datum)
  for (let z of [...zraum].reverse()) {
    if (
      Datetime.fromISO(z.ab_datum) <= d &&
      Datetime.fromISO(z.bis_datum) >= d
    ) {
      return z
    }
  }
  return null
}

export const GetCurrentZeitraum = (state) => {
  const datum = Datum(state)
  return GetZeitraum(state, datum)
}

export const GetWunschzeitraum = (state, datum) => {
  const zraum = GetZeitraum(state, datum)
  if (!zraum) {
    return null
  }
  const wzraum = GetWunschzeitraeume(state)
  const d = Datetime.fromISO(datum)
  for (let z of [...wzraum].reverse()) {
    if (z.zeitraum_id != zraum.id) {
      continue
    }
    if (
      Datetime.fromISO(z.ab_datum) <= d &&
      Datetime.fromISO(z.bis_datum) >= d
    ) {
      return z
    }
  }
  return null
}

export const GetZeitraeume = (state) => state.UPL.Zeitraum
export const GetWunschzeitraeume = (state) => state.UPL.Wunschzeitraum
