import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_MITARBEITER_INFOS = "LOAD_MITARBEITER_INFOS"
export const LOAD_MA_URL_ANSPRUCH = "LOAD_MA_URL_ANSPRUCH"

export function LoadMitarbeiterInfosStarted(int_id) {
  return {
    type: LOAD_MITARBEITER_INFOS,
    status: STARTED,
    int_id
  }
}

export function LoadMitarbeiterInfosSuccess(int_id, infos) {
  return {
    type: LOAD_MITARBEITER_INFOS,
    status: SUCCESS,
    int_id,
    infos
  }
}

export function LoadMitarbeiterInfosError(int_id, error) {
  return {
    type: LOAD_MITARBEITER_INFOS,
    status: ERROR,
    _handleInApp: true,
    int_id,
    error
  }
}

export function LoadMitarbeiterInfos(int_id) {
  return (dispatch, getState) => {
    if (getState().Mitarbeiter.Infos[int_id]) {
      return
    }
    dispatch(LoadMitarbeiterInfosStarted(int_id))
    request.get(`/api/p/${int_id}/infos`).end((err, response) => {
      if (err) {
        dispatch(LoadMitarbeiterInfosError(int_id, err))
      } else {
        dispatch(LoadMitarbeiterInfosSuccess(int_id, response.body))
      }
    })
  }
}
