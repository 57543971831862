import {
  LOAD_DIENST_QUALI,
  EDIT_DIENST_QUALI,
  ADD_DIENST_QUALI,
  REMOVE_DIENST_QUALI,
  SAVE_DIENST_QUALI,
  DELETED_DIENST_QUALI
} from "../actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

import { v4 as uuidv4 } from "uuid"

export function Quali(state = {}, action) {
  var nstate
  switch (action.type) {
    case LOAD_DIENST_QUALI:
      switch (action.status) {
        case SUCCESS:
          return { ...state, [action.DienstID]: action.data }
        case ERROR:
          return state
        case STARTED:
          return state
      }
      return state
    case EDIT_DIENST_QUALI:
      return {
        ...state,
        [action.DienstID]: [
          ...state[action.DienstID].map((quali) => {
            if (quali.id == action.QualiID) {
              return { ...quali, ...action.changes, __dirty: true }
            }
            return quali
          })
        ]
      }
    case REMOVE_DIENST_QUALI:
      return {
        ...state,
        [action.DienstID]: [
          ...state[action.DienstID].map((quali) => {
            if (quali.id == action.QualiID) {
              return { ...quali, __deleted: true }
            }
            return quali
          })
        ]
      }
    case ADD_DIENST_QUALI:
      return {
        ...state,
        [action.DienstID]: [
          ...state[action.DienstID],
          {
            id: uuidv4(),
            dienst_id: action.DienstID,
            ab_datum: "1900-01-01T00:00:00Z",
            bis_datum: null,
            __new: true
          }
        ]
      }
    case SAVE_DIENST_QUALI:
      nstate = { ...state }
      nstate[action.DienstID] = nstate[action.DienstID].map((quali) => {
        if (quali.id == action.ID) {
          return action.data
        }
        return quali
      })
      return nstate
    case DELETED_DIENST_QUALI:
      nstate = { ...state }
      nstate[action.DienstID] = nstate[action.DienstID].filter((quali) => {
        return quali.id !== action.ID
      })
      return nstate
  }
  return state
}
