import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export const LOAD_DIENST_QUALI = "dpl/LOAD_DIENST_QUALI"
export const EDIT_DIENST_QUALI = "dpl/EDIT_DIENST_QUALI"
export const ADD_DIENST_QUALI = "dpl/ADD_DIENST_QUALI"
export const REMOVE_DIENST_QUALI = "dpl/REMOVE_DIENST_QUALI"
export const SAVE_DIENST_QUALI = "dpl/SAVE_DIENST_QUALI"
export const DELETED_DIENST_QUALI = "dpl/DELETED_DIENST_QUALI"

export function AddDienstQuali(DienstID) {
  return {
    type: ADD_DIENST_QUALI,
    DienstID
  }
}
export function RemoveDienstQuali(DienstID, QualiID) {
  return {
    type: REMOVE_DIENST_QUALI,
    DienstID,
    QualiID
  }
}

export function EditDienstQuali(DienstID, QualiID, changes) {
  return {
    type: EDIT_DIENST_QUALI,
    DienstID,
    QualiID,
    changes
  }
}

export function LoadDienstQualiSuccess(DienstID, data) {
  return {
    type: LOAD_DIENST_QUALI,
    status: SUCCESS,
    DienstID,
    data
  }
}

export function LoadDienstQualiError(DienstID, err) {
  return {
    type: LOAD_DIENST_QUALI,
    status: ERROR,
    DienstID,
    err
  }
}

export function LoadDienstQualiStarted(DienstID) {
  return {
    type: LOAD_DIENST_QUALI,
    status: STARTED,
    DienstID
  }
}

export function LoadDienstQuali(DienstID) {
  return dispatch => {
    dispatch(LoadDienstQualiStarted(DienstID))
    request
      .get(`/api/dpl/basis/taetigkeiten/quali/${DienstID}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadDienstQualiError(DienstID, err))
        } else {
          dispatch(LoadDienstQualiSuccess(DienstID, response.body))
        }
      })
  }
}

export function SavedDienstQuali(DienstID, ID, data) {
  return {
    type: SAVE_DIENST_QUALI,
    DienstID,
    ID,
    data
  }
}

export function DeletedDienstQuali(DienstID, ID) {
  return {
    type: DELETED_DIENST_QUALI,
    DienstID,
    ID
  }
}

export function SaveDienstQualis() {
  return (dispatch, getState) => {
    let qualis = getState().Dpl.Quali
    for (let [DienstID, qualisPerDienst] of Object.entries(qualis)) {
      DienstID = parseInt(DienstID)
      for (let q of qualisPerDienst) {
        if (q.__new) {
          if (q.__deleted) {
            dispatch(DeletedDienstQuali(DienstID, q.id))
          } else {
            let { id, ...rest } = q
            request
              .post("/api/dpl/basis/taetigkeitsquali")
              .send(rest)
              .end((err, response) => {
                dispatch(SavedDienstQuali(DienstID, id, response.body))
              })
          }
        } else if (q.__deleted) {
          request.delete(`/api/dpl/basis/taetigkeitsquali/${q.id}`).end(() => {
            dispatch(DeletedDienstQuali(DienstID, q.id))
          })
        } else if (q.__dirty) {
          request
            .put(`/api/dpl/basis/taetigkeitsquali/${q.id}`)
            .send(q)
            .end((err, response) => {
              dispatch(SavedDienstQuali(DienstID, q.id, response.body))
            })
        }
      }
    }
  }
}
