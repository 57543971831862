import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import request from "superagent"
import swal from "sweetalert"
import { v4 as uuidv4 } from "uuid"
export const LOAD_ROLESPERM = "security/LOAD_ROLESPERM"
export const ADD_ROLESPERM_ROW = "security/ADD_ROLESPERM_ROW"
export const UPDATE_ROLESPERM = "security/UPDATE_ROLESPERM"
export const DELETE_ROLESPERM_ROW = "security/DELETE_ROLESPERM_ROW"
export const DELETED_ROLESPERM_ROW = "security/DELETED_ROLESPERM_ROW"
export const EDIT_ROLESPERM = "security/EDIT_ROLESPERM"
export const SELECT_ROLE = "security/SELECT_ROLE"
export const LOAD_ROLES = "security/LOAD_ROLES"
export const NEW_ROLE = "security/NEW_ROLE"
export const EDIT_ROLE = "security/EDIT_ROLE"
export const DELETE_ROLE_ROW = "security/DELETE_ROLE_ROW"
export const DELETED_ROLE_ROW = "security/DELETED_ROLE_ROW"
export const UPDATE_ROLE = "security/UPDATE_ROLE"
export const LOAD_ROLESREPORT = "security/LOAD_ROLESREPORT"

export function LoadRolesReportSuccess(role_id, data) {
  return {
    type: LOAD_ROLESREPORT,
    status: SUCCESS,
    role_id,
    data
  }
}

export function LoadRolesReportError(role_id, error) {
  return {
    type: LOAD_ROLESREPORT,
    status: ERROR,
    role_id,
    error
  }
}

export function LoadRolesReportStarted(role_id) {
  return {
    type: LOAD_ROLESREPORT,
    status: STARTED,
    role_id
  }
}

export function LoadRolesReport(role_id) {
  return (dispatch) => {
    dispatch(LoadRolesReportStarted())
    request.get(`/api/security/rolesreport/${role_id}`).end((err, response) => {
      if (err) {
        dispatch(LoadRolesReportError(role_id, err))
      } else {
        dispatch(LoadRolesReportSuccess(role_id, response.body))
      }
    })
  }
}

function CheckRole(role) {
  if (role.role_name == "") {
    return "bitte Rollenbezeichnung angeben"
  }
  return null
}

export function UpdateRoleStarted(role_id) {
  return {
    type: UPDATE_ROLE,
    status: STARTED,
    role_id
  }
}

export function UpdateRoleSuccess(role_id, data) {
  return {
    type: UPDATE_ROLE,
    status: SUCCESS,
    role_id,
    data
  }
}

export function UpdateRoleError(role_id, error) {
  return {
    type: UPDATE_ROLE,
    status: ERROR,
    role_id,
    error
  }
}

export function UpdateRole() {
  return (dispatch, getState) => {
    let allRoles = Object.values(getState().Security.roles.data) //[role_id] || null
    for (let data of allRoles) {
      if (data == null) {
        return
      }
      var err = CheckRole(data)
      if (err !== null) {
        swal({
          title: "Hinweis zum Speichern der Daten",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        return
      }
      let role_id = data.role_id
      let row = data
      if (row.__deleted && !row.__new) {
        dispatch(DeleteRolesRowStarted(role_id))
        request
          .delete(`/api/security/roles/${role_id}/roles`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteRolesRowError(role_id, err))
            } else {
              dispatch(DeleteRolesRowSuccess(role_id, response.body))
            }
          })
      } else if (row.__new) {
        let { role_id, ...r } = row
        dispatch(UpdateRoleStarted())
        request
          .post("/api/security/roles/new")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateRoleError(role_id, err))
            } else {
              dispatch(UpdateRoleSuccess(role_id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateRoleStarted(role_id))
        request
          .put(`/api/security/roles/${role_id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateRoleError(row.role_id, err))
            } else {
              dispatch(UpdateRoleSuccess(row.role_id, response.body))
            }
          })
      } else {
        // TODO
      }
    }
  }
}

export function RoleUnsavedChanges(state) {
  for (let roles of Object.values(state)) {
    for (let row of roles) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function DeleteRolesRowStarted(role_id) {
  return {
    type: DELETED_ROLE_ROW,
    status: STARTED,
    role_id
  }
}
export function DeleteRolesRowSuccess(role_id, id) {
  return {
    type: DELETED_ROLE_ROW,
    status: SUCCESS,
    role_id,
    id
  }
}
export function DeleteRolesRowError(role_id, error) {
  return {
    type: DELETED_ROLE_ROW,
    status: ERROR,
    role_id,
    error
  }
}

export function DeleteRolesRow(role_id, id) {
  return {
    type: DELETE_ROLE_ROW,
    role_id,
    id
  }
}

export function EditRole(role_id, changes) {
  return {
    type: EDIT_ROLE,
    role_id,
    changes
  }
}

export function NewRole() {
  return {
    type: NEW_ROLE,
    role_id: uuidv4()
  }
}

export function AddRolesPermRow(role_id) {
  return {
    type: ADD_ROLESPERM_ROW,
    role_id
  }
}

export function SelectRole(role_id) {
  return {
    type: SELECT_ROLE,
    role_id
  }
}

export function LoadRolesSuccess(data) {
  return {
    type: LOAD_ROLES,
    status: SUCCESS,
    data
  }
}

export function LoadRolesError(error) {
  return {
    type: LOAD_ROLES,
    status: ERROR,
    error
  }
}

export function LoadRolesStarted() {
  return {
    type: LOAD_ROLES,
    status: STARTED
  }
}

export function LoadRoles() {
  return (dispatch) => {
    dispatch(LoadRolesStarted())
    request.get("/api/security/roles").end((err, response) => {
      if (err) {
        dispatch(LoadRolesError(err))
      } else {
        dispatch(LoadRolesSuccess(response.body))
      }
    })
  }
}

export function LoadRolesPermSuccess(role_id, data) {
  return {
    type: LOAD_ROLESPERM,
    status: SUCCESS,
    role_id,
    data
  }
}

export function LoadRolesPermError(role_id, error) {
  return {
    type: LOAD_ROLESPERM,
    status: ERROR,
    role_id,
    error
  }
}

export function LoadRolesPermStarted(role_id) {
  return {
    type: LOAD_ROLESPERM,
    status: STARTED,
    role_id
  }
}

export function LoadRolesPerm(role_id) {
  return (dispatch) => {
    dispatch(LoadRolesPermStarted(role_id))
    request
      .get(`/api/security/roles/${role_id}/rolesperm`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadRolesPermError(role_id, err))
        } else {
          dispatch(LoadRolesPermSuccess(role_id, response.body))
        }
      })
  }
}

export function EditRolesPerm(id, changes) {
  return (dispatch, getState) => {
    let role_id = getState().Security.roles.selectedRole
    dispatch({
      type: EDIT_ROLESPERM,
      id,
      role_id,
      changes
    })
  }
}

export function DeleteRolesPermRowStarted(role_id) {
  return {
    type: DELETED_ROLESPERM_ROW,
    status: STARTED,
    role_id
  }
}
export function DeleteRolesPermRowSuccess(role_id, id) {
  return {
    type: DELETED_ROLESPERM_ROW,
    status: SUCCESS,
    role_id,
    id
  }
}
export function DeleteRolesPermRowError(role_id, error) {
  return {
    type: DELETED_ROLESPERM_ROW,
    status: ERROR,
    role_id,
    error
  }
}

export function DeleteRolesPermRow(role_id, id) {
  return {
    type: DELETE_ROLESPERM_ROW,
    role_id,
    id
  }
}

function CheckRolesPerm(rolesPerm) {
  for (let row of rolesPerm) {
    if (row.ressource_id == null) {
      return "bitte Ressource auswählen"
    }
    if (row.ressource_permission == null) {
      return "bitte Ressource-Berechtigung auswählen"
    }
    // if (row.value_permission == null) {
    //   return "bitte Werte-Berechtigung auswählen"
    // }
  }
  return null
}

export function UpdateRolesPermStarted(role_id) {
  return {
    type: UPDATE_ROLESPERM,
    status: STARTED,
    role_id
  }
}

export function UpdateRolesPermSuccess(role_id, id, data) {
  return {
    type: UPDATE_ROLESPERM,
    status: SUCCESS,
    id,
    role_id,
    data
  }
}

export function UpdateRolesPermError(role_id, id, error) {
  return {
    type: UPDATE_ROLESPERM,
    status: ERROR,
    id,
    role_id,
    error
  }
}

export function UpdateRolesPerm(role_id) {
  return (dispatch, getState) => {
    let data = getState().Security.rolesPerm[role_id] || null

    if (data == null) {
      return
    }
    var err = CheckRolesPerm(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Securitydaten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        dispatch(DeleteRolesPermRowStarted(role_id, row.id))
        request
          .delete(`/api/security/roles/${role_id}/rolesperm/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteRolesPermRowError(role_id, row.id, err))
            } else {
              dispatch(
                DeleteRolesPermRowSuccess(role_id, row.id, response.body)
              )
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row

        dispatch(UpdateRolesPermStarted())
        request
          .post("/api/security/roles/new/rolesperm")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateRolesPermError(role_id, id, err))
            } else {
              dispatch(UpdateRolesPermSuccess(role_id, id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateRolesPermStarted(role_id))
        request
          .put(`/api/security/roles/${role_id}/rolesperm`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateRolesPermError(role_id, row.id, err))
            } else {
              dispatch(UpdateRolesPermSuccess(role_id, row.id, response.body))
            }
          })
      } else {
        // TODO
      }
    }
  }
}
