import {
  LOAD_SCHICHTWSZULAGE,
  LOAD_SCHICHTWSZULAGE_QUERY,
  EDIT_WSMAN,
  UPDATE_WSMAN
} from "./swszulage_actions"
import Datetime from "../../shared/dateutil/index"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export function SchichtWsZulage(
  state = {
    schichtwszulage: {},
    schichtwszulagequery: []
  },
  action
) {
  switch (action.type) {
    case LOAD_SCHICHTWSZULAGE:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            schichtwszulage: {
              ...state.schichtwszulage,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_SCHICHTWSZULAGE_QUERY:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            schichtwszulagequery: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_WSMAN:
      return {
        ...state,
        schichtwszulagequery: state.schichtwszulagequery.map((row) => {
          if (row.monat == action.monat && row.int_id == action.int_id) {
            let datum =
              row.jahr +
              "-" +
              ("0" + row.monat).slice(-2) +
              "-" +
              "01T00:00:00:000Z"

            return {
              ...row,
              ...action.changes,
              datum: datum,
              __dirty: true,
              __new: row.id == 0
            }
          }
          return row
        })
        //}
      }
    case UPDATE_WSMAN:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichtwszulagequery: state.schichtwszulagequery.map((row) => {
            if (
              row.int_id == action.data.int_id &&
              row.monat == action.data.monat
            ) {
              return action.data
            }
            return row
          })
        }
      }
      return state
  }
  return state
}
