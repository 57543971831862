import {
  ANTRAG_EMPFAENGER,
  ANTRAG_EMPFAENGER_DEFAULT,
  ANTRAG_VERTRETER_DEFAULT,
  ANTRAG_VERTRETER,
  LOAD_ANTRAG,
  MONATS_SALDO
} from "../actions/antrag"
import { LOAD_MQUEUE } from "../actions/mqueue"

export const Wfl = (
  state = {
    Mqueue: [],
    Antrag: {},
    Saldo: {},
    Empfaenger: { default: null, data: [] },
    Vertreter: { default: null, data: [] }
  },
  action
) => {
  switch (action.type) {
    case LOAD_MQUEUE:
      return { ...state, Mqueue: Mqueue(state.Mqueue, action) }
    case LOAD_ANTRAG:
      return { ...state, Antrag: Antrag(state.Antrag, action) }
    case MONATS_SALDO:
      return { ...state, Saldo: Saldo(state.Saldo, action) }
    case ANTRAG_EMPFAENGER:
    case ANTRAG_EMPFAENGER_DEFAULT:
      return { ...state, Empfaenger: Empfaenger(state.Empfaenger, action) }
    case ANTRAG_VERTRETER:
    case ANTRAG_VERTRETER_DEFAULT:
      return { ...state, Vertreter: Vertreter(state.Vertreter, action) }
  }
  return state
}

const Empfaenger = (state = { default: null, data: [] }, action) => {
  switch (action.type) {
    case ANTRAG_EMPFAENGER:
      return { ...state, data: action.data }
    case ANTRAG_EMPFAENGER_DEFAULT:
      return { ...state, default: action.data }
  }
  return state
}

const Vertreter = (state = { default: null, data: [] }, action) => {
  switch (action.type) {
    case ANTRAG_VERTRETER:
      return { ...state, data: action.data }
    case ANTRAG_VERTRETER_DEFAULT:
      return { ...state, default: action.data }
  }
  return state
}

const Saldo = (state = {}, action) => {
  switch (action.type) {
    case MONATS_SALDO:
      return { ...state, [action.int_id]: action.data }
  }
  return state
}

const Mqueue = (state = [], action) => {
  switch (action.type) {
    case LOAD_MQUEUE:
      return action.data
  }
  return state
}

const Antrag = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ANTRAG:
      return { ...state, [action.id]: action.data }
  }
}

export default Wfl
