import request from "superagent"
import DateTime from "app/shared/dateutil"
export const LOAD_HISTORY = "dpl/LOAD_HISTORY"

export default function LoadHistory(IntID, Datum) {
  return (dispatch) => {
    let d = DateTime.fromISO(Datum, {
      zone: "utc"
    })
    if (!d.isValid() || !IntID) {
      return
    }
    request
      .get(
        `/api/dpl/history/${IntID}/${DateTime.fromISO(Datum, {
          zone: "utc"
        }).toFormat("yyyy-MM-dd")}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_HISTORY,
            int_id: IntID,
            datum: Datum,
            data: response.body
          })
        }
      })
  }
}
