import { LOAD_FEHLZEITEN, LOAD_PERS_FEHLZEITENDEF } from "../actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export function Fehlzeiten(
  state = {
    fehlzeiten: [],
    definitionen: [],
    persdefinitionen: {},
    all: [],
    allowed: {}
  },
  action
) {
  switch (action.type) {
    case LOAD_PERS_FEHLZEITENDEF:
      return {
        ...state,
        persdefinitionen: {
          ...state.persdefinitionen,
          [action.int_id]: action.data
        }
      }
    case LOAD_FEHLZEITEN:
      return {
        ...state,
        all: action.data.sort((a, b) => {
          return a.fehlz_id > b.fehlz_id ? 1 : -1
        }),
        allowed: getAllowedFehlzeiten(action.data)
      }
    // case LOAD_FEHLZEITEN:
    //   switch (action.status) {
    //     case STARTED:
    //       return state
    //     case SUCCESS:
    //       return {
    //         ...state,
    //         fehlzeiten: action.data
    //       }
    //     case ERROR:
    //       return state
    //   }
    //   break
    // case LOAD_FEHLZEITENDEF:
    //   switch (action.status) {
    //     case STARTED:
    //       return state
    //     case SUCCESS:
    //       return {
    //         ...state,
    //         definitionen: action.data
    //       }
    //     case ERROR:
    //       return state
    //   }
    //   break
  }
  return state
}

function getAllowedFehlzeiten(fehlzeiten) {
  var result = {}
  for (var fz of fehlzeiten) {
    if (fz.allowed) {
      result[fz.fehlz_id] = true
    }
  }
  return result
}
