import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export const LOAD_PLAN_TAETIGKEITEN = "dpl/LOAD_PLAN_TAETIGKEITEN"
export const LOAD_DIENST_ZUORDNUNG = "dpl/LOAD_DIENST_ZUORDNUNG"
export const LOAD_TAETIGKEITEN = "dpl/LOAD_TAETIGKEITEN"
export const ADD_TAETIGKEIT = "dpl/ADD_TAETIGKEIT"
export const REMOVE_TAETIGKEIT = "dpl/REMOVE_TAETIGKEIT"
export const CREATE_TAETIGKEIT = "dpl/CREATE_TAETIGKEIT"

export const ADDED_TAETIGKEIT = "dpl/ADDED_TAETIGKEIT"
export const REMOVED_TAETIGKEIT = "dpl/REMOVED_TAETIGKEIT"

export const SAVED_TAETIGKEIT = "dpl/SAVED_TAETIGKEIT"

export const EDIT_TAETIGKEITS_ZORD = "dpl/EDIT_TAETIGKEITS_ZORD"
export const DELETE_TAETIGKEITS_ZORD = "dpl/DELETE_TAETIGKEITS_ZORD"
export const CHANGE_SORT_ORDER = "dpl/CHANGE_SORT_ORDER"
export const EDIT_TAETIGKEIT = "dpl/EDIT_TAETIGKEIT"
export const LOAD_VORGABEN = "dpl/LOAD_VORGABEN"

export const LOAD_SWITCH_DIENST = "dpl/LOAD_SWITCH_DIENST"

import { UpdateTaetigkeitsDefinitionen } from "./taetigkeitsdef"
import Datetime from "../../shared/dateutil/index"

export function LoadSwitchDienst() {
  return (dispatch) => {
    request.get("/api/dpl/switchd").end((err, response) => {
      dispatch({
        type: LOAD_SWITCH_DIENST,
        data: response.body || []
      })
    })
  }
}

export function EditTaetigkeit(id, changes) {
  return {
    type: EDIT_TAETIGKEIT,
    id,
    changes
  }
}

export function ChangeSortOrderPlain(PlanID, OldIndex, NewIndex) {
  return {
    type: CHANGE_SORT_ORDER,
    PlanID,
    OldIndex,
    NewIndex
  }
}

export function ChangeSortOrder(OldIndex, NewIndex) {
  return (dispatch, getState) => {
    let PlanID = getState().Dpl.Plaene.selected
    dispatch(ChangeSortOrderPlain(PlanID, OldIndex, NewIndex))
    let state = getState().Dpl.Taetigkeiten.byPlan[PlanID].data
    request
      .post(`/api/dpl/basis/taetigkeiten/sort/${PlanID}`)
      .send(state)
      .end(() => {})
  }
}

export function AddTaetigkeit(PlanID) {
  return {
    type: ADD_TAETIGKEIT,
    PlanID
  }
}

export function CreateTaetigkeit(Name) {
  return {
    type: CREATE_TAETIGKEIT,
    Name
  }
}

export function RemoveTaetigkeit(PlanID, ID) {
  return {
    type: REMOVE_TAETIGKEIT,
    PlanID,
    ID
  }
}

export function EditTaetigkeitsZord(ID, changes) {
  return {
    type: EDIT_TAETIGKEITS_ZORD,
    ID,
    changes
  }
}

export function DeleteTaetigkeitsZord(ID) {
  return {
    type: DELETE_TAETIGKEITS_ZORD,
    ID
  }
}

export function LoadTaetigkeitenByPlanSuccess(PlanID, Datum, data) {
  return {
    type: LOAD_PLAN_TAETIGKEITEN,
    status: SUCCESS,
    PlanID,
    Datum,
    data
  }
}

export function LoadTaetigkeitenByPlanError(PlanID, err) {
  return {
    type: LOAD_PLAN_TAETIGKEITEN,
    status: ERROR,
    PlanID,
    err
  }
}

export function LoadTaetigkeitenByPlanStarted(PlanID) {
  return {
    type: LOAD_PLAN_TAETIGKEITEN,
    status: STARTED,
    PlanID
  }
}

export function LoadDienstZuordnungSuccess(PlanID, data) {
  return {
    type: LOAD_DIENST_ZUORDNUNG,
    PlanID,
    data
  }
}

export function LoadTaetigkeitenByPlan(PlanID) {
  return (dispatch, getState) => {
    let Datum = getState().Dpl.Datum
    dispatch(LoadTaetigkeitenByPlanStarted(PlanID))
    request
      .get(`/api/dpl/basis/dienstzuordnung/${PlanID}`)
      .end((err, response) => {
        if (err) {
          return
        } else {
          dispatch(LoadDienstZuordnungSuccess(PlanID, response.body))
        }
      })
    request
      .get(`/api/dpl/basis/taetigkeiten/plan/${PlanID}`)
      .end((err, response) => {
        // if (getState().Dpl.Datum !== Datum) {
        //   return
        // }

        if (err) {
          dispatch(LoadTaetigkeitenByPlanError(PlanID, err))
        } else {
          dispatch(LoadTaetigkeitenByPlanSuccess(PlanID, Datum, response.body))
        }
      })
  }
}

export function LoadTaetigkeitenByIntID(IntID) {
  return (dispatch, getState) => {
    let Datum = getState().Dpl.Datum
    dispatch(LoadTaetigkeitenByPlanStarted(0))
    //request
    //.get(`/api/dpl/basis/dienstzuordnung/${PlanID}`)
    //.end((err, response) => {
    //if (err) {
    //return
    //} else {
    //dispatch(LoadDienstZuordnungSuccess(PlanID, response.body))
    //}
    //})
    request
      .get(`/api/dpl/basis/taetigkeiten/int_id/${IntID}`)
      .end((err, response) => {
        // if (getState().Dpl.Datum !== Datum) {
        //   return
        // }
        if (err) {
          dispatch(LoadTaetigkeitenByPlanError(0, err))
        } else {
          dispatch(LoadTaetigkeitenByPlanSuccess(0, Datum, response.body))
        }
      })
  }
}

export function LoadTaetigkeitenStartet() {
  return {
    type: LOAD_TAETIGKEITEN,
    status: STARTED
  }
}

export function LoadTaetigkeitenSuccess(data) {
  return {
    type: LOAD_TAETIGKEITEN,
    status: SUCCESS,
    data
  }
}

export function LoadTaetigkeitenError(err) {
  return {
    type: LOAD_TAETIGKEITEN,
    status: ERROR,
    err
  }
}

export function LoadTaetigkeiten() {
  return (dispatch) => {
    dispatch(LoadTaetigkeitenStartet())
    request.get("/api/dpl/basis/taetigkeiten").end((err, response) => {
      if (err) {
        dispatch(LoadTaetigkeitenError(err))
      } else {
        dispatch(LoadTaetigkeitenSuccess(response.body))
      }
    })
  }
}

export function AddedTaetigkeit(PlanID, ID, Data) {
  return {
    type: ADDED_TAETIGKEIT,
    PlanID,
    ID,
    Data
  }
}

export function RemovedTaetigkeit(PlanID, ID, Data) {
  return {
    type: REMOVED_TAETIGKEIT,
    PlanID,
    ID,
    Data
  }
}

export function SaveTaetigkeitenZord() {
  return (dispatch, getState) => {
    let state = getState()
    for (let [PlanID, values] of Object.entries(
      state.Dpl.Taetigkeiten.dienstzuordnung
    )) {
      for (let t of values) {
        if (t.__new) {
          if (t.__deleted) {
            continue
          }
          let { id, ...rest } = t
          request
            .post(`/api/dpl/basis/dienstzuordnung/${PlanID}/${t.dienst_id}`)
            .send(rest)
            .end((err, response) => {
              if (err) {
                return
              }
              dispatch(AddedTaetigkeit(PlanID, id, response.body))
            })
        } else if (t.__dirty) {
          request
            .put(`/api/dpl/basis/dienstzuordnung/${PlanID}/${t.dienst_id}`)
            .send(t)
            .end((err, response) => {
              if (err) {
                return
              }
              dispatch(AddedTaetigkeit(PlanID, t.id, response.body))
            })
        } else if (t.__deleted) {
          request
            .delete(`/api/dpl/basis/dienstzuordnung/${t.id}`)
            .send(t)
            .end((err, response) => {
              if (err) {
                return
              }
              dispatch(RemovedTaetigkeit(PlanID, t.id))
            })
        }
      }
      //for (let removed of values.removed){
      //request.delete(`/api/dpl/basis/dienstzuordnung/${PlanID}/${removed}`).end((err) => {
      //if(err){
      //return
      //}
      //dispatch(RemovedTaetigkeit(PlanID, removed))
      //})
      //}
    }
  }
}

export function SavedTaetigkeit(id, data) {
  return {
    type: SAVED_TAETIGKEIT,
    id,
    data
  }
}

export function LoadVorgaben(PlanID, von, bis) {
  return (dispatch) => {
    return request
      .get(
        `/api/dpl/vorgaben/${PlanID}/${Datetime.fromISO(
          von
        ).toISODate()}/${Datetime.fromISO(bis).toISODate()}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_VORGABEN,
          data: response.body
        })
      })
  }
}

export function SaveTaetigkeiten() {
  return (dispatch, getState) => {
    let state = getState().Dpl.Taetigkeiten.data
    for (let [key, value] of Object.entries(state)) {
      if (value.__new) {
        if (value.__deleted) {
          dispatch(SavedTaetigkeit(key, null))
          return
        }
        delete value.id
        request
          .post("/api/dpl/basis/taetigkeit")
          .send(value)
          .end((err, response) => {
            dispatch(SavedTaetigkeit(key, response.body))
            dispatch(UpdateTaetigkeitsDefinitionen(response.body.id))
          })
      } else if (value.__dirty) {
        request
          .put(`/api/dpl/basis/taetigkeit/${key}`)
          .send(value)
          .end((err, response) => {
            dispatch(SavedTaetigkeit(key, response.body))
            dispatch(UpdateTaetigkeitsDefinitionen(response.body.id))
          })
      } else if (value.__deleted) {
        request.delete(`/api/dpl/basis/taetigkeit/${key}`).end(() => {
          dispatch(SavedTaetigkeit(key, null))
          dispatch(UpdateTaetigkeitsDefinitionen(key))
        })
      } else {
        dispatch(UpdateTaetigkeitsDefinitionen(value.id))
      }
    }
  }
}
