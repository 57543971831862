import request from "superagent"
import { STARTED, SUCCESS, ERROR } from "app/state/constants"
import { queryToString } from "app/shared/util/urls"
import { GetSelection } from "../selectors/selection"
import { GetVonBis } from "./helpers"
import { LoadSPI, ReloadLoadSPI } from "./spi"
import { GetDirtyWork, GetDirtyWuensche } from "../selectors/data"
import _ from "lodash"
import { GetZeitraum } from "../selectors/zeitraum"
import { Datum } from "../selectors/datum"
import { CanEdit } from "../selectors/fehlzeiten"
import { LoadFeiertage } from "./feiertage"
import { LoadGenehmigung } from "./genehmigung"
import { GetPers } from "../selectors/pers"
import {
  IsGenehmigungLocked,
  IsGenehmigungLockedByPers
} from "../selectors/genehmigung"

// LOAD_DATA : Laden der Urlaubsplanung Daten
export const LOAD_DATA = "upl/LOAD_DATA"

// LOAD_URLAUB : Laden der Urlaubsplanung Urlaubsdaten
export const LOAD_URLAUB = "upl/LOAD_URLAUB"

// EDIT_WUNSCH : Editieren von Daten in der Wunschzeile
export const EDIT_WUNSCH = "upl/EDIT_WUNSCH"

// EDIT_WUNSCH_STRENGTH : Editieren der Wunschstärke
export const EDIT_WUNSCH_STRENGTH = "upl/EDIT_WUNSCH_STRENGTH"

// EDIT_PLAN : Editieren von Daten in der Planzeile
export const EDIT_PLAN = "upl/EDIT_PLAN"

// SAVED_DATA : Speichern der Daten abgeschlossen
export const SAVED_DATA = "upl/SAVED_DATA"

// SAVE_DATA : Speichern der Daten begonnen
export const SAVE_DATA = "upl/SAVE_DATA"

export const RESET_DATA = "upl/RESET_DATA"

export const ResetData = () => {
  return { type: RESET_DATA }
}

/**
  Action zum Editieren der (ausgewählten Zellen) Wunschzeile

  @param {string} value - der neue Wert
*/
export const EditWunsch = (value) => {
  return (dispatch, getState) => {
    const selection = GetSelection(getState())

    for (let k of Object.keys(selection)) {
      const [s_int_id, year, month, day, s_kz] = k.split("-")
      if (s_kz != "W") {
        continue
      }
      let datum = `${year}-${month}-${day}`
      if (!CanEdit(getState(), value, datum, "W")) {
        continue
      }
      dispatch({
        type: EDIT_WUNSCH,
        int_id: parseInt(s_int_id),
        datum: datum,
        value: value
      })
    }
  }
}

export const EditWunschStrength = (value) => {
  return (dispatch, getState) => {
    const selection = GetSelection(getState())
    console.log(value)
    for (let k of Object.keys(selection)) {
      const [s_int_id, year, month, day, s_kz] = k.split("-")
      if (s_kz != "W") {
        continue
      }
      let datum = `${year}-${month}-${day}`
      if (!CanEdit(getState(), "", datum, "W")) {
        continue
      }
      dispatch({
        type: EDIT_WUNSCH_STRENGTH,
        int_id: parseInt(s_int_id),
        datum: datum,
        value: value
      })
    }
  }
}

/**
  Action zum Editieren der (ausgewählten Zellen) Planzeile

  @param {string} value - der neue Wert
*/
export const EditPlan = (value) => {
  return (dispatch, getState) => {
    const state = getState()
    const selection = GetSelection(state)
    for (let k of Object.keys(selection)) {
      const [s_int_id, year, month, day, s_kz] = k.split("-")
      if (s_kz != "P") {
        continue
      }
      let datum = `${year}-${month}-${day}`
      if (!CanEdit(state, value, datum, "P")) {
        continue
      }
      if (IsGenehmigungLockedByPers(state, datum, parseInt(s_int_id))) {
        continue
      }
      dispatch({
        type: EDIT_PLAN,
        int_id: parseInt(s_int_id),
        datum: datum,
        value: value
      })
    }
  }
}

var loadTimeOutID = null

/**
  Action zum Laden der Urlaubsplanungs - Daten 

  Als startpunkt wird das Gewöhlte Urlaubsplanung Datum verwendet
  Stellt sicher dass der gewählte Zeitraum vollständig geladen ist,
  ohne bereits geladene Daten nochmals zu laden.

  @param {int} loadCount - Anzahl der zu ladenden Tage
  @param {object} query - Query für die zu Ladenden Mitarbeiter
*/
export const LoadData = (loadCount, query) => {
  return (dispatch, getState) => {
    const { loadVon, loadBis, setLoadedVon, setLoadedBis, count } = GetVonBis(
      getState(),
      loadCount
    )
    if (count <= 0) {
      return
    }
    dispatch({
      type: LOAD_DATA,
      status: STARTED,
      start: setLoadedVon.toISODate()
    })
    clearTimeout(loadTimeOutID)
    loadTimeOutID = setTimeout(() => {
      dispatch(LoadSPI(loadCount, query))
      dispatch(LoadGenehmigung(loadCount, query))
      dispatch(LoadFeiertage(loadVon.toISODate(), loadBis.toISODate()))
      request
        .get(
          `/api/urlaubsplanung/date/query/D/${count}/${loadVon.toISODate()}T00:00:00Z`
        )
        .query(queryToString(query))
        .end((err, response) => {
          if (err) {
            dispatch({
              type: LOAD_DATA,
              status: ERROR,
              start: setLoadedVon.toISODate()
            })
            return
          }
          dispatch({
            type: LOAD_DATA,
            status: SUCCESS,
            start: setLoadedVon.toISODate(),
            end: setLoadedBis.toISODate(),
            data: response.body
          })
        })
    }, 100)
  }
}

export const LoadInfoData = (loadCount, includeAbt) => {
  return (dispatch, getState) => {
    const { loadVon, loadBis, setLoadedVon, setLoadedBis, count } = GetVonBis(
      getState(),
      loadCount
    )
    if (count <= 0) {
      return
    }
    dispatch({
      type: LOAD_DATA,
      status: STARTED,
      start: setLoadedVon.toISODate()
    })
    clearTimeout(loadTimeOutID)
    loadTimeOutID = setTimeout(() => {
      dispatch(LoadSPI(loadCount, query))
      dispatch(LoadGenehmigung(loadCount, query))
      dispatch(LoadFeiertage(loadVon.toISODate(), loadBis.toISODate()))
      request
        .get(
          `/api/info/urlaubsplanung/date/${
            includeAbt ? "abt" : "int_id"
          }/D/${count}/${loadVon.toISODate()}T00:00:00Z`
        )
        .end((err, response) => {
          if (err) {
            dispatch({
              type: LOAD_DATA,
              status: ERROR,
              start: setLoadedVon.toISODate()
            })
            return
          }
          dispatch({
            type: LOAD_DATA,
            status: SUCCESS,
            start: setLoadedVon.toISODate(),
            end: setLoadedBis.toISODate(),
            data: response.body
          })
        })
    }, 100)
  }
}

/**
 * Action zum Speichern der Urlaubsplanung Daten
 */
export const SaveData = () => {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_DATA })
    let data = {
      wunsch: {
        created: [],
        dirty: []
      },
      work: {
        created: [],
        dirty: []
      }
    }
    const state = getState()
    const dirtyWuensche = GetDirtyWuensche(state)
    const dirtyWork = GetDirtyWork(state)
    for (let w of dirtyWuensche) {
      if (w.__new) {
        data.wunsch.created.push(w)
      } else {
        data.wunsch.dirty.push(w)
      }
    }
    for (let w of dirtyWork) {
      if (w.__new) {
        data.work.created.push(w)
      } else {
        data.work.dirty.push(w)
      }
    }

    request
      .post("/api/urlaubsplanung/batch")
      .send(data)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadUrlaub(state.Query.QuerySelection))
          dispatch(ReloadLoadSPI(state.Query.QuerySelection))
          dispatch({
            type: SAVED_DATA,
            data: response.body
          })
        }
      })
  }
}

export const LoadUrlaub = (query) => {
  return (dispatch, getState) => {
    const state = getState()
    const datum = Datum(state)
    const currentZeitraum = GetZeitraum(state, datum)
    if (!currentZeitraum) {
      return
    }
    return request
      .get(`/api/urlaubsplanung/urlaub/${currentZeitraum.id}`)
      .query(queryToString(query))
      .then((response) => {
        dispatch({
          type: LOAD_URLAUB,
          data: response.body
        })
      })
  }
}
