import {SUCCESS, ERROR, STARTED} from "app/state/constants"
import {LOAD_SALDEN, UPDATED_SALDEN} from "../actions"
import {arrayToObject} from "app/shared/util/conversion"
export function Salden(state = {}, action) {
  switch (action.type) {
    case LOAD_SALDEN:
      if (action.status == SUCCESS) {
        return arrayToObject(action.data || [], "int_id")
      } else if (action.status == ERROR) {
        return state
      } else if (action.status == STARTED) {
        return state
      }
      break
    case UPDATED_SALDEN:
      return {...state, [action.IntID]: action.data}
  }
  return state
}
