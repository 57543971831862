import React, { Component, Children } from "react"
import PropTypes from "prop-types"
import Registry from "./registry"

export const ConduitContext = React.createContext({ registry: null })

class ConduitProvider extends Component {
  constructor(props) {
    super(props)
    const { registry } = this.props

    this.registry = registry ? registry : new Registry()
  }

  render() {
    return (
      <ConduitContext.Provider value={{ registry: this.registry }}>
        {this.props.children}
      </ConduitContext.Provider>
    )
  }
}

export default ConduitProvider
