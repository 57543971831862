import request from "superagent"
// import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { SUCCESS } from "app/state/constants"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"

import swal from "sweetalert"
import { queryToString } from "app/shared/util"
export const LOAD_SALDOUMBUCHUNGENVONBIS = "LOAD_SALDOUMBUCHUNGENVONBIS"
export const EDIT_SALDOUMBUCHUNG = "EDIT_SALDOUMBUCHUNG"
export const ADD_SALDOUMBUCHUNG_ROW = "ADD_SALDOUMBUCHUNG_ROW"
export const DELETE_SALDOUMBUCHUNG = "DELETE_SALDOUMBUCHUNG"
export const DELETED_SALDOUMBUCHUNG = "DELETED_SALDOUMBUCHUNG"
export const UPDATE_SALDOUMBUCHUNG = "UPDATE_SALDOUMBUCHUNG"
export const LOAD_SALDENVORTRAG = "LOAD_SALDENVORTRAG"
export const EDIT_SALDENVORTRAG = "EDIT_SALDENVORTRAG"
export const UPDATE_SALDENVORTRAG = "UPDATE_SALDENVORTRAG"
// export const ADD_SALDENVORTRAG_ROW = "ADD_SALDENVORTRAG_ROW"
export const LOAD_SPEICHERVORTRAG = "LOAD_SPEICHERVORTRAG"
export const EDIT_SPEICHERVORTRAG = "EDIT_SPEICHERVORTRAG"
export const ADD_SPEICHERVORTRAG_ROW = "ADD_SPEICHERVORTRAG_ROW"
export const DELETED_SPEICHERVORTRAG = "DELETED_SPEICHERVORTRAG"
export const DELETE_SPEICHERVORTRAG = "DELETE_SPEICHERVORTRAG"
export const UPDATE_SPEICHERVORTRAG = "UPDATE_SPEICHERVORTRAG"
export const LOAD_MONATSSPEICHER = "LOAD_MONATSSPEICHER"
export const LOAD_SPEICHERINDIVZULAGE = "LOAD_SPEICHERINDIVZULAGE"
export const EDIT_SPEICHERINDIVZULAGE = "EDIT_SPEICHERINDIVZULAGE"
export const UPDATE_SPEICHERINDIVZULAGE = "UPDATE_SPEICHERINDIVZULAGE"
export const LOAD_DPSALDENVORTRAG = "LOAD_DPSALDENVORTRAG"
export const EDIT_DPSALDENVORTRAG = "EDIT_DPSALDENVORTRAG"

export function UpdateSpeicherIndivZulageSuccess(id, data) {
  return {
    type: UPDATE_SPEICHERINDIVZULAGE,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSpeicherIndivZulage() {
  return (dispatch, getState) => {
    let data = getState().Speicher.speicherindivzulage || []
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.id == 0 && row.__new) {
        let { id, ...r } = row
        request
          .post("/api/speicherindivzulage/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateSpeicherIndivZulageSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/speicherindivzulage")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateSpeicherIndivZulageSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function EditSpeicherIndivZulage(int_id, id, changes) {
  return {
    type: EDIT_SPEICHERINDIVZULAGE,
    int_id,
    id,
    changes
  }
}

export function LoadSpeicherIndivZulageQuerySuccess(jahr, monat, int_id, data) {
  return {
    type: LOAD_SPEICHERINDIVZULAGE,
    status: SUCCESS,
    jahr,
    monat,
    int_id,
    data
  }
}

export function LoadSpeicherIndivZulageQuery(jahr, monat, query) {
  return (dispatch) => {
    request
      .get(`/api/speicherindivzulage/${jahr}/${monat}/query`)
      .query(queryToString(query))
      .end((err, response) => {
        dispatch(
          LoadSpeicherIndivZulageQuerySuccess(jahr, monat, null, response.body)
        )
      })
  }
}

export function LoadMonatsSpeicherSuccess(jar_mon, bjar_mon, int_id, data) {
  return {
    type: LOAD_MONATSSPEICHER,
    status: SUCCESS,
    jar_mon,
    bjar_mon,
    int_id,
    data
  }
}

export function LoadMonatsSpeicherQuery(jar_mon, bjar_mon, query) {
  return (dispatch) => {
    request
      .get(
        `/api/monatspeicher/${jar_mon.year}-${("0" + jar_mon.month).slice(
          -2
        )}-01T00:00:00Z/${bjar_mon.year}-${("0" + bjar_mon.month).slice(
          -2
        )}-01T00:00:00Z/query`
      )
      .query(queryToString(query))
      .end((err, response) => {
        dispatch(
          LoadMonatsSpeicherSuccess(jar_mon, bjar_mon, null, response.body)
        )
      })
  }
}

export function LoadSpeicherVortragSuccess(int_id, sjahr, ejahr, data) {
  return {
    type: LOAD_SPEICHERVORTRAG,
    status: SUCCESS,
    int_id,
    sjahr,
    ejahr,
    data
  }
}
export function LoadSpeicherVortrag(int_id, sjahr, ejahr) {
  return (dispatch) => {
    request
      .get(
        `/api/p/${int_id}/speichervortrag/${Datetime.fromISO(
          sjahr
        ).toISO()}/${Datetime.fromISO(ejahr).toISO()}`
      )
      .end((err, response) => {
        dispatch(
          LoadSpeicherVortragSuccess(int_id, sjahr, ejahr, response.body)
        )
      })
  }
}

export function EditSpeicherVortrag(int_id, id, changes) {
  return {
    type: EDIT_SPEICHERVORTRAG,
    int_id,
    id,
    changes
  }
}
export function AddSpeicherVortragRow(int_id, jahr) {
  return {
    type: ADD_SPEICHERVORTRAG_ROW,
    int_id,
    jahr
  }
}

export function DeleteSpeicherVortragSuccess(int_id, id) {
  return {
    type: DELETED_SPEICHERVORTRAG,
    status: SUCCESS,
    int_id,
    id
  }
}
export function DeleteSpeicherVortrag(int_id, id) {
  return {
    type: DELETE_SPEICHERVORTRAG,
    int_id,
    id
  }
}

function getDirtySpeicherRows(state) {
  let speicherVortrag = state.Speicher.speichervortrag
  let result = []
  for (let rows of Object.values(speicherVortrag)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}
export function UpdateSpeicherVortragSuccess(int_id, id, data) {
  return {
    type: UPDATE_SPEICHERVORTRAG,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}
export function UpdateSpeicherVortrag() {
  return (dispatch, getState) => {
    let speicherVortrag = getDirtySpeicherRows(getState())
    for (let row of speicherVortrag) {
      var err = CheckSpeicherVortrag(row)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/speichervortrag/${row.id}`)
          .end((err, response) => {
            dispatch(
              DeleteSpeicherVortragSuccess(row.int_id, row.id, response.body)
            )
          })
        return
      }
      if (row.__new) {
        let { id, ...r } = row
        console.log(r, row)
        request
          .post(
            `/api/p/${row.int_id}/speichervortrag/${Datetime.fromISO(
              row.jahr
            ).toISO()}`
          )
          .send(r)
          .end((err, response) => {
            dispatch(
              UpdateSpeicherVortragSuccess(row.int_id, id, response.body)
            )
          })
        return
      }
      if (row.__dirty && !row.__new) {
        request
          .put(`/api/p/${row.int_id}/speichervortrag`)
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdateSpeicherVortragSuccess(row.int_id, row.id, response.body)
            )
          })
        return
      }
    }
  }
}
export function CheckSpeicherVortrag(speichervortrag) {
  if (
    speichervortrag.min == "" ||
    speichervortrag.min == null ||
    speichervortrag.min == "NaN" ||
    speichervortrag.min == 0
  ) {
    return "bitte Stunden oder Minuten die umgebucht werden sollen angeben ! (0 oder leer ist nicht zugelassen)"
  }
  if (speichervortrag.spch_nr == "" || speichervortrag.spch_nr == null) {
    return "bitte Speicher für die Umbuchung angeben !"
  }
  return null
}

export function LoadDPSaldenVortragSuccess(int_id, dp_peri, data) {
  return {
    type: LOAD_DPSALDENVORTRAG,
    status: SUCCESS,
    int_id,
    dp_peri,
    data
  }
}
export function LoadDPSaldenVortrag(int_id, dp_peri) {
  return (dispatch) => {
    request
      .get(
        `/api/p/${int_id}/dpsaldenvortrag/${moment
          .utc(dp_peri)
          .format("YYYY-MM-DD")}`
      )
      .end((err, response) => {
        if (err) {
          dispatch(
            LoadDPSaldenVortragSuccess(int_id, dp_peri, {
              __new: true,
              int_id: int_id,
              dp_peri: dp_peri
            })
          )
        } else {
          dispatch(LoadDPSaldenVortragSuccess(int_id, dp_peri, response.body))
        }
      })
  }
}
export function EditDPSaldenVortrag(int_id, dp_peri, changes) {
  return {
    type: EDIT_DPSALDENVORTRAG,
    int_id,
    dp_peri,
    changes
  }
}

export function LoadSaldenVortragSuccess(int_id, jahr, data) {
  return {
    type: LOAD_SALDENVORTRAG,
    status: SUCCESS,
    int_id,
    jahr,
    data
  }
}
export function LoadSaldenVortrag(int_id, jahr) {
  return (dispatch) => {
    request
      .get(`/api/p/${int_id}/saldenvortrag/${Datetime.fromISO(jahr).toISO()}`)
      .end((err, response) => {
        if (err) {
          // dispatch(AddSaldenVortragRow(int_id, jahr, { __new: true }))
          dispatch(
            LoadSaldenVortragSuccess(int_id, jahr, {
              __new: true,
              int_id: int_id,
              jahr: jahr
            })
          )
        } else {
          dispatch(LoadSaldenVortragSuccess(int_id, jahr, response.body))
        }
      })
  }
}
export function EditSaldenVortrag(int_id, jahr, changes) {
  return {
    type: EDIT_SALDENVORTRAG,
    int_id,
    jahr,
    changes
  }
}
function getDirtySVRows(state) {
  let saldenVortrag = state.Speicher.saldenvortrag
  let result = []
  for (let row of Object.values(saldenVortrag)) {
    // for (let row of rows) {
    if (row.__dirty || row.__new || row.__deleted) {
      result.push(row)
    }
    // }
  }
  return result
}
export function UpdateSaldenVortragSuccess(int_id, jahr, data) {
  return {
    type: UPDATE_SALDENVORTRAG,
    status: SUCCESS,
    int_id,
    jahr,
    data
  }
}
export function UpdateSaldenVortrag() {
  return (dispatch, getState) => {
    let saldenVortrag = getDirtySVRows(getState())
    for (let row of saldenVortrag) {
      if (row.__new) {
        let { id, ...r } = row
        console.log(r, row)
        request
          .post(
            `/api/p/${row.int_id}/saldenvortrag/${Datetime.fromISO(
              row.jahr
            ).toISO()}`
          )
          .send(r)
          .end((err, response) => {
            dispatch(
              UpdateSaldenVortragSuccess(row.int_id, row.jahr, response.body)
            )
            dispatch(LoadDPSaldenVortrag(row.int_id, row.jahr))
          })
        return
      }
      if (row.__dirty && !row.__new) {
        request
          .put(
            `/api/p/${row.int_id}/saldenvortrag/${Datetime.fromISO(
              row.jahr
            ).toISO()}`
          )
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdateSaldenVortragSuccess(row.int_id, row.jahr, response.body)
            )
            dispatch(LoadDPSaldenVortrag(row.int_id, row.jahr))
          })
        return
      }
    }
  }
}

export function LoadSaldoUmbuchungenVonBisSuccess(
  int_id,
  sdatum,
  edatum,
  data
) {
  return {
    type: LOAD_SALDOUMBUCHUNGENVONBIS,
    status: SUCCESS,
    int_id,
    sdatum,
    edatum,
    data
  }
}
export function LoadSaldoUmbuchungenVonBis(int_id, sdatum, edatum) {
  return (dispatch) => {
    request
      .get(
        `/api/p/${int_id}/saldoumbuchung/${Datetime.fromISO(
          sdatum
        ).toISO()}/${Datetime.fromISO(edatum).toISO()}`
      )
      .end((err, response) => {
        dispatch(
          LoadSaldoUmbuchungenVonBisSuccess(
            int_id,
            sdatum,
            edatum,
            response.body
          )
        )
      })
  }
}

export function EditSaldoUmbuchung(int_id, id, changes) {
  return {
    type: EDIT_SALDOUMBUCHUNG,
    int_id,
    id,
    changes
  }
}
export function AddSaldoUmbuchungRow(int_id, datum) {
  return {
    type: ADD_SALDOUMBUCHUNG_ROW,
    int_id,
    datum
  }
}

export function DeleteSaldoUmbuchungSuccess(int_id, id) {
  return {
    type: DELETED_SALDOUMBUCHUNG,
    status: SUCCESS,
    int_id,
    id
  }
}
export function DeleteSaldoUmbuchung(int_id, id) {
  return {
    type: DELETE_SALDOUMBUCHUNG,
    int_id,
    id
  }
}

function getDirtyRows(state) {
  let saldoUmbuchung = state.Speicher.saldoumbuchung
  let result = []
  for (let rows of Object.values(saldoUmbuchung)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}
export function UpdateSaldoUmbuchungSuccess(id, int_id, data) {
  return {
    type: UPDATE_SALDOUMBUCHUNG,
    status: SUCCESS,
    id,
    int_id,
    data
  }
}
export function UpdateSaldoUmbuchung() {
  return (dispatch, getState) => {
    let saldoUmbuchung = getDirtyRows(getState())
    for (let row of saldoUmbuchung) {
      var err = CheckSaldoUmbuchung(row)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        request.delete(`/api/saldoumbuchung/${row.id}`).end((err, response) => {
          dispatch(
            DeleteSaldoUmbuchungSuccess(row.int_id, row.id, response.body)
          )
        })
        return
      }
      if (row.__new) {
        let { id, ...r } = row
        console.log(row, r)
        request
          .post(
            `/api/p/${row.int_id}/saldoumbuchung/${Datetime.fromISO(
              row.datum
            ).toISO()}`
          )
          .send(r)
          .end((err, response) => {
            dispatch(UpdateSaldoUmbuchungSuccess(id, row.int_id, response.body))
          })
        return
      }
      if (row.__dirty && !row.__new) {
        request
          .put(`/api/p/${row.int_id}/saldoumbuchung`)
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdateSaldoUmbuchungSuccess(row.id, row.int_id, response.body)
            )
          })
        return
      }
    }
  }
}
export function CheckSaldoUmbuchung(saldoumbuchungen) {
  if (
    saldoumbuchungen.lohn_std == "" ||
    saldoumbuchungen.lohn_std == null ||
    saldoumbuchungen.lohn_std == "NaN" ||
    saldoumbuchungen.lohn_std == 0
  ) {
    return "bitte Stunden oder Minuten die umgebucht werden sollen angeben ! (0 oder leer ist nicht zugelassen)"
  }
  if (saldoumbuchungen.spch_nr == "" || saldoumbuchungen.spch_nr == null) {
    return "bitte Speicher für die Umbuchung angeben !"
  }
  return null
}
