import request from "superagent"

export const LOAD_ABSENCE_CHECK = "dpl/LOAD_ABSENCE_CHECK"

export const LoadAbsenceCheck = () => {
  return (dispatch) => {
    request.get("/api/dpl/basis/fz/checks").then((response) => {
      dispatch({ type: LOAD_ABSENCE_CHECK, data: response.body })
    })
  }
}
