import { SUCCESS } from "app/state/constants"
import {
  BATCH_ACCEPT_GENEHMIGUNG,
  LOAD_SAMMEL_UEBERNAHME_COMP,
  LOAD_SAMMEL_UEBERNAHME_DUE,
  LOAD_SAMMEL_UEBERNAHME_PROG
} from "../actions/genehmigung"

export const SammelUebernahmeDue = (state = [], action) => {
  switch (action.type) {
    case LOAD_SAMMEL_UEBERNAHME_DUE:
      return action.data || []
    case BATCH_ACCEPT_GENEHMIGUNG:
      return state.map((r) => {
        if (r.id == action.id) {
          if (action.status == SUCCESS) {
            return { ...r, loading: false, status: 1 }
          } else {
            return { ...r, loading: true }
          }
        }
        return r
      })
      return
  }

  return state
}

export const SammelUebernahmeComp = (state = [], action) => {
  switch (action.type) {
    case LOAD_SAMMEL_UEBERNAHME_COMP:
      return action.data || []
  }

  return state
}

export const SammelUebernahmeProg = (state = [], action) => {
  switch (action.type) {
    case LOAD_SAMMEL_UEBERNAHME_PROG:
      return action.data || []
  }

  return state
}
