import request from "superagent"
import { DirtyVorgaben } from "./selectors"

export const LOAD_VORGABEN = "upl/LOAD_VORGABEN"
export const EDIT_VORGABEN = "upl/EDIT_VORGABEN"
export const EDIT_SONDER_BESETZUNG = "upl/EDIT_SONDER_BESETZUNG"
export const EDIT_SONDER_BESETZUNG_DATUM = "upl/EDIT_SONDER_BESETZUNG_DATUM"
export const EDIT_BESETZUNG = "upl/EDIT_BESETZUNG"
export const EDIT_SHIFT = "upl/EDIT_SHIFT"
export const UPDATE_VORGABE = "upl/UPDATE_VORGABE"
export const COPY_VORGABE = "upl/COPY_VORGABE"
export const PASTE_VORGABE = "upl/PASTE_VORGABE"

export function LoadUrlaubsplanungVorgaben(year, abt_id) {
  return (dispatch) => {
    request
      .get(`/api/urlaubsplanung/vorgaben/${year}/${abt_id}`)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_VORGABEN,
            year,
            abt_id,
            data: response.body
          })
        }
      })
  }
}

export const EditVorgabe = (year, abt_id, quali_id, changes) => {
  return {
    type: EDIT_VORGABEN,
    year,
    abt_id,
    quali_id: parseInt(quali_id),
    changes
  }
}

export const EditSonderBesetzung = (
  year,
  abt_id,
  datum,
  quali_id,
  shift,
  value
) => {
  return {
    type: EDIT_SONDER_BESETZUNG,
    year,
    abt_id,
    quali_id: parseInt(quali_id),
    datum: datum,
    shift,
    value
  }
}

export const EditSonderBesetzungDatum = (
  year,
  abt_id,
  datum,
  quali_id,
  newDatum
) => {
  return {
    type: EDIT_SONDER_BESETZUNG_DATUM,
    year,
    abt_id,
    quali_id: parseInt(quali_id),
    datum: datum,
    newDatum
  }
}

export const EditBesetzung = (
  year,
  abt_id,
  quali_id,
  weekday,
  shift,
  value
) => {
  return {
    type: EDIT_BESETZUNG,
    year,
    abt_id,
    quali_id: parseInt(quali_id),
    weekday,
    shift,
    value
  }
}

export const EditShift = (year, abt_id, quali_id, shift, value) => {
  return {
    type: EDIT_SHIFT,
    year,
    abt_id,
    quali_id: parseInt(quali_id),
    shift,
    value
  }
}

export const SaveVorgaben = () => {
  return (dispatch, getState) => {
    const dirty = DirtyVorgaben(getState())
    for (let vorgabe of dirty) {
      if (vorgabe.id) {
        return request
          .put(
            `/api/urlaubsplanung/vorgaben/${vorgabe.year}/${vorgabe.quali_id}/${vorgabe.abt_id}`
          )
          .send(vorgabe)
          .end((err, response) => {
            if (!err) {
              dispatch({
                type: UPDATE_VORGABE,
                year: vorgabe.year,
                abt_id: vorgabe.abt_id,
                quali_id: vorgabe.quali_id,
                data: response.body
              })
            }
          })
      } else {
        request
          .post(
            `/api/urlaubsplanung/vorgaben/${vorgabe.year}/${vorgabe.quali_id}/${vorgabe.abt_id}`
          )
          .send(vorgabe)
          .end((err, response) => {
            if (!err) {
              dispatch({
                type: UPDATE_VORGABE,
                year: vorgabe.year,
                abt_id: vorgabe.abt_id,
                quali_id: vorgabe.quali_id,
                data: response.body
              })
            }
          })
      }
    }
  }
}

export const CopyVorgabe = (vorgabe) => {
  return {
    type: COPY_VORGABE,
    vorgabe
  }
}

export const PasteVorgabe = (year, abt_id, quali_id, vorgabe) => {
  return {
    type: PASTE_VORGABE,
    year,
    abt_id,
    quali_id,
    vorgabe
  }
}
