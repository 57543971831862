import Datetime from "app/shared/dateutil/index"
import { SET_DATE } from "../actions/datum"
import { LOAD_DATA, RESET_DATA } from "../actions/data"

import { Data } from "./data"
import { Selection } from "./selection"
import { Zeitraum, Wunschzeitraum } from "./zeitraum"
import { SelectedSPI, SPI } from "./spi"
import { RowVisibility, SumSettings } from "./settings"
import { Fehlzeiten } from "./fehlzeiten"
import { Feiertage } from "./feiertage"
import { Vorgaben, VorgabenCopyPaste } from "../vorgaben/state"
import { Genehmigung } from "./genehmigung"
import { Berechtigungen } from "./berechtigungen"
import {
  SammelUebernahmeComp,
  SammelUebernahmeDue,
  SammelUebernahmeProg
} from "../sammeluebernahme/state"

export default (state = {}, action) => {
  return {
    Datum: Datum(state.Datum, action),
    Data: Data(state.Data, action),
    LoadingStatus: LoadingStatus(state.LoadingStatus, action),
    Selection: Selection(state.Selection, action),
    Zeitraum: Zeitraum(state.Zeitraum, action),
    Wunschzeitraum: Wunschzeitraum(state.Wunschzeitraum, action),
    SPI: SPI(state.SPI, action),
    SelectedSPI: SelectedSPI(state.SelectedSPI, action),
    RowVisibility: RowVisibility(state.RowVisibility, action),
    SumSettings: SumSettings(state.SumSettings, action),
    Fehlzeiten: Fehlzeiten(state.Fehlzeiten, action),
    Feiertage: Feiertage(state.Feiertage, action),
    Vorgaben: Vorgaben(state.Vorgaben, action),
    VorgabenCopyPaste: VorgabenCopyPaste(state.VorgabenCopyPaste, action),
    Genehmigung: Genehmigung(state.Genehmigung, action),
    Berechtigungen: Berechtigungen(state.Berechtigungen, action),
    SammelUebernahmeDue: SammelUebernahmeDue(state.SammelUebernahmeDue, action),
    SammelUebernahmeComp: SammelUebernahmeComp(
      state.SammelUebernahmeComp,
      action
    ),
    SammelUebernahmeProg: SammelUebernahmeProg(
      state.SammelUebernahmeProg,
      action
    )
  }
}

export const Datum = (state = Datetime.now().toISODate(), action) => {
  switch (action.type) {
    case SET_DATE:
      return action.date
  }
  return state
}

export const LoadingStatus = (state = { von: null, bis: null }, action) => {
  switch (action.type) {
    case LOAD_DATA:
      return { von: action.start, bis: action.end }
    case RESET_DATA:
      return { von: null, bis: null }
  }
  return state
}
