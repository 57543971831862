import PropTypes from "prop-types"
import React from "react"
import { SpinnerIcon } from "./icons/icons"
import ScrollbarSize from "react-scrollbar-size"

const styles = {
  arionImg: {
    width: "100%"
  },
  arionImgSM: {
    width: "35px"
  },
  appCaption: {
    fontSize: "11px",
    color: "#999",
    textAlign: "center",
    whiteSpace: "nowrap"
  },
  pageLoading: (bg) => ({
    width: "100%",
    height: "100%",
    backgroundColor: bg,
    opacity: "0.4",
    position: "absolute",
    top: "0px"
  }),
  pageLoadingInner: {
    position: "absolute",
    margin: "auto",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",

    display: "inline-block",
    fontSize: "40px",
    lineHeight: "50px",
    color: "#666",
    width: "50px",
    height: "50px",
    textAlign: "center",
    verticalAlign: "bottom"
  },
  spinnerIcon: {
    display: "inline-block",
    fontSize: "40px",
    lineHeight: "50px",
    color: "#666",
    width: "50px",
    height: "50px",
    textAlign: "center",
    verticalAlign: "bottom"
  }
}

export class ArionImg extends React.Component {
  static propTypes = {
    style: PropTypes.object
  }
  render() {
    return (
      <img
        css={styles.arionImg}
        id="arionicon"
        src="/static/images/pegasusLogox2.png"
      />
    )
  }
}

export class ArionImgSM extends React.Component {
  static propTypes = {
    style: PropTypes.object
  }
  render() {
    return (
      <img
        css={styles.arionImgSM}
        id="arionicon"
        src="/static/images/pegasusLogoSM.png"
      />
    )
  }
}

export class LoadingOverlay extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool
  }
  render() {
    if (!this.props.isLoading) {
      return null
    }
    return <PageLoading {...this.props} />
  }
}

export class AppCaption extends React.Component {
  static propTypes = {
    version: PropTypes.string,
    build: PropTypes.string,
    hash: PropTypes.string,
    profile: PropTypes.object
  }
  render() {
    return (
      <div>
        <ArionImg css={{ marginBottom: "-5px" }} />
        <span css={styles.appCaption}>
          Arion {this.props.version || ""} {"  "} <br />
          <i className="black small">
            {this.props.build || ""} {this.props.hash}
          </i>
        </span>
      </div>
    )
  }
}

export class LoadingImage extends React.Component {
  render() {
    return <img src="/static/images/loading.png" />
  }
}

const PageLoadingStyle = {
  position: "absolute",
  margin: "auto",
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",

  display: "inline-block",
  fontSize: "40px",
  lineHeight: "50px",
  color: "#666",
  width: "50px",
  height: "50px",
  textAlign: "center",
  verticalAlign: "bottom"
}
export class PageLoading extends React.Component {
  static propTypes = {
    background: PropTypes.string
  }
  render() {
    let bg = this.props.background || "transparent"
    return (
      <div css={styles.pageLoading(bg)}>
        <div css={styles.pageLoadingInner}>
          <SpinnerIcon css={styles.spinnerIcon} />
        </div>
      </div>
    )
  }
}

export function ScrollbarWidth() {
  const { width, height } = ScrollbarSize()
  return <div style={{ width: width }} />
}
