import {
  LOADED_FEIERTAGE,
  SET_DATE,
  REQUEST_DATE,
  SET_LOADED,
  SELECT_PLAN,
  SET_MONTH,
  SET_YEAR
} from "../actions"
import { RELOAD } from "../actions/records"

import DateTime from "app/shared/dateutil"

export function Datum(
  state = {
    ftg: {},
    date: DateTime.utc().startOf("month").toISODate(),
    bis: DateTime.utc().startOf("month").toISODate(),
    loadedVon: null,
    loadedBis: null
  },
  action
) {
  switch (action.type) {
    case SELECT_PLAN:
      return { ...state, loadedVon: null, loadedBis: null }
    case SET_DATE:
      return { ...state, date: action.date, bis: state.bis || action.date }
    case SET_MONTH:
    case SET_YEAR:
      if (action.silent) {
        return state
      }
      let d = new Date([action.year, action.month, 1])
      return { ...state, date: d, bis: d, loadedVon: null, loadedBis: null }
    case RELOAD:
      return { ...state, bis: state.date, loadedVon: null, loadedBis: null }

    case REQUEST_DATE:
      return { ...state, bis: action.date }
    case SET_LOADED:
      return {
        ...state,
        loadedVon: action.von,
        loadedBis: action.bis
      }
    case LOADED_FEIERTAGE:
      return {
        ...state,
        ftg: { ...state.ftg, ...action.ftg }
      }
  }
  return state
}
