import {
  LOAD_KALENDER,
  LOAD_KALENDERMONAT,
  LOAD_KALUEBERSICHT2,
  EDIT_KALENDERMONAT,
  EDIT_KALENDER,
  UPDATE_KALENDER,
  CREATE_KALENDER,
  UPDATE_KALENDERMONAT,
  LOAD_KALUEBERSICHTQUERY,
  LOAD_SOLLZEIT_JAHR,
  LOAD_FEIERTAGSKALENDER
} from "../actions/kalender_actions"
import { SELECT_CELL } from "../actions/selection"
//import { arrayToObject } from "app/shared/util/conversion"
// import { LOAD_FEHLZEITEN, LOAD_FEHLZEITENDEF } from "../actions/fehlzeiten"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { arrayToObjectFn } from "app/shared/util/conversion"
import Datetime from "app/shared/dateutil/index"
// import { arrayToObjectFn } from "app/shared/util/conversion"
export function Kalender(
  state = {
    error: null,
    isLoading: false,
    kalendermonat: {},
    kaluebersicht: [],
    uebersicht: [],
    sollzeit: {},
    selection: {}
  },
  action
) {
  switch (action.type) {
    case LOAD_FEIERTAGSKALENDER:
      switch (action.status) {
        case STARTED:
          return { ...state, jahr: action.jahr, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            [`${action.land_id}-${action.jahr}`]: action.data.sort((a, b) => {
              if (Datetime.fromISO(a.jahr) <= Datetime.fromISO(b.jahr)) {
                return -1
              } else {
                return 1
              }
            }),
            isLoading: false
          }
        case ERROR:
          return {
            ...state,
            jahr: action.jahr,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_KALENDER:
      switch (action.status) {
        case STARTED:
          return { ...state, jahr: action.jahr, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            [`${action.int_id}-${action.jahr}`]: action.data.sort((a, b) => {
              if (Datetime.fromISO(a.jahr) <= Datetime.fromISO(b.jahr)) {
                return -1
              } else {
                return 1
              }
            }),
            isLoading: false
          }
        case ERROR:
          return {
            ...state,
            jahr: action.jahr,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_SOLLZEIT_JAHR:
      return {
        ...state,
        sollzeit: arrayToObjectFn(action.data, (o) =>
          Datetime.fromISO(o.datum).toISODate()
        )
      }
    case LOAD_KALENDERMONAT:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            isLoading: true,
            error: null
          }
        case SUCCESS:
          return {
            ...state,
            kalendermonat: {
              ...state.kalendermonat,
              [action.int_id]: action.data
            },
            isLoading: false
          }
        case ERROR:
          return {
            ...state,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_KALUEBERSICHT2:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            isLoading: true,
            error: null
          }
        case SUCCESS:
          return {
            ...state,
            uebersicht: action.data,
            isLoading: false
          }
        case ERROR:
          return {
            ...state,
            isLoading: false,
            error: action.error
          }
      }
      break
    case LOAD_KALUEBERSICHTQUERY:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            uebersicht: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_KALENDER:
      let key = `${action.int_id}-${action.year}`
      return {
        ...state,
        [key]: [...state[key]].map((r, i) => {
          if (i == action.month) {
            return { ...r, [action.key]: action.value, __dirty: true }
          }
          return r
        })
      }
    case EDIT_KALENDERMONAT:
      return {
        ...state,
        kalendermonat: {
          ...state.kalendermonat,
          [action.int_id]: {
            ...state.kalendermonat[action.int_id],
            ...action.changes,
            __dirty: true
          }
        }
      }

    case UPDATE_KALENDER:
      if (action.status == SUCCESS) {
        let key = `${action.mitarbeiter_id}-${action.year}`
        return {
          ...state,

          [key]: state[key].map((row) => {
            if (row.jahr == action.data.jahr) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case UPDATE_KALENDERMONAT:
      if (action.status == SUCCESS) {
        return {
          ...state,
          kalendermonat: {
            ...state.kalendermonat,
            [action.int_id]: action.data
          }
        }
      }
      return state
    case SELECT_CELL:
      return { ...state, selection: Selection(state.selection, action) }
  }

  return state
}

const Selection = (state = {}, action) => {
  switch (action.type) {
    case SELECT_CELL:
      if (action.extend) {
        return {
          ...state,
          [`${action.Year}-${action.Month}-${action.Day}-${action.VN}`]: true
        }
      }
      return {
        [`${action.Year}-${action.Month}-${action.Day}-${action.VN}`]: true
      }
  }
  return state
}
