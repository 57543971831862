import { LOAD_HISTORY } from "../actions/history"

export default function History(state = {}, action) {
  switch (action.type) {
    case LOAD_HISTORY:
      return {
        ...state,
        [`${action.int_id}-${action.datum}`]: action.data
      }
  }
  return state
}
