import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import request from "superagent"
import swal from "sweetalert"
export const LOAD_GROUP = "LOAD_GROUP"
export const EDIT_TEXT = "EDIT_TEXT"
export const LOAD_GROUPEMPF = "LOAD_GROUPEMPF"
export const NEW_GROUP = "NEW_GROUP"
export const NEW_GROUPEMPF = "NEW_GROUPEMPF"
export const UPDATE_GROUP = "UPDATE_GROUP"
export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUPEMPF = "DELETE_GROUPEMPF"
export const EDIT_GROUP_EMPF = "EDIT_GROUP_EMPF"
export const LOAD_GROUPZUORD = "LOAD_GROUPZUORD"
export const NEW_GROUPZUORD = "NEW_GROUPZUORD"
export const DELETE_GROUPZUORD = "DELETE_GROUPZUORD"
export const EDIT_GROUP_ZUORD = "EDIT_GROUP_ZUORD"
export const LOAD_GROUPGROUP_ZUORD = "LOAD_GROUPGROUP_ZUORD"
export const LOAD_GROUPGROUP_ZUORD_ID = "LOAD_GROUPGROUP_ZUORD_ID"
export const EDIT_GROUPGROUP_ZUORD = "EDIT_GROUPGROUP_ZUORD"
export const ADD_GROUPGROUP_ZUORD_ROW = "ADD_GROUPGROUP_ZUORD_ROW"
export const DELETED_GROUPGROUP_ZUORD_ROW = "DELETED_GROUPGROUP_ZUORD_ROW"
export const DELETE_GROUPGROUP_ZUORD_ROW = "DELETE_GROUPGROUP_ZUORD_ROW"
export const INSERT_GROUPGROUP_ZUORD = "INSERT_GROUPGROUP_ZUORD"
export const UPDATE_GROUPGROUP_ZUORD = "UPDATE_GROUPGROUP_ZUORD"
export const NEW_GROUPGROUPZUORD = "NEW_GROUPGROUPZUORD"

export function EditText(group_id, changes) {
  return {
    type: EDIT_TEXT,
    group_id,
    changes
  }
}

const feldGruppeNamen = {
  group_bez: "Gruppe",
  group_id: "Gruppe",
  user_id: "Mitarbeiter"
}

const defaultGruppePflichtFelder = {
  group_bez: true,
  group_id: true,
  user_id: true
}
export function CheckGroupNew(felder, data) {
  for (let feld of felder) {
    if (defaultGruppePflichtFelder[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldGruppeNamen[feld] || feld
        return `Bitte ${feldName} angeben !`
      }
    }
  }
  if (data.group_bez.length > 30) {
    return "Gruppe nicht mehr 30 Zeichen erlaubt !"
  }
  return null
}
export function CheckGroupEmpfNew(felder, data) {
  for (let feld of felder) {
    if (defaultGruppePflichtFelder[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldGruppeNamen[feld] || feld
        return `Bitte ${feldName} auswählen!`
      }
    }
  }
  return null
}

export function EditGroupEmpfaenger(group_id, id, changes) {
  return {
    type: EDIT_GROUP_EMPF,
    group_id,
    id,
    changes
  }
}

export function CreateGroupSuccess(data) {
  return {
    type: NEW_GROUP,
    status: SUCCESS,
    data
  }
}
export function CreateGroup(data) {
  return (dispatch) => {
    request
      .post("/api/security/group/new")
      .send(data)
      .end((err, response) => {
        dispatch(CreateGroupSuccess(response.body))
      })
  }
}

export function DeleteGroupSuccess(group_id, data) {
  return {
    type: DELETE_GROUP,
    status: SUCCESS,
    group_id,
    data
  }
}
export function DeleteGroup(group_id, data) {
  return (dispatch) => {
    request
      .delete(`/api/security/group/${group_id}/groups`)
      .send(data)
      .end((err, response) => {
        dispatch(DeleteGroupSuccess(group_id, response.body))
      })
  }
}

export function UpdateGroupSuccess(id, data) {
  return {
    type: UPDATE_GROUP,
    status: SUCCESS,
    id,
    data
  }
}
export function UpdateGroup() {
  return (dispatch, getState) => {
    let data = getState().SecurityGroup.group || {}
    for (let rows of Object.values(data)) {
      if (data.length == 0) {
        return
      }
      for (let row of data) {
        if (row.__dirty && !row.__deleted) {
          request
            .put(`/api/security/group`)
            .send(row)
            .end((err, response) => {
              dispatch(UpdateGroupSuccess(row.id, response.body))
            })
        }
      }
    }
  }
}

export function LoadGroupSuccess(data) {
  return {
    type: LOAD_GROUP,
    status: SUCCESS,
    data
  }
}
export function LoadGroup() {
  return (dispatch) => {
    request.get("/api/security/group").end((err, response) => {
      dispatch(LoadGroupSuccess(response.body))
    })
  }
}

export function LoadGroupEmpfSuccess(group_id, data) {
  return {
    type: LOAD_GROUPEMPF,
    status: SUCCESS,
    group_id,
    data
  }
}
export function LoadGroupEmpf(group_id) {
  return (dispatch) => {
    request.get(`/api/security/groupempf/${group_id}`).end((err, response) => {
      dispatch(LoadGroupEmpfSuccess(group_id, response.body))
    })
  }
}

export function CreateGroupEmpfSuccess(data) {
  return {
    type: NEW_GROUPEMPF,
    status: SUCCESS,
    data
  }
}
export function CreateGroupEmpf(data) {
  return (dispatch) => {
    request
      .post("/api/security/groupempf/new")
      .send(data)
      .end((err, response) => {
        dispatch(CreateGroupEmpfSuccess(response.body))
      })
  }
}

export function DeleteGroupEmpfSuccess(group_id, id, data) {
  return {
    type: DELETE_GROUPEMPF,
    status: SUCCESS,
    group_id,
    id,
    data
  }
}
export function DeleteGroupEmpf(group_id, id, data) {
  return (dispatch) => {
    request
      .delete(`/api/security/groupempf/${id}/empf`)
      .send(data)
      .end((err, response) => {
        dispatch(DeleteGroupEmpfSuccess(group_id, id, response.body))
      })
  }
}

export function LoadGroupZuordSuccess(group_id, data) {
  return {
    type: LOAD_GROUPZUORD,
    status: SUCCESS,
    group_id,
    data
  }
}
export function LoadGroupZuord(group_id) {
  return (dispatch) => {
    request.get(`/api/security/groupzuord/${group_id}`).end((err, response) => {
      dispatch(LoadGroupZuordSuccess(group_id, response.body))
    })
  }
}

export function CreateGroupZuordSuccess(data) {
  return {
    type: NEW_GROUPZUORD,
    status: SUCCESS,
    data
  }
}
export function CreateGroupZuord(data) {
  return (dispatch) => {
    request
      .post("/api/security/groupzuord/new")
      .send(data)
      .end((err, response) => {
        dispatch(CreateGroupZuordSuccess(response.body))
      })
  }
}

export function DeleteGroupZuordSuccess(group_id, id, data) {
  return {
    type: DELETE_GROUPZUORD,
    status: SUCCESS,
    group_id,
    id,
    data
  }
}
export function DeleteGroupZuord(group_id, id, data) {
  return (dispatch) => {
    request
      .delete(`/api/security/groupzuord/${id}/empf`)
      .send(data)
      .end((err, response) => {
        dispatch(DeleteGroupZuordSuccess(group_id, id, response.body))
      })
  }
}
export function EditGroupZuordn(user_id, id, changes) {
  return {
    type: EDIT_GROUP_ZUORD,
    user_id,
    id,
    changes
  }
}

// export function LoadGroupGroupZuordSuccess(data) {
//   return {
//     type: LOAD_GROUPGROUP_ZUORD,
//     status: SUCCESS,
//     data
//   }
// }
// export function LoadGroupGroupZuord() {
//   return (dispatch) => {
//     request.get(`/api/security/groupgroupzuord`).end((err, response) => {
//       dispatch(LoadGroupGroupZuordSuccess(response.body))
//     })
//   }
// }

export function LoadGroupGroupZuordByGroupIDSuccess(group_id, data) {
  return {
    type: LOAD_GROUPGROUP_ZUORD_ID,
    status: SUCCESS,
    group_id,
    data
  }
}
export function LoadGroupGroupZuordByGroupID(group_id) {
  return (dispatch) => {
    request
      .get(`/api/security/groupgroupzuord/${group_id}`)
      .end((err, response) => {
        dispatch(LoadGroupGroupZuordByGroupIDSuccess(group_id, response.body))
      })
  }
}

export function EditGroupGroupZuord(group_id, id, changes) {
  return {
    type: EDIT_GROUPGROUP_ZUORD,
    group_id,
    id,
    changes
  }
}

export function AddGroupGroupZuordRow(group_id) {
  return {
    type: ADD_GROUPGROUP_ZUORD_ROW,
    group_id
  }
}

export function DeleteGroupGroupZuordRow(group_id, id) {
  return {
    type: DELETE_GROUPGROUP_ZUORD_ROW,
    group_id,
    id
  }
}
export function DeleteGroupGroupZuordSuccess(group_id, id) {
  return {
    type: DELETED_GROUPGROUP_ZUORD_ROW,
    status: SUCCESS,
    group_id,
    id
  }
}

export function InsertGroupGroupZuordSuccess(group_id, data) {
  return {
    type: INSERT_GROUPGROUP_ZUORD,
    status: SUCCESS,
    group_id,
    data
  }
}

function CheckGroupGroupZuord(groupgroupzuord) {
  for (let row of groupgroupzuord) {
    if (row.typ == null) {
      return "bitte Vorgesetzten / Stellvertreter auswählen"
    }
    if (row.zuord == null) {
      return "bitte Gruppierung auswählen"
    }
    if (row.zuord_id == null) {
      return "bitte Gruppe auswählen"
    }
  }
  return null
}

export function UpdateGroupGroupZuordSuccess(group_id, id, data) {
  return {
    type: UPDATE_GROUPGROUP_ZUORD,
    status: SUCCESS,
    group_id,
    id,
    data
  }
}
export function UpdateGroupGroupZuord(group_id) {
  return (dispatch, getState) => {
    let data = getState().SecurityGroup.groupgroupzuord[group_id] || []

    if (data.length == 0) {
      return
    }
    var err = CheckGroupGroupZuord(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/security/groupgroupzuord/${group_id}/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(
              DeleteGroupGroupZuordSuccess(group_id, row.id, response.body)
            )
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post(`/api/security/groupgroupzuord/${group_id}`)
          .send(r)
          .end((err, response) => {
            dispatch(UpdateGroupGroupZuordSuccess(group_id, id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put(`/api/security/groupgroupzuord/${group_id}`)
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdateGroupGroupZuordSuccess(group_id, row.id, response.body)
            )
          })
      }
    }
  }
}

export function GroupGroupZuordUnsavedChanges(state) {
  for (let groupgroupzuord of Object.values(state)) {
    // console.log(groupgroupzuord)
    for (let row of groupgroupzuord) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
      // console.log(row)
    }
  }
  return false
}

export function CreateGroupGroupZuordSuccess(data) {
  return {
    type: NEW_GROUPZUORD,
    status: SUCCESS,
    data
  }
}
export function CreateGroupGroupZuord(data) {
  return (dispatch) => {
    request
      .post("/api/security/groupgroupzuord/new")
      .send(data)
      .end((err, response) => {
        dispatch(CreateGroupGroupZuordSuccess(response.body))
      })
  }
}
