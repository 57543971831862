import Datetime from "app/shared/dateutil/index"

export const GetVonBis = (state, count) => {
  const start = Datetime.fromISO(state.UPL.Datum).startOf("month")
  const end = start.plus({ days: count }).endOf("month").startOf("day") //.toISODate()
  const loadingStatus = state.UPL.LoadingStatus
  const loadedVon = Datetime.fromISO(loadingStatus.von)
  const loadedBis = Datetime.fromISO(loadingStatus.bis)
  let loadVon = start
  let loadBis = end
  let setLoadedVon = start
  let setLoadedBis = end
  if (loadedVon.isValid() && loadedBis.isValid()) {
    if (start >= loadedVon && end <= loadedBis) {
      return { loadVon, loadBis, setLoadedVon, setLoadedBis, count: 0 }
    }
    if (start < loadedVon) {
      if (end > loadedVon) {
        loadBis = loadedVon
      }
    }
    if (end > loadedBis) {
      if (start < loadedBis) {
        loadVon = loadedBis
      }
    }
  }
  count = loadVon.diffDays(loadBis)
  return { loadVon, loadBis, setLoadedVon, setLoadedBis, count }
}
