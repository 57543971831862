import request from "superagent"
export const LOADED_HISTORY = "pzw/kalhistory/LOADED_HISTORY"

export const LoadHistory = (IntID, Year, YearBis) => {
  return (dispatch) => {
    request
      .get(`/api/p/${IntID}/personalkalenderhistory2/${Year}/${YearBis}`)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOADED_HISTORY,
            data: response.body
          })
        }
      })
  }
}
