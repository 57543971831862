import request from "superagent"

export const LOAD_ZEITKONTO = "ZK/LOAD_ZEITKONTO"

export const LoadZeitkonto = (IntID, Year, Month, useArion) => {
  return (dispatch) => {
    request
      .get(`/api/p/${IntID}/monatskonto/${Year}/${Month}?useArion=${useArion}`)
      .end((err, response) => {
        dispatch({
          type: LOAD_ZEITKONTO,
          IntID,
          Year,
          Month,
          data: response.body
        })
      })
  }
}
