import Datetime, { Parse, ISO } from "app/shared/dateutil/index"
// import * as dateFns from "date-fns"

// var locales = {
//   en: require("date-fns/locale/en"),
//   eo: require("date-fns/locale/eo"),
//   ru: require("date-fns/locale/ru"),
//   de: require("date-fns/locale/de")
// }

var locale = "de"

// export function isSameOrBefore(d1, d2) {
//   return dateFns.isBefore(d1, d2) || dateFns.isEqual(d1, d2)
// }

// export function isSameOrAfter(d1, d2) {
//   return dateFns.isAfter(d1, d2) || dateFns.isEqual(d1, d2)
// }

// export function isAfter(d1, d2) {
//   let dt1 = Date.parse(d1)
//   let dt2 = Date.parse(d2)
//   return dateFns.isAfter(dt1, dt2)
// }

// export function isBefore(d1, d2) {
//   let dt1 = Date.parse(d1)
//   let dt2 = Date.parse(d2)
//   return dateFns.isAfter(dt1, dt2)
// }

export function UTCNow() {
  var now = new Date()
  var utc_timestamp = Date.UTC(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
    now.getMilliseconds()
  )
  return new Date(utc_timestamp)
}

// export function UTCNowFormat() {
//   let d = UTCNow()
//   return dateFns.format(d)
// }

// export function toISODate(d) {
//   return dateFns.format(d, "YYYY-MM-DD") + "T00:00:00Z"
// }

// export function localFormat(d, format) {
//   return dateFns.format(d, format, { locale: locales[locale] })
// }
