export const SELECT_CELL = "KAL/SELECT_CELL"

export const SelectCell = (IntID, Year, Month, Day, VN, extend) => {
  return {
    type: SELECT_CELL,
    IntID,
    Year,
    Month,
    Day,
    VN,
    extend
  }
}
