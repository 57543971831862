import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_DEFINITIONEN = "dpl/LOAD_PLAN_DEFINITIONEN"
export const EDIT_DEFINITION = "dpl/EDIT_DEFINITION"
export const UPDATE_DEFINITION = "dpl/UPDATE_DEFINITION"
export const ADD_DEFINITION = "dpl/ADD_DEFINITION"
export const REMOVE_DEFINITION = "dpl/REMOVE_DEFINITION"

export function EditDefinition(id, changes) {
  if (changes.pers_art) {
    changes.wert = null
  }
  return {
    type: EDIT_DEFINITION,
    id,
    changes
  }
}

export function UpdatedDefinition(PlanID, id, data) {
  return {
    type: UPDATE_DEFINITION,
    status: SUCCESS,
    PlanID,
    id,
    data
  }
}

export function UpdatedDefinitionError(PlanID, id, err) {
  return {
    type: UPDATE_DEFINITION,
    status: ERROR,
    PlanID,
    id,
    err
  }
}

export function AddDefinition(PlanID) {
  return {
    type: ADD_DEFINITION,
    PlanID
  }
}

export function RemoveDefinition(PlanID, id) {
  return {
    type: REMOVE_DEFINITION,
    PlanID,
    id
  }
}

export function UpdateDefinitionen(PlanID) {
  return (dispatch, getState) => {
    let state = getState()
    let definitionen =
      (state.Dpl.Definitionen[PlanID] && state.Dpl.Definitionen[PlanID].data) ||
      []
    for (let def of definitionen) {
      if (def.__new) {
        if (def.__deleted) {
          continue
        }
        def = { ...def }
        let id = def.id
        delete def.id
        request
          .post("/api/dpl/basis/zuordnung")
          .send(def)
          .end((err, response) => {
            if (err) {
              dispatch(UpdatedDefinitionError(PlanID, id, err))
            } else {
              dispatch(UpdatedDefinition(PlanID, id, response.body))
            }
          })
      } else if (def.__dirty) {
        request
          .put(`/api/dpl/basis/zuordnung/${def.id}`)
          .send(def)
          .end((err, response) => {
            if (err) {
              dispatch(UpdatedDefinitionError(PlanID, def.id, err))
            } else {
              dispatch(UpdatedDefinition(PlanID, def.id, response.body))
            }
          })
      } else if (def.__deleted) {
        request.delete(`/api/dpl/basis/zuordnung/${def.id}`).end((err) => {
          if (err) {
            dispatch(UpdatedDefinitionError(PlanID, def.id, err))
          } else {
            dispatch(UpdatedDefinition(PlanID, def.id, null))
          }
        })
      }
    }
  }
}

export function LoadPlanDefinitionenStarted(PlanID) {
  return {
    type: LOAD_DEFINITIONEN,
    status: STARTED,
    PlanID
  }
}

export function LoadPlanDefinitionenError(PlanID, err) {
  return {
    type: LOAD_DEFINITIONEN,
    status: ERROR,
    PlanID,
    err
  }
}

export function LoadPlanDefinitionenSuccess(PlanID, data) {
  return {
    type: LOAD_DEFINITIONEN,
    status: SUCCESS,
    PlanID,
    data
  }
}

export function LoadPlanDefinitionen(PlanID) {
  return (dispatch) => {
    dispatch(LoadPlanDefinitionenStarted(PlanID))
    request.get(`/api/dpl/basis/zuordnungen/${PlanID}`).end((err, response) => {
      if (err) {
        dispatch(LoadPlanDefinitionenError(PlanID, err))
      } else {
        dispatch(LoadPlanDefinitionenSuccess(PlanID, response.body))
      }
    })
  }
}
