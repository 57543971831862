export const FzDef = (state, IntID, fehlz_id) => {
  let p = state.Dpl.AllPers.pers[IntID]
  let gKey = p.fz_gkey
  let abr_kreis = p.abr_kreis
  for (let fzdef of state.Dpl.FehlzeitDefinitionen) {
    if (fzdef.abr_kreis != abr_kreis) {
      continue
    }
    if (fzdef.fehlz_id != gKey) {
      continue
    }
    if (fzdef.fehlz_id == fehlz_id) {
      return fzdef
    }
  }
  for (let fzdef of state.Dpl.FehlzeitDefinitionen) {
    if (fzdef.abr_kreis != abr_kreis && fzdef.abr_kreis != 0) {
      continue
    }
    if (fzdef.g_key != gKey) {
      continue
    }
    if (fzdef.fehlz_id == fehlz_id) {
      return fzdef
    }
  }
  for (let fzdef of state.Dpl.FehlzeitDefinitionen) {
    if (fzdef.abr_kreis != abr_kreis && fzdef.abr_kreis != 0) {
      continue
    }
    if (fzdef.g_key != gKey && fzdef.g_key != " " && fzdef.g_key != "") {
      continue
    }
    if (fzdef.fehlz_id == fehlz_id) {
      return fzdef
    }
  }
  return {}
}
