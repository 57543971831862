export const WS_EVENT = "WS/WS_EVENT"
export const WS_EVENT_PROCESSED = "WS/WS_EVENT_PROCESSED"
const globalIgnored = {
  progress: true
}

export function OnEvent(event) {
  return {
    type: WS_EVENT,
    data: event
  }
}

export function SetEventProcessed(event) {
  return {
    type: WS_EVENT_PROCESSED,
    data: event
  }
}
