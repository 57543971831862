import { SELECT_CELL, CLEAR_SELECTION } from "../actions/selection"

export const Selection = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_SELECTION:
      return {}
    case SELECT_CELL:
      return {
        ...state,
        [`${action.int_id}-${action.datum}-${action.kz}`]: true
      }
  }
  return state
}
