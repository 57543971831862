import { createSelector } from "reselect"

export const IntIDs = (state) => {
  return state.UPL.Data.int_ids || []
}

export const PersO = (state) => {
  return state.UPL.Data.pers
}

export const Pers = createSelector(IntIDs, PersO, (int_ids, p) => {
  return int_ids.map((i) => p[i])
})

export const GetPers = (state, int_id) => {
  return PersO(state)[int_id]
}

export const Urlaub = (state) => {
  return state.UPL.Data.urlaub
}
