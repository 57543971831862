import request from "superagent"

export const FETCH_PERMISSION = "FETCH_PERMISSION"
export const FETCH_VALUE_PERMISSION = "FETCH_VALUE_PERMISSION"

export const FETCH_APP_PERMISSION = "FETCH_APP_PERMISSION"

export const fetchPermissionStatus = {
  start: "FETCH_PERMISSION_START",
  success: "FETCH_PERMISSION_SUCCESS",
  error: "FETCH_PERMISSION_ERROR"
}

export function fetchPermissionStart(group, name, int_id) {
  return {
    type: FETCH_PERMISSION,
    status: fetchPermissionStatus.start,
    group,
    name,
    int_id
  }
}

export function fetchPermissionSuccess(group, name, int_id, permission) {
  return {
    type: FETCH_PERMISSION,
    status: fetchPermissionStatus.success,
    group,
    name,
    int_id,
    permission
  }
}

export function fetchPermissionError(group, name, int_id, error) {
  return {
    type: FETCH_PERMISSION,
    status: fetchPermissionStatus.error,
    group,
    name,
    int_id,
    error
  }
}

export function fetchValuePermissionStart(group, name, valueName, value) {
  return {
    type: FETCH_VALUE_PERMISSION,
    status: fetchPermissionStatus.start,
    group,
    name,
    valueName,
    value
  }
}

export function fetchValuePermissionSuccess(
  group,
  name,
  valueName,
  value,
  permission
) {
  return {
    type: FETCH_VALUE_PERMISSION,
    status: fetchPermissionStatus.success,
    group,
    name,
    valueName,
    value,
    permission
  }
}

export function fetchValuePermissionError(
  group,
  name,
  valueName,
  value,
  error
) {
  return {
    type: FETCH_VALUE_PERMISSION,
    status: fetchPermissionStatus.error,
    group,
    name,
    valueName,
    value,
    error
  }
}

export function fetchAppPermissionStart(group, name) {
  return {
    type: FETCH_APP_PERMISSION,
    status: fetchPermissionStatus.start,
    group,
    name
  }
}

export function fetchAppPermissionSuccess(group, name, permission) {
  return {
    type: FETCH_APP_PERMISSION,
    status: fetchPermissionStatus.success,
    group,
    name,
    permission
  }
}

export function fetchAppPermissionError(group, name, error) {
  return {
    type: FETCH_APP_PERMISSION,
    status: fetchPermissionStatus.error,
    group,
    name,
    error
  }
}

export function fetchPermission(group, name, int_id) {
  return (dispatch, getState) => {
    if (getState().Permissions[`${group}/${name}/${int_id}`] !== undefined) {
      return
    }
    dispatch(fetchPermissionStart(group, name, int_id))
    return request
      .get(`/api/perms/int_id/${group}/${name}/${int_id}`)
      .end((err, res) => {
        if (err) {
          dispatch(fetchPermissionError(group, name, int_id, err))
        } else {
          dispatch(
            fetchPermissionSuccess(group, name, int_id, res.body.permission)
          )
        }
      })
  }
}

export function fetchValuePermission(group, name, valueName, value) {
  return (dispatch, getState) => {
    if (
      getState().Permissions[`${group}/${name}/${valueName}/${value}`] !==
      undefined
    ) {
      return
    }
    dispatch(fetchValuePermissionStart(group, name, valueName, value))
    return request
      .get(`/api/perms/value/${valueName}/${group}/${name}/${value}`)
      .end((err, res) => {
        if (err) {
          dispatch(
            fetchValuePermissionError(group, name, valueName, value, err)
          )
        } else {
          dispatch(
            fetchValuePermissionSuccess(
              group,
              name,
              valueName,
              value,
              res.body.permission
            )
          )
        }
      })
  }
}

export function fetchAppPermission(group, name) {
  return (dispatch, getState) => {
    if (getState().Permissions[`${group}/${name}`] !== undefined) {
      return
    }
    dispatch(fetchAppPermissionStart(group, name))
    return request.get(`/api/perms/app/${group}/${name}`).end((err, res) => {
      if (err) {
        dispatch(fetchAppPermissionError(group, name, err))
      } else {
        dispatch(fetchAppPermissionSuccess(group, name, res.body.permission))
      }
    })
  }
}
