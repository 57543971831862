import {
  LOAD_TAGESTEXTEMONAT,
  LOAD_TAGESTEXTDATUM,
  UPDATE_TAGESTEXT,
  EDIT_TAGESTEXTE,
  DELETE_TAGESTEXT,
  UPDATE_TAGESTEXTFP,
  DELETE_TAGESTEXTFP
} from "./actions"
import { SUCCESS } from "app/state/constants"

export function TagesTexte(state = {}, action) {
  switch (action.type) {
    case LOAD_TAGESTEXTDATUM:
    case LOAD_TAGESTEXTEMONAT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            [action.int_id]: action.data
          }
      }
      break
    case DELETE_TAGESTEXT:
      return {
        ...state,
        [action.int_id]: state[action.int_id].filter((row) => {
          return row.datum != action.datum
        })
      }
    case DELETE_TAGESTEXTFP:
      return {
        ...state,
        [action.int_id]: state[action.int_id].filter((row) => {
          return row.datum != action.datum
        })
      }
    case UPDATE_TAGESTEXT:
      if (action.status == SUCCESS) {
        return {
          ...state,
          [action.int_id]: state[action.int_id].map((row) => {
            if (row.datum == action.datum) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case UPDATE_TAGESTEXTFP:
      if (action.status == SUCCESS) {
        return {
          ...state,
          [action.int_id]: state[action.int_id].map((row) => {
            if (row.datum == action.datum) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_TAGESTEXTE:
      if (action.isNew == true) {
        return {
          ...state,
          [action.int_id]: [
            ...state[action.int_id],
            {
              int_id: action.int_id,
              datum: action.datum,
              text: action.changes.text,
              __new: true
            }
          ]
        }
      } else {
        return {
          ...state,
          [action.int_id]: state[action.int_id].map((row) => {
            if (row.datum == action.datum) {
              return {
                ...row,
                ...action.changes,
                __dirty: true,
                __deleted: action.isDeleted
              }
            }
            return row
          })
        }
      }
  }
  return state
}
