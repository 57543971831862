import {
  SET_ROW_VISIBILITY,
  SET_SHOW_PLUS_MINUS,
  SET_SHOW_SUM
} from "../actions/settings"

export const RowVisibility = (
  state = { W: true, P: true, K: true, C: true },
  action
) => {
  switch (action.type) {
    case SET_ROW_VISIBILITY:
      return { ...state, [action.key]: action.value }
  }
  return state
}

export const SumSettings = (
  state = { showSum: false, showPlusMinus: false },
  action
) => {
  switch (action.type) {
    case SET_SHOW_SUM:
      return { ...state, showSum: !state.showSum }
    case SET_SHOW_PLUS_MINUS:
      return { ...state, showPlusMinus: !state.showPlusMinus }
  }
  return state
}
