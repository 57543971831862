import {
  CREATE_GENEHMIGUNG,
  CREATE_GENEHMIGUNGSVORGANG,
  LOAD_GENEHMIGUNG,
  UPDATE_GENEHMIGUNG,
  UPDATE_GENEHMIGUNGSVORGANG
} from "../actions/genehmigung"

export const Genehmigung = (state = [], action) => {
  switch (action.type) {
    case LOAD_GENEHMIGUNG:
      return action.data
    case CREATE_GENEHMIGUNGSVORGANG:
      return [...state, action.data]
    case UPDATE_GENEHMIGUNGSVORGANG:
      return state.map((v) => {
        if (v.id == action.data.id) {
          return { ...action.data, work: v.work }
        }
        return v
      })
    case CREATE_GENEHMIGUNG:
      return state.map((s) => {
        if (s.id == action.data.gene_id) {
          return { ...s, work: [...(s.work || []), action.data] }
        }
        return s
      })
    case UPDATE_GENEHMIGUNG:
      return state.map((s) => {
        if (s.id == action.data.gene_id) {
          return {
            ...s,
            work: s.work.map((w) => {
              if (w.id == action.data.id) {
                return action.data
              }
              return w
            })
          }
        }
        return s
      })
  }
  return state
}
