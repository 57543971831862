import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import without from "lodash/without"
import {
  LOAD_ROLESPERM,
  ADD_ROLESPERM_ROW,
  UPDATE_ROLESPERM,
  DELETE_ROLESPERM_ROW,
  DELETED_ROLESPERM_ROW,
  EDIT_ROLESPERM,
  LOAD_ROLES,
  SELECT_ROLE,
  NEW_ROLE,
  DELETE_ROLE_ROW,
  DELETED_ROLE_ROW,
  UPDATE_ROLE,
  EDIT_ROLE,
  LOAD_ROLESREPORT
} from "./role_actions"
import { arrayToObject } from "app/shared/util/conversion"
import { v4 as uuidv4 } from "uuid"

export default function Roles(
  state = {
    selectedRole: null,
    roles: {
      selectedRole: null,
      isFetching: false,
      error: null,
      data: {},
      allIds: []
    },
    rolesReport: {
      data: {}
    },
    rolesPerm: {
      data: {}
    }
  },
  action
) {
  switch (action.type) {
    case LOAD_ROLESREPORT:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            rolesReport: {
              ...state.rolesReport,
              [action.role_id]: action.data
            },
            isLoading: false,
            error: null
          }
      }
      break
    case SELECT_ROLE:
      return {
        ...state,
        roles: {
          ...state.roles,
          selectedRole: action.role_id
        }
      }
    case EDIT_ROLE:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: {
            ...state.roles.data,
            [action.role_id]: {
              ...state.roles.data[action.role_id],
              ...action.changes,
              __dirty: true
            }
          }
        }
      }
    case NEW_ROLE:
      return {
        ...state,
        roles: {
          ...state.roles,
          selectedRole: action.role_id,
          data: {
            ...state.roles.data,
            [action.role_id]: {
              role_id: action.role_id,
              role_name: "",
              __new: true,
              __dirty: true
            }
          },
          allIds: [...state.roles.allIds, action.role_id]
        }
      }
    case LOAD_ROLES:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            roles: {
              ...state.roles,
              isFetching: false,
              error: null,
              data: arrayToObject(action.data, "role_id"),
              allIds: action.data.map((role) => role.role_id)
            }
          }
        case ERROR:
          return {
            ...state,
            roles: { ...state.roles, isFetching: false, error: action.error }
          }
        case STARTED:
          return { ...state, roles: { ...state.roles, isFetching: true } }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
    case DELETED_ROLE_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          roles: {
            ...state.roles,
            [action.role_id]: state.roles[action.role_id].filter((row) => {
              if (row.id == action.id) {
                return false
              }
              return true
            })
          }
        }
      }
      return state
    case DELETE_ROLE_ROW:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: {
            ...state.roles.data,
            [action.role_id]: {
              ...state.roles.data[action.role_id],
              __deleted: true,
              __dirty: true
            }
          },
          allIds: state.roles.allIds.filter((id) => id !== action.role_id)
        }
      }
    case UPDATE_ROLE:
      if (action.status == SUCCESS) {
        let roleData = { ...state.roles.data }
        delete roleData[action.role_id]
        roleData[action.data.role_id] = action.data
        let newSelected = state.roles.selectedRole
        if (newSelected == action.role_id) {
          newSelected = action.data.role_id
        }
        return {
          ...state,
          roles: {
            ...state.roles,
            selectedRole: newSelected,
            data: roleData,
            allIds: [
              ...without(state.roles.allIds, action.role_id),
              action.data.role_id
            ]
          }
        }
      }
      return state
    case ADD_ROLESPERM_ROW:
      return {
        ...state,
        rolesPerm: {
          ...state.rolesPerm,
          [action.role_id]: [
            ...state.rolesPerm[action.role_id],
            {
              __new: true,
              id: uuidv4(),
              role_id: action.role_id
            }
          ]
        }
      }
    case EDIT_ROLESPERM:
      return {
        ...state,
        rolesPerm: {
          ...state.rolesPerm,
          [action.role_id]: state.rolesPerm[action.role_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case LOAD_ROLESPERM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            rolesPerm: {
              ...state.rolesPerm,
              [action.role_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_ROLESPERM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rolesPerm: {
            ...state.rolesPerm,
            [action.role_id]: state.rolesPerm[action.role_id].map((row) => {
              if (row.id == action.id) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    case DELETED_ROLESPERM_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rolesPerm: {
            ...state.rolesPerm,
            [action.role_id]: state.rolesPerm[action.role_id].filter((row) => {
              if (row.id == action.id) {
                return false
              }
              return true
            })
          }
        }
      }
      return state
    case DELETE_ROLESPERM_ROW:
      return {
        ...state,
        rolesPerm: {
          ...state.rolesPerm,
          [action.role_id]: state.rolesPerm[action.role_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true, __dirty: true }
            }
            return row
          })
        }
      }
  }
  return state
}
