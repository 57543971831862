import { createSelector } from "reselect"
import { Datum } from "./datum"
import { Personen, SortGroup, SortLimitsByGroup, SortLimitsByID } from "./pers"
import { create } from "lodash"
import { DateTime } from "luxon"
import Datetime from "app/shared/dateutil/index"

export const Zeitmodell = (state, DpSymb, datum) => {
  let d = Datetime.fromISO(datum)
  for (let zord of state.Dpl.DpZord.dp) {
    if (zord.dpl_symb == DpSymb) {
      if (
        Datetime.fromISO(zord.ab_datum) <=
        d <=
        Datetime.fromISO(zord.bis_dat || "2900-01-01")
      ) {
        for (let m of zord.modell) {
          if (
            Datetime.fromISO(m.ab_datum) <=
            d <=
            Datetime.fromISO(m.bis_dat || "2900-01-01")
          ) {
            return m
          }
        }
      }
    }
  }
  return {}
}

export const Records = (state) => {
  return state.Dpl.Records.byID
}

export const SollRecordByID = (state, RecordID) => {
  return Records(state)[RecordID + "-dp-S"]
}

export const PlanRecordByID = (state, RecordID) => {
  return Records(state)[RecordID + "-dp-P"]
}

export const IstRecordByID = (state, RecordID) => {
  return Records(state)[RecordID + "-dp-S"]
}

export const RbRecordByID = (state, RecordID) => {
  return Records(state)[RecordID + "-rb-S"]
}

export const PlaceHolders = (state) => {
  return state.Dpl.PlaceHolders
}

export const AfByPlaceHolder = createSelector(PlaceHolders, (placeholders) => {
  let result = {}
  for (let [key, val] of Object.entries(placeholders)) {
    if (val == "--") {
      result[key] = true
    }
  }
  return result
})

const LoadedVon = (state) => {
  return state.Dpl.Datum.loadedVon
}

const LoadedBis = (state) => {
  return state.Dpl.Datum.loadedBis
}

export const VisibleRecords = createSelector(
  LoadedVon,
  LoadedBis,
  Records,
  (loadedVon, loadedBis, records) => {
    let result = {}
    let dispVon = Datetime.fromISO(loadedVon)
    let dispBis = Datetime.fromISO(loadedBis)
    for (let r of Object.values(records)) {
      let datum = Datetime.fromISO(r.datum)
      if (dispVon <= datum && datum <= dispBis) {
        result["" + r.id + "-" + r.dprb + "-" + r.art] = r
      }
    }
    return result
  }
)

export const RecordsByPlaceHolder = createSelector(
  PlaceHolders,
  (placeholders) => {
    let result = {}
    for (let [key, val] of Object.entries(placeholders)) {
      if (val != "--" && val != "") {
        result[key] = true
      }
    }
    return result
  }
)

export const RecordsByIntIDDatum = createSelector(VisibleRecords, (records) => {
  let result = {}
  for (let r of Object.values(records)) {
    if (r.__deleted) {
      continue
    }
    let key = Datetime.fromISO(r.datum).toISODate()
    let prevR = result[`${r.int_id}-${key}-${r.dprb}-${r.art}`] || []
    result[`${r.int_id}-${key}-${r.dprb}-${r.art}`] = [...prevR, r]
  }
  return result
})

export const Kalender = (state) => {
  return state.Dpl.Records.kalender
}

export const VisibleKalender = createSelector(
  LoadedVon,
  LoadedBis,
  Kalender,
  (loadedVon, loadedBis, kalender) => {
    let result = {}
    let dispVon = Datetime.fromISO(loadedVon)
    let dispBis = Datetime.fromISO(loadedBis)
    for (let [key, r] of Object.entries(kalender)) {
      let datum = Datetime.fromISO(r.datum)
      if (dispVon <= datum && datum <= dispBis) {
        result[key] = r
      }
    }
    return result
  }
)

export const DirtyKalender = createSelector(Kalender, (kalender) => {
  let result = {}
  for (let [key, r] of Object.entries(kalender)) {
    if (r.__dirty || r.__new || r.__deleted) {
      result[key] = r
    }
  }
  return result
})

export const AllRecordsByDienstIDDatum = createSelector(
  VisibleRecords,
  (records) => {
    let result = {}
    for (let r of Object.values(records)) {
      if (r.__deleted) {
        continue
      }
      const key = `${r.dienst_id}-${Datetime.fromISO(r.datum).toISODate()}-${
        r.dprb
      }-${r.art}`
      let prevR = result[key] || []
      result[key] = [...prevR, r]
    }
    return result
  }
)

export const GetAllowedFzByDienst = (state) => {
  return state.Dpl.AllowedFehlzeitenDienst
}

export const RecordsByDienstIDDatum = createSelector(
  VisibleRecords,
  Kalender,
  GetAllowedFzByDienst,
  (records, kalender, allowedFz) => {
    let result = { hiddenByKal: {} }
    for (let r of Object.values(records)) {
      if (r.__deleted) {
        continue
      }
      const isoDatum = Datetime.fromISO(r.datum).toISODate()
      const key = `${r.dienst_id}-${isoDatum}-${r.dprb}-${r.art}`
      let keyFrg = `${r.int_id}-${isoDatum}`
      if (
        kalender[keyFrg] &&
        ((kalender[keyFrg].v && kalender[keyFrg].v.trim()) ||
          (kalender[keyFrg].n && kalender[keyFrg].n.trim()))
      ) {
        let fz = kalender[keyFrg].v || kalender[keyFrg].n
        let prevRKal = result.hiddenByKal[key] || []
        let skip = false
        if (allowedFz[r.dienst_id]) {
          if (allowedFz[r.dienst_id][fz]) {
            skip = true
          }
        }
        if (!skip) {
          result.hiddenByKal[key] = [...prevRKal, { int_id: r.int_id, fz: fz }]
          continue
        }
      }

      let prevR = result[key] || []
      result[key] = [...prevR, r]
    }
    return result
  }
)

export const SwitchDienst = (state) => state.Dpl.Taetigkeiten.switchDienst

export const SwitchedDienste = createSelector(
  RecordsByDienstIDDatum,
  SwitchDienst,
  Datum,
  (records, switched, datum) => {
    var result = {}
    for (let sw of switched) {
      for (let [key, recs] of Object.entries(records)) {
        if (key == "hiddenByKal") {
          continue
        }
        for (let r of recs || []) {
          if (r.dprb != "dp") {
            continue
          }
          if (r.art != "S") {
            continue
          }
          const dIndex = Datetime.fromISO(r.datum).toISODate()
          if (
            sw.ftg &&
            (datum.ftg[dIndex] == "F" || datum.ftg[dIndex] == "FF")
          ) {
          } else {
            let day = Datetime.fromISO(r.datum).day()
            if (sw.weekday != day) {
              continue
            }
          }

          if (r.dienst_id == sw.target || r.dienst_id == sw.target2) {
            result[key] = sw.source_1
            result[
              `${sw.source_1}-${Datetime.fromISO(r.datum).toISODate()}-${
                r.dprb
              }-${r.art}`
            ] = sw.target
            let t2 = sw.target
            if (sw.target2 != null) {
              t2 = sw.target2
            }
            result[
              `${sw.source_2}-${Datetime.fromISO(r.datum).toISODate()}-${
                r.dprb
              }-${r.art}`
            ] = t2
            result[
              `${sw.target}-${Datetime.fromISO(r.datum).toISODate()}-${
                r.dprb
              }-${r.art}`
            ] = sw.source_1
            if (sw.source_2 !== null) {
              result[
                `${sw.target2}-${Datetime.fromISO(r.datum).toISODate()}-${
                  r.dprb
                }-${r.art}`
              ] = sw.source_2
            }
          }
        }
      }
    }
    return result
  }
)

export const MaxRecordsByDienstID = createSelector(
  RecordsByDienstIDDatum,
  (records) => {
    let res = {}
    for (let [k, v] of Object.entries(records)) {
      let dienstID = parseInt(k.split("-")[0])
      if (!res[dienstID] || res[dienstID] < v.length) {
        res[dienstID] = v.length
      }
    }
    return res
  }
)

export const KalenderByFehlzeitDatum = createSelector(Kalender, (kalender) => {
  let result = {}
  for (let kal of Object.values(kalender)) {
    let key = `${kal.v || kal.n}-${Datetime.fromISO(kal.datum).toISODate()}`
    let prevR = result[key] || []
    result[key] = [...prevR, kal]
  }
  return result
})

export const KalenderByDatum = createSelector(VisibleKalender, (kalender) => {
  let result = {}
  for (let kal of Object.values(kalender)) {
    let subKey = `${Datetime.fromISO(kal.datum).toISODate()}`
    if (!kal.v && !kal.n) {
      continue
    }
    let key = `${kal.v || kal.n}`
    let byDate = result[key] || {}
    byDate[subKey] = [...(byDate[subKey] || []), kal]
    result[key] = byDate
  }
  return result
})

export const DirtyRecords = createSelector(Records, (records) => {
  let result = {}
  for (let r of Object.values(records)) {
    if (r.__dirty || r.__new || r.__deleted) {
      result[`${r.id}-${r.dprb}-${r.art}`] = r
    }
  }
  return result
})

export const AfByReocrds = createSelector(VisibleRecords, (records) => {
  let result = {}
  for (let r of Object.values(records)) {
    if (r.art !== "S") {
      continue
    }
    if (r.dpl_symb == "" || r.dpl_symb.trim() == "") {
      continue
    }
    let idx = Datetime.fromISO(r.datum).toISODate()
    let key = `${r.int_id}-${idx}`
    if (result[key] === false) {
      continue
    }
    if (r.dprb == "dp") {
      let ist = records[`${r.id}-dp-I`]
      if (!ist || !ist.dpl_symb) {
        let plan = records[`${r.id}-dp-P`]
        if (plan && plan.dpl_symb) {
          r = plan
        }
      } else {
        r = ist
      }
      if (r.dpl_symb == "--") {
        result[key] = true
      } else {
        result[key] = false
      }
    }
  }
  return result
})

export const CountRecordsNotAf = createSelector(VisibleRecords, (records) => {
  let result = {}
  for (let r of Object.values(records)) {
    if (r.art !== "S") {
      continue
    }
    if (r.dpl_symb == "" || r.dpl_symb.trim() == "") {
      continue
    }
    let idx = Datetime.fromISO(r.datum).toISODate()
    let key = `${r.int_id}-${idx}`
    if (result[key] === false) {
      continue
    }
    if (r.dprb == "dp") {
      let ist = records[`${r.id}-dp-I`]
      if (!ist || !ist.dpl_symb || r.dpl_symb.trim() == "") {
        let plan = records[`${r.id}-dp-P`]
        if (plan && plan.dpl_symb && r.dpl_symb.trim() != "") {
          r = plan
        }
      } else {
        r = ist
      }
      if (r.dpl_symb == "--") {
        result[key] = false
      } else {
        result[key] = true
      }
    }
  }
  return result
})

export const Texte = (state) => {
  return state.Dpl.Records.texte
}

export const TexteByIntIDDatum = createSelector(Texte, (texte) => {
  let result = {}
  for (let r of Object.values(texte)) {
    result[`${r.int_id}-${Datetime.fromISO(r.datum).toISODate()}`] = r
  }
  return result
})

export const DirtyTexte = createSelector(Texte, (texte) => {
  let result = {}
  for (let r of Object.values(texte)) {
    if (r.__dirty || r.__new || r.__deleted) {
      result[r.id] = r
    }
  }
  return result
})

export const PlanTexte = (state) => {
  return state.Dpl.Records.plantexte
}

export const PlanTexteByDatum = createSelector(PlanTexte, (texte) => {
  let result = {}
  for (let r of Object.values(texte)) {
    result[`${Datetime.fromISO(r.datum).toISODate()}`] = r
  }
  return result
})

export const DirtyPlanTexte = createSelector(PlanTexte, (texte) => {
  let result = {}
  for (let r of Object.values(texte)) {
    if (r.__dirty || r.__new || r.__deleted) {
      result[r.id] = r
    }
  }
  return result
})

export const Wuensche = (state) => {
  return state.Dpl.Records.wuensche
}

export const WuenscheByIntIDDatum = createSelector(Wuensche, (records) => {
  let result = {}
  for (let r of Object.values(records)) {
    result[`${r.int_id}-${Datetime.fromISO(r.datum).toISODate()}`] = r
  }
  return result
})

export const DirtyWuensche = createSelector(Wuensche, (wuensche) => {
  let result = {}
  for (let r of Object.values(wuensche)) {
    if (r.__dirty || r.__new || r.__deleted) {
      result[r.id] = r
    }
  }
  return result
})

//const disabledFehlzeiten = { "&": true }

export const DisableFehlzeitenCount = (state) => {
  return state.Dpl.NoCountFz
}

export const getCurrentLimit = (limitsByGroup, limitsByID, group, datum) => {
  if (!limitsByGroup) {
    return [null, group]
  }
  let d = Datetime.fromISO(datum)
  let currentLimits = limitsByGroup[group] || []
  let currentLimit = null
  let currentGroup = group
  for (let l of currentLimits) {
    if (Datetime.fromISO(l.ab_datum) > d) {
      continue
    }
    if (l.bis_datum != null && Datetime.fromISO(l.bis_dat) < d) {
      continue
    }
    currentLimit = l
    break
  }
  if (currentLimit && currentLimit.group_link) {
    let id = currentLimit.group_link
    currentLimit = limitsByID[id]
    currentGroup = currentLimit.group_value
  }
  return [currentLimit, currentGroup]
}

export const CountFehlzeitenPerGroup = createSelector(
  Kalender,
  AfByPlaceHolder,
  AfByReocrds,
  Personen,
  SortGroup,
  SortLimitsByGroup,
  SortLimitsByID,
  DisableFehlzeitenCount,
  (
    kalender,
    afByPlaceHolder,
    afByRecords,
    pers,
    group,
    limitsByGroup,
    limitsByID,
    disabledFehlzeiten
  ) => {
    let result = {}
    let processed = {}
    if (group == null) {
      return result
    }
    for (let [key, kal] of Object.entries(kalender)) {
      let [int_id, year, month, day] = key.split("-")
      int_id = parseInt(int_id)
      if (!pers[int_id]) {
        continue
      }
      let [currentLimit, currentGroup] = getCurrentLimit(
        limitsByGroup,
        limitsByID,
        pers[int_id][group],
        kal.datum
      )
      // let currentLimit = limitsByGroup[currentGroup]
      // if (currentLimit && currentLimit.group_link) {
      //   let id = currentLimit.group_link
      //
      //   currentLimit = limitsByID[id]
      //   currentGroup = currentLimit.group_value
      // }
      let nKey = `${currentGroup}-${year}-${month}-${day}`

      // Deaktivierte Fehlzeiten nicht prüfen
      if (disabledFehlzeiten[kal.v] == true) {
        continue
      }
      if (disabledFehlzeiten[kal.n] == true) {
        continue
      }
      // prüfen auf andere Fehlzeiten
      if (kal.v || kal.n) {
        result[nKey] = (result[nKey] || 0) + 1
        processed[key] = true
      }
    }
    for (let [key, val] of Object.entries(afByRecords)) {
      if (processed[key]) {
        continue
      }

      if (val == true) {
        let [int_id, year, month, day] = key.split("-")
        int_id = parseInt(int_id)
        if (!pers[int_id]) {
          continue
        }
        let [currentLimit, currentGroup] = getCurrentLimit(
          limitsByGroup,
          limitsByID,
          pers[int_id][group],
          `${year}-${month}-${day}`
        )
        // let currentGroup = pers[int_id][group]
        //
        // let currentLimit = limitsByGroup[currentGroup]
        // if (currentLimit && currentLimit.group_link) {
        //   let id = currentLimit.group_link
        //
        //   currentLimit = limitsByID[id]
        //   currentGroup = currentLimit.group_value
        // }
        let nKey = `${currentGroup}-${year}-${month}-${day}`
        result[nKey] = (result[nKey] || 0) + 1
        processed[key] = true
      } else if (val == false) {
        processed[key] = true
      }
    }
    for (let [key, val] of Object.entries(afByPlaceHolder)) {
      if (processed[key]) {
        continue
      }
      if (val == true) {
        let [int_id, year, month, day] = key.split("-")
        int_id = parseInt(int_id)
        if (!pers[int_id]) {
          continue
        }
        let [currentLimit, currentGroup] = getCurrentLimit(
          limitsByGroup,
          limitsByID,
          pers[int_id][group],
          `${year}-${month}-${day}`
        )
        // let currentGroup = pers[int_id][group]
        //
        // let currentLimit = limitsByGroup[currentGroup]
        // if (currentLimit && currentLimit.group_link) {
        //   let id = currentLimit.group_link
        //
        //   currentLimit = limitsByID[id]
        //   currentGroup = currentLimit.group_value
        // }
        let nKey = `${currentGroup}-${year}-${month}-${day}`
        result[nKey] = (result[nKey] || 0) + 1
      }
    }
    return result
  }
)

export const CountRecordsByGroup = createSelector(
  Kalender,
  RecordsByPlaceHolder,
  CountRecordsNotAf,
  Personen,
  SortGroup,
  SortLimitsByGroup,
  SortLimitsByID,
  (kalender, placeholders, records, pers, group, limitsByGroup, limitsByID) => {
    let result = {}
    let processed = {}
    if (group == null) {
      return result
    }
    for (let [key, kal] of Object.entries(kalender)) {
      let [int_id, year, month, day] = key.split("-")
      int_id = parseInt(int_id)
      if (!pers[int_id]) {
        continue
      }
      // let [currentLimit, currentGroup] = getCurrentLimit(
      //   limitsByGroup,
      //   limitsByID,
      //   pers[int_id][group],
      //   kal.datum
      // )
      // let currentGroup = pers[int_id][group]
      //
      // let currentLimit = limitsByGroup[currentGroup]
      // if (currentLimit && currentLimit.group_link) {
      //   let id = currentLimit.group_link
      //
      //   currentLimit = limitsByID[id]
      //   currentGroup = currentLimit.group_value
      // }
      // prüfen auf andere Fehlzeiten
      if (kal.v || kal.n) {
        processed[key] = true
      }
    }
    for (let [key, val] of Object.entries(records)) {
      if (processed[key]) {
        continue
      }

      if (val == true) {
        let [int_id, year, month, day] = key.split("-")
        int_id = parseInt(int_id)
        if (!pers[int_id]) {
          continue
        }
        let [currentLimit, currentGroup] = getCurrentLimit(
          limitsByGroup,
          limitsByID,
          pers[int_id][group],

          `${year}-${month}-${day}`
        )
        // let currentGroup = pers[int_id][group]
        //
        // let currentLimit = limitsByGroup[currentGroup]
        // if (currentLimit && currentLimit.group_link) {
        //   let id = currentLimit.group_link
        //
        //   currentLimit = limitsByID[id]
        //   currentGroup = currentLimit.group_value
        // }
        let nKey = `${currentGroup}-${year}-${month}-${day}`
        result[nKey] = (result[nKey] || 0) + 1
        processed[key] = true
      } else {
        processed[key] = true
      }
    }
    for (let [key, val] of Object.entries(placeholders)) {
      if (processed[key]) {
        continue
      }
      if (val == true) {
        let [int_id, year, month, day] = key.split("-")
        int_id = parseInt(int_id)
        if (!pers[int_id]) {
          continue
        }
        let [currentLimit, currentGroup] = getCurrentLimit(
          limitsByGroup,
          limitsByID,
          pers[int_id][group],
          `${year}-${month}-${day}`
        )
        // let currentGroup = pers[int_id][group]
        //
        // let currentLimit = limitsByGroup[currentGroup]
        // if (currentLimit && currentLimit.group_link) {
        //   let id = currentLimit.group_link
        //
        //   currentLimit = limitsByID[id]
        //   currentGroup = currentLimit.group_value
        // }
        let nKey = `${currentGroup}-${year}-${month}-${day}`
        result[nKey] = (result[nKey] || 0) + 1
      }
    }
    return result
  }
)

export const GetLinkedGroups = createSelector(SortLimitsByGroup, (limits) => {
  let linked = {}
  for (let [k, v] of Object.entries(limits || {})) {
    if (v.group_link) {
      linked[k] = v.group_link
    }
  }
  return linked
})

export const GetActLimitByGroup = createSelector(
  SortLimitsByGroup,
  (limits) => {
    let linked = {}
    for (let [k, v] of Object.entries(limits || {})) {
      if (!v.group_link) {
        linked[k] = v.max_fz
      }
    }
    return linked
  }
)

export const CanSave = createSelector(
  DirtyRecords,
  DirtyKalender,
  DirtyWuensche,
  DirtyTexte,
  DirtyPlanTexte,
  (recs, kals, wuensche, texte, plantexte) => {
    return (
      Object.keys(recs).length > 0 ||
      Object.keys(kals).length > 0 ||
      Object.keys(wuensche).length > 0 ||
      Object.keys(texte).length > 0 ||
      Object.keys(plantexte).length > 0
    )
  }
)

export const getMissing = (state) => {
  return state.Dpl.CanStartGenehmigung.missing || {}
}

export const INVALID_RECORD = "INVALID_RECORD"
export const FEHLZEIT_PLANZEILE = "FEHLZEIT_PLANZEILE"
export const INVALID_FEHLZEIT = "INVALID_FEHLZEIT"
export const MUST_VALIDATE = "MUST_VALIDATE_FEHLZEIT"
export const CANT_GENEHMIG = "CANT_GENEHMIG"

export const GetIssues = createSelector(
  Records,
  Kalender,
  getMissing,
  (records, kalender, missing) => {
    let issues = []
    for (let rec of Object.values(records)) {
      if (rec.__valid == false) {
        if (
          rec.dpl_symb.startsWith("*") ||
          rec.dpl_symb.startsWith(":") ||
          rec.dpl_symb.startsWith("+")
        ) {
          issues.push({
            type: FEHLZEIT_PLANZEILE,
            id: rec.id,
            int_id: rec.int_id,
            datum: rec.datum,
            symbol: rec.dpl_symb
          })
        } else {
          issues.push({
            type: INVALID_RECORD,
            id: rec.id,
            int_id: rec.int_id,
            datum: rec.datum,
            symbol: rec.dpl_symb
          })
        }
      }
    }
    for (let rec of Object.values(kalender)) {
      if (rec.__valid == false) {
        issues.push({
          type: INVALID_FEHLZEIT,
          int_id: rec.int_id,
          datum: rec.datum,
          fehlzeit: rec.v || rec.n
        })
      }
      if (rec.__mustValidate) {
        issues.push({
          type: MUST_VALIDATE,
          int_id: rec.int_id,
          datum: rec.datum,
          fehlzeit: rec.v || rec.n,
          dienst_id: rec.dienst_id,
          mustValidate: rec.__mustValidate
        })
      }
    }
    for (let [int_id, missinglist] of Object.entries(missing)) {
      for (let m of Object.keys(missinglist)) {
        issues.push({
          type: CANT_GENEHMIG,
          int_id: int_id,
          datum: m
        })
      }
    }
    return issues
  }
)

export const CheckDpl = (state) => state.Dpl.CheckDpl
