import Datetime from "app/shared/dateutil/index"
import { GetSelection } from "../selectors/selection"

export const SELECT_CELL = "upl/SELECT_CELL"
export const CLEAR_SELECTION = "upl/CLEAR_SELECTION"

const findClosest = (selection, int_id, datum, kz) => {
  var diff = 99999
  var closest = null
  const d1 = Datetime.fromISO(datum)
  for (let k of Object.keys(selection)) {
    const [s_int_id, year, month, day, s_kz] = k.split("-")
    if (parseInt(s_int_id) != int_id || s_kz != kz) {
      continue
    }
    const d2 = Datetime.fromValues(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    )
    const c_diff = Math.abs(d1.diffDays(d2))
    if (c_diff < diff) {
      closest = d2
      diff = c_diff
    }
  }
  return closest.toISODate()
}

const makeRange = (d1, d2) => {
  let start = Datetime.fromISO(d1)
  let end = Datetime.fromISO(d2)
  if (end < start) {
    ;[start, end] = [end, start]
  }
  let result = []
  while (start < end) {
    result.push(start.toISODate())
    start = start.plus({ days: 1 })
  }
  return result
}

export const SelectCell = (int_id, datum, kz, selectRange) => {
  return (dispatch, getState) => {
    if (selectRange) {
      const selection = GetSelection(getState())
      const closest = findClosest(selection, int_id, datum, kz)
      const range = makeRange(datum, closest)
      for (let datum of range) {
        dispatch({
          type: SELECT_CELL,
          int_id,
          datum,
          kz
        })
      }
    }
    dispatch({
      type: SELECT_CELL,
      int_id,
      datum,
      kz
    })
  }
}

export const ClearSelection = () => {
  return {
    type: CLEAR_SELECTION
  }
}
