import request from "superagent"
import Datetime from "../../shared/dateutil/index"
import { queryToString } from "../../shared/util/urls"
export const LOAD_ANWESEND_ABWESEND = "ANW/LOAD_ANWESEND_ABWESEND"
export const LOAD_FEHLZEIT_ENDE = "ANW/LOAD_FEHLZEIT_ENDE"

export function LoadAnwesendAbwesend(datum, queryData) {
  return (dispatch) => {
    if (datum == null) {
      datum = Datetime.now().toISO()
    }
    return request
      .get(`/api/anwesenheit/${Datetime.fromISO(datum).toISO()}`)
      .query(queryToString(queryData))
      .end((err, response) => {
        dispatch({
          type: LOAD_ANWESEND_ABWESEND,
          data: response.body
        })
      })
  }
}

export function LoadFehlzeitEnde(intID, datum) {
  return (dispatch) => {
    return request
      .get(`/api/p/${intID}/fehlzeitende/${Datetime.fromJSDate(datum).toISO()}`)
      .end((err, response) => {
        dispatch({
          type: LOAD_FEHLZEIT_ENDE,
          int_id: intID,
          datum: datum,
          data: response.body
        })
      })
  }
}
