// SET_DATE : Setzen des Gewählten Datums
export const SET_DATE = "upl/SET_DATE"

var dateTimer = null

export const SetDate = (date) => {
  clearTimeout(dateTimer)
  return (dispatch, getState) => {
    dateTimer = setTimeout(() => {
      dispatch({
        type: SET_DATE,
        date
      })
    }, 250)
  }
}
