import request from "superagent"

export const LOAD_RULES = "dpl/LOAD_RULES"
export const EDIT_RULE = "dpl/EDIT_RULE"
export const DELETE_RULE = "dpl/DELETE_RULE"
export const ADD_RULE = "dpl/ADD_RULE"
export const LOAD_RULE_CONFIG = "dpl/LOAD_RULE_CONFIG"
export const EDIT_RULE_CONFIG = "dpl/EDIT_RULE_CONFIG"
export const ADD_CONFIG_ROW = "dpl/ADD_CONFIG_ROW"
export const REMOVE_CONFIG_ROW = "dpl/REMOVE_CONFIG_ROW"

import { SUCCESS, STARTED, ERROR } from "app/state/constants"

export function LoadRulesSuccess(PlanID, data) {
  return {
    type: LOAD_RULES,
    status: SUCCESS,
    PlanID,
    data
  }
}

export function LoadRules(PlanID) {
  return (dispatch) => {
    request.get(`/api/dpl/auto/active/rules/${PlanID}`).end((err, response) => {
      if (!err) {
        dispatch(LoadRulesSuccess(PlanID, response.body))
      }
    })
  }
}

export function EditRule(ID, changes) {
  return {
    type: EDIT_RULE,
    ID,
    changes
  }
}

export function AddRule(PlanID, rule) {
  return (dispatch) => {
    request
      .put("/api/dpl/auto/rules/add")
      .send(rule)
      .end(() => {
        dispatch(LoadRules(PlanID))
      })
  }
}

export function RemoveRule(PlanID, RuleID) {
  return (dispatch) => {
    request.delete(`/api/dpl/auto/rules/remove/${RuleID}`).end(() => {
      dispatch(LoadRules(PlanID))
    })
  }
}

export function SaveRules() {
  return (dispatch, getState) => {
    var rules = getState().Dpl.Rules.byID
    for (let rule of Object.values(rules)) {
      if (rule.__dirty) {
        request
          .post(`/api/dpl/auto/rules/update/${rule.id}`)
          .send(rule)
          .end(() => {})
      }
    }
  }
}

export function LoadRuleConfigSuccess(RuleID, data) {
  return {
    type: LOAD_RULE_CONFIG,
    RuleID,
    data
  }
}

export function LoadRuleConfig(RuleID) {
  return (dispatch) => {
    request.get(`/api/dpl/auto/rules/config/${RuleID}`).end((err, response) => {
      dispatch(LoadRuleConfigSuccess(RuleID, response.body || []))
    })
  }
}

export function SaveRuleConfig(RuleID) {
  return (dispatch, getState) => {
    let rule = getState().Dpl.Rules.config[RuleID]
    request
      .post(`/api/dpl/auto/rules/config/${RuleID}`)
      .send(rule)
      .end((err, response) => {
        dispatch(LoadRuleConfigSuccess(RuleID, response.body))
      })
  }
}

export function SaveRuleConfigPlain(RuleID, Config) {
  return (dispatch, getState) => {
    request
      .post(`/api/dpl/auto/rules/config/${RuleID}`)
      .send(Config)
      .end((err, response) => {
        dispatch(LoadRuleConfigSuccess(RuleID, response.body))
      })
  }
}

export function EditRuleConfig(RuleID, ConfigID, changes) {
  return {
    type: EDIT_RULE_CONFIG,
    RuleID,
    ConfigID,
    changes
  }
}

export function AddConfigRow(RuleID, RuleType) {
  return { type: ADD_CONFIG_ROW, RuleID, RuleType }
}

export function RemoveConfigRow(RuleID, RuleType, ConfigID) {
  return { type: REMOVE_CONFIG_ROW, RuleID, RuleType, ConfigID }
}
