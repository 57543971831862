import {
  CHANGE_UI_SETTINGS,
  LOADED_UI_SETTINGS,
  SWITCH_UI_MODE,
  CHANGE_DPL_UI_SETTINGS,
  LOADED_DPL_UI_SETTINGS,
  CHANGE_DPL_UI_VISIBILITY,
  CHANGE_DPL_UI_WERTESPALTE,
  LOAD_PLANZEILE_PARAM,
  LOAD_DIENST_SPERREN,
  LOAD_DPL_COLORS,
  SET_HIGHLIGHT
} from "../actions"

export function UI(
  state = {
    columnWidth: 60,
    vHeaderWidth: 180,
    rowHeight: 30,
    fontSize: 10,
    mode: "tpl",
    planZeileBack: 1,
    replaceMode: "ask",
    sperren: {},
    colors: [],
    highlight: null,
    loaded: false
  },
  action
) {
  switch (action.type) {
    case CHANGE_UI_SETTINGS:
      return {
        ...state,
        [action.setting]: state[action.setting] + action.change
      }
    case LOADED_UI_SETTINGS:
      return { ...state, ...action.settings, loaded: true } || state
    case LOAD_PLANZEILE_PARAM:
      return { ...state, planZeileBack: action.value }
    case LOAD_DIENST_SPERREN:
      return { ...state, sperren: action.data }
    case LOAD_DPL_COLORS:
      return { ...state, colors: action.value || [] }
    case SET_HIGHLIGHT:
      return { ...state, highlight: action.int_id }
    case SWITCH_UI_MODE:
      if (state.mode == "dpl") {
        return { ...state, mode: "tpl" }
      } else {
        return { ...state, mode: "dpl" }
      }
  }
  return state
}

export function DUI(
  state = {
    cell_width: 30,
    cell_height: 30,
    user_id: null,
    index_width: 0,
    show_sum: 1,
    show_wunsch: 0,
    show_soll: 1,
    show_plan: 0,
    show_ist: 0,
    show_rb: 0,
    show_taet: 0,
    show_text: 0,
    show_left_values: "",
    show_right_values: "VVA-Stunden",
    show_headertext: 0,
    group_by: null,
    loaded: false
  },
  action
) {
  switch (action.type) {
    case CHANGE_DPL_UI_SETTINGS:
      if (action.setting == "group_by") {
        return { ...state, [action.setting]: action.change }
      }
      if (action.setting == "cell_width") {
        if (state[action.setting] + action.change < 18 && action.change < 0) {
          return state
        }
      }
      if (action.setting == "cell_height") {
        if (state[action.setting] + action.change < 15 && action.change < 0) {
          return state
        }
      }
      return {
        ...state,
        [action.setting]: state[action.setting] + action.change
      }
    case CHANGE_DPL_UI_VISIBILITY:
      return {
        ...state,
        [`show_${action.setting}`]: action.change === true ? 1 : 0
      }
    case CHANGE_DPL_UI_WERTESPALTE:
      if (action.setting == "left") {
        return { ...state, show_left_values: action.change.join(",") }
      } else {
        return { ...state, show_right_values: action.change.join(",") }
      }
    case LOADED_DPL_UI_SETTINGS:
      return { ...state, ...action.settings, loaded: true } || state
  }
  return state
}
