import {
  ADD_DP_SONMOD_TAG,
  EDIT_DP_SONMOD_TAG,
  LOAD_DP_SONMOD_TAG,
  LOAD_ZEITTAG,
  LOAD_ZEITTAG_DEF,
  LOAD_ZEITTAG_PROPS,
  REMOVE_DP_SONMOD_TAG
} from "./zeittag_actions"
import { v4 as uuidv4 } from "uuid"
import { arrayToObject, arrayToObjectFn } from "app/shared/util/conversion"
import { STARTED, SUCCESS } from "app/state/constants"

export default function ZeitTags(
  state = { tagdef: {}, tagprops: {}, tags: {}, dpsonmodtags: {} },
  action
) {
  switch (action.type) {
    case LOAD_ZEITTAG:
      return { ...state, tags: { ...state.tags, [action.id]: action.data } }
    case LOAD_ZEITTAG_DEF:
      return { ...state, tagdef: arrayToObject(action.data, "id") }
    case LOAD_ZEITTAG_PROPS:
      if (action.status == STARTED) {
        return {
          ...state,
          tagprops: {
            ...state.tagprops,
            [action.id]: {}
          }
        }
      }
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagprops: {
            ...state.tagprops,
            [action.id]: arrayToObjectFn(
              action.data,
              (o) => o.prop_name,
              (o) => {
                if (o.prop_name == "filterdd") {
                  return JSON.parse(o.prop_value)
                }
                return o.prop_value
              }
            )
          }
        }
      }
    case EDIT_DP_SONMOD_TAG:
    case ADD_DP_SONMOD_TAG:
    case REMOVE_DP_SONMOD_TAG:
    case LOAD_DP_SONMOD_TAG:
      return {
        ...state,
        dpsonmodtags: DpSonModTags(state.dpsonmodtags, action)
      }
  }
  return state
}

const DpSonModTags = (state, action) => {
  switch (action.type) {
    case EDIT_DP_SONMOD_TAG:
      return {
        ...state,
        [action.mod_id]: [...(state[action.mod_id] || [])].map((r) => {
          if (r.id == action.id) {
            return action.data
          }
          return r
        })
      }
    case ADD_DP_SONMOD_TAG:
      return {
        ...state,
        [action.mod_id]: [
          ...(state[action.mod_id] || []),
          { id: uuidv4(), __new: true, sonmod_id: action.mod_id }
        ]
      }
    case REMOVE_DP_SONMOD_TAG:
      return {
        ...state,
        [action.mod_id]: [...(state[action.mod_id] || [])].map((r) => {
          if (r.id == action.id) {
            return { ...r, __deleted: true }
          }
          return r
        })
      }
    case LOAD_DP_SONMOD_TAG:
      return {
        ...state,
        [action.mod_id]: action.data
      }
  }
  return state
}
