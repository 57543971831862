import request from "superagent"

export const LOAD_ZEITRAUM = "upl/LOAD_ZEITRAUM"
export const LOAD_WUNSCH_ZEITRAUM = "upl/LOAD_WUNSCH_ZEITRAUM"

export const LoadZeitraum = () => {
  return (dispatch) => {
    request.get("/api/urlaubsplanung/zeitraeume").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_ZEITRAUM,
          data: response.body
        })
      }
    })
  }
}

export const LoadWunschZeitraum = () => {
  return (dispatch) => {
    request.get("/api/urlaubsplanung/wunschzeitraeume").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_WUNSCH_ZEITRAUM,
          data: response.body
        })
      }
    })
  }
}
