import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import Datetime from "../../shared/dateutil/index"
export const LOAD_SALDEN = "dpl/LOAD_SALDEN"
export const LOAD_SINGLE_SALDEN = "dpl/LOAD_SINGLE_SALDEN"
export const UPDATED_SALDEN = "dpl/UPDATED_SALDEN"

function LoadSaldenSuccess(data) {
  return {
    type: LOAD_SALDEN,
    status: SUCCESS,
    data
  }
}

function LoadSaldenError(err) {
  return {
    type: LOAD_SALDEN,
    status: ERROR,
    _handleInApp: true,
    err
  }
}

function LoadSaldenStarted() {
  return {
    type: LOAD_SALDEN,
    status: STARTED
  }
}

export function RefreshSalden(IntID) {
  return (dispatch, getState) => {
    let d = getState().Dpl.Datum.date
    console.log(d)
    let date = Datetime.fromISO(d)
    request
      .get(
        `/api/dpl/basis/update/salden/${IntID}/${date.year()}/${date.month()}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: UPDATED_SALDEN,
            IntID,
            status: SUCCESS,
            data: response.body
          })
        }
      })
  }
}

export function LoadSalden(PlanID, Year, Month) {
  return (dispatch, getState) => {
    dispatch(LoadSaldenStarted())
    request
      .get(`/api/dpl/basis/salden/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        // let { year, month } = getState().Dpl.Datum
        // if (year !== Year || month !== Month) {
        //   return
        // }
        if (err) {
          dispatch(LoadSaldenError(err))
        } else {
          dispatch(LoadSaldenSuccess(response.body))
        }
      })
  }
}

function UpdateSaldenSuccess(IntID, data) {
  return {
    type: UPDATED_SALDEN,
    status: SUCCESS,
    IntID,
    data
  }
}

export function UpdateSalden(IntID, Year, Month) {
  return (dispatch, getState) => {
    dispatch(LoadSaldenStarted())
    request
      .get(`/api/dpl/salden/calc/${IntID}/${Year}/${Month}`)
      .end((err, response) => {
        if (!err) {
          let { date } = getState().Dpl.Datum
          if (date.getFullYear() !== Year || date.getMonth() + 1 !== Month) {
            return
          }
          dispatch(UpdateSaldenSuccess(IntID, response.body))
        }
      })
  }
}
