import { LOADED_HISTORY } from "./actions"
export const KalenderHistory = (state = [], action) => {
  switch (action.type) {
    case LOADED_HISTORY:
      return action.data
  }
  return state
}

export default KalenderHistory
