import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import {
  LOAD_RULES,
  EDIT_RULE,
  LOAD_RULE_CONFIG,
  EDIT_RULE_CONFIG,
  ADD_CONFIG_ROW,
  REMOVE_CONFIG_ROW
} from "../actions"
import { arrayToObject } from "app/shared/util/conversion"

export function Rules(state = { byID: {}, byPlan: {}, config: {} }, action) {
  switch (action.type) {
    case LOAD_RULES:
      if (action.status == SUCCESS) {
        return {
          ...state,
          byPlan: RulesByPlan(state.byPlan, action),
          byID: RulesByID(state.byID, action)
        }
      }
      break
    case EDIT_RULE:
      return {
        ...state,
        byID: RulesByID(state.byID, action)
      }
    case LOAD_RULE_CONFIG:
      return {
        ...state,
        config: { ...state.config, [action.RuleID]: action.data }
      }
    case EDIT_RULE_CONFIG:
    case ADD_CONFIG_ROW:
    case REMOVE_CONFIG_ROW:
      return {
        ...state,
        config: {
          ...state.config,
          [action.RuleID]: EditRuleConfig(state.config[action.RuleID], action)
        }
      }
  }

  return state
}

const defaultRows = {
  5: { id: null, cid: null, mo: 0, di: 0, mi: 0, do: 0, fr: 0, sa: 0, so: 0 },
  24: { id: 0, zm_tg: "", dienst_id: null, __isNew: true }
}

export function EditRuleConfig(state, action) {
  switch (action.type) {
    case EDIT_RULE_CONFIG:
      if (action.RuleID == action.ConfigID) {
        return { ...state, ...action.changes }
      } else {
        return state.map((conf) => {
          if (conf.id == action.ConfigID) {
            return { ...conf, ...action.changes }
          }
          return conf
        })
      }
    case ADD_CONFIG_ROW:
      if (action.RuleType == 5 || action.RuleType == 24) {
        return [
          ...state,
          { ...defaultRows[action.RuleType], id: 0, cid: action.RuleID }
        ]
      }
      return state
    case REMOVE_CONFIG_ROW:
      if (action.RuleType == 5 || action.RuleType == 24) {
        return state.map((row) => {
          if (row.id == action.ConfigID) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
      return state
  }
}

export function RulesByID(state = {}, action) {
  switch (action.type) {
    case LOAD_RULES:
      if (action.status == SUCCESS) {
        return {
          ...state,
          ...arrayToObject(action.data, "id")
        }
      }
      break
    case EDIT_RULE:
      return {
        ...state,
        [action.ID]: { ...state[action.ID], ...action.changes, __dirty: true }
      }
  }
  return state
}

export function RulesByPlan(state = {}, action) {
  switch (action.type) {
    case LOAD_RULES:
      if (action.status == SUCCESS) {
        return {
          ...state,
          [action.PlanID]: action.data.map((r) => r.id)
        }
      }
  }
  return state
}
