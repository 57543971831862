import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import request from "superagent"
import { v4 as uuidv4 } from "uuid"
export const LOAD_USERS = "security/LOAD_USERS"
export const LOAD_ROLES = "security/LOAD_ROLES"
export const LOAD_USER_ROLES = "security/LOAD_USER_ROLES"
export const LOAD_VALUE_PERM = "security/LOAD_VALUE_PERM"
export const LOAD_RESSOURCE_PERM = "security/LOAD_RESSOURCE_PERM"
export const LOAD_ROLE_PERM = "security/LOAD_ROLE_PERM"
export const LOAD_USER_PERM = "security/LOAD_USER_PERM"
export const ADD_USER_ROLE = "security/ADD_USER_ROLE"
export const REMOVE_USER_ROLE = "security/REMOVE_USER_ROLE"
export const EDIT_USER = "security/EDIT_USER"
export const SELECT_USER = "security/SELECT_USER"
export const SAVE_USER = "security/SAVE_USER"
export const NEW_USER = "security/NEW_USER"
export const ADD_USER_PERM = "security/ADD_USER_VALUE"
export const REMOVE_USER_PERM = "security/REMOVE_USER_VALUE"
export const SET_USER_PERM_VALUE = "security/SET_USER_PERM_VALUE"
export const SAVE_USER_PERMS = "security/SAVE_USER_PERMS"
export const SELECT_ROLE = "security/SELECT_ROLE"
export const NEW_VALUES = "NEW_VALUES"
export const LOAD_SPERRDATUM = "LOAD_SPERRDATUM"
export const ADD_SPERRDATUM_ROW = "ADD_SPERRDATUM_ROW"
export const DELETED_SPERRDATUM_ROW = "DELETED_SPERRDATUM_ROW"
export const DELETE_SPERRDATUM_ROW = "DELETE_SPERRDATUM_ROW"
export const EDIT_SPERRDATUM = "EDIT_SPERRDATUM"
export const UPDATE_SPERRDATUM = "UPDATE_SPERRDATUM"
export const LOAD_SPERRDATUMUSER = "LOAD_SPERRDATUMUSER"
export const ADD_SPERRDATUMUSER_ROW = "ADD_SPERRDATUMUSER_ROW"
export const DELETED_SPERRDATUMUSER_ROW = "DELETED_SPERRDATUMUSER_ROW"
export const DELETE_SPERRDATUMUSER_ROW = "DELETE_SPERRDATUMUSER_ROW"
export const EDIT_SPERRDATUMUSER = "EDIT_SPERRDATUMUSER"
export const UPDATE_SPERRDATUMUSER = "UPDATE_SPERRDATUMUSER"
export const LOAD_SPERRDAT = "LOAD_SPERRDAT"
export const EDIT_SPERRDAT = "EDIT_SPERRDAT"
export const UPDATE_SPERRDAT = "UPDATE_SPERRDAT"
export const LOAD_STARTDATUM = "LOAD_STARTDATUM"
export const EDIT_STARTDATUM = "EDIT_STARTDATUM"
export const UPDATE_STARTDATUM = "UPDATE_STARTDATUM"

export function LoadStartDatumSuccess(data) {
  return {
    type: LOAD_STARTDATUM,
    status: SUCCESS,
    data
  }
}

export function LoadStartDatum() {
  return (dispatch) => {
    request.get("/api/parameter/berechnung/startdatum").end((err, response) => {
      dispatch(LoadStartDatumSuccess(response.body))
      // }
    })
  }
}
export function UpdateStartDatumSuccess(data) {
  return {
    type: UPDATE_STARTDATUM,
    status: SUCCESS,
    data
  }
}

export function UpdateStartDatum() {
  return (dispatch, getState) => {
    let data = getState().Security.startdatum || {}
    for (let startdatum of Object.values(data)) {
      if (startdatum == true) {
        request
          .put("/api/parameter")
          .send(data)
          .end((err, response) => {
            dispatch(UpdateStartDatumSuccess(response.body))
          })
      }
    }
  }
}
export function EditStartDatum(changes) {
  return {
    type: EDIT_STARTDATUM,
    changes
  }
}

export function EditSperrDat(changes) {
  return {
    type: EDIT_SPERRDAT,
    changes
  }
}
export function LoadSperrDatSuccess(data) {
  return {
    type: LOAD_SPERRDAT,
    status: SUCCESS,
    data
  }
}

export function LoadSperrDat() {
  return (dispatch) => {
    request.get("/api/sperrdat").end((err, response) => {
      dispatch(LoadSperrDatSuccess(response.body))
    })
  }
}
export function UpdateSperrDatSuccess(data) {
  return {
    type: UPDATE_SPERRDAT,
    status: SUCCESS,
    data
  }
}

export function UpdateSperrDat() {
  return (dispatch, getState) => {
    let data = getState().Security.sperrdat || {}
    for (let spdat of Object.values(data)) {
      if (spdat == true) {
        request
          .put("/api/sperrdat")
          .send(data)
          .end((err, response) => {
            dispatch(UpdateSperrDatSuccess(response.body))
          })
      }
      // }
    }
  }
}

export function EditSperrDatumUser(abr_kreis, id, changes) {
  return {
    type: EDIT_SPERRDATUMUSER,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteSperrDatumUserSuccess(id) {
  return {
    type: DELETED_SPERRDATUMUSER_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteSperrDatumUserRow(id) {
  return {
    type: DELETE_SPERRDATUMUSER_ROW,
    id
  }
}

export function AddSperrDatumUserRow(abr_kreis) {
  return {
    type: ADD_SPERRDATUMUSER_ROW,
    abr_kreis
  }
}

export function LoadSperrDatumUserSuccess(data) {
  return {
    type: LOAD_SPERRDATUMUSER,
    status: SUCCESS,
    data
  }
}

export function LoadSperrDatumUser() {
  return (dispatch) => {
    request.get("/api/sperrdatumuser").end((err, response) => {
      dispatch(LoadSperrDatumUserSuccess(response.body))
    })
  }
}

function CheckSperrDatumUser(sperrdatumuser) {
  for (let row of sperrdatumuser) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    if (row.user_id == null) {
      return "bitte Benutzer auswählen"
    }
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
  }
  return null
}

export function UpdateSperrDatumUserSuccess(id, data) {
  return {
    type: UPDATE_SPERRDATUMUSER,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSperrDatumUser() {
  return (dispatch, getState) => {
    let data = getState().Security.sperrdatumuser || []

    if (data.length == 0) {
      return
    }
    var err = CheckSperrDatumUser(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/sperrdatumuser/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteSperrDatumUserSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/sperrdatumuser")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateSperrDatumUserSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/sperrdatumuser")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateSperrDatumUserSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function EditSperrDatum(abr_kreis, id, changes) {
  return {
    type: EDIT_SPERRDATUM,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteSperrDatumSuccess(id) {
  return {
    type: DELETED_SPERRDATUM_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteSperrDatumRow(id) {
  return {
    type: DELETE_SPERRDATUM_ROW,
    id
  }
}

export function AddSperrDatumRow(abr_kreis) {
  return {
    type: ADD_SPERRDATUM_ROW,
    abr_kreis
  }
}

export function LoadSperrDatumSuccess(data) {
  return {
    type: LOAD_SPERRDATUM,
    status: SUCCESS,
    data
  }
}

export function LoadSperrDatum() {
  return (dispatch) => {
    request.get("/api/sperrdatum").end((err, response) => {
      dispatch(LoadSperrDatumSuccess(response.body))
    })
  }
}

function CheckSperrDatum(sperrdatum) {
  for (let row of sperrdatum) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
  }
  return null
}

export function UpdateSperrDatumSuccess(id, data) {
  return {
    type: UPDATE_SPERRDATUM,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSperrDatum() {
  return (dispatch, getState) => {
    let data = getState().Security.sperrdatum || []

    if (data.length == 0) {
      return
    }
    var err = CheckSperrDatum(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/sperrdatum/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteSperrDatumSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/sperrdatum")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateSperrDatumSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/sperrdatum")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateSperrDatumSuccess(row.id, response.body))
          })
      }
    }
  }
}

const feldWerteNamen = {
  value: "Werte"
  // value_name: "Wert"
}

const defaultWertePflichtFelder = {
  value: true
  // value_name: true
}
export function CheckValuesNew(felder, data) {
  for (let feld of felder) {
    if (defaultWertePflichtFelder[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldWerteNamen[feld] || feld
        return `Bitte ${feldName} auswählen!`
      }
    }
  }
  return null
}

export function CreateValuesSuccess(data) {
  return {
    type: NEW_VALUES,
    status: SUCCESS,
    data
  }
}
export function CreateValues(data) {
  return (dispatch) => {
    request
      .post("/api/security/values/new")
      .send(data)
      .end((err, response) => {
        dispatch(CreateValuesSuccess(response.body))
      })
  }
}

export function SetUserPermValue(user_id, value_id, value) {
  return {
    type: SET_USER_PERM_VALUE,
    user_id,
    value_id,
    value
  }
}

export function ResetUserPassword(userID, User) {
  return request.post(`/api/security/users/${userID}/password/reset`).send(User)
}

export function AddUserPerm(user_id, value_id) {
  return {
    type: ADD_USER_PERM,
    user_id,
    value_id
  }
}

export function RemoveUserPerm(user_id, value_id) {
  return {
    type: REMOVE_USER_PERM,
    user_id,
    value_id
  }
}

export function EditUser(user_id, changes) {
  return {
    type: EDIT_USER,
    user_id,
    changes
  }
}

export function NewUser() {
  return {
    type: NEW_USER,
    user_id: uuidv4()
  }
}

export function SelectUser(user_id) {
  return {
    type: SELECT_USER,
    user_id
  }
}

export function SelectRole(role_id) {
  return {
    type: SELECT_ROLE,
    role_id
  }
}

export function SaveUserSuccess(user_id, user) {
  return {
    type: SAVE_USER,
    user_id: user_id,
    user
  }
}

export function SaveUser(user_id) {
  return (dispatch, getState) => {
    let user = getState().Security.users.data[user_id]
    if (!user) {
      return
    }
    if (user.__new) {
      request
        .put(`/api/security/user/new/${user.user_type}`)
        .send({ ...user, user_id: undefined })
        .end((err, response) => {
          if (err) {
            // TODO: Etwas mit dem fehler tun
          } else {
            dispatch(SaveUserSuccess(user_id, response.body))
            dispatch(SaveUserPerms(response.body.user_id, user_id))
            dispatch(SaveUserRoles(response.body.user_id, user_id))
          }
        })
    } else if (user.__deleted) {
      request
        .delete(`/api/security/user/${user.user_id}/${user.user_type}`)
        .send({ ...user })
        .end((err, response) => {
          if (err) {
            // TODO: Etwas mit dem fehler tun
          } else {
            dispatch(SaveUserSuccess(user_id, null))
          }
        })
    } else {
      request
        .post(`/api/security/user/${user.user_id}/${user.user_type}`)
        .send(user)
        .end((err, response) => {
          if (err) {
            // TODO: Etwas mit dem fehler tun
          } else {
            dispatch(SaveUserSuccess(user_id, response.body))
            dispatch(SaveUserRoles(user_id))
            dispatch(SaveUserPerms(user_id))
          }
        })
    }
  }
}

export function SaveUserPermsSuccess(user_id, data) {
  return {
    type: SAVE_USER_PERMS,
    user_id,
    status: SUCCESS,
    data
  }
}

export function SaveUserPermsError(user_id, error) {
  return {
    type: SAVE_USER_PERMS,
    user_id,
    status: ERROR,
    error
  }
}

export function SaveUserPerms(user_id, tempUserId = null) {
  return (dispatch, getState) => {
    let perms = getState().Security.userPerms[tempUserId || user_id].permissions
    if (!perms) {
      return
    }
    if (tempUserId) {
      //Fix temporary user id -> new user id
      perms = perms.map((perm) => {
        return { ...perm, user_id: user_id }
      })
    }
    request
      .put(`/api/security/users/${user_id}/values`)
      .send(perms)
      .end((err, response) => {
        if (err) {
          dispatch(SaveUserPermsError(user_id, err))
        } else {
          dispatch(SaveUserPermsSuccess(user_id, response.body))
        }
      })
  }
}

export function SaveUserRoles(user_id, tempUserId = null) {
  return (dispatch, getState) => {
    let roles = getState().Security.userRoles[tempUserId || user_id].roles
    if (!roles) {
      return
    }
    request
      .put(`/api/security/users/${user_id}/roles`)
      .send(roles)
      .end((err, response) => {
        if (err) {
          dispatch(LoadUserRolesError(user_id, err))
        } else {
          dispatch(
            LoadUserRolesSuccess(
              user_id,
              response.body.map((role_id) => {
                return {
                  role_id
                }
              })
            )
          )
        }
      })
  }
}

export function AddUserRole(user_id, role_id) {
  //request.post(`/api/security/users/${user_id}/roles/${role_id}`).end()
  return {
    type: ADD_USER_ROLE,
    user_id,
    role_id
  }
}

export function RemoveUserRole(user_id, role_id) {
  //request.delete(`/api/security/users/${user_id}/roles/${role_id}`).end()
  return {
    type: REMOVE_USER_ROLE,
    user_id,
    role_id
  }
}

export function LoadUsersSuccess(data) {
  return {
    type: LOAD_USERS,
    status: SUCCESS,
    data
  }
}

export function LoadUsersError(error) {
  return {
    type: LOAD_USERS,
    status: ERROR,
    error
  }
}

export function LoadUsersStarted() {
  return {
    type: LOAD_USERS,
    status: STARTED
  }
}

export function LoadUsers() {
  return (dispatch) => {
    dispatch(LoadUsersStarted())
    request.get("/api/security/users").end((err, response) => {
      if (err) {
        dispatch(LoadUsersError(err))
      } else {
        dispatch(LoadUsersSuccess(response.body))
      }
    })
  }
}

export function LoadRolesSuccess(data) {
  return {
    type: LOAD_ROLES,
    status: SUCCESS,
    data
  }
}

export function LoadRolesError(error) {
  return {
    type: LOAD_ROLES,
    status: ERROR,
    error
  }
}

export function LoadRolesStarted() {
  return {
    type: LOAD_ROLES,
    status: STARTED
  }
}

export function LoadRoles() {
  return (dispatch) => {
    dispatch(LoadRolesStarted())
    request.get("/api/security/roles").end((err, response) => {
      if (err) {
        dispatch(LoadRolesError(err))
      } else {
        dispatch(LoadRolesSuccess(response.body))
      }
    })
  }
}

export function LoadUserRolesSuccess(user_id, data) {
  return {
    type: LOAD_USER_ROLES,
    status: SUCCESS,
    user_id,
    data
  }
}

export function LoadUserRolesError(user_id, error) {
  return {
    type: LOAD_USER_ROLES,
    status: ERROR,
    user_id,
    error
  }
}

export function LoadUserRolesStarted(user_id) {
  return {
    type: LOAD_USER_ROLES,
    status: STARTED,
    user_id
  }
}

export function LoadUserRoles(user_id) {
  return (dispatch) => {
    dispatch(LoadUserRolesStarted(user_id))
    request.get(`/api/security/users/${user_id}/roles`).end((err, response) => {
      if (err) {
        dispatch(LoadUserRolesError(user_id, err))
      } else {
        dispatch(LoadUserRolesSuccess(user_id, response.body))
      }
    })
  }
}

export function LoadSecValuesSuccess(data) {
  return {
    type: LOAD_VALUE_PERM,
    status: SUCCESS,
    data
  }
}

export function LoadSecValuesError(error) {
  return {
    type: LOAD_VALUE_PERM,
    status: ERROR,
    error
  }
}

export function LoadSecValuesStarted() {
  return {
    type: LOAD_VALUE_PERM,
    status: STARTED
  }
}

export function LoadSecValues() {
  return (dispatch) => {
    dispatch(LoadSecValuesStarted())
    request.get("/api/security/perm/values").end((err, response) => {
      if (err) {
        dispatch(LoadSecValuesError(err))
      } else {
        dispatch(LoadSecValuesSuccess(response.body))
      }
    })
  }
}

export function LoadSecUserValuesSuccess(user_id, data) {
  return {
    type: LOAD_USER_PERM,
    status: SUCCESS,
    user_id,
    data
  }
}

export function LoadSecUserValuesError(user_id, error) {
  return {
    type: LOAD_USER_PERM,
    status: ERROR,
    user_id,
    error
  }
}

export function LoadSecUserValuesStarted(user_id) {
  return {
    type: LOAD_USER_PERM,
    user_id,
    status: STARTED
  }
}

export function LoadSecUserValues(user_id) {
  return (dispatch) => {
    dispatch(LoadSecUserValuesStarted(user_id))
    request
      .get(`/api/security/users/${user_id}/values`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadSecUserValuesError(user_id, err))
        } else {
          dispatch(LoadSecUserValuesSuccess(user_id, response.body))
        }
      })
  }
}
