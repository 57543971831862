import { LOAD_ANWESEND_ABWESEND, LOAD_FEHLZEIT_ENDE } from "./actions"

export default (state = { AnwesendAbwesend: [], FehlzeitEnde: {} }, action) => {
  switch (action.type) {
    case LOAD_ANWESEND_ABWESEND:
      return { ...state, AnwesendAbwesend: action.data }
    case LOAD_FEHLZEIT_ENDE:
      return {
        ...state,
        FehlzeitEnde: { ...state.FehlzeitEnde, [action.int_id]: action.datum }
      }
  }
  return state
}
