import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_TAETIGKEITS_DEFINITIONEN = "dpl/LOAD_TAETIGKEITS_DEFINITIONEN"
export const LOAD_PLAN_TAETIGKEITS_DEFINITIONEN =
  "dpl/LOAD_PLAN_TAETIGKEITS_DEFINITIONEN"
export const EDIT_TAETIGKEITS_DEFINITION = "dpl/EDIT_TAETIGKEITS_DEFINITION"
export const UPDATE_TAETIGKEITS_DEFINITION = "dpl/UPDATE_TAETIGKEITS_DEFINITION"
export const ADD_TAETIGKEITS_DEFINITION = "dpl/ADD_TAETIGKEITS_DEFINITION"
export const REMOVE_TAETIGKEITS_DEFINITION = "dpl/REMOVE_TAETIGKEITS_DEFINITION"

export function EditTaetigkeitsDefinition(id, changes, datum) {
  if (changes.pers_art) {
    changes.wert = null
  }
  return {
    type: EDIT_TAETIGKEITS_DEFINITION,
    id,
    datum,
    changes
  }
}

export function UpdatedTaetigkeitsDefinition(DienstID, id, data) {
  return {
    type: UPDATE_TAETIGKEITS_DEFINITION,
    DienstID,
    id,
    data
  }
}

export function AddTaetigkeitsDefinition(DienstID) {
  return {
    type: ADD_TAETIGKEITS_DEFINITION,
    DienstID
  }
}

export function RemoveTaetigkeitsDefinition(DienstID, id) {
  return {
    type: REMOVE_TAETIGKEITS_DEFINITION,
    DienstID,
    id
  }
}

export function UpdateTaetigkeitsDefinitionen(DienstID) {
  return (dispatch, getState) => {
    let state = getState()
    let definitionen =
      (state.Dpl.TaetigkeitsDefinitionen[DienstID] &&
        state.Dpl.TaetigkeitsDefinitionen[DienstID].data) ||
      []
    for (let def of definitionen) {
      if (def.__new) {
        if (def.__deleted) {
          continue
        }
        def = { ...def }
        let id = def.id
        delete def.id
        def.dienst_id = DienstID
        request
          .post("/api/dpl/basis/taetigkeitsdef")
          .send(def)
          .end((err, response) => {
            dispatch(UpdatedTaetigkeitsDefinition(DienstID, id, response.body))
          })
      } else if (def.__dirty) {
        request
          .put(`/api/dpl/basis/taetigkeitsdef/${def.id}`)
          .send(def)
          .end((err, response) => {
            dispatch(
              UpdatedTaetigkeitsDefinition(DienstID, def.id, response.body)
            )
          })
      } else if (def.__deleted) {
        request.delete(`/api/dpl/basis/taetigkeitsdef/${def.id}`).end(() => {
          dispatch(UpdatedTaetigkeitsDefinition(DienstID, def.id, null))
        })
      }
    }
  }
}

export function LoadDienstTaetigkeitsDefinitionenStarted(DienstID) {
  return {
    type: LOAD_TAETIGKEITS_DEFINITIONEN,
    status: STARTED,
    DienstID
  }
}

export function LoadDienstTaetigkeitsDefinitionenError(DienstID, err) {
  return {
    type: LOAD_TAETIGKEITS_DEFINITIONEN,
    status: ERROR,
    DienstID,
    err
  }
}

export function LoadDienstTaetigkeitsDefinitionenSuccess(DienstID, data) {
  return {
    type: LOAD_TAETIGKEITS_DEFINITIONEN,
    status: SUCCESS,
    DienstID,
    data
  }
}

export function LoadDienstTaetigkeitsDefinitionen(DienstID) {
  return (dispatch, getState) => {
    let dienst = getState().Dpl.Taetigkeiten.data[DienstID]
    if (dienst.__new) {
      return
    }
    dispatch(LoadDienstTaetigkeitsDefinitionenStarted(DienstID))
    request
      .get(`/api/dpl/basis/taetigkeiten/def/${DienstID}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadDienstTaetigkeitsDefinitionenError(DienstID, err))
        } else {
          dispatch(
            LoadDienstTaetigkeitsDefinitionenSuccess(DienstID, response.body)
          )
        }
      })
  }
}

export function LoadPlanTaetigkeitsDefinitionenStarted(PlanID) {
  return {
    type: LOAD_PLAN_TAETIGKEITS_DEFINITIONEN,
    status: STARTED,
    PlanID
  }
}

export function LoadPlanTaetigkeitsDefinitionenError(PlanID, err) {
  return {
    type: LOAD_PLAN_TAETIGKEITS_DEFINITIONEN,
    status: ERROR,
    PlanID,
    err
  }
}

export function LoadPlanTaetigkeitsDefinitionenSuccess(PlanID, data) {
  return {
    type: LOAD_PLAN_TAETIGKEITS_DEFINITIONEN,
    status: SUCCESS,
    PlanID,
    data
  }
}

export function LoadPlanTaetigkeitsDefinitionen(PlanID) {
  return dispatch => {
    dispatch(LoadPlanTaetigkeitsDefinitionenStarted(PlanID))
    request
      .get(`/api/dpl/basis/taetigkeitsdefp/plan/${PlanID}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadPlanTaetigkeitsDefinitionenError(PlanID, err))
        } else {
          dispatch(
            LoadPlanTaetigkeitsDefinitionenSuccess(PlanID, response.body)
          )
        }
      })
  }
}
