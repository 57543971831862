import request from "superagent"

import { SetDplGroupBy } from "./pers.js"
export const CHANGE_UI_SETTINGS = "dpl/CHANGE_UI_SETTINGS"
export const LOADED_UI_SETTINGS = "dpl/LOADED_UI_SETTINGS"

export const CHANGE_DPL_UI_SETTINGS = "dpl/CHANGE_DPL_UI_SETTINGS"
export const CHANGE_DPL_UI_VISIBILITY = "dpl/CHANGE_DPL_UI_VISIBILITY"
export const CHANGE_DPL_UI_WERTESPALTE = "dpl/CHANGE_DPL_UI_WERTESPALTE"
export const LOADED_DPL_UI_SETTINGS = "dpl/LOADED_DPL_UI_SETTINGS"
export const SWITCH_UI_MODE = "dpl/SWITCH_UI_MODE"
export const LOAD_PLANZEILE_PARAM = "dpl/LOAD_PLANZEILE_PARAM"
export const LOAD_DIENST_SPERREN = "dpl/LOAD_DIENST_SPERREN"
export const LOAD_DPL_COLORS = "dpl/LOAD_DPL_COLORS"
export const SET_HIGHLIGHT = "dpl/SET_HIGHLIGHT"

export function LoadPlanZeileParam() {
  return (dispatch) => {
    request
      .get("/api/parameter/dienstplan/planzeile_offen")
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_PLANZEILE_PARAM,
            value: parseInt(response.body.tab_wert) || 0
          })
        }
      })
  }
}

export function SetHighLight(int_id) {
  return {
    type: SET_HIGHLIGHT,
    int_id: int_id
  }
}

export const LoadDplColors = () => {
  return (dispatch) => {
    request.get("/api/dpl/basis/colors").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_DPL_COLORS,
          value: response.body
        })
      }
    })
  }
}

export function LoadDienstSperren() {
  return (dispatch) => {
    request.get("/api/dpl/basis/sperren").then((response) => {
      dispatch({ type: LOAD_DIENST_SPERREN, data: response.body })
    })
  }
}

export function ChangeUISettingsPlain(setting, change) {
  return {
    type: CHANGE_UI_SETTINGS,
    setting,
    change
  }
}

export function LoadedUISettings(settings) {
  return {
    type: LOADED_UI_SETTINGS,
    settings
  }
}

export function ChangeUISettings(setting, change) {
  return (dispatch, getState) => {
    dispatch(ChangeUISettingsPlain(setting, change))
    let settings = getState().Dpl.UI
    request
      .post("/api/dpl/basis/settings/ui")
      .send(settings)
      .end(() => {})
  }
}

export function LoadUserSettings() {
  return (dispatch) => {
    request.get("/api/dpl/basis/settings/ui").end((err, response) => {
      if (!err) {
        dispatch(LoadedUISettings(response.body))
      }
    })
  }
}

export function ChangeDplUISettingsPlain(setting, change) {
  return {
    type: CHANGE_DPL_UI_SETTINGS,
    setting,
    change
  }
}

export function ChangeDplUIVisibilityPlain(setting, change) {
  return {
    type: CHANGE_DPL_UI_VISIBILITY,
    setting,
    change
  }
}

export function LoadedDplUISettings(settings) {
  return {
    type: LOADED_DPL_UI_SETTINGS,
    settings
  }
}

export function ChangeDplUISettings(setting, change) {
  return (dispatch, getState) => {
    dispatch(ChangeDplUISettingsPlain(setting, change))
    let settings = getState().Dpl.DUI
    request
      .post("/api/dpl/basis/settings/dui")
      .send(settings)
      .end(() => {})
  }
}

export function ChangeDplUIVisibility(setting, change) {
  return (dispatch, getState) => {
    dispatch(ChangeDplUIVisibilityPlain(setting, change))
    let settings = getState().Dpl.DUI
    request
      .post("/api/dpl/basis/settings/dui")
      .send(settings)
      .end(() => {})
  }
}

export function ChangeDplUIWerteSpaltePlain(setting, change) {
  return {
    type: CHANGE_DPL_UI_WERTESPALTE,
    setting,
    change
  }
}

export function ChangeDplUIWerteSpalte(setting, change) {
  return (dispatch, getState) => {
    dispatch(ChangeDplUIWerteSpaltePlain(setting, change))
    let settings = getState().Dpl.DUI
    request
      .post("/api/dpl/basis/settings/dui")
      .send(settings)
      .end(() => {})
  }
}

export function LoadDplUserSettings(PlanID) {
  return (dispatch) => {
    let url = "/api/dpl/basis/settings/dui"
    if (PlanID) {
      url = `/api/dpl/basis/settings/dui/${PlanID}`
    }
    request.get(url).end((err, response) => {
      if (!err) {
        dispatch(SetDplGroupBy(response.body.group_by, true))
        dispatch(LoadedDplUISettings(response.body))
      }
    })
  }
}

export function SwitchMode() {
  return {
    type: SWITCH_UI_MODE
  }
}
