import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import request from "superagent"
import swal from "sweetalert"
export const MQUEUE_EMPFAENGERZUORD = "security/MQUEUE_EMPFAENGERZUORD"
export const ADD_MQUEUE_EMPFAENGERZUORD_ROW =
  "security/ADD_MQUEUE_EMPFAENGERZUORD_ROW"
export const INSERT_MQUEUE_EMPFAENGERZUORD =
  "security/INSERT_MQUEUE_EMPFAENGERZUORD"
export const EDIT_MQUEUE_EMPFAENGERZUORD =
  "security/EDIT_MQUEUE_EMPFAENGERZUORD"
export const DELETE_MQUEUE_EMPFAENGERZUORD_ROW =
  "security/DELETE_MQUEUE_EMPFAENGERZUORD_ROW"
export const DELETED_MQUEUE_EMPFAENGERZUORD_ROW =
  "security/DELETED_MQUEUE_EMPFAENGERZUORD_ROW"
export const UPDATE_MQUEUE_EMPFAENGERZUORD =
  "security/UPDATE_MQUEUE_EMPFAENGERZUORD"

export function MQueueEmpfaengerZuordStarted(user_id) {
  return {
    type: MQUEUE_EMPFAENGERZUORD,
    status: STARTED,
    user_id
  }
}
export function MQueueEmpfaengerZuordSuccess(user_id, data) {
  return {
    type: MQUEUE_EMPFAENGERZUORD,
    status: SUCCESS,
    user_id,
    data
  }
}
export function MQueueEmpfaengerZuordError(user_id, error) {
  return {
    type: MQUEUE_EMPFAENGERZUORD,
    status: ERROR,
    user_id,
    error
  }
}

export function MQueueEmpfaengerZuord(user_id) {
  return dispatch => {
    dispatch(MQueueEmpfaengerZuordStarted(user_id))
    request
      .get(`/api/security/mqueue/${user_id}/empfaengerzuord`)
      .end((err, response) => {
        if (err) {
          dispatch(MQueueEmpfaengerZuordError(user_id, err))
        } else {
          dispatch(MQueueEmpfaengerZuordSuccess(user_id, response.body))
        }
      })
  }
}

export function EditMQueueEmpfaengerZuord(user_id, id, changes) {
  return {
    type: EDIT_MQUEUE_EMPFAENGERZUORD,
    user_id,
    id,
    changes
  }
}

export function AddMQueueEmpfaengerZuordRow(user_id) {
  return {
    type: ADD_MQUEUE_EMPFAENGERZUORD_ROW,
    user_id
  }
}

export function DeleteMQueueEmpfaengerZuordStarted(user_id) {
  return {
    type: DELETED_MQUEUE_EMPFAENGERZUORD_ROW,
    status: STARTED,
    user_id
  }
}
export function DeleteMQueueEmpfaengerZuordSuccess(user_id, id) {
  return {
    type: DELETED_MQUEUE_EMPFAENGERZUORD_ROW,
    status: SUCCESS,
    user_id,
    id
  }
}
export function DeleteMQueueEmpfaengerZuordError(user_id, error) {
  return {
    type: DELETED_MQUEUE_EMPFAENGERZUORD_ROW,
    status: ERROR,
    user_id,
    error
  }
}

export function DeleteMQueueEmpfaengerZuordRow(user_id, id) {
  return {
    type: DELETE_MQUEUE_EMPFAENGERZUORD_ROW,
    user_id,
    id
  }
}

export function InsertMQueueEmpfaengerZuordStarted(user_id) {
  return {
    type: INSERT_MQUEUE_EMPFAENGERZUORD,
    status: STARTED,
    user_id
  }
}
export function InsertMQueueEmpfaengerZuordSuccess(user_id, data) {
  return {
    type: INSERT_MQUEUE_EMPFAENGERZUORD,
    status: SUCCESS,
    user_id,
    data
  }
}
export function InsertMQueueEmpfaengerZuordError(user_id, error) {
  return {
    type: INSERT_MQUEUE_EMPFAENGERZUORD,
    status: ERROR,
    user_id,
    error
  }
}

function CheckMQueueEmpfaengerZuord(empfaengerzuord) {
  for (let row of empfaengerzuord) {
    if (row.typ == null) {
      return "bitte Vorgesetzten / Stellvertreter auswählen"
    }
    if (row.zuord == null) {
      return "bitte Gruppierung auswählen"
    }
    if (row.zuord_id == null) {
      return "bitte Gruppe auswählen"
    }
  }
  return null
}

export function UpdateMQueueEmpfaengerZuordStarted(user_id) {
  return {
    type: UPDATE_MQUEUE_EMPFAENGERZUORD,
    status: STARTED,
    user_id
  }
}

export function UpdateMQueueEmpfaengerZuordSuccess(user_id, id, data) {
  return {
    type: UPDATE_MQUEUE_EMPFAENGERZUORD,
    status: SUCCESS,
    id,
    user_id,
    data
  }
}

export function UpdateMQueueEmpfaengerZuordError(user_id, id, error) {
  return {
    type: UPDATE_MQUEUE_EMPFAENGERZUORD,
    status: ERROR,
    id,
    user_id,
    error
  }
}

export function UpdateMQueueEmpfaengerZuord(user_id) {
  return (dispatch, getState) => {
    let data = getState().Security.empfaengerzuord.data[user_id] || []

    if (data.length == 0) {
      return
    }
    var err = CheckMQueueEmpfaengerZuord(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Personalstammdaten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        dispatch(DeleteMQueueEmpfaengerZuordStarted(user_id, row.id))
        request
          .delete(`/api/security/mqueue/${user_id}/empfaengerzuord/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteMQueueEmpfaengerZuordError(user_id, row.id, err))
            } else {
              dispatch(
                DeleteMQueueEmpfaengerZuordSuccess(
                  user_id,
                  row.id,
                  response.body
                )
              )
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row

        dispatch(UpdateMQueueEmpfaengerZuordStarted(user_id))
        request
          .post(`/api/security/mqueue/${user_id}/empfaengerzuord`)
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateMQueueEmpfaengerZuordError(user_id, id, err))
            } else {
              dispatch(
                UpdateMQueueEmpfaengerZuordSuccess(user_id, id, response.body)
              )
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateMQueueEmpfaengerZuordStarted(user_id))
        request
          .put(`/api/security/mqueue/${user_id}/empfaengerzuord`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateMQueueEmpfaengerZuordError(user_id, row.id, err))
            } else {
              dispatch(
                UpdateMQueueEmpfaengerZuordSuccess(
                  user_id,
                  row.id,
                  response.body
                )
              )
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
  }
}

export function EmpfaengerZuordUnsavedChanges(state) {
  for (let empfaengerzuord of Object.values(state)) {
    for (let row of empfaengerzuord) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}
