import React from "react"
//import AuthenticationActions from "./authentication_actions.js"
import tr from "app/shared/translation/translate.js"
import { Row, Col } from "app/shared/ui/grid"
import Button from "app/shared/ui/button"
import Input from "app/shared/ui/input"
import GlyphIcon from "app/shared/ui/icons/icons"
import { AppCaption } from "app/shared/ui/various.js"
import { InitUser as InitUserAction } from "./actions"
import PropTypes from "prop-types"

class InitUser extends React.Component {
  static propTypes = {
    initUser: PropTypes.func
  }
  constructor(props) {
    super(props)
    this.state = { user: "admin", password: "" }
    this.changePassword = this.changePassword.bind(this)
    this.changeUser = this.changeUser.bind(this)
    this.initUser = this.initUser.bind(this)
  }
  changePassword(event) {
    this.setState({ password: event.target.value, error: "", success: "" })
  }

  changeUser(event) {
    this.setState({ user: event.target.value, error: "", success: "" })
  }

  initUser() {
    if (this.state.password !== "" && this.state.user !== "") {
      this.props.initUser({
        user_name: this.state.user,
        password: this.state.password
      })
    }
  }

  mayBeEnter(event) {
    // Keydown event
    // Falls "enter" (code 13)
    // Logon ausführen
    if (event.which === 13) {
      this.initUser()
    }
  }
  render() {
    return (
      <div>
        <div
          css={{
            borderRadius: "15px",
            textAlign: "center",
            lineHeight: "50px",
            backgroundColor: "#fefecc",
            position: "absolute",
            top: "20%",
            left: "50%",
            width: "500px",
            margin: "-100px 0px 0px -240px"
          }}
        >
          <GlyphIcon
            css={{ fontSize: "50px", color: "#f00" }}
            icon="triangle-exclamation"
          />
          <span>Es sind noch keine Benutzer angelegt</span>
        </div>
        <div
          css={{
            borderRadius: "15px",
            border: "1px solid #ccc",
            backgroundColor: "#f9f9f9",
            width: "330px",
            height: "200px",
            position: "absolute",
            left: "50%",
            top: "40%",
            margin: "-100px 0 0 -180px",
            padding: "15px",
            boxShadow: "3px 3px 9px #aaa"
          }}
        >
          <div className="login-form">
            <Row>
              <Col xs="1-2">
                <AppCaption />
              </Col>
              <Col xs="1-2">
                <div className="login-inputs" css={{ marginTop: "5px" }}>
                  <Input label={tr("Neuer Admin Benutzer")} block>
                    <input
                      type="text"
                      ref={(input) => {
                        this.input = input
                      }}
                      placeholder={tr("User")}
                      bsSize="small"
                      value={this.state.user}
                      onChange={this.changeUser}
                      onKeyPress={this.mayBeEnter}
                    />
                  </Input>
                  <Input label={tr("Neues Kennwort")} block>
                    <input
                      type="password"
                      placeholder={tr("Password")}
                      bsSize="small"
                      value={this.state.password}
                      onChange={this.changePassword}
                      onKeyPress={this.mayBeEnter}
                      autoFocus={true}
                    />
                  </Input>
                  <Button
                    block
                    css={{
                      float: "right",
                      marginRight: "0px",
                      marginTop: "5px",
                      minWidth: "80px",
                      textAlign: "right"
                    }}
                    className="default-gradient"
                    onClick={this.initUser}
                  >
                    {tr("Setzen")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initUser: (user) => {
      return dispatch(InitUserAction(user))
    }
  }
}

import { connect } from "react-redux"

const InitUserContainer = connect(mapStateToProps, mapDispatchToProps)(InitUser)

export default InitUserContainer
