import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { SPERRDATUM_CHECK } from "./actions"
import { NOSPERRE } from "./actions"

export default function Sperrdatum(
  state = {
    data: [],
    sperrdatum: {},
    nosperre: {}
  },
  action
) {
  switch (action.type) {
    case SPERRDATUM_CHECK:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            sperrdatum: {
              ...state.sperrdatum,
              [action.int_id]: { datum: null }
            },
            isLoading: true,
            error: null
          }
        case SUCCESS:
          return {
            ...state,
            sperrdatum: {
              ...state.sperrdatum,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case NOSPERRE:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            nosperre: {
              ...state.nosperre,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
  }

  return state
}
