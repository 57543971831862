import { queryToString } from "app/shared/util/urls"
import request from "superagent"

export const LOAD_MQUEUE = "WFL/LOAD_MQUEUE"
export const UPDATED_MQUEUE = "WFL/UPDATED_MQUEUE"
export const CREATED_MQUEUE = "WFL/CREATED_MQUEUE"

export function UpdateMQueue(data) {
  return (dispatch, getState) => {
    request
      .put(`/api/arionworkflow/${data.id}`)
      .send(data)
      .end((err, response) => {
        if (!err) {
          if (response.body.updated) {
            //dispatch(UpdateMQueueSuccess(data.id, response.body.updated))
          }
          if (response.body.new) {
            dispatch({
              type: CREATED_MQUEUE,
              data: { mqueue: [response.body.new] }
            })
          }
        }
        /*   Meldungs-Text bei update weitergeleitet */
        let message = (response.body && response.body.message) || null
        if (err) {
          swal(
            "Fehler beim erstellen des Antrags !",
            message || "Antrag ändern momentan nicht möglich",
            "error"
          )
          return
        } else {
          swal("Antrag erfolgreich geändert !", "", "success")
        }
      })
  }
}

export function LoadMQueue() {
  return (dispatch) => {
    request.get("/api/arionworkflow/").end((err, response) => {
      if (!err) {
        dispatch({ type: LOAD_MQUEUE, data: response.body })
      }
    })
  }
}

export function LoadMQueueQuery(query) {
  return (dispatch) => {
    request
      .get("/api/arionworkflow/query")
      .query(queryToString(query))
      .end((err, response) => {
        if (!err) {
          dispatch({ type: LOAD_MQUEUE, data: response.body })
        }
      })
  }
}
