import { BYPASS_LOCK, SET_YEAR, SET_MONTH } from "../actions"

export function Lock(state = { byPass: false }, action) {
  switch (action.type) {
    case SET_YEAR:
    case SET_MONTH:
      return { ...state, byPass: false }
    case BYPASS_LOCK:
      return { ...state, byPass: !state.byPass }
  }
  return state
}
