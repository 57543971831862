import { LOAD_PLACEHOLDERS } from "../actions"
import { SUCCESS, STARTED } from "app/state/constants"

export function PlaceHolders(state = {}, action) {
  switch (action.type) {
    case LOAD_PLACEHOLDERS:
      if (action.status == STARTED) {
        return state
      }
      if (action.status == SUCCESS) {
        return { ...state, ...action.data }
      }
  }
  return state
}
