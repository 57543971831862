import PropTypes from "prop-types"
import React from "react"

const styles = {
  grid: { display: "flex", flexDirection: "column", height: "100%" },
  row: { display: "flex", flex: 1, flexDirection: "row" },
  col: { display: "flex", flex: 1, flexDirection: "column" },
  colRatio: (ratio) => {
    return { flexGrow: ratio || 1 }
  },
  colFixed: { flexGrow: 0, flexShrink: 0 }
}

export class Grid extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }
  render() {
    let { children, ...props } = this.props
    return (
      <div css={styles.grid} {...props}>
        {this.props.children}
      </div>
    )
  }
}

export class Row extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }
  render() {
    const { children, ...props } = this.props
    return (
      <div css={styles.row} {...props}>
        {this.props.children}
      </div>
    )
  }
}

export class Col extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }
  constructor(props) {
    super(props)
  }

  render() {
    let { children, xs, md, lg, xl, fixed, ...props } = this.props
    return (
      <div
        css={[
          styles.col,
          styles.colRatio(props.ratio),
          this.props.fixed && styles.colFixed,
          this.props.width && { flexBasis: this.props.width }
        ]}
        {...props}
      >
        {children}
      </div>
    )
  }
}
