import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import {
  DELETE_WOCHENMODELL_FEIERTAG,
  EDIT_WOCHENMODELL_FEIERTAG,
  LOAD_WOCHENMODELL_FEIERTAG,
  LOAD_ZEITMODELL
} from "./actions"
import {
  LOAD_SCHICHTMODELLE,
  LOAD_SCHICHTMODELLE_BEZ,
  EDIT_SCHICHTTEXT,
  UPDATE_SCHICHTMODELLBEZ,
  UPDATE_SCHICHTMODELL,
  NEW_SCHICHTMODELL,
  NEW_SCHICHTMODELLBEZ
} from "./actions"
import {
  //LOAD_WOCHENMODELLE,
  LOAD_WOCHENMODELL,
  LOAD_WOCHENMODELLE_BEZ,
  NEW_WOCHENMODELL,
  NEW_WOCHENMODELLBEZ,
  UPDATE_WOCHENMODELLBEZ,
  UPDATE_WOCHENMODELL,
  EDIT_TAGESMOD,
  EDIT_WOCHENTEXT
} from "./actions"
import {
  LOAD_TAGESMODELLEBEZ,
  LOAD_TAGESMODELLE,
  NEW_TAGESMODELL,
  NEW_TAGESMODELLBEZ,
  UPDATE_TAGESMODELL,
  EDIT_TZM,
  CREATETZM_NEW_AB_DATUM,
  EDIT_TAGESMODELLTEXT,
  UPDATE_TAGESMODELLBEZ,
  EDIT_WOCHENMOD
} from "./actions"
import {
  LOAD_GRUPPENMODELLZUORD,
  ADD_GRUPPENMODELLZUORD_ROW,
  DELETE_GRUPPENMODELLZUORD_ROW,
  DELETED_GRUPPENMODELLZUORD_ROW,
  EDIT_GRUPPENMODELLZUORD,
  UPDATE_GRUPPENMODELLZUORD,
  LOAD_SCHICHTTAUSCH,
  ADD_SCHICHTTAUSCH_ROW,
  DELETE_SCHICHTTAUSCH_ROW,
  DELETED_SCHICHTTAUSCH_ROW,
  EDIT_SCHICHT,
  UPDATE_SCHICHTTAUSCH,
  LOAD_SYMBOL_ZUORD,
  EDIT_SYMBOL_ZUORD,
  ADD_SYMBOLZUORD_ROW,
  DELETED_SYMBOLZUORD_ROW,
  DELETE_SYMBOLZUORD_ROW,
  UPDATE_SYMBOL_ZUORD,
  LOAD_RBSYMBOL_ZUORD,
  ADD_RBSYMBOLZUORD_ROW,
  DELETED_RBSYMBOLZUORD_ROW,
  DELETE_RBSYMBOLZUORD_ROW,
  UPDATE_RBSYMBOL_ZUORD,
  EDIT_RBSYMBOL_ZUORD,
  LOAD_RBTAGESMODELLE,
  LOAD_RBTAGESMODELLEBEZ,
  EDIT_RBTAGESMODELLTEXT,
  EDIT_RBTZM,
  UPDATE_RBTAGESMODELL,
  UPDATE_RBTAGESMODELLBEZ,
  NEW_RBTAGESMODELL,
  NEW_RBTAGESMODELLBEZ,
  EDIT_RBTAGESMOD,
  LOAD_RBVERTAGESMOD,
  ADD_RBVERTAGESMOD_ROW,
  DELETED_RBVERTAGESMOD_ROW,
  DELETE_RBVERTAGESMOD_ROW,
  EDIT_RBVERTAGESMOD,
  UPDATE_RBVERTAGESMOD,
  LOAD_SALDENBEREINIGUNG,
  ADD_SALDENBEREINIGUNG_ROW,
  DELETE_SALDENBEREINIGUNG_ROW,
  DELETED_SALDENBEREINIGUNG_ROW,
  EDIT_SALDENBEREINIGUNG,
  UPDATE_SALDENBEREINIGUNG,
  LOAD_VVA,
  EDIT_VVA,
  EDIT_VVA_TGL,
  ADD_VVA_ROW,
  UPDATE_VVA,
  DELETED_VVA_ROW,
  DELETE_VVA_ROW,
  EDIT_VVAAB,
  ADD_VVA_ROW_AB,
  LOAD_TAGESMODZTUMBUCH,
  UPDATE_TAGESMODZTUMBUCH,
  EDIT_TAGESMODZTUMBUCH,
  ADD_TAGESMODZTUMBUCH_ROW,
  DELETED_TAGESMODZTUMBUCH_ROW,
  DELETE_TAGESMODZTUMBUCH_ROW
} from "./actions"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"
import { v4 as uuidv4 } from "uuid"

export default function Zeitmodell(
  state = {
    data: [],
    tagesmodellebez: [],
    tagesmodell: {},
    wochenmod: [],
    wochenmodell: {},
    wochenmodellftg: {},
    wochenmodbez: [],
    schichtmod: [],
    schichtmodbez: [],
    gruppenmodellzuord: [],
    schichttausch: [],
    dplsymbolzuord: [],
    rbsymbolzuord: [],
    rbtagesmodellebez: [],
    rbtagesmodell: {},
    rbvertagesmodelle: [],
    saldenbereinigung: [],
    tagesmodztumbuch: {},
    vva: []
  },
  action
) {
  switch (action.type) {
    case LOAD_TAGESMODZTUMBUCH:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            tagesmodztumbuch: {
              ...state.tagesmodztumbuch,
              [action.tg_ztmod]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break

    case UPDATE_TAGESMODZTUMBUCH:
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagesmodztumbuch: {
            ...state.tagesmodztumbuch,
            [action.data.tg_ztmod]: [
              ...state.tagesmodztumbuch[action.data.tg_ztmod]
            ].map((r) => {
              if (action.id == r.id) {
                return action.data
              }
              return r
            })
          }
        }
      }
      return state

    case DELETED_TAGESMODZTUMBUCH_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagesmodztumbuch: {
            ...state.tagesmodztumbuch,
            [action.tg_ztmod]: state.tagesmodztumbuch[action.tg_ztmod].filter(
              (row) => {
                if (row.id == action.id) {
                  return false
                }
                return true
              }
            )
          }
        }
      }
      return state
    case DELETE_TAGESMODZTUMBUCH_ROW:
      return {
        ...state,
        tagesmodztumbuch: {
          ...state.tagesmodztumbuch,
          [action.tg_ztmod]: state.tagesmodztumbuch[action.tg_ztmod].map(
            (row) => {
              if (row.id == action.id) {
                return { ...row, __deleted: true }
              }
              return row
            }
          )
        }
      }
    case ADD_TAGESMODZTUMBUCH_ROW:
      let datum = action.ab_datum
      return {
        ...state,
        tagesmodztumbuch: {
          ...state.tagesmodztumbuch,
          [action.tg_ztmod]: [
            {
              id: uuidv4(),
              __new: true,
              tg_ztmod: action.tg_ztmod,
              mand_id: 1,
              firm_id: 1,
              ab_datum: datum,
              lfd_nr: getNextLfdNr(
                state.tagesmodztumbuch[action.tg_ztmod],
                state.tagesmodztumbuch[action.ab_datum]
              ),
              ...(action.values || {})
            },
            ...(state.tagesmodztumbuch[action.tg_ztmod] || [])
          ]
        }
      }

    case EDIT_TAGESMODZTUMBUCH:
      return {
        ...state,
        tagesmodztumbuch: {
          ...state.tagesmodztumbuch,
          [action.tg_ztmod]: state.tagesmodztumbuch[action.tg_ztmod].map(
            (row) => {
              if (row.id == action.id) {
                return { ...row, ...action.changes, __dirty: true }
              }
              return row
            }
          )
        }
      }

    case LOAD_VVA:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            vva: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_VVA:
      return {
        ...state,
        vva: state.vva.map((row) => {
          if (row.id == action.id) {
            let r = { ...row, __dirty: true, ...action.changes }
            return {
              ...r,
              arb_zt_tgl: calcTgl(r),
              arb_zt_wtl: calcWo(r),
              arb_zt_4wo: calc4Wo(r),
              arb_zt_mtl: calcMtl(r),
              arb_zt_mo: calcWD(r, 1),
              arb_zt_di: calcWD(r, 2),
              arb_zt_mi: calcWD(r, 3),
              arb_zt_do: calcWD(r, 4),
              arb_zt_fr: calcWD(r, 5),
              arb_zt_sa: calcWD(r, 6),
              arb_zt_so: calcWD(r, 7)
            }
          }
          return row
        })
      }
    case EDIT_VVA_TGL:
      return {
        ...state,
        vva: state.vva.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    // case EDIT_VVAAB:
    //   return {
    //     ...state,
    //     nVVa: state.vva.map((row) => {
    //       if (row.id == action.id) {
    //         return { ...row, ...action.changes, __dirty: true }
    //       }
    //       return row
    //     })
    //   }
    // case EDIT_VVAAB:
    //   let nvva = { ...state.vva }
    //   for (let [key, values] of Object.entries(nvva)) {
    //     nvva[key] = values.map((v) => {
    //       if (v.id === action.id) {
    //         return { ...v, ab_datum: action.ab_datum, __dirty: true }
    //       }
    //       return v
    //     })
    //   }
    //   return {
    //     ...state,
    //     vva: nvva
    //   }
    case ADD_VVA_ROW_AB:
      return {
        ...state,
        vva: [
          ...state.vva,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            ab_datum: action.ab_datum,
            arb_zt_tgl: "0.000",
            tage_wo: "0.000",
            arb_zt_wtl: "0.000",
            arb_zt_4wo: "0.000",
            arb_zt_mtl: "0.000",
            abr_kreis: action.abr_kreis,
            // int_id: action.int_id,
            ...(action.values || {})
          }
        ]
      }
    case ADD_VVA_ROW:
      return {
        ...state,
        vva: [
          ...state.vva,
          // ...(state.vva[action.abr_kreis] || []),
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            ab_datum: Datetime.now().startOf("month").toISO(),
            arb_zt_tgl: 0,
            tage_wo: 0,
            arb_zt_wtl: 0,
            arb_zt_4wo: 0,
            arb_zt_mtl: 0,
            abr_kreis: action.abr_kreis,
            ...(action.values || {})
          }
        ]
      }

    case UPDATE_VVA:
      if (action.status == SUCCESS) {
        return {
          ...state,
          vva: state.vva.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case DELETED_VVA_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          vva: state.vva.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_VVA_ROW:
      return {
        ...state,
        vva: state.vva.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case LOAD_RBTAGESMODELLEBEZ:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            rbtagesmodellebez: action.data,
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_RBTAGESMODELLE:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            rbtagesmodell: {
              ...state.rbtagesmodell,
              [action.tg_ztmod]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_RBTZM:
      return {
        ...state,
        rbtagesmodell: {
          ...state.rbtagesmodell,
          [action.tg_ztmod]: state.rbtagesmodell[action.tg_ztmod].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case UPDATE_RBTAGESMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbtagesmodellebez: state.rbtagesmodellebez.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_RBTAGESMODELLTEXT:
      return {
        ...state,
        rbtagesmodellebez: state.rbtagesmodellebez.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case UPDATE_RBTAGESMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbtagesmodell: {
            ...state.rbtagesmodell,
            [action.id]: state.rbtagesmodell[action.id].map((r) => {
              if (action.data.id == r.id) {
                return action.data
              }
              return r
            })
          }
        }
      }
      return state
    case NEW_RBTAGESMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbtagesmodell: {
            ...state.rbtagesmodell,
            [action.tg_ztmod]: [action.data]
          }
        }
      }
      return state

    case NEW_RBTAGESMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbtagesmodellebez: [action.data, ...state.rbtagesmodellebez]
        }
      }
      return state
    case LOAD_RBVERTAGESMOD:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            rbvertagesmodelle: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_RBVERTAGESMOD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbvertagesmodelle: state.rbvertagesmodelle.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_RBVERTAGESMOD_ROW:
      return {
        ...state,
        rbvertagesmodelle: state.rbvertagesmodelle.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_RBVERTAGESMOD_ROW:
      return {
        ...state,
        rbvertagesmodelle: [
          ...state.rbvertagesmodelle,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            int_id: 0,
            g_key: " ",
            ab_datum: Datetime.now().startOf("day").toISO(),
            tg_ztmod: action.tg_ztmod,
            ...(action.values || {})
          }
        ]
      }
    case UPDATE_RBVERTAGESMOD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbvertagesmodelle: state.rbvertagesmodelle.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_RBVERTAGESMOD:
      return {
        ...state,
        rbvertagesmodelle: state.rbvertagesmodelle.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }

    case EDIT_RBTAGESMOD:
      return {
        ...state,
        rbwochenmodell: {
          ...state.rbwochenmodell,
          [action.tg_ztmod]: state.rbwochenmodell[action.tg_ztmod].map(
            (row) => {
              if (row.id == action.id) {
                return {
                  ...row,
                  ...action.changes,
                  __dirty: true,
                  __valid: action.valid
                }
              }
              return row
            }
          )
        }
      }

    case LOAD_RBSYMBOL_ZUORD:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            rbsymbolzuord: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_RBSYMBOL_ZUORD:
      return {
        ...state,
        rbsymbolzuord: state.rbsymbolzuord.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case ADD_RBSYMBOLZUORD_ROW:
      return {
        ...state,
        rbsymbolzuord: [
          ...state.rbsymbolzuord,
          {
            id: uuidv4(),
            __new: true,
            ab_datum: Datetime.now().startOf("day").toISO(),
            rb_symb: action.tg_ztmod,
            ...(action.values || {})
          }
        ]
      }
    case DELETED_RBSYMBOLZUORD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbsymbolzuord: state.rbsymbolzuord.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_RBSYMBOLZUORD_ROW:
      return {
        ...state,
        rbsymbolzuord: state.rbsymbolzuord.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case UPDATE_RBSYMBOL_ZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          rbsymbolzuord: state.rbsymbolzuord.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_SYMBOL_ZUORD:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            dplsymbolzuord: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_SYMBOL_ZUORD:
      return {
        ...state,
        dplsymbolzuord: state.dplsymbolzuord.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case ADD_SYMBOLZUORD_ROW:
      return {
        ...state,
        dplsymbolzuord: [
          ...state.dplsymbolzuord,
          {
            id: uuidv4(),
            __new: true,
            // plan_id: 0,
            ab_datum: Datetime.now().startOf("day").toISO(),
            dpl_symb: action.zm_tg,
            ...(action.values || {})
          }
        ]
      }
    case DELETED_SYMBOLZUORD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          dplsymbolzuord: state.dplsymbolzuord.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_SYMBOLZUORD_ROW:
      return {
        ...state,
        dplsymbolzuord: state.dplsymbolzuord.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case UPDATE_SYMBOL_ZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          dplsymbolzuord: state.dplsymbolzuord.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case UPDATE_SCHICHTTAUSCH:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichttausch: state.schichttausch.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_SCHICHTTAUSCH:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            schichttausch: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_SCHICHTTAUSCH_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichttausch: state.schichttausch.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_SCHICHTTAUSCH_ROW:
      return {
        ...state,
        schichttausch: state.schichttausch.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_SCHICHTTAUSCH_ROW:
      return {
        ...state,
        schichttausch: [
          ...state.schichttausch,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            ab_datum: Datetime.now().startOf("day").toISO(),
            von_uhr: null,
            bis_uhr: null,
            geht_von: null,
            geht_bis: null,
            zm_tg: action.zm_tg,
            ...(action.values || {})
          }
        ]
      }

    case UPDATE_SALDENBEREINIGUNG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          saldenbereinigung: state.saldenbereinigung.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_SALDENBEREINIGUNG:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            saldenbereinigung: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_SALDENBEREINIGUNG_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          saldenbereinigung: state.saldenbereinigung.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_SALDENBEREINIGUNG_ROW:
      return {
        ...state,
        saldenbereinigung: state.saldenbereinigung.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_SALDENBEREINIGUNG_ROW:
      return {
        ...state,
        saldenbereinigung: [
          ...state.saldenbereinigung,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            g_key: " ",
            int_id: 0,
            ab_datum: Datetime.now().startOf("day").toISO(),
            // von_uhr: null,
            // bis_uhr: null,
            // geht_von: null,
            // geht_bis: null,
            zm_tg: action.zm_tg,
            ...(action.values || {})
          }
        ]
      }
    case EDIT_SALDENBEREINIGUNG:
      return {
        ...state,
        saldenbereinigung: state.saldenbereinigung.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }

    case UPDATE_TAGESMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagesmodellebez: state.tagesmodellebez.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case UPDATE_WOCHENMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          wochenmodbez: state.wochenmodbez.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case UPDATE_SCHICHTMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichtmodbez: state.schichtmodbez.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_TAGESMODELLTEXT:
      return {
        ...state,
        tagesmodellebez: state.tagesmodellebez.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case EDIT_SCHICHTTEXT:
      return {
        ...state,
        schichtmodbez: state.schichtmodbez.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case EDIT_WOCHENTEXT:
      return {
        ...state,
        wochenmodbez: state.wochenmodbez.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case EDIT_SCHICHT:
      return {
        ...state,
        schichttausch: state.schichttausch.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case UPDATE_GRUPPENMODELLZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          gruppenmodellzuord: state.gruppenmodellzuord.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_GRUPPENMODELLZUORD:
      return {
        ...state,
        gruppenmodellzuord: state.gruppenmodellzuord.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }

    case CREATETZM_NEW_AB_DATUM:
      var oldModell = state.tagesmodell[action.tg_ztmod].find((row) => {
        return row.ab_datum == action.ab_datum
      })
      var nextModell = state.tagesmodell[action.tg_ztmod].find((row) => {
        return (
          Datetime.fromISO(row.ab_datum) > Datetime.fromISO(action.ab_datum)
        )
      })
      var bis_dat = null
      if (action.ab_datum == action.new_ab_datum) {
        return
      }
      if (nextModell) {
        bis_dat = Datetime.fromISO(nextModell.ab_datum)
          .plus({ days: -1 })
          .toISO()
      }
      // }
      return {
        ...state,
        tagesmodell: {
          ...state.tagesmodell,
          [action.tg_ztmod]: [
            {
              ...oldModell,
              ab_datum: action.new_ab_datum,
              bis_dat: bis_dat,
              __new: true
            }
          ]
            .concat(
              state.tagesmodell[action.tg_ztmod].map((row) => {
                if (row.ab_datum == action.ab_datum) {
                  return {
                    ...row,
                    bis_dat: Datetime.fromISO(action.new_ab_datum)
                      .plus({ days: -1 })
                      .toISO(),
                    __dirty: true
                  }
                }
                return row
              })
            )
            .sort((a, b) => {
              return Datetime.fromISO(a.ab_datum) > Datetime.fromISO(b.ab_datum)
                ? -1
                : 1
            })
        }
      }
    case EDIT_WOCHENMOD:
      return {
        ...state,
        schichtmod: {
          ...state.schichtmod,
          [action.zm_var]: state.schichtmod[action.zm_var].map((row) => {
            if (row.id == action.id) {
              return {
                ...row,
                ...action.changes,
                __dirty: true
              }
            }
            return row
          })
        }
      }
    case EDIT_TAGESMOD:
      return {
        ...state,
        wochenmodell: {
          ...state.wochenmodell,
          [action.tg_ztmod]: state.wochenmodell[action.tg_ztmod].map((row) => {
            if (row.id == action.id) {
              return {
                ...row,
                ...action.changes,
                __dirty: true,
                __valid: action.valid
              }
            }
            return row
          })
        }
      }

    case DELETED_GRUPPENMODELLZUORD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          gruppenmodellzuord: state.gruppenmodellzuord.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_GRUPPENMODELLZUORD_ROW:
      return {
        ...state,
        gruppenmodellzuord: state.gruppenmodellzuord.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_GRUPPENMODELLZUORD_ROW:
      return {
        ...state,
        gruppenmodellzuord: [
          ...state.gruppenmodellzuord,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            ab_datum: Datetime.now().startOf("day").toISO(),
            zm_tg: action.zm_tg,
            ...(action.values || {})
          }
          // ,
          // ...(state.gruppenmodellzuord[action.zm_tg] || [])
        ]
      }
    case NEW_SCHICHTMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichtmod: {
            ...state.schichtmod,
            [action.zm_var]: action.data
          }
        }
      }
      return state
    case NEW_SCHICHTMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichtmodbez: [action.data, ...state.schichtmodbez]
        }
      }
      return state
    case NEW_WOCHENMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          wochenmodell: { ...state.wochenmodell, [action.zm_wo]: action.data }
        }
      }
      return state
    case NEW_WOCHENMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          wochenmodbez: [action.data, ...state.wochenmodbez]
        }
      }
      return state
    case UPDATE_SCHICHTMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          schichtmod: {
            ...state.schichtmod,
            [action.id]: state.schichtmod[action.id].map((r) => {
              if (action.data.id == r.id) {
                return action.data
              }
              return r
            })
          }
        }
      }
      return state
    case UPDATE_WOCHENMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          wochenmodell: {
            ...state.wochenmodell,
            [action.id]: state.wochenmodell[action.id].map((r) => {
              if (action.data.id == r.id) {
                return action.data
              }
              return r
            })
          }
        }
      }
      return state
    case NEW_TAGESMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagesmodell: {
            ...state.tagesmodell,
            [action.tg_ztmod]: [action.data]
          }
        }
      }
      return state

    case NEW_TAGESMODELLBEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagesmodellebez: [action.data, ...state.tagesmodellebez]
        }
      }
      return state
    case UPDATE_TAGESMODELL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          tagesmodell: {
            ...state.tagesmodell,
            [action.id]: state.tagesmodell[action.id].map((r) => {
              if (action.data.id == r.id) {
                return action.data
              }
              return r
            })
          }
        }
      }
      return state

    case EDIT_TZM:
      return {
        ...state,
        tagesmodell: {
          ...state.tagesmodell,
          [action.tg_ztmod]: state.tagesmodell[action.tg_ztmod].map((row) => {
            if (row.ab_datum == action.ab_datum) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }

    case LOAD_GRUPPENMODELLZUORD:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            gruppenmodellzuord: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_TAGESMODELLEBEZ:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            tagesmodellebez: action.data,
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_TAGESMODELLE:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            tagesmodell: {
              ...state.tagesmodell,
              [action.tg_ztmod]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_WOCHENMODELL_FEIERTAG:
      return {
        ...state,
        wochenmodellftg: {
          ...state.wochenmodellftg,
          [action.zmwo]: action.data
        }
      }
    case DELETE_WOCHENMODELL_FEIERTAG:
      let prevWochenmod = { ...state.wochenmodellftg }
      delete prevWochenmod[action.zmwo]
      return {
        ...state,
        wochenmodellftg: prevWochenmod
      }
    case EDIT_WOCHENMODELL_FEIERTAG:
      return {
        ...state,
        wochenmodellftg: {
          ...state.wochenmodellftg,
          [action.zmwo]: {
            ...(state.wochenmodellftg[action.zmwo] || {
              mand_id: 1,
              firm_id: 1,
              zm_wo: action.zmwo,
              __new: true
            }),
            ...action.changes,
            __deleted: action.changes.tg_ztmod === "",
            __dirty: true
          }
        }
      }

    case LOAD_WOCHENMODELL:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            wochenmodell: {
              ...state.wochenmodell,
              [action.zm_wo]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    // case LOAD_WOCHENMODELLE:
    //   switch (action.status) {
    //     case STARTED:
    //       return { ...state, isLoading: true, error: null }
    //     case SUCCESS:
    //       return {
    //         ...state,
    //         wochenmod: action.data,
    //         isLoading: false,
    //         error: null
    //       }
    //     case ERROR:
    //       return { ...state, isLoading: false, error: action.error }
    //   }
    //   break
    case LOAD_WOCHENMODELLE_BEZ:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            wochenmodbez: action.data,
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_SCHICHTMODELLE:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            schichtmod: {
              ...state.schichtmod,
              [action.zm_var]: action.data || []
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break

    case LOAD_SCHICHTMODELLE_BEZ:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            schichtmodbez: action.data,
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_ZEITMODELL:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            [action.datum]: {
              ...(state[action.datum] || {}),
              [action.int_id]: action.data
            }
          }
        case ERROR:
          return {
            ...state,
            [action.datum]: {
              ...(state[action.datum] || {}),
              [action.int_id]: { zm_tg: "???" }
            }
          }
      }
  }
  return state
}

const getNextLfdNr = (tagesmodellztumbuch, datum) => {
  let tmztumDatum = (tagesmodellztumbuch || [])
    .filter((r) => {
      return r.datum == datum
    })
    .map((r) => {
      return r.lfd_nr
    })
  let maxNbr = Math.max(...tmztumDatum)
  if (maxNbr == -Infinity) {
    maxNbr = 0
  }
  return maxNbr + 1
}

const calcTgl = (row) => {
  return parseFloat(row.arb_zt_tgl).toFixed(1)
}
const calcWo = (row) => {
  return parseFloat(row.arb_zt_tgl * row.tage_wo).toFixed(1)
}
const calc4Wo = (row) => {
  return parseFloat(row.arb_zt_tgl * row.tage_wo * 4.0).toFixed(1)
}
const calcMtl = (row) => {
  return parseFloat(row.arb_zt_tgl * row.tage_wo * 4.348).toFixed(1)
}
const calcMo = (row) => {
  if (row.tage_wo >= 1) {
    return row.arb_zt_tgl
  }
  return null
}

const calcWD = (row, wd) => {
  if (row.tage_wo >= wd) {
    return row.arb_zt_tgl
  }
  return null
}
