import request from "superagent"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_URLAUBSANSPRUCH = "LOAD_URLAUBSANSPRUCH"
export const LOAD_URLAUBSANSPRUCH_FIX = "LOAD_URLAUBSANSPRUCH_FIX"
export const LOAD_URLAUBS_VERFALL = "LOAD_URLAUBS_VERFALL"
export const INCRMENT_URLAUB = "INCREMENT_URLAUB"
export const DECREMENT_URLAUB = "DECREMENT_URLAUB"

export function IncrementUrlaub(int_id, datum) {
  return {
    type: INCRMENT_URLAUB,
    year: Datetime.fromISO(datum).year(),
    int_id,
    datum
  }
}

export function DecrementUrlaub(int_id, datum) {
  return {
    type: DECREMENT_URLAUB,
    year: Datetime.fromISO(datum).year(),
    int_id,
    datum
  }
}

export function LoadUrlaubsanspruchStarted(int_id, year) {
  return {
    type: LOAD_URLAUBSANSPRUCH,
    status: STARTED,
    int_id,
    year
  }
}

export function LoadUrlaubsanspruchSuccess(int_id, year, data) {
  return {
    type: LOAD_URLAUBSANSPRUCH,
    status: SUCCESS,
    int_id,
    year,
    data
  }
}

export function LoadUrlaubsanspruchError(int_id, year, error) {
  return {
    type: LOAD_URLAUBSANSPRUCH,
    status: ERROR,
    _handleInApp: true,
    int_id,
    year,
    error
  }
}

export function LoadUrlaubsAnspruch(int_id, year) {
  return (dispatch, getState) => {
    //let ansp = getState().UrlaubsAnspruch[int_id]
    // if (ansp && ansp[year]) {
    //   return
    // }
    dispatch(LoadUrlaubsanspruchStarted(int_id, year))
    request
      .get(`/api/p/${int_id}/urlaubsanspruch/${year}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadUrlaubsanspruchError(int_id, year, err))
        } else {
          dispatch(LoadUrlaubsanspruchSuccess(int_id, year, response.body))
        }
      })
  }
}

export function LoadUrlaubsanspruchFixStarted(int_id, year) {
  return {
    type: LOAD_URLAUBSANSPRUCH_FIX,
    status: STARTED,
    int_id,
    year
  }
}

export function LoadUrlaubsanspruchFixSuccess(int_id, year, data) {
  return {
    type: LOAD_URLAUBSANSPRUCH_FIX,
    status: SUCCESS,
    int_id,
    year,
    data
  }
}

export function LoadUrlaubsanspruchFixError(int_id, year, error) {
  return {
    type: LOAD_URLAUBSANSPRUCH_FIX,
    status: ERROR,
    _handleInApp: true,
    int_id,
    year,
    error
  }
}

export function LoadUrlaubsAnspruchFix(int_id, year) {
  return (dispatch, getState) => {
    let ansp = getState().UrlaubsAnspruchFix[int_id]
    if (ansp && ansp[year]) {
      return
    }
    dispatch(LoadUrlaubsanspruchFixStarted(int_id, year))
    request
      .get(`/api/p/${int_id}/urlaubsanspruch/fix/${year}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadUrlaubsanspruchFixError(int_id, year, err))
        } else {
          dispatch(LoadUrlaubsanspruchFixSuccess(int_id, year, response.body))
        }
      })
  }
}

export function UpdateUrlaubsAnspruchFix(int_id, year, anspruch) {
  return (dispatch) => {
    dispatch(LoadUrlaubsanspruchFixStarted(int_id, year))
    request
      .post(`/api/p/${int_id}/urlaubsanspruch/fix/${year}`)
      .send(anspruch)
      .end((err, response) => {
        if (err) {
          dispatch(LoadUrlaubsanspruchFixError(int_id, year, err))
        } else {
          dispatch(LoadUrlaubsanspruchFixSuccess(int_id, year, response.body))
        }
      })
  }
}

export function LoadUrlaubsVerfallStarted(int_id, year) {
  return {
    type: LOAD_URLAUBS_VERFALL,
    status: STARTED,
    int_id,
    year
  }
}

export function LoadUrlaubsVerfallSuccess(int_id, year, data) {
  return {
    type: LOAD_URLAUBS_VERFALL,
    status: SUCCESS,
    int_id,
    year,
    data
  }
}

export function LoadUrlaubsVerfallError(int_id, year, error) {
  return {
    type: LOAD_URLAUBS_VERFALL,
    status: ERROR,
    _handleInApp: true,
    int_id,
    year,
    error
  }
}

export function LoadUrlaubsVerfall(int_id, year) {
  return (dispatch, getState) => {
    dispatch(LoadUrlaubsVerfallStarted(int_id, year))
    request
      .get(`/api/p/${int_id}/urlaubsverfall/${year}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadUrlaubsVerfallError(int_id, year, err))
        } else {
          dispatch(LoadUrlaubsVerfallSuccess(int_id, year, response.body))
        }
      })
  }
}
