import {
  LOAD_TAETIGKEITS_DEFINITIONEN,
  EDIT_TAETIGKEITS_DEFINITION,
  ADD_TAETIGKEITS_DEFINITION,
  UPDATE_TAETIGKEITS_DEFINITION,
  REMOVE_TAETIGKEITS_DEFINITION,
  SAVED_TAETIGKEIT,
  LOAD_PLAN_TAETIGKEITS_DEFINITIONEN
} from "../actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"

export function TaetigkeitsDefinitionen(state = {}, action) {
  var nState
  switch (action.type) {
    case LOAD_TAETIGKEITS_DEFINITIONEN:
    case ADD_TAETIGKEITS_DEFINITION:
    case UPDATE_TAETIGKEITS_DEFINITION:
    case REMOVE_TAETIGKEITS_DEFINITION:
      return {
        ...state,
        [action.DienstID]: Definition(state[action.DienstID], action)
      }
    case EDIT_TAETIGKEITS_DEFINITION:
      nState = { ...state }
      for (let [key, value] of Object.entries(state)) {
        nState[key] = Definition(value, action)
      }
      return nState
    case LOAD_PLAN_TAETIGKEITS_DEFINITIONEN:
      if (action.status != SUCCESS) {
        return state
      }
      nState = { ...state }
      for (let d2 of action.data) {
        var foundKey = false
        for (let [key, value] of Object.entries(nState)) {
          if (d2.dienst_id != key) {
            continue
          }
          foundKey = true
          var found = false
          for (let def of value.data) {
            var idx = 0
            if (d2.id == def.id) {
              found = true
              value[idx] = d2
            }
            idx++
          }
          if (!found && d2.dienst_id == key) {
            value.data = [...value.data, d2]
          }
          nState[key] = { ...value }
        }
        if (!foundKey) {
          let prevData = nState[d2.dienst_id] || {}
          nState[d2.dienst_id] = {
            loading: false,
            data: [...(prevData.data || []), d2],
            error: null
          }
        }
      }
      return nState
    case SAVED_TAETIGKEIT:
      if (action.id != action.data.id) {
        nState = { ...state }
        nState[action.data.id] = { ...nState[action.id] }
        delete nState[action.id]
        return nState
      }
  }
  return state
}

export function Definition(
  state = { loading: false, data: [], error: null },
  action
) {
  switch (action.type) {
    case LOAD_TAETIGKEITS_DEFINITIONEN:
      switch (action.status) {
        case STARTED:
          return state
        case ERROR:
          return { ...state, loading: false, error: action.err }
        case SUCCESS:
          return {
            ...state,
            loading: false,
            data: (state.data || [])
              .filter((v) => {
                return v.__new
              })
              .concat(action.data),
            error: null
          }
      }
      break
    case EDIT_TAETIGKEITS_DEFINITION:
    case REMOVE_TAETIGKEITS_DEFINITION:
      return {
        ...state,
        data: state.data.map((def) => {
          if (def.id === action.id) {
            return SingleDefinition(def, action)
          }
          return def
        })
      }
    case ADD_TAETIGKEITS_DEFINITION:
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: uuidv4(),
            dienst_id: action.DienstID,
            woche: 1,
            ab_datum: "1900-01-01T00:00:00Z",
            bis_datum: null,
            pers_art: "pers",
            __new: true
          }
        ]
      }

    case UPDATE_TAETIGKEITS_DEFINITION:
      if (action.data === null) {
        return {
          ...state,
          data: [...state.data].filter((def) => {
            return def.id !== action.id
          })
        }
      }
      return {
        ...state,
        data: state.data.map((def) => {
          if (def.id == action.id) {
            return action.data
          }
          return def
        })
      }
  }
  return state
}

export function SingleDefinition(state = {}, action) {
  switch (action.type) {
    case EDIT_TAETIGKEITS_DEFINITION:
      return { ...state, ...action.changes, __dirty: true }
    case REMOVE_TAETIGKEITS_DEFINITION:
      return { ...state, __deleted: true }
  }
  return state
}
