import {
  SELECT_DPL_CELL,
  SELECT_TPL_CELL,
  CLEAR_SELECTION,
  CLEAR_TPL_SELECTION,
  SWITCH_DPL_NUM,
  CLEAN_DIRTY_RECORDS,
  SELECT_PLAN
} from "../actions"

export function Selection(state = {}, action) {
  switch (action.type) {
    case SELECT_DPL_CELL:
      //if (action.extend){
      //return {...state, ...findClosest(state, action.row, action.column, action.kz)}
      //}
      return { ...state, [`${action.row}-${action.column}-${action.kz}`]: true }
    case CLEAR_SELECTION:
    case SELECT_PLAN:
      return {}
  }
  return state
}

export function SelectionNum(state = {}, action) {
  switch (action.type) {
    case SWITCH_DPL_NUM:
      //if (action.extend){
      //return {...state, ...findClosest(state, action.row, action.column, action.kz)}
      //}
      return {
        ...state,
        [`${action.row}-${action.column}`]: action.index
      }
    case CLEAN_DIRTY_RECORDS:
      return {}
  }
  return state
}

export function TplSelection(state = {}, action) {
  switch (action.type) {
    case SELECT_TPL_CELL:
      //if (action.extend){
      //return {...state, ...findClosest(state, action.row, action.column, action.kz)}
      //}
      return {
        ...state,
        [`${action.row}-${action.column}-${action.nbr}`]: true
      }
    case CLEAR_TPL_SELECTION:
      return {}
  }
  return state
}

function findClosest(state, row, column, kz) {
  let closest = 9999
  let ncolumn = null
  for (let key of Object.keys(state)) {
    let [krow, kcolumn, kkz] = key.split("-")
    let diff = 9999
    krow = parseInt(row)
    kcolumn = parseInt(kcolumn)
    if (row == krow && kkz == kz) {
      diff = Math.abs(kcolumn - column)
      if (diff < closest) {
        ncolumn = kcolumn
        closest = diff
      }
    }
  }
  let nsel = { [`${row}-${column}-${kz}`]: true }
  if (ncolumn != null) {
    let lower, upper
    if (ncolumn < column) {
      lower = ncolumn
      upper = column
    } else {
      lower = column
      upper = ncolumn
    }
    for (let c = lower; c <= upper; c++) {
      nsel = { [`${row}-${c}-${kz}`]: true, ...nsel }
    }
  }
  return nsel
}
