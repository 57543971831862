import Datetime from "app/shared/dateutil/index"
import { IsGenehmigungLockedByPers } from "./genehmigung"
import { GetWunschzeitraum, GetZeitraum } from "./zeitraum"

export const IsSelected = (state, int_id, datum, kz) => {
  return GetSelection(state)[`${int_id}-${datum}-${kz}`] || false
}

export const IsRowIndexSelected = (state, int_id, kz) => {
  const selection = GetSelection(state)
  for (let k of Object.keys(selection)) {
    const [s_int_id, y, m, d, s_kz] = k.split("-")
    if (kz == s_kz && parseInt(s_int_id) == int_id) {
      return true
    }
  }
  return false
}

export const GetSelection = (state) => {
  return state.UPL.Selection
}

export const IsWunschSelectable = (state, int_id, datum) => {
  const ownIntID = state.currentUser.user.int_id
  if (int_id != ownIntID) {
    return false
  }
  const wzraum = GetWunschzeitraum(state, datum)
  if (!wzraum || !wzraum.stichtag) {
    return false
  }
  if (Datetime.fromISO(wzraum.stichtag) < Datetime.now().startOf("day")) {
    return false
  }
  return true
}

export const IsWorkSelectable = (state, int_id, datum) => {
  const isInfo = state.currentUser.user.user_type == "info"
  if (isInfo) {
    return false
  }
  const zraum = GetZeitraum(state, datum)
  if (!zraum) {
    return false
  }
  if (IsGenehmigungLockedByPers(state, datum, parseInt(int_id))) {
    return false
  }
  return true
}
