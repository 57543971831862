import { LOAD_FEHLERPROTOKOLL } from "./actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { UPDATE_FEHLERPROTOKOLL } from "./actions"

export function Fehlerprotokoll(
  state = {
    data: [],
    sperrdatum: {},
    nosperre: {}
  },
  action
) {
  switch (action.type) {
    case UPDATE_FEHLERPROTOKOLL:
      if (action.status == SUCCESS) {
        return {
          ...state,
          data: [
            ...state.data.map((row) => {
              if (row.id == action.id) {
                return action.data
              }
              return row
            })
          ]
        }
      }
      return state

    case LOAD_FEHLERPROTOKOLL:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            data: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
  }

  return state
}
