import {
  LOAD_GENEHMIGUNG_STATUS,
  CAN_START_GENEHMIGUNG_RESET,
  CAN_START_GENEHMIGUNG,
  LOAD_GENEHMIGUNG_STUFEN,
  LOAD_GENEHMIGUNG_BERECHTIGUNG,
  EDIT_STUFE,
  EDIT_BERECHTIGUNG,
  ADD_STUFE,
  ADD_BERECHTIGUNG,
  DELETE_STUFE,
  DELETE_BERECHTIGUNG,
  SAVED_STUFE,
  SAVED_BERECHTIGUNG
} from "../actions"

import { v4 as uuidv4 } from "uuid"
import { SUCCESS } from "app/state/constants"
export function Genehmigung(state = {}, action) {
  switch (action.type) {
    case LOAD_GENEHMIGUNG_STATUS:
      return {
        ...state,
        [`${action.Year}-${action.Month}`]: action.Status
      }
  }
  return state
}

export function CanStartGenehmigung(state = {}, action) {
  switch (action.type) {
    case CAN_START_GENEHMIGUNG_RESET:
      return {}
    case CAN_START_GENEHMIGUNG:
      if (action.status == SUCCESS) {
        return action.data
      }
  }
  return state
}

export function Stufen(state = {}, action) {
  switch (action.type) {
    case LOAD_GENEHMIGUNG_STUFEN:
      return { ...state, [action.PlanID]: action.Stufen }
    case ADD_STUFE:
      return {
        ...state,
        [action.PlanID]: [
          ...(state[action.PlanID] || []),
          {
            id: uuidv4(),
            plan_id: action.PlanID,
            stufe: null,
            bezeichnung: "",
            __new: true
          }
        ]
      }
    case EDIT_STUFE:
      return {
        ...state,
        [action.PlanID]: state[action.PlanID].map((s) => {
          if (s.id == action.ID) {
            return { ...s, ...action.Changes, __dirty: true }
          }
          return s
        })
      }
    case DELETE_STUFE:
      return {
        ...state,
        [action.PlanID]: state[action.PlanID].map((s) => {
          if (s.id == action.ID) {
            return { ...s, __deleted: true }
          }
          return s
        })
      }
    case SAVED_STUFE:
      return {
        ...state,
        [action.PlanID]: state[action.PlanID]
          .map((s) => {
            if (s.id == action.ID) {
              return action.Stufe
            }
            return s
          })
          .filter((v) => {
            return v !== null
          })
      }
  }
  return state
}

export function Berechtigungen(state = {}, action) {
  switch (action.type) {
    case LOAD_GENEHMIGUNG_BERECHTIGUNG:
      return { ...state, [action.PlanID]: action.Berechtigungen }
    case ADD_BERECHTIGUNG:
      return {
        ...state,
        [action.PlanID]: [
          ...(state[action.PlanID] || []),
          {
            id: uuidv4(),
            plan_id: action.PlanID,
            stufe: null,
            user_id: null,
            __new: true
          }
        ]
      }
    case EDIT_BERECHTIGUNG:
      return {
        ...state,
        [action.PlanID]: state[action.PlanID].map((s) => {
          if (s.id == action.ID) {
            return { ...s, ...action.Changes, __dirty: true }
          }
          return s
        })
      }
    case DELETE_BERECHTIGUNG:
      return {
        ...state,
        [action.PlanID]: state[action.PlanID].map((s) => {
          if (s.id == action.ID) {
            return { ...s, __deleted: true }
          }
          return s
        })
      }
    case SAVED_BERECHTIGUNG:
      return {
        ...state,
        [action.PlanID]: state[action.PlanID]
          .map((s) => {
            if (s.id == action.ID) {
              return action.Berechtigung
            }
            return s
          })
          .filter((v) => {
            return v !== null
          })
      }
  }
  return state
}
