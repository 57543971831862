import request from "superagent"
import { LoadVersion } from "../version"
export const USER_DID_LOGIN = "USER_DID_LOGIN"
export const USER_DID_LOGOUT = "USER_DID_LOGOUT"
export const CHECK_USER_LOGON = "CHECK_USER_LOGON"
export const INIT_USER = "INIT_USER"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
import { SUCCESS, ERROR } from "app/state/constants"
export const LOGIN_STARTED = "LOGIN_STARTED"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export function UserCheckLogon() {
  return {
    type: CHECK_USER_LOGON,
    status: LOGIN_STARTED
  }
}

export function UserWillLogin(user) {
  return {
    type: USER_DID_LOGIN,
    status: LOGIN_STARTED,
    user
  }
}

export function UserDidLoginSuccess(user) {
  return {
    type: USER_DID_LOGIN,
    status: LOGIN_SUCCESS,
    user,
    error: null
  }
}
export function UserDidLoginError(user, error) {
  return {
    type: USER_DID_LOGIN,
    status: LOGIN_ERROR,
    user,
    error
  }
}

export function UserWillLogout(user) {
  return {
    type: USER_DID_LOGOUT,
    status: LOGIN_STARTED,
    user
  }
}

export function UserDidLogout(user) {
  return {
    type: USER_DID_LOGOUT,
    status: LOGIN_SUCCESS,
    user
  }
}

export function InitUserStarted(user) {
  return {
    type: INIT_USER,
    status: LOGIN_STARTED,
    user
  }
}

export function InitUser(user) {
  return function (dispatch) {
    dispatch(InitUserStarted(user))
    request
      .post("/api/auth/init")
      .send({ user_name: user.user_name, user_pass: user.password })
      .end((error, response) => {
        if (error) {
          dispatch(UserDidLoginError(user, response.body))
        } else {
          dispatch(UserDidLoginSuccess(response.body))
        }
      })
  }
}

export default function Logon(user) {
  return function (dispatch) {
    dispatch(UserWillLogin(user))
    request
      .post("/api/auth")
      .send({ user_name: user.user_name, user_pass: user.password })
      .end((error, response) => {
        if (error) {
          dispatch(UserDidLoginError(user, response.body))
        } else {
          dispatch(UserDidLoginSuccess(response.body))
        }
      })
  }
}

export function ChangeUserPassword(user, current_password, new_password) {
  return function (dispatch) {
    dispatch(UserWillLogin(user))
    request
      .post("/api/auth/password")
      .send({
        user_name: user,
        user_pass: current_password,
        new_pass: new_password
      })
      .end((error, response) => {
        if (error) {
          dispatch(UserDidLoginError(user, response.body))
        } else {
          dispatch(UserDidLoginSuccess(response.body))
        }
      })
  }
}

/*
* 	UserName    string `json:"user_name"`
	Code        string `json:"code"`
	PersID      string `json:"pers_id"`
	NewPassword string `json:"new_pass"`
* */

export function ChangeUserPasswordEmail(user, code, persID, newPassword) {
  return function (dispatch) {
    dispatch(UserWillLogin(user))
    request
      .post("/api/auth/doreset/")
      .send({
        user_name: user,
        new_pass: newPassword,
        code: code,
        pers_id: persID
      })
      .end((error, response) => {
        if (error) {
          dispatch(UserDidLoginError(user, response.body))
        } else {
          dispatch(UserDidLoginSuccess(response.body))
        }
      })
  }
}

export function ChangePassword(user, current_password, new_password) {
  return function (dispatch) {
    //dispatch(UserWillLogin(user))
    request
      .post("/api/auth/cpassword")
      .send({
        user_name: user,
        user_pass: current_password,
        new_pass: new_password
      })
      .end((error, response) => {
        if (error) {
          dispatch({
            type: CHANGE_PASSWORD,
            status: ERROR,
            error: response.body.message,
            _handleInApp: true
          })
          //dispatch(UserDidLoginError(user, response.body))
        } else {
          dispatch({
            type: CHANGE_PASSWORD,
            status: SUCCESS,
            message: response.body
          })
          //dispatch(UserDidLoginSuccess(response.body))
        }
      })
  }
}

export function Logout() {
  return function (dispatch) {
    dispatch(UserWillLogout())
    request.delete("/api/auth").end(() => {
      dispatch(UserDidLogout())
      dispatch(LoadVersion())
    })
  }
}

export function CheckLogin() {
  return function (dispatch) {
    dispatch(UserCheckLogon())
    request.get("/api/auth").end((error, response) => {
      if (!error) {
        dispatch(UserDidLoginSuccess(response.body))
      }
    })
  }
}
