import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export const LOAD_DPL_SYZM = "dpl/LOAD_DPL_SYZM"
export const LOAD_RB_SYZM = "dpl/LOAD_RB_SYZM"

function LoadDplSyzmStarted() {
  return {
    type: LOAD_DPL_SYZM,
    status: STARTED
  }
}

function LoadDplSyzmError(err) {
  return {
    type: LOAD_DPL_SYZM,
    status: ERROR,
    err
  }
}

function LoadDplSyzmSuccess(data) {
  return {
    type: LOAD_DPL_SYZM,
    status: SUCCESS,
    data
  }
}

export function LoadDplSyzm(PlanID) {
  return dispatch => {
    dispatch(LoadDplSyzmStarted())
    request.get(`/api/dpl/basis/symzord/dp/${PlanID}`).end((err, response) => {
      if (err) {
        dispatch(LoadDplSyzmError(err))
      } else {
        dispatch(LoadDplSyzmSuccess(response.body))
      }
    })
  }
}

export function LoadDplSyzmIntID(IntID) {
  return dispatch => {
    dispatch(LoadDplSyzmStarted())
    request
      .get(`/api/dpl/basis/symzord/int_id/dp/${IntID}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadDplSyzmError(err))
        } else {
          dispatch(LoadDplSyzmSuccess(response.body))
        }
      })
  }
}

function LoadRbSyzmStarted() {
  return {
    type: LOAD_RB_SYZM,
    status: STARTED
  }
}

function LoadRbSyzmError(err) {
  return {
    type: LOAD_RB_SYZM,
    status: ERROR,
    err
  }
}

function LoadRbSyzmSuccess(data) {
  return {
    type: LOAD_RB_SYZM,
    status: SUCCESS,
    data
  }
}

export function LoadRbSyzm(PlanID) {
  return dispatch => {
    dispatch(LoadRbSyzmStarted())
    request.get(`/api/dpl/basis/symzord/rb/${PlanID}`).end((err, response) => {
      if (err) {
        dispatch(LoadRbSyzmError(err))
      } else {
        dispatch(LoadRbSyzmSuccess(response.body))
      }
    })
  }
}

export function LoadRbSyzmIntID(IntID) {
  return dispatch => {
    dispatch(LoadRbSyzmStarted())
    request
      .get(`/api/dpl/basis/symzord/int_id/rb/${IntID}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadRbSyzmError(err))
        } else {
          dispatch(LoadRbSyzmSuccess(response.body))
        }
      })
  }
}
