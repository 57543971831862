export const GetWunschFreitexte = (state) => state.UPL.Data.ftext_wunsch

export const GetWunschFreitext = (state, int_id, datum) => {
  const ftext = GetWunschFreitexte(state)[`${int_id}-${datum}`]
  if (ftext && ftext.text) {
    return ftext
  }
  return null
}

export const GetPlanFreitexte = (state) => state.UPL.Data.ftext_plan

export const GetPlanFreitext = (state, int_id, datum) => {
  const ftext = GetPlanFreitexte(state)[`${int_id}-${datum}`]
  if (ftext && ftext.text) {
    return ftext
  }
  return null
}
