import store from "app/state"
import { useSelector } from "react-redux"

export default function isInfo() {
  return store.getState().currentUser.user.user_type != "security"
}

export const useIsInfo = () => {
  const isInfo = useSelector(
    (state) => state.currentUser.user.user_type != "security"
  )
  return isInfo
}
