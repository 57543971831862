import {
  LOAD_DATA,
  EDIT_WUNSCH,
  EDIT_WUNSCH_STRENGTH,
  EDIT_PLAN,
  SAVED_DATA,
  SAVE_DATA,
  LOAD_URLAUB,
  RESET_DATA
} from "../actions/data"
import {
  EDIT_WUNSCH_FREITEXT,
  EDIT_PLAN_FREITEXT,
  SAVED_PLAN_TEXT,
  SAVED_WUNSCH_TEXT,
  DELETED_PLAN_TEXT,
  DELETED_WUNSCH_TEXT
} from "../actions/ftext"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { arrayToObjectFn } from "app/shared/util/conversion"
import Datetime from "app/shared/dateutil/index"
export const Data = (
  state = {
    loading: false,
    saving: false,
    pers: [],
    work: {},
    kal: {},
    wunsch: {},
    dpl: {},
    urlaub: {},
    ftext_wunsch: {},
    ftext_plan: {}
  },
  action
) => {
  switch (action.type) {
    case RESET_DATA:
      return {
        loading: false,
        saving: false,
        pers: [],
        work: {},
        kal: {},
        wunsch: {},
        dpl: {},
        urlaub: {},
        ftext_wunsch: {},
        ftext_plan: {}
      }
    case EDIT_WUNSCH:
      var key = `${action.int_id}-${action.datum}`
      return {
        ...state,
        wunsch: {
          ...state.wunsch,
          [key]: {
            ...(state.wunsch[key] || {
              int_id: action.int_id,
              datum: Datetime.fromISO(action.datum).toISO(),
              __new: true,
              strength: 1
            }),
            __dirty: true,
            fehlz_id: action.value
          }
        }
      }
    case EDIT_WUNSCH_STRENGTH:
      var key = `${action.int_id}-${action.datum}`
      return {
        ...state,
        wunsch: {
          ...state.wunsch,
          [key]: {
            ...(state.wunsch[key] || {
              int_id: action.int_id,
              datum: Datetime.fromISO(action.datum).toISO(),
              __new: true,
              strength: 1
            }),
            __dirty: true,
            strength: action.value
          }
        }
      }
    case EDIT_PLAN:
      var key = `${action.int_id}-${action.datum}`
      return {
        ...state,
        work: {
          ...state.work,
          [key]: {
            ...(state.work[key] || {
              int_id: action.int_id,
              datum: Datetime.fromISO(action.datum).toISO(),
              __new: true
            }),
            __dirty: true,
            fehlz_id: action.value
          }
        }
      }
    case SAVE_DATA:
      return { ...state, saving: true }
    case SAVED_DATA:
      return {
        ...state,
        saving: false,
        wunsch: arrayToObjectFn(
          [
            ...Object.values(state.wunsch).filter(
              (v) => !v.__new && !v.__dirty
            ),
            ...action.data.wunsch.created
              .filter((o) => o !== null)
              .map((o) => ({ ...o, __initial: o.fehlz_id })),
            ...action.data.wunsch.dirty
              .filter((o) => o !== null)
              .map((o) => ({ ...o, __initial: o.fehlz_id }))
          ],
          (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
        ),
        work: arrayToObjectFn(
          [
            ...Object.values(state.work).filter((v) => !v.__new && !v.__dirty),
            ...action.data.work.created
              .filter((o) => o !== null)
              .map((o) => ({ ...o, __initial: o.fehlz_id })),
            ...action.data.work.dirty
              .filter((o) => o !== null)
              .map((o) => ({ ...o, __initial: o.fehlz_id }))
          ],
          (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
        )
      }

    case LOAD_URLAUB:
      return {
        ...state,
        urlaub: {
          ...state.urlaub,
          ...arrayToObjectFn(action.data, (o) => o.int_id)
        }
      }
    case SAVED_WUNSCH_TEXT:
      return {
        ...state,
        ftext_wunsch: {
          ...state.ftext_wunsch,
          [`${action.int_id}-${action.datum}`]: action.text
        }
      }
    case DELETED_WUNSCH_TEXT:
      return {
        ...state,
        ftext_wunsch: {
          ...state.ftext_wunsch,
          [`${action.int_id}-${action.datum}`]: undefined
        }
      }
    case SAVED_PLAN_TEXT:
      return {
        ...state,
        ftext_plan: {
          ...state.ftext_plan,
          [`${action.int_id}-${action.datum}`]: action.text
        }
      }
    case DELETED_PLAN_TEXT:
      return {
        ...state,
        ftext_plan: {
          ...state.ftext_plan,
          [`${action.int_id}-${action.datum}`]: undefined
        }
      }
    case EDIT_WUNSCH_FREITEXT:
      return {
        ...state,
        ftext_wunsch: {
          ...state.ftext_wunsch,
          [`${action.int_id}-${action.datum}`]: {
            ...(state.ftext_wunsch[`${action.int_id}-${action.datum}`] || {
              int_id: action.int_id,
              datum: Datetime.fromISO(action.datum).toISO()
            }),
            text: action.value,
            __dirty: true
          }
        }
      }
    case EDIT_PLAN_FREITEXT:
      return {
        ...state,
        ftext_plan: {
          ...state.ftext_plan,
          [`${action.int_id}-${action.datum}`]: {
            ...(state.ftext_plan[`${action.int_id}-${action.datum}`] || {
              int_id: action.int_id,
              datum: Datetime.fromISO(action.datum).toISO()
            }),
            text: action.value,
            __dirty: true
          }
        }
      }
    case LOAD_DATA:
      if (action.status == SUCCESS) {
        return {
          ...state,
          loading: false,
          int_ids: action.data.map((v) => v.mitarbeiter.int_id),
          pers: arrayToObjectFn(
            action.data.map((v) => ({
              ...v.mitarbeiter,
              quali_id: v.quali_id,
              wunsch_only: v.wunsch_only,
              vk_anteil: v.vk_anteil
            })),
            (o) => o.int_id
          ),
          wunsch: {
            ...state.wunsch,
            ...arrayToObjectFn(
              action.data
                .map((v) =>
                  v.tage
                    .filter((v) => v.wunsch != null)
                    .map((t) => ({
                      ...t.wunsch,
                      __initial: t.wunsch.fehlz_id
                    }))
                )
                .flat(),
              (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
            )
          },
          work: {
            ...state.work,
            ...arrayToObjectFn(
              action.data
                .map((v) =>
                  v.tage
                    .filter((v) => v.plan != null)
                    .map((t) => ({
                      ...t.plan,
                      __initial: t.plan.fehlz_id
                    }))
                )
                .flat(),
              (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
            )
          },
          kal: {
            ...state.kal,
            ...arrayToObjectFn(
              action.data
                .map((v) => v.tage.map((t) => t.kalender))
                .flat()
                .filter((o) => o != null),
              (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
            )
          },
          ftext_wunsch: {
            ...state.ftext_wunsch,
            ...arrayToObjectFn(
              action.data
                .map((v) => v.tage.map((t) => t.ftext_wunsch))
                .flat()
                .filter((o) => o != null),
              (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
            )
          },
          ftext_plan: {
            ...state.ftext_plan,
            ...arrayToObjectFn(
              action.data
                .map((v) => v.tage.map((t) => t.ftext_plan))
                .flat()
                .filter((o) => o != null),
              (o) => `${o.int_id}-${o.datum.slice(0, 10)}`
            )
          }
        }
      } else {
        return { ...state, loading: true }
      }
  }
  return state
}
