import {
  LOAD_BUCHUNGEN,
  CREATE_BUCHUNG,
  LOAD_BEFTAS,
  LOAD_BUCHUNGEN120,
  LOAD_BUCHUNGENVONBIS,
  LOAD_BUCHUNGENDATUM,
  ADD_BUCHUNG,
  DELETE_BUCHUNG,
  EDIT_BUCHUNG,
  EDIT_BUCHUNG_SORT,
  EDIT_BEFTAS,
  LOAD_PAUSCHALZEITEN,
  DELETE_PAUSCHALZEITEN,
  EDIT_PZT,
  ADD_PAUSCHALZEITEN,
  UPDATE_PAUSCHALZEIT,
  LOAD_UEBERZEITEN,
  LOAD_UEBERZEITENVONBIS,
  LOAD_UEBERZEITDATUM,
  BUCHUNG_MESSAGE,
  UPDATE_BUCHUNG,
  CLEAR_DIRTY_BUCHUNG,
  CREATE_PAUSCHALZEIT,
  UPDATE_UEBERZEIT,
  LOAD_KALUNDZEITBUCHUNGEN,
  LOAD_KALUNDZEITBUCHUNGENVONBIS,
  EDIT_KALUNDZEITBUCHUNGEN,
  UPDATE_KALUNDZEITBUCHUNG,
  EDIT_UEBERZEIT,
  EDIT_UEBERZEIT_KONTO,
  EDIT_UEZVON,
  EDIT_UEZBIS,
  ADD_UEZ,
  UPDATE_UEZ,
  DELETED_UEZ_ROW,
  DELETE_UEZ_ROW,
  LOAD_ANZEIGEBUCHUNG,
  LOAD_ANZEIGEURLAUB,
  LOAD_ANZEIGESALDO
} from "./actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import Datetime from "../../shared/dateutil/index"
import { v4 as uuidv4 } from "uuid"

export function Buchungen(
  state = {
    buchungdata: {},
    buchungendatum: {},
    kalundzeitbuchungen: {},
    pauschalzeitdata: {},
    ueberzeitdata: {},
    ueberzeiten: {},
    infoanzurl: {},
    infoanzbuc: {},
    infoanzsal: {},
    message: null
  },
  action
) {
  switch (action.type) {
    case LOAD_BUCHUNGEN:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            buchungdata: { ...state.buchungdata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_BUCHUNGEN120:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            buchungdata: { ...state.buchungdata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_KALUNDZEITBUCHUNGEN:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            kalundzeitbuchungen: {
              ...state.kalundzeitbuchungen,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_KALUNDZEITBUCHUNGENVONBIS:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            kalundzeitbuchungen: {
              ...state.kalundzeitbuchungen,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_UEZ:
      if (action.status == SUCCESS) {
        return {
          ...state,
          ueberzeitdata: {
            ...state.ueberzeitdata,
            [action.int_id]: state.ueberzeitdata[action.int_id].map((row) => {
              if (row.id == action.id) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    case EDIT_KALUNDZEITBUCHUNGEN:
      // console.log(state, action)
      return {
        ...state,
        kalundzeitbuchungen: {
          ...state.kalundzeitbuchungen,
          [action.int_id]: state.kalundzeitbuchungen[action.int_id].map(
            (row) => {
              if (row.datum == action.datum) {
                return { ...row, ...action.changes, __dirty: true }
              }
              return row
            }
          )
        }
      }
    case ADD_UEZ:
      return {
        ...state,
        ueberzeitdata: {
          ...state.ueberzeitdata,
          [action.int_id]: [
            {
              __new: true,
              ...action.uez
            },
            ...state.ueberzeitdata[action.int_id]
          ]
        }
      }
    case UPDATE_KALUNDZEITBUCHUNG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          kalundzeitbuchungen: {
            ...state.kalundzeitbuchungen,
            [action.int_id]: state.kalundzeitbuchungen[action.int_id].map(
              (row) => {
                if (row.datum == action.datum) {
                  return action.data
                }
                return row
              }
            )
          }
        }
      }
      return state
    case EDIT_UEBERZEIT:
      // console.log(state, action)
      return {
        ...state,
        ueberzeitdata: {
          ...state.ueberzeitdata,
          [action.int_id]: state.ueberzeitdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case EDIT_UEBERZEIT_KONTO:
      return {
        ...state,
        ueberzeitdata: {
          ...state.ueberzeitdata,
          [action.int_id]: state.ueberzeitdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes }
            }
            return row
          })
        }
      }
    case EDIT_UEZVON:
      let nDataVon = { ...state.ueberzeitdata }
      for (let [key, values] of Object.entries(nDataVon)) {
        nDataVon[key] = values.map((v) => {
          if (v.id === action.id) {
            return { ...v, ab_uhr: action.vonUhr, __dirty: true }
          }
          return v
        })
      }
      return {
        ...state,
        ueberzeitdata: nDataVon
      }
    case EDIT_UEZBIS:
      let nDataBis = { ...state.ueberzeitdata }
      for (let [key, values] of Object.entries(nDataBis)) {
        nDataBis[key] = values.map((v) => {
          if (v.id === action.id) {
            return { ...v, end_uhr: action.bisUhr, __dirty: true }
          }
          return v
        })
      }
      return {
        ...state,
        ueberzeitdata: nDataBis
      }

    case LOAD_BUCHUNGENVONBIS:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            buchungdata: { ...state.buchungdata, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case ADD_BUCHUNG:
      return {
        ...state,
        buchungdata: {
          ...state.buchungdata,
          [action.int_id]: [
            // ...state.buchungdata[action.int_id],
            {
              __new: true,
              int_id: action.int_id,
              id: uuidv4(),
              datum: Datetime.now().toISO(),
              buc_zeit: "1900-01-01T00:00:00Z"
            },
            ...state.buchungdata[action.int_id]
          ]
        }
      }
    case DELETE_BUCHUNG:
      return {
        ...state,
        buchungdata: {
          ...state.buchungdata,
          [action.int_id]: state.buchungdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true, kz_fehl: "L" }
            }
            return row
          })
        }
      }
    case LOAD_BUCHUNGENDATUM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            buchungendatum: {
              ...state.buchungendatum,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_UEBERZEITDATUM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            ueberzeitdata: {
              ...state.ueberzeitdata,
              [action.int_id]: action.data
              // [action.int_id]: [
              //   ...(state.ueberzeitdata[action.int_id] || []),
              //   ...action.data
              // ]
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_PAUSCHALZEITEN:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pauschalzeitdata: {
              ...state.pauschalzeitdata,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case CREATE_PAUSCHALZEIT:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pauschalzeitdata: {
              ...state.pauschalzeitdata,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETE_PAUSCHALZEITEN:
      return {
        ...state,
        pauschalzeitdata: {
          ...state.pauschalzeitdata,
          [action.int_id]: state.pauschalzeitdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
    case EDIT_PZT:
      return {
        ...state,
        pauschalzeitdata: {
          ...state.pauschalzeitdata,
          [action.int_id]: state.pauschalzeitdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              let r = { ...row, ...action.changes, __dirty: true }

              return { ...r, min_net: calcMinNet(r) }
            }
            return row
          })
        }
      }
    case ADD_PAUSCHALZEITEN:
      let datum = Datetime.fromISO(action.datum).toISODate()
      return {
        ...state,
        pauschalzeitdata: {
          ...state.pauschalzeitdata,
          [action.int_id]: [
            {
              id: uuidv4(),
              __new: true,
              int_id: action.int_id,
              datum: datum,
              lfd_nr: getNextLfdNr(
                state.pauschalzeitdata[action.int_id],
                datum
              ),
              von_uhr: "1900-01-01T00:00:00Z",
              bis_uhr: "1900-01-01T00:00:00Z",
              ...(action.values || {})
            },
            ...(state.pauschalzeitdata[action.int_id] || [])
          ]
        }
      }
    case UPDATE_PAUSCHALZEIT:
      if (action.status == SUCCESS) {
        return {
          ...state,
          pauschalzeitdata: {
            ...state.pauschalzeitdata,
            [action.int_id]: state.pauschalzeitdata[action.int_id]
              .map((row) => {
                if (row.id == action.id) {
                  return action.data
                }
                return row
              })
              .filter((r) => r !== null)
          }
        }
      }
      return state
    case LOAD_UEBERZEITENVONBIS:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            ueberzeiten: { ...state.ueberzeiten, [action.int_id]: action.data },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_UEBERZEITEN:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            ueberzeiten: {
              ...state.ueberzeiten,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case BUCHUNG_MESSAGE:
      return { ...state, message: action.message, koge: action.koge }
    case LOAD_BEFTAS:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infobeftas: action.data
          }
        case ERROR:
          return state
      }
      break
    case LOAD_ANZEIGEBUCHUNG:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infoanzbuc: action.data
          }
        case ERROR:
          return state
      }
      break
    case LOAD_ANZEIGEURLAUB:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infoanzurl: action.data
          }
        case ERROR:
          return state
      }
      break
    case LOAD_ANZEIGESALDO:
      switch (action.status) {
        case STARTED:
          return state
        case SUCCESS:
          return {
            ...state,
            infoanzsal: action.data
          }
        case ERROR:
          return state
      }
      break
    case CREATE_BUCHUNG:
      return {
        ...state,
        buchungdata: {
          ...state.buchungdata,
          [action.int_id]: [action.data, ...state.buchungdata[action.int_id]]
        }
      }
    case CLEAR_DIRTY_BUCHUNG:
      return {
        ...state,
        buchungdata: {
          ...state.data,
          [action.int_id]: [...state.buchungdata[action.int_id]].filter(
            (row) => {
              if (row.id == action.id) {
                return false
              }
              return true
            }
          )
        }
      }
    case UPDATE_BUCHUNG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          buchungdata: {
            ...state.data,
            [action.int_id]: [
              ...state.buchungdata[action.int_id].filter(
                (b) => b.id != action.data.id
              ),
              action.data
            ].sort((a, b) => {
              if (Datetime.fromISO(a.datum).equals(Datetime.fromISO(b.datum))) {
                if (
                  Datetime.fromISO(a.buc_zeit) < Datetime.fromISO(b.buc_zeit)
                ) {
                  return -1
                } else {
                  return 1
                }
              } else {
                if (Datetime.fromISO(a.datum) < Datetime.fromISO(b.datum)) {
                  return -1
                } else {
                  return 1
                }
              }
            })
          }
        }
        // return {
        //   ...state,
        //   buchungdata: {
        //     ...state.data,
        //     [action.int_id]: state.buchungdata[action.int_id].map((row) => {
        //       if (row.id == action.id) {
        //         return action.data
        //       }
        //       return row
        //     })
        //   }
        // }
      }
      return state
    case EDIT_BUCHUNG:
      return {
        ...state,
        buchungdata: {
          ...state.buchungdata,
          [action.int_id]: state.buchungdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case EDIT_BEFTAS:
      return {
        ...state,
        buchungdata: {
          ...state.buchungdata,
          [action.int_id]: state.buchungdata[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }

    case EDIT_BUCHUNG_SORT:
      let alterBuc = false
      let newBuchungen = [...state.buchungdata[action.int_id]].map((b) => {
        if (b.id == action.id) {
          if (b.__new) {
            alterBuc = true
            return { ...b, ...action.changes }
          } else {
            if (b.buc_zeit == action.changes.buc_zeit) {
              alterBuc = true
              return b
            }
            return { ...b, kz_fehl: "L", __dirty: true, __deleted: true }
          }
        }
        return b
      })
      if (!alterBuc) {
        newBuchungen.push({
          id: uuidv4(),
          int_id: parseInt(action.int_id),
          datum: action.datum,
          buc_trm: "( Dialog )",
          __new: true,
          // aen_usr: currentUser.user.user_name,
          ...action.changes
        })
      }

      newBuchungen.sort((a, b) => {
        if (Datetime.fromISO(a.datum).equals(Datetime.fromISO(b.datum))) {
          if (Datetime.fromISO(a.buc_zeit) < Datetime.fromISO(b.buc_zeit)) {
            return -1
          } else {
            return 1
          }
        } else {
          if (Datetime.fromISO(a.datum) < Datetime.fromISO(b.datum)) {
            return -1
          } else {
            return 1
          }
        }
      })

      return {
        ...state,
        buchungdata: {
          ...state.buchungdata,
          [action.int_id]: newBuchungen
        }
      }
    case UPDATE_UEBERZEIT:
      if (action.status == SUCCESS) {
        return {
          ...state,
          ueberzeitdata: {
            ...state.ueberzeitdata,
            [action.int_id]: state.ueberzeitdata[action.int_id].map((row) => {
              if (row.id == action.data.id) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    case DELETED_UEZ_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          ueberzeitdata: state.ueberzeitdata.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_UEZ_ROW:
      return {
        ...state,
        ueberzeitdata: state.ueberzeitdata.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
  }

  return state
}

const calcMinNet = (row) => {
  return Datetime.fromISO(row.von_uhr).diffMinutes(
    Datetime.fromISO(row.bis_uhr)
  )
}

const getNextLfdNr = (pauschalzeiten, datum) => {
  let pztDatum = (pauschalzeiten || [])
    .filter((r) => {
      return r.datum == datum
    })
    .map((r) => {
      return r.lfd_nr
    })
  let maxNbr = Math.max(...pztDatum)
  if (maxNbr == -Infinity) {
    maxNbr = 0
  }
  return maxNbr + 1
}
