import request from "superagent"
import { STARTED, SUCCESS, ERROR } from "app/state/constants"
import { useEffect, useState } from "react"
export const LOAD_ATAB = "LOAD_ATAB"
export const SAVED_ATAB = "SAVED_ATAB"
export const UPDATED_ATAB = "UPDATED_ATAB"

export function LoadAtabStarted() {
  return {
    type: LOAD_ATAB,
    status: STARTED
  }
}

export function LoadAtabSuccess(atabs) {
  return {
    type: LOAD_ATAB,
    status: SUCCESS,
    atabs
  }
}

export function LoadAtabError(error) {
  return {
    type: LOAD_ATAB,
    status: ERROR,
    error
  }
}

export function SaveAtabSuccess(atab) {
  return {
    type: SAVED_ATAB,
    atab
  }
}

export function UpdateAtabSuccess(atab) {
  return {
    type: UPDATED_ATAB,
    atab
  }
}

export function LoadAtab() {
  return (dispatch) => {
    dispatch(LoadAtabStarted())
    request.get("/api/parameter").end((err, response) => {
      if (err) {
        dispatch(LoadAtabError(err))
      } else {
        dispatch(LoadAtabSuccess(response.body))
      }
    })
  }
}

export function SaveAtab(atab) {
  return (dispatch) => {
    request
      .post("/api/parameter")
      .send(atab)
      .end((err, response) => {
        if (err) {
          dispatch(LoadAtabError(err))
        } else {
          dispatch(SaveAtabSuccess(response.body))
        }
      })
  }
}

export function UpdateAtab(atab) {
  return (dispatch) => {
    request
      .put("/api/parameter")
      .send(atab)
      .end((err, response) => {
        if (err) {
          dispatch(LoadAtabError(err))
        } else {
          dispatch(UpdateAtabSuccess(response.body))
        }
      })
  }
}

export const useAtab = (tab_art, tab_key, unprotected = false) => {
  const [value, setValue] = useState(null)
  const url = unprotected
    ? `/api/unprotected/parameter/${tab_art}/${tab_key}`
    : `/api/parameter/${tab_art}/${tab_key}`
  useEffect(() => {
    request.get(url).end((err, response) => {
      if (err) {
        setValue({})
      } else {
        setValue(response.body)
      }
    })
  }, [tab_art, tab_key])
  return (value && value.tab_wert) || undefined
}
