import thunkMiddleware from "redux-thunk"
//import { createLogger } from "redux-logger"
import { createStore, applyMiddleware } from "redux"
import rootReducer from "./reducers"
import { composeWithDevTools } from "redux-devtools-extension"
const composeEnhancers = composeWithDevTools({
  maxAge: 225,
  serialize: {
    options: {
      undefined: true
    }
  }
})

const timing = store => next => action => {
  performance.mark(`${action.type}_start`)
  let result = next(action)
  performance.mark(`${action.type}_end`)
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`
  )
  return result
}
//const loggerMiddleware = createLogger()

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      timing // lets us dispatch() functions
      //loggerMiddleware // neat middleware that logs actions
    )
  )
)

if (process.env.NODE_ENV !== "production") {
  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    //module.hot.accept("./reducers", () => store.replaceReducer(require("./reducers").default))
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers").default
      store.replaceReducer(nextRootReducer)
    })
  }
}

export default store
