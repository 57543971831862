import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import swal from "sweetalert"
import Datetime from "../../shared/dateutil/index"
export const LOAD_RUFBEREITSCHAFT = "LOAD_RUFBEREITSCHAFT"
export const LOAD_TELRUFDIENST = "LOAD_TELRUFDIENST"
export const EDIT_TELRUFZEITEN = "EDIT_TELRUFZEITEN"
export const ADD_TELRUFZEITEN = "ADD_TELRUFZEITEN"
export const UPDATE_TELRUFZEITEN = "UPDATE_TELRUFZEITEN"
export const DELETE_TELRUFZEITEN = "DELETE_TELRUFZEITEN"
export const EDIT_NEXTDAY = "EDIT_NEXTDAY"
export const EDIT_TELBIS = "EDIT_TELBIS"

export function DeleteTelRufzeiten(int_id, id) {
  return {
    type: DELETE_TELRUFZEITEN,
    int_id,
    id
  }
}

export function AddTelRufzeiten(int_id, datum) {
  return {
    type: ADD_TELRUFZEITEN,
    int_id,
    datum
  }
}

export function EditTelRufzeiten(int_id, id, changes) {
  return {
    type: EDIT_TELRUFZEITEN,
    int_id,
    id,
    changes
  }
}

export function LoadTelRufdienstStarted(int_id, jahr, monat) {
  return {
    type: LOAD_TELRUFDIENST,
    status: STARTED,
    int_id,
    jahr,
    monat
  }
}
export function LoadTelRufdienstSuccess(int_id, jahr, monat, data) {
  return {
    type: LOAD_TELRUFDIENST,
    status: SUCCESS,
    int_id,
    jahr,
    monat,
    data
  }
}
export function LoadTelRufdienstError(int_id, jahr, monat, error) {
  return {
    type: LOAD_TELRUFDIENST,
    status: ERROR,
    int_id,
    jahr,
    monat,
    error
  }
}

export function LoadTelRufdienst(int_id, jahr, monat) {
  return (dispatch) => {
    dispatch(LoadTelRufdienstStarted(int_id, jahr, monat))
    request
      .get(`/api/p/${int_id}/telrufdienst/${jahr}/${monat}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadTelRufdienstError(int_id, jahr, monat, err))
        } else {
          dispatch(LoadTelRufdienstSuccess(int_id, jahr, monat, response.body))
        }
      })
  }
}

export function LoadRufBereitschaftStarted(int_id, jahr, monat) {
  return {
    type: LOAD_RUFBEREITSCHAFT,
    status: STARTED,
    int_id,
    jahr,
    monat
  }
}
export function LoadRufBereitschaftSuccess(int_id, jahr, monat, data) {
  return {
    type: LOAD_RUFBEREITSCHAFT,
    status: SUCCESS,
    int_id,
    jahr,
    monat,
    data
  }
}
export function LoadRufBereitschaftError(int_id, jahr, monat, error) {
  return {
    type: LOAD_RUFBEREITSCHAFT,
    status: ERROR,
    int_id,
    jahr,
    monat,
    error
  }
}

export function LoadRufBereitschaft(int_id, jahr, monat) {
  return (dispatch) => {
    dispatch(LoadRufBereitschaftStarted(int_id, jahr, monat))
    request
      .get(`/api/p/${int_id}/bereitschaft/${jahr}/${monat}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadRufBereitschaftError(int_id, jahr, monat, err))
        } else {
          dispatch(
            LoadRufBereitschaftSuccess(int_id, jahr, monat, response.body)
          )
        }
      })
  }
}

function getDirtyRows(state) {
  let telrufdienst = state.TelRufdienst.telrufdienst
  let result = []
  for (let rows of Object.values(telrufdienst)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}

export function UpdateTelRufzeitenStarted(int_id) {
  return {
    type: UPDATE_TELRUFZEITEN,
    status: STARTED,
    int_id
  }
}

export function UpdateTelRufzeitenSuccess(int_id, id, data) {
  return {
    type: UPDATE_TELRUFZEITEN,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}

function GetTelSatzByID(state, id) {
  for (let telrufdienst of Object.values(state.TelRufdienst.telrufdienst)) {
    for (let tel of telrufdienst) {
      if (tel.id == id) {
        return tel
      }
    }
  }
}
export function EditTelBis(id, bisUhr) {
  return (dispatch, getState) => {
    let tel = GetTelSatzByID(getState(), id)
    if (Datetime.fromISO(tel.von_uhr) > Datetime.fromISO(bisUhr)) {
      bisUhr = Datetime.fromISO(bisUhr).plus({ days: 1 }).toISO()
    } else {
      let von = Datetime.fromISO(tel.von_uhr)
      let bis = Datetime.fromISO(bisUhr)
      let nBis = von.set({ hour: bis.hour(), minute: bis.minute() })
      if (nBis > von) {
        bisUhr = nBis.toISO()
      }
    }
    dispatch({
      type: EDIT_TELBIS,
      id,
      bisUhr
    })
  }
}

export function EditNextDay(int_id, id, isnextday) {
  return (dispatch, getState) => {
    let tel = GetTelSatzByID(getState(), id)
    let von, bis
    if (isnextday == false) {
      von = Datetime.fromISO(tel.von_uhr).plus({ days: 1 }).toISO()
      bis = Datetime.fromISO(tel.bis_uhr).plus({ days: 1 }).toISO()
    }
    if (isnextday == true) {
      von = Datetime.fromISO(tel.von_uhr).plus({ days: -1 }).toISO()
      bis = Datetime.fromISO(tel.bis_uhr).plus({ days: -1 }).toISO()
    }
    dispatch({
      type: EDIT_NEXTDAY,
      int_id,
      id,
      isnextday,
      changes: {
        von_uhr: von,
        bis_uhr: bis
      }
    })
  }
}

function CheckTelRufdienst(telrufdienst) {
  if (
    Datetime.fromISO(telrufdienst.von_uhr).equals(
      Datetime.fromISO(telrufdienst.bis_uhr)
    )
  ) {
    return "von-Uhrzeit und bis-Uhrzeit dürfen nicht den gleichen Wert haben"
  }
  return null
}

export function UpdateTelRufzeiten() {
  return (dispatch, getState) => {
    let telrufdienst = getDirtyRows(getState())
    if (telrufdienst.length == 0) {
      return
    }
    for (let row of telrufdienst || []) {
      if (!row.__deleted) {
        var err = CheckTelRufdienst(row)
        if (err !== null) {
          swal({
            title: "Hinweis",
            text: err,
            type: "warning",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok!",
            closeOnConfirm: false
          })
          continue
        }
      }
      if (row.__deleted && !row.__new) {
        dispatch(UpdateTelRufzeitenStarted(row.int_id, row.id))
        request
          .delete(`/api/p/${row.int_id}/telrufdienst/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              // dispatch(UpdateTelRufzeitenError(row.int_id, row.id, err))
            } else {
              dispatch(
                UpdateTelRufzeitenSuccess(row.int_id, row.id, response.body)
              )
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row
        let int_id = parseInt(row.int_id)
        dispatch(UpdateTelRufzeitenStarted(row.int_id))
        request
          .post(`/api/p/${int_id}/telrufdienst/create`)
          .send(r)
          .end((err, response) => {
            if (err) {
              // dispatch(UpdateTelRufzeitenError(row.int_id, row.id, err))
            } else {
              dispatch(
                UpdateTelRufzeitenSuccess(row.int_id, row.id, response.body)
              )
            }
          })
      } else if (row.__dirty && !row.__deleted && !row.__new) {
        dispatch(UpdateTelRufzeitenStarted(row.int_id))
        request
          .put(`/api/p/${row.int_id}/telrufdienst`)
          .send(row)
          .end((err, response) => {
            if (err) {
              // dispatch(UpdateTelRufzeitenError(row.int_id, row.id, err))
            } else {
              dispatch(
                UpdateTelRufzeitenSuccess(row.int_id, row.id, response.body)
              )
            }
          })
      }
    }
  }
}
export function TelRufdienstUnsavedChanges(state) {
  for (let telrufdienst of Object.values(state)) {
    for (let row of telrufdienst) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}
