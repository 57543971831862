import PropTypes from "prop-types"
import React from "react"
import Tooltip from "./tooltip"
const styles = {
  button: {
    padding: "0.5em 1em",
    borderRadius: "3px",
    backgroundColor: "#fefefe",
    cursor: "pointer",
    margin: "1px",
    fontSize: "12px",
    lineHeight: "1",
    position: "relative",
    transition: "all 0.5s ease",
    color: "#9f9f9f",
    margin: "1px",
    background: "transparent",
    border: "none !important",
    color: "#7f7f7f",
    cursor: "pointer",
    outline: "none",
    transition: "color 750ms",
    "&:hover": {
      backgroundColor: "#dedede",
      color: "#fefefe",
      backgroundImage: "none",
      filter: "none",
      border: "1px solid #ccc"
    },
    "&:active": {
      backgroundColor: "#fefefe",
      backgroundImage: "none",
      filter: "none"
    },
    "&:disabled": {
      color: "#dfdfdf"
    }
  },

  buttonExpanded: {
    width: "100%"
  },
  buttonFlex: {
    alignItems: "center",
    flex: "1",
    padding: "0.5em 0em"
  },
  buttonActive: {
    borderRadius: "3px",
    backgroundColor: "#fefefe",
    color: "#00ffff",
    borderColor: "#00ffff"
  },
  buttonInactive: {
    borderRadius: "3px",
    backgroundColor: "#fafafa",
    border: "1px solid #ccc"
  },
  buttonGroup: {
    margin: "0",
    display: "inline-block"
  },
  wrapperFlex: {
    flex: 1,
    display: "flex"
  }
}

export const Button = React.forwardRef(
  (
    {
      children,
      onClick,
      active,
      isPrimary,
      onFocus,
      onMouseOver,
      onMouseLeave,
      onBlur,
      title,
      expand,
      className,
      flex,
      ...props
    },
    ref
  ) => {
    let activeStyle = null
    if (active == true) {
      activeStyle = styles.buttonActive
    } else if (active == false) {
      activeStyle = styles.buttonInactive
    }
    return (
      <Tooltip title={title || ""} disableHoverListener={!title}>
        <span className={className} css={flex ? styles.wrapperFlex : null}>
          <button
            ref={ref}
            data-title={title}
            {...props}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            css={[
              styles.button,
              expand && styles.buttonExpanded,
              activeStyle,
              flex && styles.buttonFlex
            ]}
            className={className}
          >
            {children}
          </button>
        </span>
      </Tooltip>
    )
  }
)

export default Button

export const ButtonGroup = ({ children }) => {
  return <div css={styles.buttonGroup}>{children}</div>
}

export const ButtonRadio = ({ values, selected, onChange }) => {
  return (
    <ButtonGroup>
      {values.map((v) => (
        <Button
          key={val.value}
          active={selected == val.value}
          onClick={() => {
            onChange(val.value)
          }}
        >
          {val.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}
