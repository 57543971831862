import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import {
  LOAD_URLAUBSANSPRUCH,
  LOAD_URLAUBSANSPRUCH_FIX,
  LOAD_URLAUBS_VERFALL,
  INCRMENT_URLAUB,
  DECREMENT_URLAUB
} from "./actions"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"

function changeUrlaub(state, action) {
  let cur_urlaub = { ...state.data }
  let verfall_datum = Datetime.fromISO(action.datum)
    .set({ month: 3 })
    .endOf("month")
  let verfallen = false
  if (Datetime.fromISO(action.datum) < verfall_datum) {
    verfallen = true
  }
  let value = action.type === INCRMENT_URLAUB ? -0.5 : 0.5
  if (Datetime.fromISO(action.datum) > Datetime.now()) {
    cur_urlaub.url_gepl = (parseFloat(cur_urlaub.url_gepl) + value).toFixed(2)
  } else {
    cur_urlaub.url_entn = (parseFloat(cur_urlaub.url_entn) + value).toFixed(2)
  }
  if (
    verfallen &&
    cur_urlaub.verfallen - value >= 0 &&
    cur_urlaub.verfallen - value <= cur_urlaub.rest_vorjahr
  ) {
    cur_urlaub.verfallen -= value
    cur_urlaub.gesamt += value
  } else {
    cur_urlaub.url_r_lj -= value
    cur_urlaub.url_uvp -= value
  }
  return { ...state, data: cur_urlaub }
}

export function UrlaubsAnspruchYear(state = {}, action) {
  switch (action.type) {
    case LOAD_URLAUBSANSPRUCH:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            [action.year]: { isFetching: true, data: null, error: null }
          }
        case ERROR:
          return {
            ...state,
            [action.year]: {
              isFetching: false,
              data: { int_id: action.int_id },
              error: action.error
            }
          }
        case SUCCESS:
          return {
            ...state,
            [action.year]: { isFetching: false, data: action.data, error: null }
          }
      }
      break
    case INCRMENT_URLAUB:
      return {
        ...state,
        [action.year]: changeUrlaub(state[action.year], action)
      }
    case DECREMENT_URLAUB:
      return {
        ...state,
        [action.year]: changeUrlaub(state[action.year], action)
      }
  }
  return state
}

export function UrlaubsAnspruch(state = {}, action) {
  switch (action.type) {
    case LOAD_URLAUBSANSPRUCH:
    case INCRMENT_URLAUB:
    case DECREMENT_URLAUB:
      return {
        ...state,
        [action.int_id]: UrlaubsAnspruchYear(state[action.int_id], action)
      }
  }
  return state
}

export function UrlaubsAnspruchFixYear(state = {}, action) {
  switch (action.type) {
    case LOAD_URLAUBSANSPRUCH_FIX:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            [action.year]: { isFetching: true, data: null, error: null }
          }
        case ERROR:
          return {
            ...state,
            [action.year]: {
              isFetching: false,
              data: null,
              error: action.error
            }
          }
        case SUCCESS:
          return {
            ...state,
            [action.year]: { isFetching: false, data: action.data, error: null }
          }
      }
  }
  return state
}

export function UrlaubsAnspruchFix(state = {}, action) {
  switch (action.type) {
    case LOAD_URLAUBSANSPRUCH_FIX:
      return {
        ...state,
        [action.int_id]: UrlaubsAnspruchFixYear(state[action.int_id], action)
      }
  }
  return state
}

export function UrlaubsVerfallYear(state = {}, action) {
  switch (action.type) {
    case LOAD_URLAUBS_VERFALL:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            [action.year]: { isFetching: true, data: null, error: null }
          }
        case ERROR:
          return {
            ...state,
            [action.year]: {
              isFetching: false,
              data: null,
              error: action.error
            }
          }
        case SUCCESS:
          return {
            ...state,
            [action.year]: { isFetching: false, data: action.data, error: null }
          }
      }
  }
  return state
}

export function UrlaubsVerfall(state = {}, action) {
  switch (action.type) {
    case LOAD_URLAUBS_VERFALL:
      return {
        ...state,
        [action.int_id]: UrlaubsVerfallYear(state[action.int_id], action)
      }
  }
  return state
}
