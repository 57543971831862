import request from "superagent"
import { queryToString } from "app/shared/util"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_SCHICHTWSZULAGE = "LOAD_SCHICHTWSZULAGE"
export const LOAD_SCHICHTWSZULAGE_QUERY = "LOAD_SCHICHTWSZULAGE_QUERY"
export const EDIT_WSMAN = "EDIT_WSMAN"
export const UPDATE_WSMAN = "UPDATE_WSMAN"
export const DELETED_WSMAN_ROW = "DELETED_WSMAN_ROW"
export const NEW_WSMAN = "NEW_WSMAN"

export function LoadSchichtWsZulageStarted(int_id, jahr) {
  return {
    type: LOAD_SCHICHTWSZULAGE,
    status: STARTED,
    int_id,
    jahr
  }
}
export function LoadSchichtWsZulageSuccess(int_id, jahr, data) {
  return {
    type: LOAD_SCHICHTWSZULAGE,
    status: SUCCESS,
    int_id,
    jahr,
    data
  }
}
export function LoadSchichtWsZulageError(int_id, jahr, error) {
  return {
    type: LOAD_SCHICHTWSZULAGE,
    status: ERROR,
    int_id,
    jahr,
    error
  }
}

export function LoadSchichtWsZulage(int_id, jahr) {
  return (dispatch) => {
    dispatch(LoadSchichtWsZulageStarted(int_id, jahr))
    request
      .get(`/api/p/${int_id}/schichtwszulage/${jahr}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadSchichtWsZulageError(int_id, jahr, err))
        } else {
          dispatch(LoadSchichtWsZulageSuccess(int_id, jahr, response.body))
        }
      })
  }
}

export function LoadSchichtWsZulageQuerySuccess(jahr, int_id, data) {
  return {
    type: LOAD_SCHICHTWSZULAGE_QUERY,
    status: SUCCESS,
    jahr,
    int_id,
    data
  }
}

export function LoadSchichtWsZulageQuery(jahr, query) {
  return (dispatch) => {
    request
      .get(`/api/schichtwszulage/${jahr}/query`)
      .query(queryToString(query))
      .end((err, response) => {
        dispatch(LoadSchichtWsZulageQuerySuccess(jahr, null, response.body))
      })
  }
}

export function EditWS(int_id, monat, changes) {
  return {
    type: EDIT_WSMAN,
    int_id,
    monat,
    changes
  }
}
export function DeleteWSManuellSuccess(id) {
  return {
    type: DELETED_WSMAN_ROW,
    status: SUCCESS,
    id
  }
}
export function UpdateWSManuellSuccess(id, data) {
  return {
    type: UPDATE_WSMAN,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateWSManuell() {
  return (dispatch, getState) => {
    let data = getState().Info.SchichtWsZulage.schichtwszulagequery || []
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      // if (row.__deleted && !row.__new) {
      //   request
      //     .delete(`/api/schichtwszulage/${row.id}`)
      //     .send(row)
      //     .end((err, response) => {
      //       dispatch(DeleteWSManuellSuccess(row.id, response.body))
      //     })
      // } else
      if (row.id == 0 && row.__new) {
        let { id, ...r } = row
        request
          .post("/api/schichtwszulage/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateWSManuellSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/schichtwszulage")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateWSManuellSuccess(row.id, response.body))
          })
      }
    }
  }
}
export function CreateWSManuellSuccess(data) {
  return {
    type: NEW_WSMAN,
    status: SUCCESS,
    data
  }
}
export function CreateWSManuell(data) {
  return (dispatch) => {
    request
      .post("/api/schichtwszulage/new")
      .send(data)
      .end((err, response) => {
        dispatch(CreateWSManuellSuccess(response.body))
      })
  }
}
export function CheckWSManuellNew(felder, data) {
  for (let feld of felder) {
    if (defaultGruppePflichtFelder[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldGruppeNamen[feld] || feld
        return `Bitte ${feldName} auswählen!`
      }
    }
  }
  return null
}
const feldGruppeNamen = {
  int_id: "Person",
  datum: "Datum",
  szu: "Schicht-WS-Zulage Manuell"
}

const defaultGruppePflichtFelder = {
  int_id: true,
  datum: true,
  szu: true
}
