import { LOAD_CUSTOM_GROUPS } from "../actions/summen"
export function Summen(state = { custom: [] }, action) {
  switch (action.type) {
    case LOAD_CUSTOM_GROUPS:
      return { ...state, custom: action.data }
  }
  return state
}

// function countDienste(action) {
//   let summen = {dienste: {}, fehlzeiten: {}, fzKeys: [], dKeys: []}
//   let fz_processed = {}
//   let intid_day_processed = {}
//   let start = moment(action.Datum).add(-1, "month")
//   for (let r of Object.values(action.Records)) {
//     let idx = Math.abs(start.diff(r.datum, "days"))
//     if (r.fehlz_id_v || r.fehlz_id_n) {
//       if (!fz_processed[`${r.int_id}-${moment(r.datum).format("YYYY-MM-DD")}`]) {
//         fz_processed[`${r.int_id}-${moment(r.datum).format("YYYY-MM-DD")}`] = true
//         if (r.fehlz_id_v) {
//           if (!summen.fehlzeiten[r.fehlz_id_v]) {
//             summen.fehlzeiten[r.fehlz_id_v] = []
//           }

//           intid_day_processed[`${r.int_id}-${idx}`] = true
//           summen.fehlzeiten[r.fehlz_id_v][idx] = (summen.fehlzeiten[r.fehlz_id_v][idx] || 0.0) + 0.5
//         }
//         if (r.fehlz_id_n) {
//           if (!summen.fehlzeiten[r.fehlz_id_n]) {
//             summen.fehlzeiten[r.fehlz_id_n] = []
//           }

//           intid_day_processed[`${r.int_id}-${idx}`] = true
//           summen.fehlzeiten[r.fehlz_id_n][idx] = (summen.fehlzeiten[r.fehlz_id_n][idx] || 0.0) + 0.5
//         }
//       }
//     } else if (r.ist && r.ist.__valid !== false && r.ist.dpl_symb && r.ist.dpl_symb.trim()) {
//       let fzC = r.ist.dpl_symb.slice(0, 1)
//       if (fzC == "*" || fzC == ":" || fzC == "+") {
//         let fehlzeit = r.ist.dpl_symb.slice(1)
//         let fehlz_id_v = null
//         let fehlz_id_n = null
//         if (fzC == ":") {
//           fehlz_id_v = fehlzeit
//         } else if (fzC == "+") {
//           fehlz_id_n = fehlzeit
//         } else {
//           fehlz_id_n = fehlzeit
//           fehlz_id_v = fehlzeit
//         }
//         if (!fz_processed[`${r.int_id}-${moment(r.datum).format("YYYY-MM-DD")}`]) {
//           fz_processed[`${r.int_id}-${moment(r.datum).format("YYYY-MM-DD")}`] = true
//           if (fehlz_id_v) {
//             if (!summen.fehlzeiten[fehlz_id_v]) {
//               summen.fehlzeiten[fehlz_id_v] = []
//             }

//             intid_day_processed[`${r.int_id}-${idx}`] = true
//             summen.fehlzeiten[fehlz_id_v][idx] = (summen.fehlzeiten[fehlz_id_v][idx] || 0.0) + 0.5
//           }
//           if (fehlz_id_n) {
//             if (!summen.fehlzeiten[fehlz_id_n]) {
//               summen.fehlzeiten[fehlz_id_n] = []
//             }

//             intid_day_processed[`${r.int_id}-${idx}`] = true
//             summen.fehlzeiten[fehlz_id_n][idx] = (summen.fehlzeiten[fehlz_id_n][idx] || 0.0) + 0.5
//           }
//         }
//       } else {
//         if (!summen.dienste[r.ist.dpl_symb]) {
//           summen.dienste[r.ist.dpl_symb] = []
//         }

//         intid_day_processed[`${r.int_id}-${idx}`] = true
//         summen.dienste[r.ist.dpl_symb][idx] = (summen.dienste[r.ist.dpl_symb][idx] || 0) + 1
//       }
//     } else if (r.plan && r.plan.__valid !== false && r.plan.dpl_symb.trim()) {
//       if (!summen.dienste[r.plan.dpl_symb]) {
//         summen.dienste[r.plan.dpl_symb] = []
//       }

//       intid_day_processed[`${r.int_id}-${idx}`] = true
//       summen.dienste[r.plan.dpl_symb][idx] = (summen.dienste[r.plan.dpl_symb][idx] || 0) + 1
//     } else if (r.soll && r.soll.__valid !== false && r.soll.dpl_symb.trim()) {
//       if (!summen.dienste[r.soll.dpl_symb]) {
//         summen.dienste[r.soll.dpl_symb] = []
//       }

//       intid_day_processed[`${r.int_id}-${idx}`] = true
//       summen.dienste[r.soll.dpl_symb][idx] = (summen.dienste[r.soll.dpl_symb][idx] || 0) + 1
//     }
//   }
//   for (let [int_id, p] of Object.entries(action.PlaceHolders || {})) {
//     for (let i = 0; i < p.length; i++) {
//       if (!intid_day_processed[`${int_id}-${i}`]) {
//         if (p[i] == "") {
//           continue
//         }
//         if (!summen.dienste[p[i]]) {
//           summen.dienste[p[i]] = []
//         }
//         summen.dienste[p[i]][i] = (summen.dienste[p[i]][i] || 0) + 1
//       }
//     }
//   }
//   summen.fzKeys = Object.keys(summen.fehlzeiten).sort()
//   summen.dKeys = Object.keys(summen.dienste).sort()
//   return summen
// }
