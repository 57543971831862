import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import GlyphIcon from "./icons/icons"

import { styled } from "@mui/material/styles"
import MUIBaseTextField from "@mui/material/TextField"
import Datetime from "../dateutil/index"
import MUISwitch from "@mui/material/Switch"
import EditableCell, { CellEditor, HiddenInput } from "./cell"
import { isNil } from "lodash"

export const Switch = MUISwitch

Switch.defaultProps = {
  size: "small"
}

const styles = {
  textField: {
    fontSize: "11px",
    flex: 1,
    width: "100%",
    backgroundColor: "transparent"
  },
  align: {
    center: {
      textAlign: "center"
    },
    left: {
      textAlign: "left"
    },
    right: {
      textAlign: "right"
    }
  },
  input: {
    width: "100%"
  },
  inputLabel: {
    fontSize: "10px",
    color: "#666"
  },
  charsleft: {
    fontSize: "10px",
    cursor: "pointer",
    color: "#ccc",
    position: "absolute",
    bottom: "5px",
    right: "9px"
  },
  charsleftWarn: {
    fontSize: "10px",
    cursor: "pointer",
    color: "#e90",
    position: "absolute",
    bottom: "5px",
    right: "9px",
    fontWeight: "bold"
  },
  textAreaContainer: {
    display: "flex",
    flex: "1",

    position: "relative"
  },
  textArea: {
    fontFamily: "RobotoArion",
    fontSize: "11px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    outline: "none",
    flex: "1"
  },
  textAreaClearIcon: {
    fontSize: "10px",
    cursor: "pointer",
    color: "#ccc",
    position: "absolute",
    top: "5px",
    right: "9px"
  },
  searchContainer: {
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    fontWeight: "bold",
    position: "relative",
    borderRadius: "10px",
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  searchField: {
    borderRadius: "10px",
    width: "100%",
    paddingLeft: "20px",
    maxHeight: "20px",
    fontWeight: "normal",
    border: "none"
  },
  searchIcon: {
    position: "absolute",
    left: "5px",
    fontSize: "11px",
    top: "3px",
    color: "#ccc"
  }
}

export const MUITextField = styled(MUIBaseTextField)(({ theme }) => ({
  minHeight: "25px",
  fontSize: "11px",
  "& .MuiInput-root": {
    fontSize: "11px"
  },
  "& .MuiInputBase-root": {
    fontSize: "11px",
    paddingLeft: "10px",
    "&:after": {}
  },
  "& .MuiInputLabel-root": {
    fontSize: "11px",

    "&.MuiInputLabel-shrink": {}
  }
}))

export const TextField = React.forwardRef(
  (
    {
      value,
      onFocus,
      onMouseOver,
      onMouseLeave,
      onBlur,
      onClick,
      onChange,
      align,
      hidden,
      cell,
      disabled,
      readOnly,
      ...props
    },
    ref
  ) => {
    const alignStyle = styles.align[align]
    if (cell) {
      const { selected, ...rest } = props
      return (
        <CellEditor
          ref={ref}
          css={[styles.textField, alignStyle]}
          InputProps={{
            inputProps: {
              style: { textAlign: align }
            }
          }}
          value={value || ""}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onClick={onClick}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled || readOnly}
          {...rest}
        />
      )
    }
    if (hidden) {
      return (
        <HiddenInput
          ref={ref}
          css={[styles.textField, alignStyle]}
          InputProps={{
            inputProps: {
              style: { textAlign: align }
            }
          }}
          value={value || ""}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onClick={onClick}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled || readOnly}
          {...props}
        />
      )
    }
    return (
      <MUITextField
        ref={ref}
        css={[styles.textField, alignStyle]}
        InputProps={{
          inputProps: {
            style: { textAlign: align }
          }
        }}
        value={isNil(value) ? "" : value}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled || readOnly}
        {...props}
      />
    )
  }
)

TextField.displayName = "TextField"

TextField.defaultProps = {
  variant: "standard",
  size: "small"
}

export const NumberField = ({ value, onChange, places, ...props }) => {
  const parseValue = (ev) => {
    let pos = ev.target.selectionStart
    let cvalue = ev.currentTarget.value

    if (cvalue == "") {
      onChange(ev, null)
      return
    }

    // Default value
    let pValue = parseFloat(cvalue.replace(",", "."))
      .toFixed(places)
      .replace(".", ",")
    if (value && value[pos] == "," && cvalue.indexOf(",") == -1) {
      pValue = value
    }

    //Cursor Position anpassen
    ev.currentTarget.value = pValue

    onChange(ev, pValue.replace(",", "."))
    ev.target.selectionStart = pos
    ev.target.selectionEnd = pos
  }
  return (
    <TextField
      value={
        (value && parseFloat(value).toFixed(places).replace(".", ",")) || ""
      }
      onChange={parseValue}
      {...props}
    />
  )
}

const D1900 = Datetime.fromValues(1900, 0, 1).toJSDate()

export const TimeField = ({ value, onChange, onBlur, ...props }) => {
  const [cvalue, setValue] = useState(value)
  useEffect(() => {
    setValue(value)
  }, [value])
  return (
    <RawTimeField
      {...props}
      value={(cvalue && Datetime.fromJSDate(cvalue).formatTimeShort()) || ""}
      onBlur={
        onBlur
          ? (ev) => {
              if (ev.currentTarget.value == "") {
                onBlur && onBlur(ev, null, !value)

                return
              }
              const time = Datetime.fromTime(ev.currentTarget.value)
              const newValue = Datetime.fromJSDate(value || D1900).set({
                hour: time.hour(),
                minute: time.minute()
              })
              onBlur && onBlur(ev, newValue.toJSDate(), cvalue == newValue)
            }
          : undefined
      }
      onChange={(ev) => {
        if (ev.currentTarget.value == "") {
          setValue(null)
          onChange && onChange(ev, null)

          return
        }
        const time = Datetime.fromTime(ev.currentTarget.value)
        const newValue = Datetime.fromJSDate(value || D1900).set({
          hour: time.hour(),
          minute: time.minute()
        })
        setValue(newValue.toJSDate())
        onChange && onChange(ev, newValue.toJSDate())
      }}
    />
  )
}

export const RawTimeField = ({ value, onChange, onBlur, ...props }) => {
  const parseValue = (cb) => (ev) => {
    let pos = ev.target.selectionStart
    let cvalue = ev.currentTarget.value
    if (cvalue == "") {
      cb(ev)
      return
    }

    if (cvalue[pos] == ":" && cvalue[pos - 1] != ":") {
      cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 2)
    } else {
      cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 1)
    }

    // Stunden und minuten ermitteln
    const pattern = /^([0-9]{1,2}):?([0-9]{0,2})/
    const results = cvalue.match(pattern)

    // Default value
    let pValue = "00:00"
    if (results) {
      // Stunden normalisieren - Maximal 23
      if (parseInt(results[1]) > 23) {
        if (results[1][0] == "2") {
          results[1] = "20"
        } else {
          results[1] = "23"
        }
      }

      // Minuten Normalisieren - maximal 59
      if (parseInt((results[2] + "00").slice(0, 2)) > 59) {
        results[2] = "00"
      }

      pValue = `${(results[1] + "00").slice(0, 2)}:${(results[2] + "00").slice(
        0,
        2
      )}`
    }

    //Cursor Position anpassen

    if (pValue[pos - 1] == ":" && cvalue[pos - 1] != ":") {
      // beim eintippen
      pos = pos + 1
    }
    ev.currentTarget.value = pValue
    ev.target.selectionStart = pos
    ev.target.selectionEnd = pos
    cb(ev)
  }
  return (
    <TextField
      {...props}
      value={value}
      onChange={onChange && parseValue(onChange)}
      onBlur={onBlur && parseValue(onBlur)}
    />
  )
}

export const DateField = React.forwardRef(
  ({ value, onChange, ...props }, ref) => {
    return (
      <RawDateField
        ref={ref}
        value={Datetime.fromJSDate(value).formatDateShort()}
        onChange={(ev) => {
          const date = Datetime.fromDate(ev.currentTarget.value)
          if (!date.isValid()) {
            onChange(ev, value)
            return
          }
          onChange(ev, date.toJSDate())
        }}
        {...props}
      />
    )
  }
)

export const RawDateField = React.forwardRef(
  ({ value, onChange, ...props }, ref) => {
    const parseValue = (ev) => {
      let cvalue = ev.currentTarget.value
      if (cvalue == null) {
        onChange(ev)
        return
      }
      let pos = ev.target.selectionStart
      if (cvalue[pos] == "." && cvalue[pos - 1] != ".") {
        cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 2)
      } else {
        cvalue = cvalue.slice(0, pos) + cvalue.slice(pos + 1)
      }
      // Tag Monat und Jahr ermitteln
      const pattern = /^([0-9]{1,2})\.?([0-9]{0,2})\.?([0-9]{0,4})/
      const results = cvalue.match(pattern)

      // Default value
      let pValue = "01.01.0000"
      if (results) {
        // Tag normalisieren - Maximal 31
        if (parseInt(results[1].slice(0, 2)) > 31) {
          results[1] = "31"
        } else if (parseInt(results[1]) == 0) {
          results[1] = "01"
        }

        // Monat Normalisieren - maximal 12
        if (parseInt((results[2] + "00").slice(0, 2)) > 12) {
          results[2] = "12"
        } else if (parseInt(results[2]) == 0) {
          results[2] = "01"
        }

        if (parseInt(results[3]) == 0) {
          results[3] = "0000"
        }

        pValue = `${(results[1] + "01").slice(0, 2)}.${(
          results[2] + "01"
        ).slice(0, 2)}.${(results[3] + "0000").slice(0, 4)}`
      }

      //Cursor Position anpassen
      if (pValue[pos - 1] == "." && cvalue[pos - 1] != ".") {
        // beim eintippen
        pos = pos + 1
      }
      ev.currentTarget.value = pValue
      ev.target.selectionStart = pos
      ev.target.selectionEnd = pos
      onChange(ev)
    }
    return (
      <TextField ref={ref} value={value} onChange={parseValue} {...props} />
    )
  }
)

export const Input = ({
  style,
  labelWidth,
  labelAlign,
  labelColor,
  block,
  onFocus,
  onBlur,
  onMouseOver,
  onMouseLeave,
  reverse,
  label,
  children
}) => {
  let labelStyle = { display: "inline-block" }
  if (labelWidth) {
    labelStyle.width = labelWidth
  }
  if (labelAlign) {
    labelStyle.textAlign = labelAlign
  }
  if (block) {
    labelStyle.display = "block"
  }
  if (labelColor) {
    labelStyle.color = labelColor
  }
  return (
    <div css={[styles.input, style]}>
      <label css={styles.inputLabel}>
        {!reverse ? (
          <span css={[styles.labelStyle, labelStyle]}>{label}</span>
        ) : null}
        {React.cloneElement(children, {
          onMouseOver: onMouseOver,
          onMouseLeave: onMouseLeave,
          onFocus: onFocus,
          onBlur: onBlur
        })}
        {reverse ? (
          <span css={[styles.labelStyle, labelStyle]}> {label}</span>
        ) : null}
      </label>
    </div>
  )
}

export default Input

export const SearchField = React.memo(({ setSearchText, ...props }) => {
  const { containerStyle, ...other } = props
  return (
    <div style={containerStyle} css={styles.searchContainer}>
      <GlyphIcon icon="search" css={styles.searchIcon} />
      <input
        onChange={(ev) => {
          setSearchText(ev.currentTarget.value)
        }}
        {...other}
        css={styles.searchField}
        type="search"
      />
    </div>
  )
})

SearchField.propTypes = {
  setSearchText: PropTypes.func.isRequired
}

export class TextArea extends React.Component {
  static propTypes = {
    onFocus: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    style: PropTypes.object,
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
    value: PropTypes.string,
    maxLen: PropTypes.number,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = { active: false, hover: false }
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onClear = this.onClear.bind(this)
    this.isClearable = this.isClearable.bind(this)
    this.charactersLeft = this.charactersLeft.bind(this)
    this.textArea = React.createRef()
  }

  onFocus(event) {
    this.setState({ active: true })
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  onBlur(event) {
    this.setState({ active: false })
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
  }

  onMouseOver(event) {
    this.setState({ hover: true })
    if (this.props.onMouseOver) {
      this.props.onMouseOver(event)
    }
  }

  onMouseLeave(event) {
    this.setState({ hover: false })
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event)
    }
  }

  isClearable() {
    return this.props.allowClear && this.props.value && this.props.value.trim()
      ? true
      : false
  }

  onClear() {
    this.props.onClear()
    this.textArea && this.textArea.focus()
  }

  charactersLeft() {
    if (this.props.maxLen) {
      let remaining = this.props.maxLen - this.props.value.length
      if (remaining < 10) {
        return (
          <span css={styles.charsleftWarn}>
            {this.props.maxLen - this.props.value.length}
          </span>
        )
      }
      return (
        <span css={styles.charsleft}>
          {this.props.maxLen - this.props.value.length}
        </span>
      )
    }
    return null
  }

  onChange(ev) {
    let val = ev.currentTarget.value
    if (this.props.withEvent) {
      if (this.props.maxLen) {
        return this.props.onChange(ev, val.substring(0, this.props.maxLen))
      }
      return this.props.onChange(ev, val)
    }
    if (this.props.maxLen) {
      return this.props.onChange(val.substring(0, this.props.maxLen))
    }
    return this.props.onChange(val)
  }

  // onChange(ev) {
  //   let val = ev.currentTarget.value
  //   if (this.props.maxLen) {
  //     return this.props.onChange(val.substring(0, this.props.maxLen))
  //   }
  //   return this.props.onChange(val)
  // }

  render() {
    let {
      onChange,
      containerStyle,
      value,
      onClear,
      allowClear,
      maxLen,
      ...props
    } = this.props
    return (
      <div css={styles.textAreaContainer} style={containerStyle}>
        <textarea
          ref={this.textArea}
          {...props}
          css={styles.textArea}
          value={value || ""}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        {this.isClearable() && (
          <span css={styles.textAreaClearIcon} onClick={this.onClear}>
            <GlyphIcon icon="delete" />
          </span>
        )}
        {this.charactersLeft()}
      </div>
    )
  }
}

export const FloatFormattingField = NumberField
