import "core-js/stable"

import Raven from "raven-js"

if (!__DEV__) {
  Raven.config("https://54e78943df524593808c93e8969254b6@sentry.io/205940", {
    release: COMMITHASH.slice(0, 8)
  }).install()
}

import React from "react"
import { App } from "./app.js"

const debugUpdates = true
if (process.env.NODE_ENV !== "production" && debugUpdates) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render")
  whyDidYouRender(React)
}

import ReactModal from "react-modal"
ReactModal.setAppElement("#app")

// import dateFns from "date-fns"
// dateFns.parseISO = (v) => v

/*




Globale CSS Imports
 */
// Arion Global
import "./global.css"
import "react-base-table/styles.css"

// React Datepicker
import "../../node_modules/react-datepicker/dist/react-datepicker.css"

//Slider
import "../../node_modules/rc-slider/assets/index.css"

//import "../../node_modules/react-tabs/style/react-tabs.css"
import "../../node_modules/reactjs-popup/dist/index.css"

import { Settings } from "luxon"

// Configure the time zone
Settings.defaultZoneName = "utc"

Settings.defaultLocale = "de-de"
import { library } from "@fortawesome/fontawesome-svg-core"
//import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fas as pfas } from "@fortawesome/pro-solid-svg-icons"
import { createRoot } from "react-dom/client"
library.add(fas)
library.add(pfas)
/*
Raven nur aktivieren in Production Build
 */
const container = document.getElementById("app")
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
