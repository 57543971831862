import Datetime from "./luxon/index"
export default Datetime

export const Parse = (iso) => {
  return Datetime.fromISO(iso).toJSDate()
}

export const ISO = (jsDate) => {
  return Datetime.fromJSDate(jsDate).toISO()
}

export const dateutil = {
  toISO: (d) => {
    return Datetime.fromJSDate(d).toISO()
  },
  toISODate: (d) => {
    return Datetime.fromJSDate(d).toISODate()
  },
  fromISO: (iso) => {
    return Datetime.fromISO(iso).toJSDate()
  },
  formatDateShort: (d) => {
    return Datetime.fromJSDate(d).formatDateShort()
  },
  formatDateTimeShort: (d) => {
    return Datetime.fromJSDate(d).formatDateTimeShort()
  },
  formatTimeShort: (d) => {
    return Datetime.fromJSDate(d).formatTimeShort()
  },
  formatMonthLong: (d) => {
    return Datetime.fromJSDate(d).formatMonthLong()
  },
  formatDayLong: (d) => {
    return Datetime.fromJSDate(d).formatDayLong()
  },
  startOfMonth: (d) => {
    return Datetime.fromJSDate(d).startOf("month").toJSDate()
  },
  startOfDay: (d) => {
    return Datetime.fromJSDate(d).startOf("day").toJSDate()
  },
  endOfMonth: (d) => {
    return Datetime.fromJSDate(d).endOf("month").toJSDate()
  },
  endOfDay: (d) => {
    return Datetime.fromJSDate(d).endOf("day").toJSDate()
  },
  addMonths: (d, nbr) => {
    return Datetime.fromJSDate(d).plus({ months: nbr }).toJSDate()
  },
  addDays: (d, nbr) => {
    return Datetime.fromJSDate(d).plus({ days: nbr }).toJSDate()
  }
}
