import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import request from "superagent"
import { queryToString } from "app/shared/util"
export const LOAD_FEHLZEITEN = "kalender/LOAD_FEHLZEITEN"
export const LOAD_FEHLZEITENDEF = "kalender/LOAD_FEHLZEITENDEF"
export const LOAD_PERS_FEHLZEITENDEF = "kalender/LOAD_PERS_FEHLZEITENDEF"

export function LoadFehlzeitenSuccess(data) {
  return {
    type: LOAD_FEHLZEITEN,
    status: SUCCESS,
    data
  }
}

export function LoadFehlzeitenError(err) {
  return {
    type: LOAD_FEHLZEITEN,
    status: ERROR,
    err
  }
}

export function LoadFehlzeitenStarted() {
  return {
    type: LOAD_FEHLZEITEN,
    status: STARTED
  }
}

export function LoadFehlzeiten() {
  return (dispatch) => {
    request.get("/api/personalkalender/fehlzeiten").end((err, response) => {
      if (err) {
        dispatch(LoadFehlzeitenError(err))
      } else {
        dispatch(LoadFehlzeitenSuccess(response.body))
      }
    })
  }
}

export function LoadFehlzeitenDefStarted() {
  return {
    type: LOAD_FEHLZEITENDEF,
    status: STARTED
  }
}

export function LoadFehlzeitenDefSuccess(data) {
  return {
    type: LOAD_FEHLZEITENDEF,
    status: SUCCESS,
    data
  }
}

export function LoadFehlzeitenDefError(error) {
  return {
    type: LOAD_FEHLZEITENDEF,
    status: ERROR,
    error
  }
}

export function LoadPersFehlzeitenDef(IntID, year) {
  return (dispatch) => {
    request
      .get(`/api/p/${IntID}/fehlzeiten/definition/${year}-01-01`)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_PERS_FEHLZEITENDEF,
            int_id: IntID,
            year: year,
            data: response.body
          })
        }
      })
  }
}

export function LoadFehlzeitenDef() {
  return (dispatch) => {
    dispatch(LoadFehlzeitenDefStarted())
    request
      .get("/api/personalkalender/fehlzeiten/definition")
      .end((err, response) => {
        if (err) {
          dispatch(LoadFehlzeitenDefError(err))
        } else {
          dispatch(LoadFehlzeitenDefSuccess(response.body))
        }
      })
  }
}
