import { LOAD_RUFBEREITSCHAFT } from "./telrufdienst_actions"
import { LOAD_TELRUFDIENST } from "./telrufdienst_actions"
import { EDIT_TELRUFZEITEN } from "./telrufdienst_actions"
import { EDIT_NEXTDAY } from "./telrufdienst_actions"
import { EDIT_TELBIS } from "./telrufdienst_actions"
import { ADD_TELRUFZEITEN } from "./telrufdienst_actions"
import { UPDATE_TELRUFZEITEN } from "./telrufdienst_actions"
import { DELETE_TELRUFZEITEN } from "./telrufdienst_actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"

export function TelRufdienst(
  state = {
    rufbereitschaft: {},
    telrufdienst: {}
  },
  action
) {
  switch (action.type) {
    case LOAD_RUFBEREITSCHAFT:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            rufbereitschaft: {
              ...state.rufbereitschaft,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_TELRUFDIENST:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            telrufdienst: {
              ...state.telrufdienst,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_TELRUFZEITEN:
      if (action.status == SUCCESS) {
        return {
          ...state,
          telrufdienst: {
            ...state.telrufdienst,
            [action.int_id]: state.telrufdienst[action.int_id]
              .map((row) => {
                if (row.id == action.id) {
                  return action.data
                }
                return row
              })
              .filter((r) => r !== null)
          }
        }
      }
      return state
    case ADD_TELRUFZEITEN:
      return {
        ...state,
        telrufdienst: {
          ...state.telrufdienst,
          [action.int_id]: [
            {
              id: uuidv4(),
              __new: true,
              int_id: action.int_id,
              datum: action.datum,
              von_uhr: action.datum,
              bis_uhr: action.datum
              // ...(action.values || {})
            },
            ...(state.telrufdienst[action.int_id] || [])
          ]
        }
      }
    case DELETE_TELRUFZEITEN:
      return {
        ...state,
        telrufdienst: {
          ...state.telrufdienst,
          [action.int_id]: state.telrufdienst[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
    case EDIT_NEXTDAY:
      return {
        ...state,
        telrufdienst: {
          ...state.telrufdienst,
          [action.int_id]: state.telrufdienst[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case EDIT_TELRUFZEITEN:
      return {
        ...state,
        telrufdienst: {
          ...state.telrufdienst,
          [action.int_id]: state.telrufdienst[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case EDIT_TELBIS:
      let nDataBis = { ...state.telrufdienst }
      for (let [key, values] of Object.entries(nDataBis)) {
        nDataBis[key] = values.map((v) => {
          if (v.id === action.id) {
            return { ...v, bis_uhr: action.bisUhr, __dirty: true }
          }
          return v
        })
      }
      return {
        ...state,
        telrufdienst: nDataBis
      }
  }
  return state
}
