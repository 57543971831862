import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_KOMPETENZEN = "LOAD_KOMPETENZEN"
export const LOAD_PFLICHTVERAN = "LOAD_PFLICHTVERAN"
export const LOAD_PFLICHTVERANALL = "LOAD_PFLICHTVERANALL"
export const LOAD_MEDIZINPRODUKTE = "LOAD_MEDIZINPRODUKTE"
export const LOAD_INNERBETRIEBLICHEFORTBILDUNG =
  "LOAD_INNERBETRIEBLICHEFORTBILDUNG"
export const LOAD_GEKLASTAMMAKTUELL = "LOAD_GEKLASTAMMAKTUELL"

export function LoadGeklastammAktuellSuccess(int_id, data) {
  return {
    type: LOAD_GEKLASTAMMAKTUELL,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadGeklastammAktuell(int_id) {
  return (dispatch) => {
    request.get(`/api/p/${int_id}/geklastammaktuell`).end((err, response) => {
      dispatch(LoadGeklastammAktuellSuccess(int_id, response.body))
    })
  }
}

export function LoadKompetenzenStarted(int_id) {
  return {
    type: LOAD_KOMPETENZEN,
    status: STARTED,
    int_id
  }
}
export function LoadKompetenzenSuccess(int_id, data) {
  return {
    type: LOAD_KOMPETENZEN,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadKompetenzenError(int_id, error) {
  return {
    type: LOAD_KOMPETENZEN,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadKompetenzen(int_id) {
  return (dispatch) => {
    dispatch(LoadKompetenzenStarted(int_id))
    request.get(`/api/p/${int_id}/kompetenzen`).end((err, response) => {
      if (err) {
        dispatch(LoadKompetenzenError(int_id, err))
      } else {
        dispatch(LoadKompetenzenSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadPflichtVerAnStarted(int_id) {
  return {
    type: LOAD_PFLICHTVERAN,
    status: STARTED,
    int_id
  }
}
export function LoadPflichtVerAnSuccess(int_id, data) {
  return {
    type: LOAD_PFLICHTVERAN,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadPflichtVerAnError(int_id, error) {
  return {
    type: LOAD_PFLICHTVERAN,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadPflichtVerAn(int_id) {
  return (dispatch) => {
    dispatch(LoadPflichtVerAnStarted(int_id))
    request.get(`/api/p/${int_id}/pflichtveran`).end((err, response) => {
      if (err) {
        dispatch(LoadPflichtVerAnError(int_id, err))
      } else {
        dispatch(LoadPflichtVerAnSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadPflichtVerAnAllStarted(int_id) {
  return {
    type: LOAD_PFLICHTVERANALL,
    status: STARTED,
    int_id
  }
}
export function LoadPflichtVerAnAllSuccess(int_id, data) {
  return {
    type: LOAD_PFLICHTVERANALL,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadPflichtVerAnAllError(int_id, error) {
  return {
    type: LOAD_PFLICHTVERANALL,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadPflichtVerAnAll(int_id) {
  return (dispatch) => {
    dispatch(LoadPflichtVerAnAllStarted(int_id))
    request.get(`/api/p/${int_id}/pflichtveranall`).end((err, response) => {
      if (err) {
        dispatch(LoadPflichtVerAnAllError(int_id, err))
      } else {
        dispatch(LoadPflichtVerAnAllSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadMedizinProdukteStarted(int_id) {
  return {
    type: LOAD_MEDIZINPRODUKTE,
    status: STARTED,
    int_id
  }
}
export function LoadMedizinProdukteSuccess(int_id, data) {
  return {
    type: LOAD_MEDIZINPRODUKTE,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadMedizinProdukteError(int_id, error) {
  return {
    type: LOAD_MEDIZINPRODUKTE,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadMedizinProdukte(int_id) {
  return (dispatch) => {
    dispatch(LoadMedizinProdukteStarted(int_id))
    request.get(`/api/p/${int_id}/medizinprodukte`).end((err, response) => {
      if (err) {
        dispatch(LoadMedizinProdukteError(int_id, err))
      } else {
        dispatch(LoadMedizinProdukteSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadInnerbetrieblicheFortbildungStarted(int_id) {
  return {
    type: LOAD_INNERBETRIEBLICHEFORTBILDUNG,
    status: STARTED,
    int_id
  }
}
export function LoadInnerbetrieblicheFortbildungSuccess(int_id, data) {
  return {
    type: LOAD_INNERBETRIEBLICHEFORTBILDUNG,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadInnerbetrieblicheFortbildungError(int_id, error) {
  return {
    type: LOAD_INNERBETRIEBLICHEFORTBILDUNG,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadInnerbetrieblicheFortbildung(int_id) {
  return (dispatch) => {
    dispatch(LoadInnerbetrieblicheFortbildungStarted(int_id))
    request
      .get(`/api/p/${int_id}/innerbetrieblichefortbildung`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadInnerbetrieblicheFortbildungError(int_id, err))
        } else {
          dispatch(
            LoadInnerbetrieblicheFortbildungSuccess(int_id, response.body)
          )
        }
      })
  }
}
