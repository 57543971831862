import { includes } from "lodash"
import { LOAD_SPI, SELECT_SPI } from "../actions/spi"

export const SPI = (state = {}, action) => {
  switch (action.type) {
    case LOAD_SPI:
      return action.data
  }
  return state
}

export const SelectedSPI = (state = null, action) => {
  switch (action.type) {
    case SELECT_SPI:
      return action.value
    case LOAD_SPI:
      const available = Object.keys(action.data)
      if (!available.length) {
        return state
      }
      if (!state || !includes(available, state)) {
        return available[0]
      }
  }
  return state
}
