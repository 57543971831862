import { LOAD_ABSENCE_CHECK } from "../actions/absencecheck"

const emptyState = { fehlzeiten: {}, dienste: {} }

export default function AbsenceCheck(state = emptyState, action) {
  switch (action.type) {
    case LOAD_ABSENCE_CHECK:
      return action.data
  }
  return state
}
