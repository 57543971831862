import request from "superagent"

import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_GENEHMIGUNG_STATUS = "dpl/LOAD_GENEHMIGUNG_STATUS"
export const CAN_START_GENEHMIGUNG = "dpl/CAN_START_GENEHMIGUNG"

export const CAN_START_GENEHMIGUNG_RESET = "dpl/CAN_START_GENEHMIGUNG_RESET"

export const LOAD_GENEHMIGUNG_STUFEN = "dpl/LOAD_GENEHMIGUNG_STUFEN"

export const LOAD_GENEHMIGUNG_BERECHTIGUNG = "dpl/LOAD_GENEHMIGUNG_BERECHTIGUNG"

export const EDIT_STUFE = "dpl/EDIT_STUFE"
export const ADD_STUFE = "dpl/ADD_STUFE"

export const DELETE_STUFE = "dpl/DELETE_STUFE"

export const DELETE_BERECHTIGUNG = "dpl/DELETE_BERECHTIGUNG"
export const EDIT_BERECHTIGUNG = "dpl/EDIT_BERECHTIGUNG"

export const ADD_BERECHTIGUNG = "dpl/ADD_BERECHTIGUNG"
export const SAVED_STUFE = "dpl/SAVED_STUFE"
export const SAVED_BERECHTIGUNG = "dpl/SAVED_BERECHTIGUNG"
import { LoadRecordsByPlan } from "./records"

export function AddStufe(PlanID) {
  return {
    type: ADD_STUFE,
    PlanID
  }
}

export function SavedStufe(PlanID, ID, Stufe) {
  return {
    type: SAVED_STUFE,
    status: SUCCESS,
    PlanID,
    ID,
    Stufe
  }
}

export function SavedStufeError(PlanID, ID, err) {
  return {
    type: SAVED_STUFE,
    status: ERROR,
    PlanID,
    ID,
    err
  }
}

export function SavedBerechtigung(PlanID, ID, Berechtigung) {
  return {
    type: SAVED_BERECHTIGUNG,
    status: SUCCESS,
    PlanID,
    ID,
    Berechtigung
  }
}

export function SavedBerechtigungError(PlanID, ID, err) {
  return {
    type: SAVED_BERECHTIGUNG,
    status: ERROR,
    PlanID,
    ID,
    err
  }
}

export function SaveBerechtigungen() {
  return (dispatch, getState) => {
    let state = getState()
    for (let [PlanID, bers] of Object.entries(state.Dpl.Berechtigungen)) {
      if (!bers) {
        continue
      }
      for (let ber of bers) {
        if (ber.__new) {
          if (!ber.__deleted) {
            var { id, ...rest } = ber
            request
              .post("/api/dpl/genehmigung/berechtigung/" + PlanID)
              .send(rest)
              .end((err, response) => {
                if (err) {
                  dispatch(SavedBerechtigungError(parseInt(PlanID), id, err))
                } else {
                  dispatch(
                    SavedBerechtigung(parseInt(PlanID), id, response.body)
                  )
                }
              })
          } else {
            dispatch(SavedBerechtigung(parseInt(PlanID), ber.id, null))
          }
        } else if (ber.__deleted) {
          request
            .delete(
              "/api/dpl/genehmigung/berechtigung/" + PlanID + "/" + ber.id
            )
            .send(ber)
            .end((err, response) => {
              if (err) {
                dispatch(SavedBerechtigungError(parseInt(PlanID), ber.id, err))
              } else {
                dispatch(
                  SavedBerechtigung(parseInt(PlanID), ber.id, response.body)
                )
              }
            })
        } else if (ber.__dirty) {
          request
            .put("/api/dpl/genehmigung/berechtigung/" + PlanID + "/" + ber.id)
            .send(ber)
            .end((err, response) => {
              if (err) {
                dispatch(SavedBerechtigungError(parseInt(PlanID), ber.id, err))
              } else {
                dispatch(
                  SavedBerechtigung(parseInt(PlanID), ber.id, response.body)
                )
              }
            })
        }
      }
    }
  }
}

export function SaveStufen() {
  return (dispatch, getState) => {
    let state = getState()
    for (let [PlanID, bers] of Object.entries(state.Dpl.Stufen)) {
      if (!bers) {
        continue
      }
      for (let ber of bers) {
        if (ber.__new) {
          if (!ber.__deleted) {
            var { id, ...rest } = ber
            request
              .post("/api/dpl/genehmigung/stufe/" + PlanID)
              .send(rest)
              .end((err, response) => {
                if (err) {
                  dispatch(SavedStufeError(parseInt(PlanID), id, err))
                } else {
                  dispatch(SavedStufe(parseInt(PlanID), id, response.body))
                }
              })
          } else {
            dispatch(SavedStufe(parseInt(PlanID), ber.id, null))
          }
        } else if (ber.__deleted) {
          request
            .delete("/api/dpl/genehmigung/stufe/" + PlanID + "/" + ber.id)
            .send(ber)
            .end((err, response) => {
              if (err) {
                dispatch(SavedStufeError(parseInt(PlanID), ber.id, err))
              } else {
                dispatch(SavedStufe(parseInt(PlanID), ber.id, response.body))
              }
            })
        } else if (ber.__dirty) {
          request
            .put("/api/dpl/genehmigung/stufe/" + PlanID + "/" + ber.id)
            .send(ber)
            .end((err, response) => {
              if (err) {
                dispatch(SavedStufeError(parseInt(PlanID), ber.id, err))
              } else {
                dispatch(SavedStufe(parseInt(PlanID), ber.id, response.body))
              }
            })
        }
      }
    }
  }
}

export function DeleteStufe(PlanID, ID) {
  return {
    type: DELETE_STUFE,
    PlanID,
    ID
  }
}

export function DeleteBerechtigung(PlanID, ID) {
  return {
    type: DELETE_BERECHTIGUNG,
    PlanID,
    ID
  }
}

export function AddBerechtigung(PlanID) {
  return {
    type: ADD_BERECHTIGUNG,
    PlanID
  }
}

export function EditStufe(PlanID, ID, Changes) {
  return {
    type: EDIT_STUFE,
    PlanID,
    ID,
    Changes
  }
}

export function EditBerechtigung(PlanID, ID, Changes) {
  return {
    type: EDIT_BERECHTIGUNG,
    PlanID,
    ID,
    Changes
  }
}

export function LoadedStufen(PlanID, Stufen) {
  return {
    type: LOAD_GENEHMIGUNG_STUFEN,
    status: SUCCESS,
    PlanID,
    Stufen
  }
}

export function LoadedStufenError(PlanID, err) {
  return {
    type: LOAD_GENEHMIGUNG_STUFEN,
    status: ERROR,
    PlanID,
    err
  }
}

export function LoadStufen(PlanID) {
  return dispatch => {
    request
      .get(`/api/dpl/genehmigung/stufen/${PlanID}`)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadedStufen(PlanID, response.body))
        } else {
          dispatch(LoadedStufenError(PlanID, err))
        }
      })
  }
}

export function LoadedBerechtigungen(PlanID, Berechtigungen) {
  return {
    type: LOAD_GENEHMIGUNG_BERECHTIGUNG,
    PlanID,
    Berechtigungen
  }
}

export function LoadBerechtigungen(PlanID) {
  return dispatch => {
    request
      .get(`/api/dpl/genehmigung/berechtigung/${PlanID}`)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadedBerechtigungen(PlanID, response.body))
        }
      })
  }
}

export function LoadedGehmigungsStatus(PlanID, Year, Month, Status) {
  return {
    type: LOAD_GENEHMIGUNG_STATUS,
    PlanID,
    Year,
    Month,
    Status
  }
}

export function LoadGenehmigungStatus(PlanID, Year, Month) {
  return dispatch => {
    request
      .get(`/api/dpl/genehmigung/status/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        dispatch(LoadedGehmigungsStatus(PlanID, Year, Month, response.body))
      })
  }
}

export function CanStartGenehmigung(PlanID, Year, Month) {
  return dispatch => {
    dispatch(ResetCanStartGenehmigung(PlanID, Year, Month))
    dispatch(CanStartGenehmigungStarted(PlanID, Year, Month))
    request
      .get(`/api/dpl/genehmigung/canstart/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        if (err) {
          dispatch(CanStartGenehmigungError(PlanID, Year, Month, err))
        } else {
          dispatch(
            CanStartGenehmigungSuccess(PlanID, Year, Month, response.body)
          )
        }
      })
  }
}

export function CanStartGenehmigungError(PlanID, Year, Month, err) {
  return {
    type: CAN_START_GENEHMIGUNG,
    status: ERROR,
    err,
    PlanID,
    Year,
    Month
  }
}

export function CanStartGenehmigungStarted(PlanID, Year, Month) {
  return {
    type: CAN_START_GENEHMIGUNG,
    status: STARTED,
    PlanID,
    Year,
    Month
  }
}

export function CanStartGenehmigungSuccess(PlanID, Year, Month, data) {
  return {
    type: CAN_START_GENEHMIGUNG,
    status: SUCCESS,
    data,
    PlanID,
    Year,
    Month
  }
}

export function ResetCanStartGenehmigung(PlanID, Year, Month) {
  return {
    type: CAN_START_GENEHMIGUNG_RESET,
    PlanID,
    Year,
    Month
  }
}

export function DoGenehmigung(PlanID, Year, Month) {
  return dispatch => {
    request
      .get(`/api/dpl/genehmigung/start/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadedGehmigungsStatus(PlanID, Year, Month, response.body))
          dispatch(LoadRecordsByPlan(PlanID, Year, Month))
        }
      })
  }
}
