import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import swal from "sweetalert"
import { v4 as uuidv4 } from "uuid"
import Datetime from "../../shared/dateutil/index"
export const LOAD_BUCHUNGENVONBIS = "LOAD_BUCHUNGENVONBIS"
export const LOAD_BUCHUNGEN = "LOAD_BUCHUNGEN"
export const LOAD_BUCHUNGEN120 = "LOAD_BUCHUNGEN120"
export const LOAD_BUCHUNGENDATUM = "LOAD_BUCHUNGENDATUM"
export const CREATE_BUCHUNG = "CREATE_BUCHUNG"
export const UPDATE_BUCHUNG = "UPDATE_BUCHUNG"
export const CLEAR_DIRTY_BUCHUNG = "CLEAR_DIRTY_BUCHUNG"
export const ADD_BUCHUNG = "ADD_BUCHUNG"
export const DELETE_BUCHUNG = "DELETE_BUCHUNG"
export const EDIT_BUCHUNG = "EDIT_BUCHUNG"
export const EDIT_BUCHUNG_SORT = "EDIT_BUCHUNG_SORT"
export const EDIT_BEFTAS = "EDIT_BEFTAS"
export const LOAD_BEFTAS = "LOAD_BEFTAS"
export const BUCHUNG_MESSAGE = "BUCHUNG_MESSAGE"
export const LOAD_PAUSCHALZEITEN = "LOAD_PAUSCHALZEITEN"
export const DELETE_PAUSCHALZEITEN = "DELETE_PAUSCHALZEITEN"
export const EDIT_PZT = "EDIT_PZT"
export const ADD_PAUSCHALZEITEN = "ADD_PAUSCHALZEITEN"
export const UPDATE_PAUSCHALZEIT = "UPDATE_PAUSCHALZEIT"
export const LOAD_UEBERZEITEN = "LOAD_UEBERZEITEN"
export const LOAD_UEBERZEITENVONBIS = "LOAD_UEBERZEITENVONBIS"
export const LOAD_UEBERZEITDATUM = "LOAD_UEBERZEITDATUM"
export const DELETED_UEZ_ROW = "DELETED_UEZ_ROW"
export const DELETE_UEZ_ROW = "DELETE_UEZ_ROW"
export const CREATE_PAUSCHALZEIT = "CREATE_PAUSCHALZEIT"
export const ANTWORT_MESSAGE = "ANTWORT_MESSAGE"
export const UPDATE_UEBERZEIT = "UPDATE_UEBERZEIT"
export const LOAD_KALUNDZEITBUCHUNGEN = "LOAD_KALUNDZEITBUCHUNGEN"
export const LOAD_KALUNDZEITBUCHUNGENVONBIS = "LOAD_KALUNDZEITBUCHUNGENVONBIS"
export const EDIT_KALUNDZEITBUCHUNGEN = "EDIT_KALUNDZEITBUCHUNGEN"
export const UPDATE_KALUNDZEITBUCHUNG = "UPDATE_KALUNDZEITBUCHUNG"
export const EDIT_UEBERZEIT = "EDIT_UEBERZEIT"
export const EDIT_UEZVON = "EDIT_UEZVON"
export const EDIT_UEZBIS = "EDIT_UEZBIS"
export const ADD_UEZ = "ADD_UEZ"
export const UPDATE_UEZ = "UPDATE_UEZ"
export const CREATED_BUCHUNG = "CREATED_BUCHUNG"
export const LOAD_ANZEIGEBUCHUNG = "LOAD_ANZEIGEBUCHUNG"
export const LOAD_ANZEIGEURLAUB = "LOAD_ANZEIGEURLAUB"
export const LOAD_ANZEIGESALDO = "LOAD_ANZEIGESALDO"
import { queryToString } from "app/shared/util"

export function UpdateBuchungSuccess(int_id, id, data) {
  return {
    type: UPDATE_BUCHUNG,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}

export function ClearDirtyBuchung(buc) {
  return {
    type: CLEAR_DIRTY_BUCHUNG,
    id: buc.id,
    int_id: buc.int_id
  }
}

export function BuchungenUnsavedChanges(state) {
  for (let buchungdata of Object.values(state)) {
    for (let row of buchungdata) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

function getDirtyRows(state) {
  let data = state.Buchungen.buchungdata
  let result = []
  for (let rows of Object.values(data)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}
function CheckBuchung(data, sperrdatum, nosperre) {
  if (sperrdatum.datum !== "0001-01-01T00:00:00Z" && nosperre.int_id == 0) {
    // if (data.datum < sperrdatum.datum) {
    if (data.datum <= sperrdatum.datum) {
      return "Änderungen vor dem Sperrdatum nicht möglich !"
    }
  }
  return null
}

export function UpdateBuchungAll() {
  return (dispatch, getState) => {
    let data = getDirtyRows(getState())
    // let data = getState().Buchungen.buchungdata[int_id] || []
    if (data.length == 0) {
      return
    }
    let updateRows = []
    let clearRows = []
    let IntID = 0
    for (let row of data) {
      IntID = row.int_id
      let sperrdatum = getState().Fehlerprotokoll.sperrdatum[row.int_id] || {}
      let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || {}
      var err = CheckBuchung(row, sperrdatum, nosperre)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        updateRows = [...updateRows, row]
        clearRows = [...clearRows, row]
      } else if (row.__deleted && row.__new) {
        clearRows = [...clearRows, row]
      } else if (row.__new) {
        let { id, ...r } = row
        clearRows = [...clearRows, row]
        updateRows = [...updateRows, r]
      } else if (row.__dirty && !row.__deleted && !row.__new) {
        updateRows = [...updateRows, row]
      }
    }
    if (updateRows.length > 0) {
      request
        .put(`/api/p/${IntID}/buchungen/`)
        .send(updateRows)
        .end((err, response) => {
          if (err) {
          } else {
            let rows = response.body
            for (let row of clearRows) {
              dispatch(ClearDirtyBuchung(row))
            }
            if (rows.length) {
              for (let row of rows) {
                dispatch(UpdateBuchungSuccess(row.int_id, row.id, row))
              }
            }
          }
        })
    }
  }
}

// export function UpdateBuchungAll() {
//   return (dispatch, getState) => {
//     let data = getDirtyRows(getState())
//     // let data = getState().Buchungen.buchungdata[int_id] || []
//     if (data.length == 0) {
//       return
//     }
//     for (let row of data) {
//       let sperrdatum =
//         getState().Fehlerprotokoll.sperrdatum[row.int_id] || {}
//       let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || {}
//       var err = CheckBuchung(row, sperrdatum, nosperre)
//       if (err !== null) {
//         swal({
//           title: "Hinweis",
//           text: err,
//           type: "warning",
//           confirmButtonColor: "#DD6B55",
//           confirmButtonText: "Ok!",
//           closeOnConfirm: false
//         })
//         continue
//       }
//       if (row.__deleted && !row.__new) {
//         // dispatch(DeleteBuchungStarted(row.int_id, row.id))
//         request
//           .put(`/api/p/${row.int_id}/buchungen/${row.id}`)
//           .send(row)
//           .end((err, response) => {
//             if (err) {
//               // dispatch(DeleteBuchungError(row.int_id, row.id, err))
//             } else {
//               dispatch(UpdateBuchungSuccess(row.int_id, row.id, response.body))
//             }
//           })
//       } else if (row.__new) {
//         let { id, ...r } = row
//         //dispatch(UpdateBuchungenStarted(row.int_id))
//         request
//           .post("/api/buchunggene/create")
//           .send(r)
//           .end((err, response) => {
//             if (err) {
//               //dispatch(UpdateBuchungenError(row.int_id, id, err))
//             } else {
//               dispatch(UpdateBuchungSuccess(row.int_id, id, response.body))
//             }
//           })
//       } else if (row.__dirty && !row.__deleted && !row.__new) {
//         //dispatch(UpdateBuchungenStarted(row.int_id, row.id))
//         request
//           .put(`/api/p/${row.int_id}/buchungen/${row.id}`)
//           .send(row)
//           .end((err, response) => {
//             if (err) {
//               //dispatch(UpdateBuchungenError(row.int_id, row.id, err))
//             } else {
//               dispatch(UpdateBuchungSuccess(row.int_id, row.id, response.body))
//             }
//           })
//       }
//     }
//   }
// }

export function BuchungCreated(data) {
  return {
    type: CREATED_BUCHUNG,
    data
  }
}
export function NewBuchung() {
  return {
    type: CREATED_BUCHUNG,
    data: {}
  }
}

function getDirtyRowsPau(state) {
  let data = state.Buchungen.pauschalzeitdata
  let result = []
  for (let rows of Object.values(data)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}

function CheckPausch(data, sperrdatum, nosperre) {
  if (data.pk_kenn == "" || data.pk_kenn == null) {
    return "bitte Pauschalzeit angeben"
  }
  if (data.bis_uhr == "1900-01-01T00:00:00Z") {
    return "bitte Uhrzeit angeben"
  }
  if (sperrdatum.datum !== "0001-01-01T00:00:00Z" && nosperre.int_id == 0) {
    // if (data.datum < sperrdatum.datum) {
    if (data.datum <= sperrdatum.datum) {
      return "Änderungen vor dem Sperrdatum nicht möglich !"
    }
  }
  return null
}

export function UpdatePauschalzeit() {
  return (dispatch, getState) => {
    // let data = getState().Buchungen.pauschalzeitdata[int_id] || []
    let data = getDirtyRowsPau(getState())
    if (data.length == 0) {
      return
    }
    for (let row of data || []) {
      let sperrdatum = getState().Fehlerprotokoll.sperrdatum[row.int_id] || {}
      let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || {}
      var err = CheckPausch(row, sperrdatum, nosperre)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        dispatch(UpdatePauschalzeitStarted(row.int_id, row.id))
        request
          .delete(`/api/p/${row.int_id}/pauschalzeit/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              // dispatch(UpdatePauschalzeitError(row.int_id, row.id, err))
            } else {
              dispatch(
                UpdatePauschalzeitSuccess(row.int_id, row.id, response.body)
              )
            }
          })
      } else if (row.__new) {
        row.int_id = parseInt(row.int_id)
        let { id, ...r } = row
        dispatch(UpdatePauschalzeitStarted(row.int_id))
        request
          .post(`/api/p/${row.int_id}/pauschalzeit/create`)
          .send(r)
          .end((err, response) => {
            if (err) {
              // dispatch(UpdatePauschalzeitError(row.int_id, row.id, err))
            } else {
              dispatch(
                UpdatePauschalzeitSuccess(row.int_id, row.id, response.body)
              )
            }
          })
      } else if (row.__dirty && !row.__deleted && !row.__new) {
        dispatch(UpdatePauschalzeitStarted(row.int_id))
        request
          .put(`/api/p/${row.int_id}/pauschalzeit`)
          .send(row)
          .end((err, response) => {
            if (err) {
              // dispatch(UpdatePauschalzeitError(row.int_id, row.id, err))
            } else {
              dispatch(
                UpdatePauschalzeitSuccess(row.int_id, row.id, response.body)
              )
            }
          })
      }
    }
  }
}

export function UpdatePauschalzeitStarted(int_id) {
  return {
    type: UPDATE_PAUSCHALZEIT,
    status: STARTED,
    int_id
  }
}

export function UpdatePauschalzeitSuccess(int_id, id, data) {
  return {
    type: UPDATE_PAUSCHALZEIT,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}

export function PauschalzeitUnsavedChanges(state) {
  for (let pauschalzeiten of Object.values(state)) {
    for (let row of pauschalzeiten) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function DeleteUezRow(id) {
  return {
    type: DELETE_UEZ_ROW,
    id
  }
}
export function DeleteUezSuccess(id) {
  return {
    type: DELETED_UEZ_ROW,
    status: SUCCESS,
    id
  }
}
export function UpdateUezStarted(int_id) {
  return {
    type: UPDATE_UEZ,
    status: STARTED,
    int_id
  }
}

export function UpdateUezSuccess(int_id, id, data) {
  return {
    type: UPDATE_UEZ,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}

export function UpdateUezError(int_id, error) {
  return {
    type: UPDATE_UEZ,
    status: ERROR,
    int_id,
    error
  }
}

export function UpdateUez(int_id) {
  return (dispatch, getState) => {
    let data = getDirtyUeberzeit(getState())
    // let data = getState().Buchungen.ueberzeitdata[int_id] || []
    if (data.length == 0) {
      return
    }
    for (let row of data || []) {
      let sperrdatum = getState().Fehlerprotokoll.sperrdatum[row.int_id] || []
      let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || []
      var err = CheckUeberzeit(row, sperrdatum, nosperre)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/p/${row.int_id}/ueberzeiten/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteUezSuccess(row.int_id, row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        dispatch(UpdateUezStarted(row.int_id))
        request
          .post(`/api/p/${row.int_id}/ueberzeiten/create/${row.id}`)
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateUezError(row.int_id, id, err))
            } else {
              dispatch(UpdateUezSuccess(row.int_id, id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateUezStarted(row.int_id))
        request
          // .put(`/api/p/${int_id}/ueberzeiten/${row.id}`)
          .put(`/api/p/${row.int_id}/ueberzeiten/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateUezError(row.int_id, row.id, err))
            } else {
              dispatch(UpdateUezSuccess(row.int_id, row.id, response.body))
            }
          })
      }
    }
  }
}
function getDirtyUeberzeit(state) {
  let data = state.Buchungen.ueberzeitdata
  let result = []
  for (let rows of Object.values(data)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}
function CheckUeberzeit(data, sperrdatum, nosperre) {
  if (sperrdatum.datum !== "0001-01-01T00:00:00Z" && nosperre.int_id == 0) {
    // if (data.datum < sperrdatum.datum) {
    if (data.datum <= sperrdatum.datum) {
      return "Änderungen vor dem Sperrdatum nicht möglich !"
    }
  }
  return null
}

export function UezUnsavedChanges(state) {
  for (let ueberzeitdata of Object.values(state)) {
    for (let row of ueberzeitdata) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function AddUez(int_id, uez) {
  return {
    type: ADD_UEZ,
    int_id,
    uez
  }
}

function GetUezSatzByID(state, id) {
  for (let ueberzeiten of Object.values(state.Buchungen.ueberzeitdata)) {
    for (let uez of ueberzeiten) {
      if (uez.id == id) {
        return uez
      }
    }
  }
}

export function EditUezVon(id, vonUhr) {
  return (dispatch, getState) => {
    let uez = GetUezSatzByID(getState(), id)
    // Ab Uhr nach vorne verschieben ist nicht möglich!
    if (Datetime.fromISO(uez.ab_uhr) > Datetime.fromISO(vonUhr)) {
      return
    }
    // Wenn Ab Uhr nach hinten verschoben wird
    // muss ein neuer satz erstellt werden
    if (Datetime.fromISO(uez.ab_uhr) < Datetime.fromISO(vonUhr)) {
      let nUez = { ...uez, end_uhr: vonUhr, id: uuidv4() }
      dispatch(AddUez(uez.int_id, nUez))
    }
    // Von Uhr editieren
    dispatch({
      type: EDIT_UEZVON,
      id,
      vonUhr
    })
  }
}

export function EditUezBis(id, bisUhr) {
  return (dispatch, getState) => {
    let uez = GetUezSatzByID(getState(), id)
    if (Datetime.fromISO(uez.end_uhr) < Datetime.fromISO(bisUhr)) {
      return
    }
    if (Datetime.fromISO(uez.end_uhr) > Datetime.fromISO(bisUhr)) {
      let nUez = { ...uez, ab_uhr: bisUhr, id: uuidv4() }
      dispatch(AddUez(uez.int_id, nUez))
    }
    dispatch({
      type: EDIT_UEZBIS,
      id,
      bisUhr
    })
  }
}

export function EditUeberzeit(int_id, id, changes) {
  return {
    type: EDIT_UEBERZEIT,
    int_id,
    id,
    changes
  }
}

export function LoadUeberzeitDatumStarted(int_id, datum) {
  return {
    type: LOAD_UEBERZEITDATUM,
    status: STARTED,
    int_id,
    datum
  }
}
export function LoadUeberzeitDatumSuccess(int_id, datum, data) {
  return {
    type: LOAD_UEBERZEITDATUM,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}
export function LoadUeberzeitDatumError(int_id, datum, error) {
  return {
    type: LOAD_UEBERZEITDATUM,
    status: ERROR,
    int_id,
    datum,
    error
  }
}

export function LoadUeberzeitDatum(int_id, datum) {
  return (dispatch) => {
    dispatch(LoadUeberzeitDatumStarted(int_id, datum))
    request
      .get(`/api/p/${int_id}/ueberzeiten/${Datetime.fromISO(datum).toISO()}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadUeberzeitDatumError(int_id, datum, err))
        } else {
          dispatch(LoadUeberzeitDatumSuccess(int_id, datum, response.body))
        }
      })
  }
}

export function EditKalundZeitbuchungen(int_id, datum, changes) {
  return {
    type: EDIT_KALUNDZEITBUCHUNGEN,
    int_id,
    datum,
    changes
  }
}

export function LoadKalundZeitbuchungenStarted(int_id) {
  return {
    type: LOAD_KALUNDZEITBUCHUNGEN,
    status: STARTED,
    int_id
  }
}
export function LoadKalundZeitbuchungenSuccess(int_id, data) {
  return {
    type: LOAD_KALUNDZEITBUCHUNGEN,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadKalundZeitbuchungenError(int_id, error) {
  return {
    type: LOAD_KALUNDZEITBUCHUNGEN,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadKalundZeitbuchungen(int_id) {
  return (dispatch) => {
    dispatch(LoadKalundZeitbuchungenStarted(int_id))
    request.get(`/api/p/${int_id}/kalundzeitbuchungen`).end((err, response) => {
      if (err) {
        dispatch(LoadKalundZeitbuchungenError(int_id, err))
      } else {
        dispatch(LoadKalundZeitbuchungenSuccess(int_id, response.body))
      }
    })
  }
}

export function AddBuchung(int_id, datum) {
  return {
    type: ADD_BUCHUNG,
    int_id,
    datum
  }
}
export function DeleteBuchungRow(int_id, id) {
  return {
    type: DELETE_BUCHUNG,
    int_id,
    id
  }
}

export function EditBuchung(int_id, id, changes) {
  return {
    type: EDIT_BUCHUNG,
    int_id,
    id,
    changes
  }
}
export function EditBuchungSort(int_id, id, changes, datum) {
  return {
    type: EDIT_BUCHUNG_SORT,
    int_id,
    id,
    changes,
    datum
  }
}
export function EditBeftas(int_id, id, changes, datum) {
  return {
    type: EDIT_BEFTAS,
    int_id,
    id,
    changes,
    datum
  }
}

export function LoadBuchungenStarted(int_id) {
  return {
    type: LOAD_BUCHUNGEN,
    status: STARTED,
    int_id
  }
}
export function LoadBuchungenSuccess(int_id, data) {
  return {
    type: LOAD_BUCHUNGEN,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadBuchungenError(int_id, error) {
  return {
    type: LOAD_BUCHUNGEN,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadBuchungen(int_id) {
  return (dispatch) => {
    dispatch(LoadBuchungenStarted(int_id))
    request.get(`/api/p/${int_id}/buchungen`).end((err, response) => {
      if (err) {
        dispatch(LoadBuchungenError(int_id, err))
      } else {
        dispatch(LoadBuchungenSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadBuchungen120Started(int_id) {
  return {
    type: LOAD_BUCHUNGEN120,
    status: STARTED,
    int_id
  }
}
export function LoadBuchungen120Success(int_id, data) {
  return {
    type: LOAD_BUCHUNGEN120,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadBuchungen120Error(int_id, error) {
  return {
    type: LOAD_BUCHUNGEN120,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadBuchungen120(int_id) {
  return (dispatch) => {
    dispatch(LoadBuchungen120Started(int_id))
    request.get(`/api/p/${int_id}/buchungen120`).end((err, response) => {
      if (err) {
        dispatch(LoadBuchungen120Error(int_id, err))
      } else {
        dispatch(LoadBuchungen120Success(int_id, response.body))
      }
    })
  }
}

export function LoadBuchungenDatumStarted(int_id, datum) {
  return {
    type: LOAD_BUCHUNGENDATUM,
    status: STARTED,
    int_id,
    datum
  }
}
export function LoadBuchungenDatumSuccess(int_id, datum, data) {
  return {
    type: LOAD_BUCHUNGENDATUM,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}
export function LoadBuchungenDatumError(int_id, datum, error) {
  return {
    type: LOAD_BUCHUNGENDATUM,
    status: ERROR,
    int_id,
    datum,
    error
  }
}

export function LoadBuchungenDatum(int_id, datum) {
  return (dispatch) => {
    dispatch(LoadBuchungenDatumStarted(int_id, datum))
    request
      .get(`/api/p/${int_id}/buchungendatum/${Datetime.fromISO(datum).toISO()}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadBuchungenDatumError(int_id, datum, err))
        } else {
          dispatch(LoadBuchungenDatumSuccess(int_id, datum, response.body))
        }
      })
  }
}

export function LoadBuchungenVonBisStarted(int_id, sdatum, edatum) {
  return {
    type: LOAD_BUCHUNGENVONBIS,
    status: STARTED,
    int_id,
    sdatum,
    edatum
  }
}
export function LoadBuchungenVonBisSuccess(int_id, sdatum, edatum, data) {
  return {
    type: LOAD_BUCHUNGENVONBIS,
    status: SUCCESS,
    int_id,
    sdatum,
    edatum,
    data
  }
}
export function LoadBuchungenVonBisError(int_id, sdatum, edatum, error) {
  return {
    type: LOAD_BUCHUNGENVONBIS,
    status: ERROR,
    int_id,
    sdatum,
    edatum,
    error
  }
}

export function LoadBuchungenVonBis(int_id, sdatum, edatum) {
  return (dispatch) => {
    dispatch(LoadBuchungenVonBisStarted(int_id, sdatum, edatum))
    request
      .get(
        `/api/p/${int_id}/buchungen/${Datetime.fromISO(
          sdatum
        ).toISO()}/${Datetime.fromISO(edatum).toISO()}`
      )
      .end((err, response) => {
        if (err) {
          dispatch(LoadBuchungenVonBisError(int_id, sdatum, edatum, err))
        } else {
          dispatch(
            LoadBuchungenVonBisSuccess(int_id, sdatum, edatum, response.body)
          )
        }
      })
  }
}
export function LoadStempelkarteQuery(query) {
  return (dispatch) => {
    dispatch(LoadBuchungenVonBisStarted())
    request
      .get("/api/buchungen/query")
      .query(queryToString(query))
      .end((err, response) => {
        if (err) {
          dispatch(LoadBuchungenVonBisError(null, err))
        } else {
          dispatch(LoadBuchungenVonBisSuccess(null, response.body))
        }
      })
  }
}

export function LoadPauschalzeitenStarted(int_id) {
  return {
    type: LOAD_PAUSCHALZEITEN,
    status: STARTED,
    int_id
  }
}
export function LoadPauschalzeitenSuccess(int_id, data) {
  return {
    type: LOAD_PAUSCHALZEITEN,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadPauschalzeitenError(int_id, error) {
  return {
    type: LOAD_PAUSCHALZEITEN,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadPauschalzeiten(int_id) {
  return (dispatch) => {
    dispatch(LoadPauschalzeitenStarted(int_id))
    request.get(`/api/p/${int_id}/pauschalzeiten`).end((err, response) => {
      if (err) {
        dispatch(LoadPauschalzeitenError(int_id, err))
      } else {
        dispatch(LoadPauschalzeitenSuccess(int_id, response.body))
      }
    })
  }
}

export function DeletePauschalzeiten(int_id, id) {
  return {
    type: DELETE_PAUSCHALZEITEN,
    int_id,
    id
  }
}
export function EditPZt(int_id, id, changes) {
  return {
    type: EDIT_PZT,
    int_id,
    id,
    changes
  }
}

export function AddPauschalzeiten(int_id, datum, values) {
  return {
    type: ADD_PAUSCHALZEITEN,
    int_id,
    datum,
    values
  }
}

export function LoadUeberzeitenStarted(int_id) {
  return {
    type: LOAD_UEBERZEITEN,
    status: STARTED,
    int_id
  }
}
export function LoadUeberzeitenSuccess(int_id, data) {
  return {
    type: LOAD_UEBERZEITEN,
    status: SUCCESS,
    int_id,
    data
  }
}
export function LoadUeberzeitenError(int_id, error) {
  return {
    type: LOAD_UEBERZEITEN,
    status: ERROR,
    int_id,
    error
  }
}

export function LoadUeberzeiten(int_id) {
  return (dispatch) => {
    dispatch(LoadUeberzeitenStarted(int_id))
    request.get(`/api/p/${int_id}/ueberzeiten`).end((err, response) => {
      if (err) {
        dispatch(LoadUeberzeitenError(int_id, err))
      } else {
        dispatch(LoadUeberzeitenSuccess(int_id, response.body))
      }
    })
  }
}

export function LoadUeberzeitenVonBisSuccess(int_id, sdatum, edatum, data) {
  return {
    type: LOAD_UEBERZEITENVONBIS,
    status: SUCCESS,
    int_id,
    sdatum,
    edatum,
    data
  }
}
export function LoadUeberzeitenVonBis(int_id, sdatum, edatum) {
  return (dispatch) => {
    request
      .get(
        `/api/p/${int_id}/ueberzeiten/${Datetime.fromISO(
          sdatum
        ).toISO()}/${Datetime.fromISO(edatum).toISO()}`
      )
      .end((err, response) => {
        dispatch(
          LoadUeberzeitenVonBisSuccess(int_id, sdatum, edatum, response.body)
        )
      })
  }
}

export function BuchungMessage(message, koge) {
  return {
    type: BUCHUNG_MESSAGE,
    message,
    koge
  }
}

export function ClearBuchungMessage() {
  return BuchungMessage(null)
}

export function CreateBuchung(beftas) {
  return (dispatch) => {
    dispatch(BuchungMessage("Führe Buchung aus..."))
    request
      .post("/api/buchung/create")
      .query({ beftas: beftas })
      .end((err, response) => {
        let message =
          (response && response.body && response.body.message) || null
        let koge =
          (response && response.body && response.body.kommt_geht) || null
        if (err) {
          dispatch(BuchungMessage(message || "Buchung nicht möglich"))
          setTimeout(() => {
            dispatch(ClearBuchungMessage())
          }, 5000)
          return
        } else {
          dispatch(LoadBuchungen(response.body.buchung.int_id))
          dispatch(BuchungMessage(message || "Buchung erfolgreich !", koge))
          setTimeout(() => {
            dispatch(ClearBuchungMessage())
          }, 5000)
        }
      })
  }
}

export function LoadAnzeigeBuchungSuccess(data) {
  return {
    type: LOAD_ANZEIGEBUCHUNG,
    status: SUCCESS,
    data
  }
}
export function LoadAnzeigeBuchung() {
  return (dispatch) => {
    request
      .get("/api/parameter/zbu_info/anzeige_buchung")
      .end((err, response) => {
        dispatch(LoadAnzeigeBuchungSuccess(response.body))
      })
  }
}

export function LoadAnzeigeUrlaubSuccess(data) {
  return {
    type: LOAD_ANZEIGEURLAUB,
    status: SUCCESS,
    data
  }
}
export function LoadAnzeigeUrlaub() {
  return (dispatch) => {
    request
      .get("/api/parameter/zbu_info/anzeige_urlaub")
      .end((err, response) => {
        dispatch(LoadAnzeigeUrlaubSuccess(response.body))
      })
  }
}

export function LoadAnzeigeSaldoSuccess(data) {
  return {
    type: LOAD_ANZEIGESALDO,
    status: SUCCESS,
    data
  }
}
export function LoadAnzeigeSaldo() {
  return (dispatch) => {
    request
      .get("/api/parameter/zbu_info/anzeige_saldo")
      .end((err, response) => {
        dispatch(LoadAnzeigeSaldoSuccess(response.body))
      })
  }
}

export function CreateBuchungGene(buchung) {
  return (dispatch) => {
    request
      .post("/api/buchunggene/create")
      .send(buchung)
      .end((err, response) => {
        let message = (response.body && response.body.message) || null
        if (err) {
          // dispatch(BuchungMessage(message || "Genehmigung nicht möglich"))
          setTimeout(() => {
            dispatch(ClearBuchungMessage())
          }, 5000)
          return
        } else {
          dispatch(LoadBuchungen120(response.body.buchung.int_id))
          dispatch(BuchungMessage(message || "Buchung genehmigt !"))
          setTimeout(() => {
            dispatch(ClearBuchungMessage())
          }, 5000)
        }
      })
  }
}

export function CreateBuchungDatum(buchung) {
  return (dispatch) => {
    request
      .post("/api/buchunggene/create")
      .send(buchung)
      .end((err, response) => {
        let message = (response.body && response.body.message) || null
        if (err) {
          // dispatch(BuchungMessage(message || "Genehmigung nicht möglich"))
          setTimeout(() => {
            dispatch(ClearBuchungMessage())
          }, 5000)
          return
        } else {
          dispatch(
            LoadBuchungenDatum(
              response.body.buchung.int_id,
              response.body.buchung.datum
            )
          )
          dispatch(BuchungMessage(message || "Buchung genehmigt !"))
          setTimeout(() => {
            dispatch(ClearBuchungMessage())
          }, 5000)
        }
      })
  }
}

export function UpdateBuchung(buchung) {
  return (dispatch) => {
    //dispatch(UpdateBuchungStarted(buchung.int_id, buchung.id))
    request
      .put(`/api/p/${buchung.int_id}/buchungen/${buchung.id}`)
      .send(buchung)
      .end((err, response) => {
        if (err) {
          //dispatch(UpdateBuchungError(buchung.int_id, buchung.id, err))
        } else {
          dispatch(
            UpdateBuchungSuccess(buchung.int_id, buchung.id, response.body)
          )
        }
      })
  }
}

export function AntwortMessage(message) {
  return {
    type: ANTWORT_MESSAGE,
    message
  }
}
export function ClearAntwortMessage() {
  return AntwortMessage(null)
}

export function CreatePauschalZeit(pauschalzeiten) {
  return (dispatch) => {
    dispatch(AntwortMessage("Antrag erfolgreich verschickt  !"))
    request
      .post("/api/pauschalzeit/create")
      .send(pauschalzeiten)
      .end((err, response) => {
        let message = (response.body && response.body.message) || null
        if (err) {
          swal(
            "Fehler beim erstellen des Antrags!",
            message || "Antrag momentan nicht möglich",
            "error"
          )
          return
        } else {
          swal("Antrag erfolgreich versendet!", "", "success")
        }
      })
  }
}

export function UpdateUeberzeitStarted(int_id, id) {
  return {
    type: UPDATE_UEBERZEIT,
    status: STARTED,
    int_id,
    id
  }
}
export function UpdateUeberzeitSuccess(int_id, id, data) {
  return {
    type: UPDATE_UEBERZEIT,
    status: SUCCESS,
    int_id,
    id,
    data
  }
}
export function UpdateUeberzeitError(int_id, id, error) {
  return {
    type: UPDATE_UEBERZEIT,
    status: ERROR,
    int_id,
    id,
    error
  }
}
export function UpdateUeberzeit(ueberzeit) {
  return (dispatch) => {
    // let data = getDirtyUeberzeit(getState())
    // if (data.length == 0) {
    //   return
    // }
    // for (let row of data || []) {
    //   let sperrdatum =
    //     getState().Fehlerprotokoll.sperrdatum[row.int_id] || []
    //   let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || []
    //   var err = CheckUeberzeit(row, sperrdatum, nosperre)
    //   if (err !== null) {
    //     swal({
    //       title: "Hinweis",
    //       text: err,
    //       type: "warning",
    //       confirmButtonColor: "#DD6B55",
    //       confirmButtonText: "Ok!",
    //       closeOnConfirm: false
    //     })
    //     continue
    //   }
    dispatch(UpdateUeberzeitStarted(ueberzeit.int_id, ueberzeit.id))
    request
      .put(`/api/p/${ueberzeit.int_id}/ueberzeiten/${ueberzeit.id}`)
      .send(ueberzeit)
      .end((err, response) => {
        if (err) {
          dispatch(UpdateUeberzeitError(ueberzeit.int_id, ueberzeit.id, err))
        } else {
          dispatch(
            UpdateUeberzeitSuccess(
              ueberzeit.int_id,
              ueberzeit.id,
              response.body
            )
          )
        }
      })
    // }
  }
}

// export function UpdateKalundZeitbuchungStarted(int_id, datum) {
//   return {
//     type: UPDATE_KALUNDZEITBUCHUNG,
//     status: STARTED,
//     int_id,
//     datum
//   }
// }
export function UpdateKalundZeitbuchungSuccess(int_id, datum, data) {
  return {
    type: UPDATE_KALUNDZEITBUCHUNG,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}

// export function UpdateKalundZeitbuchungError(int_id, datum, error) {
//   return {
//     type: UPDATE_KALUNDZEITBUCHUNG,
//     status: ERROR,
//     int_id,
//     datum,
//     error
//   }
// }

export function UpdateKalundZeitbuchung(kalundzeitbuchungen) {
  return (dispatch, getState) => {
    let data = getDirtyKalundZeit(getState())
    if (data.length == 0) {
      return
    }
    for (let row of data || []) {
      let sperrdatum = getState().Fehlerprotokoll.sperrdatum[row.int_id] || []
      let nosperre = getState().Fehlerprotokoll.nosperre[row.int_id] || []
      var err = CheckKalundZeit(row, sperrdatum, nosperre)
      if (err !== null) {
        swal({
          title: "Hinweis",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        continue
      }
      // dispatch(
      //   UpdateKalundZeitbuchungStarted(
      //     kalundzeitbuchungen.int_id,
      //     kalundzeitbuchungen.datum
      //     // kalundzeitbuchungen.changes
      //   )
      // )
      request
        .put(
          `/api/p/${kalundzeitbuchungen.int_id}/kalundzeitbuchungen/${kalundzeitbuchungen.datum}`
        )
        .send(kalundzeitbuchungen)
        .end((err, response) => {
          // if (err) {
          //   dispatch(
          //     UpdateKalundZeitbuchungError(
          //       kalundzeitbuchungen.int_id,
          //       kalundzeitbuchungen.datum,
          //       err
          //     )
          //   )
          // } else {
          dispatch(
            UpdateKalundZeitbuchungSuccess(
              kalundzeitbuchungen.int_id,
              kalundzeitbuchungen.datum,
              response.body
            )
          )
          // }
        })
    }
  }
}

function getDirtyKalundZeit(state) {
  let data = state.Buchungen.kalundzeitbuchungen
  let result = []
  for (let rows of Object.values(data)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}

function CheckKalundZeit(data, sperrdatum, nosperre) {
  if (sperrdatum.datum !== "0001-01-01T00:00:00Z" && nosperre.int_id == 0) {
    // if (data.datum < sperrdatum.datum) {
    if (data.datum <= sperrdatum.datum) {
      return "Änderungen vor dem Sperrdatum nicht möglich !"
    }
  }
  return null
}

export function LoadKalundZeitbuchungenVonBisSuccess(
  int_id,
  sdatum,
  edatum,
  data
) {
  return {
    type: LOAD_KALUNDZEITBUCHUNGENVONBIS,
    status: SUCCESS,
    int_id,
    sdatum,
    edatum,
    data
  }
}
export function LoadKalundZeitbuchungenVonBis(int_id, sdatum, edatum) {
  return (dispatch) => {
    request
      .get(
        `/api/p/${int_id}/kalundzeitbuchungen/${Datetime.fromISO(
          sdatum
        ).toISO()}/${Datetime.fromISO(edatum).toISO()}`
      )
      .end((err, response) => {
        dispatch(
          LoadKalundZeitbuchungenVonBisSuccess(
            int_id,
            sdatum,
            edatum,
            response.body
          )
        )
      })
  }
}
