import store from "app/state/index"

export function getDropDownValue(state, key, value) {
  let dd = state.DropDown[key]
  if (!dd || !dd.data) {
    return null
  }
  let val = dd.byKey[value]
  if (val) {
    return val.label
  }
  return null
  //let val = dd.data.filter(d => {
  //return d.value == value
  //})
  //if (!val || val.length === 0) {
  //return value
  //}
  //return val[0].label
}

export const dropdownValue = (key, value) => {
  const state = store.getState()
  return getDropDownValue(state, key, value)
}

export function getDropDownData(state, key) {
  let dd = state.DropDown[key]
  if (!dd || !dd.data) {
    dd = state.DropDown[key + "/"]
    if (!dd || !dd.data) {
      return []
    }
  }
  return dd.data
}

export function getDropDownExtra(state, key, value) {
  let dd = state.DropDown[key]
  if (!dd || !dd.data) {
    return null
  }
  let val = dd.byKey[value]
  if (val) {
    return val.extra
  }
  return null
  //let val = dd.data.filter(d => {
  //return d.value == value
  //})
  //if (!val || val.length === 0) {
  //return null
  //}
  //return val[0].extra
}
