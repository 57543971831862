import request from "superagent"
import { queryToString } from "app/shared/util"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_KALENDER = "LOAD_KALENDER"
export const UPDATE_KALENDER = "UPDATE_KALENDER"
export const CREATE_KALENDER = "CREATE_KALENDER"
export const LOAD_FEHLZEITEN = "LOAD_FEHLZEITEN"
export const LOAD_KALENDERMONAT = "LOAD_KALENDERMONAT"
export const EDIT_KALENDERMONAT = "EDIT_KALENDERMONAT"
export const EDIT_KALENDER = "KAL/EDIT_KALENDER"
export const UPDATE_KALENDERMONAT = "UPDATE_KALENDERMONAT"
import isInfo from "app/info/util"
import swal from "sweetalert"
import Datetime from "../../../shared/dateutil/index"
import {
  DecrementUrlaub,
  IncrementUrlaub
} from "app/shared/urlaubsanspruch/actions"
export const LOAD_KALUEBERSICHT2 = "LOAD_KALUEBERSICHT2"
export const LOAD_KALUEBERSICHTQUERY = "LOAD_KALUEBERSICHTQUERY"
export const LOAD_SOLLZEIT_JAHR = "KAL/LOAD_SOLLZEIT_JAHR"
export const LOAD_FEIERTAGSKALENDER = "LOAD_FEIERTAGSKALENDER"

export function LoadFeiertagsKalenderStarted(natio, land_id, year) {
  return {
    type: LOAD_FEIERTAGSKALENDER,
    status: STARTED,
    natio: natio,
    land_id: land_id,
    jahr: year
  }
}

export function LoadFeiertagsKalenderSuccess(natio, land_id, year, data) {
  return {
    type: LOAD_FEIERTAGSKALENDER,
    status: SUCCESS,
    natio: natio,
    land_id: land_id,
    jahr: year,
    data
  }
}

export function LoadFeiertagsKalenderError(natio, land_id, year, error) {
  return {
    type: LOAD_FEIERTAGSKALENDER,
    status: ERROR,
    natio: natio,
    land_id: land_id,
    jahr: year,
    error
  }
}

export function LoadFeiertagsKalender(natio, land_id, year) {
  return (dispatch) => {
    dispatch(LoadFeiertagsKalenderStarted(natio, land_id, year))
    request
      .get(`/api/feiertagskalender/${natio}/${land_id}/${year}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadFeiertagsKalenderError(natio, land_id, year, err))
        } else {
          dispatch(
            LoadLoadFeiertagsKalenderSuccess(
              natio,
              land_id,
              year,
              response.body
            )
          )
        }
      })
  }
}

export const CreatePkal = (IntID, Jahr) => {
  return (dispatch) => {
    request
      .get(`/api/p/${IntID}/create/personalkalender/${Jahr}`)
      .end((err, response) => {
        dispatch({
          type: LOAD_KALENDER,
          data: response.body,
          int_id: IntID,
          year: Jahr
        })
      })
  }
}
export function LoadKalUebersichtQuerySuccess(year, month, data) {
  return {
    type: LOAD_KALUEBERSICHTQUERY,
    status: SUCCESS,
    year,
    month,
    data
  }
}

export function LoadKalUebersichtQuery(year, month, query) {
  return (dispatch) => {
    request
      .get(`/api/kaluebersicht/${year}/${month}/intids`)
      .query(queryToString(query))
      .end((err, response) => {
        dispatch(LoadKalUebersichtQuerySuccess(year, month, response.body))
      })
  }
}

export function LoadKalUebersichtStarted(art, int_id, year, month) {
  return {
    type: LOAD_KALUEBERSICHT2,
    status: STARTED,
    art,
    int_id,
    year,
    month
  }
}
export function LoadKalUebersichtSuccess(art, int_id, year, month, data) {
  return {
    type: LOAD_KALUEBERSICHT2,
    status: SUCCESS,
    art,
    int_id,
    year,
    month,
    data
  }
}
export function LoadKalUebersichtError(art, int_id, year, month, error) {
  return {
    type: LOAD_KALUEBERSICHT2,
    status: ERROR,
    art,
    int_id,
    year,
    month,
    error
  }
}
export function LoadKalUebersicht(art, int_id, year, month) {
  return (dispatch) => {
    dispatch(LoadKalUebersichtStarted(art, year, month))
    request
      .get(`/api/wfl/uebersicht/${art}/${int_id}/${year}/${month}`)

      .end((err, response) => {
        if (err) {
          dispatch(LoadKalUebersichtError(art, int_id, year, month, err))
        } else {
          dispatch(
            LoadKalUebersichtSuccess(art, int_id, year, month, response.body)
          )
        }
      })
  }
}

export function CreateKalenderStarted(mitarbeiter_id) {
  return {
    type: CREATE_KALENDER,
    status: STARTED,
    mitarbeiter_id
  }
}
export function CreateKalenderSuccess(mitarbeiter_id, year, data) {
  return {
    type: CREATE_KALENDER,
    status: SUCCESS,
    mitarbeiter_id,
    year,
    data
  }
}
export function CreateKalenderError(mitarbeiter_id, year, error) {
  return {
    type: CREATE_KALENDER,
    status: ERROR,
    mitarbeiter_id,
    year,
    error
  }
}

export function UpdateKalenderStarted(mitarbeiter_id, year) {
  return {
    type: UPDATE_KALENDER,
    status: STARTED,
    mitarbeiter_id,
    year
  }
}

export function UpdateKalenderSuccess(mitarbeiter_id, year, data) {
  return {
    type: UPDATE_KALENDER,
    status: SUCCESS,
    mitarbeiter_id,
    year,
    data
  }
}

export function UpdateKalenderError(mitarbeiter_id, year, error) {
  return {
    type: UPDATE_KALENDER,
    status: ERROR,
    mitarbeiter_id,
    year,
    error
  }
}

export function UpdateKalender(mitarbeiter_id, year) {
  if (isInfo()) {
    return
  }
  return (dispatch, getState) => {
    let kalenderRows = getState().Kalender[`${mitarbeiter_id}-${year}`]
    if (!kalenderRows) {
      return
    }
    for (let kalender of kalenderRows) {
      if (kalender.__dirty) {
        dispatch(UpdateKalenderStarted(mitarbeiter_id, year))
        request
          .put(
            `/api/p/${mitarbeiter_id}/personalkalender/${year}/${Datetime.fromISO(
              kalender.jahr
            ).month()}`
          )
          // .post(`/api/p/${mitarbeiter_id}/personalkalender/${year}/${month}`
          .send(kalender)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateKalenderError(mitarbeiter_id, year, err))
            } else {
              dispatch(
                UpdateKalenderSuccess(mitarbeiter_id, year, response.body)
              )
            }
          })
      }
      // })
    }
  }
}

export function LoadKalenderStarted(mitarbeiter_id, year) {
  return {
    type: LOAD_KALENDER,
    status: STARTED,
    int_id: mitarbeiter_id,
    jahr: year
  }
}

export function LoadKalenderSuccess(mitarbeiter_id, year, data) {
  return {
    type: LOAD_KALENDER,
    status: SUCCESS,
    int_id: mitarbeiter_id,
    jahr: year,
    data
  }
}

export function LoadKalenderError(mitarbeiter_id, year, error) {
  return {
    type: LOAD_KALENDER,
    status: ERROR,
    int_id: mitarbeiter_id,
    jahr: year,
    error
  }
}

export function EditKalender(int_id, year, month, key, value) {
  return {
    type: EDIT_KALENDER,
    int_id,
    year,
    month,
    key,
    value
  }
}

const FehlzeitDefinition = (state, int_id, value) => {
  return (state.Fehlzeiten.persdefinitionen[int_id] || {})[value] || {}
}

const AllowedFehlzeit = (state, value) => {
  return state.Fehlzeiten.allowed[value] || false
}
const isValid = (state, int_id, value) => {
  const def = FehlzeitDefinition(state, int_id, value)
  if (def.fehlz_id) {
    return AllowedFehlzeit(state, value)
  }
  return false
}
const isUrlaub = (state, int_id, value) => {
  const def = FehlzeitDefinition(state, int_id, value)
  return def.url_ansp_v == "1"
}

const kz567 = (state, int_id, value) => {
  const def = FehlzeitDefinition(state, int_id, value)
  return def.kz_567
}

const kzSoll = (state, int_id, value) => {
  const def = FehlzeitDefinition(state, int_id, value)
  return def.kz_sol == "1"
}

const feitUbs = (state, int_id, value) => {
  const def = FehlzeitDefinition(state, int_id, value)
  return def.feit_ubs == "1"
}

const Feiertag = (state, int_id, y, m, d) => {
  const kal = state.Kalender[`${int_id}-${y}`]
  if (!kal) {
    return ""
  }
  const ftgKey = `t${("0" + d).slice(-2)}`
  const ftg = kal[parseInt(m) - 1][ftgKey]
  return ftg
}

const IsFeiertag = (state, int_id, y, m, d) => {
  const ftg = Feiertag(state, int_id, y, m, d)
  return ftg == "F" || ftg == "H"
}

const Sollzeit = (state, int_id, datum) => {
  const sollRow = state.Kalender.sollzeit[datum]
  const soll = parseFloat(sollRow.sol_min || 0)
  return soll
}

const PreviousValue = (state, int_id, year, month, day, vn) => {
  const kalender = (state.Kalender[`${int_id}-${year}`] || [])[month - 1]

  if (!kalender) {
    return ["", "", ""]
  }
  const key = `t${("0" + day).slice(-2)}${vn}`
  return kalender[key] || ""
}

export const EditKalenderTag = (int_id, year, month, day, vn, value) => {
  return (dispatch, getState) => {
    const state = getState()
    const selection = state.Kalender.selection
    for (let selected of Object.keys(selection)) {
      const [y, m, d, vn] = selected.split("-")
      const key = `t${("0" + d).slice(-2)}${vn}`
      const datum = Datetime.fromValues(
        parseInt(y),
        parseInt(m) - 1,
        parseInt(d)
      )
      const previousValue = PreviousValue(state, int_id, y, m, d, vn)
      const wd = datum.weekday()
      if (value == "") {
        if (isUrlaub(state, int_id, previousValue)) {
          dispatch(IncrementUrlaub(int_id, datum.toISO()))
        }
        dispatch(EditKalender(int_id, parseInt(y), parseInt(m) - 1, key, value))
        continue
      }
      if (!isValid(state, int_id, value)) {
        continue
      }
      if (wd == 6 || wd == 7) {
        if (wd > kz567(state, int_id, value)) {
          continue
        }
      }
      if (kzSoll(state, int_id, value)) {
        const soll = Sollzeit(state, int_id, datum.toISODate())
        if (soll <= 0) {
          continue
        }
      }
      if (!feitUbs(state, int_id, value)) {
        if (IsFeiertag(state, int_id, y, m, d)) {
          continue
        }
      }
      const IsUrlaub = isUrlaub(state, int_id, value)
      const WasUrlaub = isUrlaub(state, int_id, previousValue)
      if (IsUrlaub != WasUrlaub) {
        if (IsUrlaub) {
          dispatch(DecrementUrlaub(int_id, datum.toISO()))
        } else {
          dispatch(IncrementUrlaub(int_id, datum.toISO()))
        }
      }
      dispatch(EditKalender(int_id, parseInt(y), parseInt(m) - 1, key, value))
    }
  }
}

export function LoadKalender(mitarbeiter_id, year) {
  return (dispatch) => {
    if (isInfo()) {
      return request
        .get(`/api/info/personalkalender/${year}`)
        .end((err, response) => {
          if (err) {
            dispatch(LoadKalenderError(mitarbeiter_id, year, err))
          } else {
            dispatch(LoadKalenderSuccess(mitarbeiter_id, year, response.body))
          }
        })
    }
    dispatch(LoadKalenderStarted(year))
    request
      .get(`/api/p/${mitarbeiter_id}/personalkalender/${year}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadKalenderError(mitarbeiter_id, year, err))
        } else {
          dispatch(LoadKalenderSuccess(mitarbeiter_id, year, response.body))
        }
      })
  }
}

export const LoadSollZeitJahr = (IntID, Jahr) => {
  return (dispatch) => {
    return request
      .get(`/api/p/${IntID}/sollzeit/year/${Jahr}`)
      .end((err, response) => {
        dispatch({
          type: LOAD_SOLLZEIT_JAHR,
          int_id: IntID,
          jahr: Jahr,
          data: response.body
        })
      })
  }
}

export function LoadKalenderMonatStarted(int_id, year, month) {
  return {
    type: LOAD_KALENDERMONAT,
    status: STARTED,
    int_id,
    year,
    month
  }
}

export function LoadKalenderMonatSuccess(int_id, year, month, data) {
  return {
    type: LOAD_KALENDERMONAT,
    status: SUCCESS,
    int_id,
    year,
    month,
    data
  }
}

export function LoadKalenderMonatError(int_id, year, month, error) {
  return {
    type: LOAD_KALENDERMONAT,
    status: ERROR,
    int_id,
    year,
    month,
    error
  }
}

export function LoadKalenderMonat(int_id, year, month, query) {
  return (dispatch) => {
    dispatch(LoadKalenderMonatStarted(year, month))
    request
      .get(`/api/p/${int_id}/personalkalender/${year}/${month}`)
      .query(queryToString(query))
      .end((err, response) => {
        if (err) {
          dispatch(LoadKalenderMonatError(int_id, year, month, err))
        } else {
          dispatch(LoadKalenderMonatSuccess(int_id, year, month, response.body))
        }
      })
  }
}

export function EditKalenderMonat(int_id, jahr, changes) {
  return (dispatch, getState) => {
    let allowed = getState().Fehlzeiten.allowed
    for (let [k, v] of Object.entries(changes)) {
      if (v != "" && allowed[v] !== true) {
        return
      }
    }
    dispatch({
      type: EDIT_KALENDERMONAT,
      int_id,
      jahr,
      changes
    })
  }
}

export function UpdateKalenderMonatStarted(int_id, year, month) {
  return {
    type: UPDATE_KALENDERMONAT,
    status: STARTED,
    int_id,
    year,
    month
  }
}

export function UpdateKalenderMonatSuccess(int_id, year, month, data) {
  return {
    type: UPDATE_KALENDERMONAT,
    status: SUCCESS,
    int_id,
    year,
    month,
    data
  }
}

export function UpdateKalenderMonatError(int_id, year, month, error) {
  return {
    type: UPDATE_KALENDERMONAT,
    status: ERROR,
    int_id,
    year,
    month,
    error
  }
}

function getDirtyRows(state) {
  let kalendermonat = state.Kalender.kalendermonat
  let result = []
  for (let row of Object.values(kalendermonat)) {
    if (row !== null) {
      if (row.__dirty || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}

export function UpdateKalenderMonat() {
  return (dispatch, getState) => {
    let rows = getDirtyRows(getState())
    for (let row of rows) {
      if (row.__dirty) {
        let int_id = row.int_id
        let datum = Datetime.fromISO(row.jahr)
        let year = datum.year()
        let month = datum.month()
        let sperrdatum = getState().Sperrdatum.sperrdatum[row.int_id] || {}
        let nosperre = getState().Sperrdatum.nosperre[row.int_id] || {}
        var err = CheckKalender(row, sperrdatum, nosperre)
        if (err !== null) {
          swal({
            title: "Hinweis",
            text: err,
            type: "warning",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok!",
            closeOnConfirm: false
          })
          continue
        }
        dispatch(UpdateKalenderMonatStarted(int_id, year, month))
        request
          .put(`/api/p/${int_id}/personalkalender/${year}/${month}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              swal({
                title: "Hinweis",
                text: "Fehler beim Speichern",
                type: "warning",
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ok!",
                closeOnConfirm: false
              })
              dispatch({ type: "SET_FPROT_RELOAD" })

              dispatch(UpdateKalenderMonatError(int_id, year, month, err))
            } else {
              dispatch(
                UpdateKalenderMonatSuccess(int_id, year, month, response.body)
              )
            }
          })
      }
    }
  }
}

function CheckKalender(row, sperrdatum, nosperre) {
  if (sperrdatum.datum !== "0001-01-01T00:00:00Z" && nosperre.int_id == 0) {
    if (row.jahr < sperrdatum.datum) {
      return "Änderungen vor dem Sperrdatum nicht möglich !"
    }
  }
  return null
}
