import { queryToString } from "app/shared/util/urls"
import { STARTED, SUCCESS } from "app/state/constants"
import request from "superagent"
import { GetVonBis } from "./helpers"
import swal from "sweetalert"

export const LOAD_GENEHMIGUNG = "upl/LOAD_GENEHMIGUNG"
export const CREATE_GENEHMIGUNG = "upl/CREATE_GENEHIGUNG"
export const UPDATE_GENEHMIGUNG = "upl/UPDATE_GENEHMIGUNG"
export const CREATE_GENEHMIGUNGSVORGANG = "upl/CREATE_GENEHMIGUNGSVORGANG"
export const UPDATE_GENEHMIGUNGSVORGANG = "upl/UPDATE_GENEHMIGUNGSVORGANG"

export const LOAD_SAMMEL_UEBERNAHME_DUE = "upl/LOAD_SAMMEL_UEBERNAHME_DUE"
export const LOAD_SAMMEL_UEBERNAHME_PROG = "upl/LOAD_SAMMEL_UEBERNAHME_PROG"
export const LOAD_SAMMEL_UEBERNAHME_COMP = "upl/LOAD_SAMMEL_UEBERNAHME_COMP"

export const BATCH_ACCEPT_GENEHMIGUNG = "upl/BATCH_ACCEPT_GENEHMIGUNG"

var loadTimeOutID = null
export const LoadGenehmigung = (loadCount, query) => {
  return (dispatch, getState) => {
    const { loadVon, setLoadedVon, setLoadedBis, count } = GetVonBis(
      getState(),
      loadCount
    )
    if (count <= 0) {
      return
    }
    clearTimeout(loadTimeOutID)
    return request
      .get(
        `/api/urlaubsplanung/date/query/genehmigungen/D/${count}/${loadVon.toISO()}/`
      )
      .send(queryToString(query))
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_GENEHMIGUNG,
            data: response.body
          })
        }
      })
  }
}

export const CreateGenehmigung = (gene) => {
  return (dispatch) => {
    request
      .post("/api/urlaubsplanung/genehmigungenwork")
      .send(gene)
      .end((err, response) => {
        if (!err) {
          if (gene.id) {
            dispatch({
              type: UPDATE_GENEHMIGUNG,
              data: response.body
            })
          } else {
            dispatch({
              type: CREATE_GENEHMIGUNG,
              data: response.body
            })
          }
        }
      })
  }
}

export const CreateGenehmigungsVorgang = (gene) => {
  return (dispatch) => {
    request
      .post("/api/urlaubsplanung/genehmigungen")
      .send(gene)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: CREATE_GENEHMIGUNGSVORGANG,
            data: response.body
          })
        }
      })
  }
}

export const UpdateGenehmigungsVorgang = (gene) => {
  return (dispatch) => {
    request
      .put("/api/urlaubsplanung/genehmigungen")
      .send(gene)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: UPDATE_GENEHMIGUNGSVORGANG,
            data: response.body
          })
        }
      })
  }
}

export const AcceptGenehmigungsVorgang = (id) => {
  return (dispatch) => {
    request
      .post(`/api/urlaubsplanung/genehmigung/accept/${id}`)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: UPDATE_GENEHMIGUNGSVORGANG,
            data: response.body
          })
        }
      })
  }
}

export const BatchAcceptGenehmigungen = (genehmigungen) => {
  return (dispatch) => {
    genehmigungen.map((g) => {
      dispatch({
        type: BATCH_ACCEPT_GENEHMIGUNG,
        status: STARTED,
        id: g.id
      })
      request
        .post(`/api/urlaubsplanung/genehmigung/accept/${g.id}`)
        .end((err, response) => {
          if (!err) {
            dispatch({
              type: BATCH_ACCEPT_GENEHMIGUNG,
              status: SUCCESS,
              id: g.id
            })
          } else {
            swal(
              "Fehler bei Übernahme",
              "Bei der Übernahme der Fehlzeiten ist ein Fehler augetreten: " +
                (response.body && response.body.message) || "Unbekannter Fehler"
            )
          }
        })
    })
  }
}

export const LoadSammelUebernahme = () => {
  return (dispatch) => {
    dispatch(LoadSammelUebernahmeDue())
    dispatch(LoadSammelUebernahmeCompleted())
    dispatch(LoadSammelUebernahmeProgress())
  }
}

export const LoadSammelUebernahmeDue = () => {
  return (dispatch) => {
    return request
      .get("/api/urlaubsplanung/genehmigung/due")
      .end((err, response) => {
        if (err) {
          return
        }
        dispatch({ type: LOAD_SAMMEL_UEBERNAHME_DUE, data: response.body })
      })
  }
}

export const LoadSammelUebernahmeProgress = () => {
  return (dispatch) => {
    return request
      .get("/api/urlaubsplanung/genehmigung/progress")
      .end((err, response) => {
        if (err) {
          return
        }
        dispatch({ type: LOAD_SAMMEL_UEBERNAHME_PROG, data: response.body })
      })
  }
}

export const LoadSammelUebernahmeCompleted = () => {
  return (dispatch) => {
    return request
      .get("/api/urlaubsplanung/genehmigung/completed")
      .end((err, response) => {
        if (err) {
          return
        }
        dispatch({ type: LOAD_SAMMEL_UEBERNAHME_COMP, data: response.body })
      })
  }
}

///urlaubsplanung/genehmigungen
