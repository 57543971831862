import { WS_EVENT, WS_EVENT_PROCESSED } from "./actions"

const globalIgnored = {
  progress: true
}

export function WSData(state = [], action) {
  switch (action.type) {
    case WS_EVENT:
      if (globalIgnored[action.data.type]) {
        return state
      }
      let nState = [...state, action.data]
      return nState.slice(Math.max(nState.length - 100, 0))
    case WS_EVENT_PROCESSED:
      return state.map((ev) => {
        if (ev == action.data) {
          return { ...ev, processed: true }
        }
        return ev
      })
  }
  return state
}
