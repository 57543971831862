import request from "superagent"
import { GetPlanFreitexte, GetWunschFreitexte } from "../selectors/ftext"

export const EDIT_WUNSCH_FREITEXT = "upl/EDIT_WUNSCH_FREITEXT"
export const EDIT_PLAN_FREITEXT = "upl/EDIT_PLAN_FREITEXT"
export const SAVED_WUNSCH_TEXT = "upl/SAVED_WUNSCH_TEXT"
export const SAVED_PLAN_TEXT = "upl/SAVED_PLAN_TEXT"
export const DELETED_WUNSCH_TEXT = "upl/DELETED_WUNSCH_TEXT"
export const DELETED_PLAN_TEXT = "upl/DELETED_PLAN_TEXT"

export const EditWunschFreitext = (int_id, datum, value) => {
  return {
    type: EDIT_WUNSCH_FREITEXT,
    int_id,
    datum,
    value
  }
}

export const EditPlanFreitext = (int_id, datum, value) => {
  return {
    type: EDIT_PLAN_FREITEXT,
    int_id,
    datum,
    value
  }
}

export const SaveTagesTexte = () => {
  return (dispatch, getState) => {
    const state = getState()
    const wunsch = GetWunschFreitexte(state)
    const plan = GetPlanFreitexte(state)
    for (let w of Object.values(wunsch)) {
      if (!w) {
        continue
      }
      if (w.__dirty) {
        dispatch(SaveWunschTagesText(w))
      }
    }
    for (let w of Object.values(plan)) {
      if (!w) {
        continue
      }
      if (w.__dirty) {
        dispatch(SavePlanTagesText(w))
      }
    }
  }
}

export const SavePlanTagesText = (text) => {
  return (dispatch) => {
    if (text.id) {
      if (text.text === "" || text.text == null) {
        request
          .delete(`/api/urlaubsplanung/texte/plan/${text.id}`)
          .end((err, response) => {
            if (err) {
              return
            }
            dispatch(DeletedPlanText(text.int_id, text.datum.slice(0, 10)))
          })
        return
      }
      request
        .put(`/api/urlaubsplanung/texte/plan/${text.id}`)
        .send(text)
        .end((err, response) => {
          if (err) {
            return
          }
          dispatch(
            SavedPlanText(text.int_id, text.datum.slice(0, 10), response.body)
          )
        })
    } else {
      request
        .post("/api/urlaubsplanung/texte/plan/")
        .send(text)
        .end((err, response) => {
          if (err) {
            return
          }
          dispatch(
            SavedPlanText(text.int_id, text.datum.slice(0, 10), response.body)
          )
        })
    }
  }
}

export const SaveWunschTagesText = (text) => {
  return (dispatch) => {
    if (text.id) {
      if (text.text === "" || text.text == null) {
        request
          .delete(`/api/urlaubsplanung/texte/wunsch/${text.id}`)
          .end((err, response) => {
            if (err) {
              return
            }
            dispatch(DeletedWunschText(text.int_id, text.datum.slice(0, 10)))
          })
        return
      }
      request
        .put(`/api/urlaubsplanung/texte/wunsch/${text.id}`)
        .send(text)
        .end((err, response) => {
          if (err) {
            return
          }
          dispatch(
            SavedWunschText(text.int_id, text.datum.slice(0, 10), response.body)
          )
        })
    } else {
      request
        .post("/api/urlaubsplanung/texte/wunsch/")
        .send(text)
        .end((err, response) => {
          if (err) {
            return
          }
          dispatch(
            SavedWunschText(text.int_id, text.datum.slice(0, 10), response.body)
          )
        })
    }
  }
}

const SavedWunschText = (int_id, datum, text) => {
  return {
    type: SAVED_WUNSCH_TEXT,
    int_id,
    datum,
    text
  }
}

const DeletedWunschText = (int_id, datum) => {
  return {
    type: DELETED_WUNSCH_TEXT,
    int_id,
    datum
  }
}

const SavedPlanText = (int_id, datum, text) => {
  return {
    type: SAVED_PLAN_TEXT,
    int_id,
    datum,
    text
  }
}

const DeletedPlanText = (int_id, datum) => {
  return {
    type: DELETED_PLAN_TEXT,
    int_id,
    datum
  }
}
