import { LOAD_FZ_DEF, LOAD_SOLLZEIT, LOAD_ALLOWED_FZ_DIENST } from "../actions"

import { LOAD_VVA } from "../actions/fehlzeiten"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"
import { arrayToObjectFn } from "app/shared/util/conversion"

export function FehlzeitDefinitionen(state = [], action) {
  switch (action.type) {
    case LOAD_FZ_DEF:
      return action.data
  }
  return state
}

export function AllowedFehlzeitenDienst(state = {}, action) {
  switch (action.type) {
    case LOAD_ALLOWED_FZ_DIENST:
      return action.data
  }
  return state
}

export function Sollzeit(state = {}, action) {
  switch (action.type) {
    case LOAD_SOLLZEIT:
      let s = { ...state }
      for (let [key, values] of Object.entries(action.data || {})) {
        s[key] = {
          ...arrayToObjectFn(values, (v) => v.datum.slice(0, 10)),
          ...(s[key] || {})
        }
      }
      return s
  }
  return state
}

export function VVA(state = {}, action) {
  switch (action.type) {
    case LOAD_VVA:
      let s = { ...state }
      for (let [key, values] of Object.entries(action.data)) {
        s[key] = {
          ...arrayToObjectFn(values, (v) => v.datum.slice(0, 10)),
          ...(s[key] || {})
        }
      }
      return s
  }
  return state
}
