import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"
import {
  LOAD_GROUP,
  LOAD_GROUPEMPF,
  EDIT_TEXT,
  NEW_GROUP,
  NEW_GROUPEMPF,
  UPDATE_GROUP,
  EDIT_GROUP_EMPF,
  DELETE_GROUP,
  DELETE_GROUPEMPF,
  LOAD_GROUPZUORD,
  NEW_GROUPZUORD,
  DELETE_GROUPZUORD,
  EDIT_GROUP_ZUORD,
  LOAD_GROUPGROUP_ZUORD,
  LOAD_GROUPGROUP_ZUORD_ID,
  EDIT_GROUPGROUP_ZUORD,
  ADD_GROUPGROUP_ZUORD_ROW,
  DELETED_GROUPGROUP_ZUORD_ROW,
  DELETE_GROUPGROUP_ZUORD_ROW,
  UPDATE_GROUPGROUP_ZUORD,
  NEW_GROUPGROUPZUORD
} from "./actions"
import { arrayToObject } from "app/shared/util/conversion"

export function SecurityGroup(
  state = {
    group: {},
    groupempf: {},
    groupzuord: {},
    groupgroupzuord: [],
    data: {}
  },
  action
) {
  switch (action.type) {
    case NEW_GROUP:
      if (action.status == SUCCESS) {
        return {
          ...state,
          group: {
            ...state.group,
            [action.data.group_id]: action.data
          }
        }
      }
      return state
    case DELETE_GROUP:
      if (action.status == SUCCESS) {
        let nState = { ...state.group }
        delete nState[action.group_id]
        return {
          ...state,
          group: nState
        }
      }
      return state

    case EDIT_TEXT:
      // console.log(state, action)
      return {
        ...state,
        group: {
          ...state.group,
          [action.group_id]: {
            ...state.group[action.group_id],
            ...action.changes,
            __dirty: true
          }
        }
      }
    case UPDATE_GROUP:
      if (action.status == SUCCESS) {
        return {
          ...state,
          group: state.group.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_GROUP:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            isFetching: false,
            error: null,
            group: arrayToObject(action.data, "group_id")
          }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }

    case LOAD_GROUPEMPF:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            groupempf: {
              ...state.groupempf,
              [action.group_id]: action.data
            }
          }
        case ERROR:
          return {
            ...state,
            groupempf: {
              ...state.groupempf,
              isFetching: false
            }
          }
        case STARTED:
          return {
            ...state,
            groupempf: { ...state.groupempf, isFetching: true }
          }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
    case NEW_GROUPEMPF:
      if (action.status == SUCCESS) {
        return {
          ...state,
          groupempf: {
            ...state.groupempf,
            [action.data.group_id]: [
              ...(state.groupempf[action.data.group_id] || []),
              action.data
            ]
          }
        }
      }
      return state
    case DELETE_GROUPEMPF:
      if (action.status == SUCCESS) {
        // console.log(state.groupempf[action.group_id])
        let nState = [...state.groupempf[action.group_id]]
        nState = nState.filter((r) => r.id != action.id)
        return {
          ...state,
          groupempf: {
            ...state.groupempf,
            [action.group_id]: nState
          }
        }
      }
      return state

    case EDIT_GROUP_EMPF:
      return {
        ...state,
        groupempf: {
          ...state.groupempf,
          [action.group_id]: state.groupempf[action.group_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }

    case LOAD_GROUPZUORD:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            groupzuord: {
              ...state.groupzuord,
              [action.group_id]: action.data
            }
          }
        case ERROR:
          return {
            ...state,
            groupzuord: {
              ...state.groupzuord,
              isFetching: false
            }
          }
        case STARTED:
          return {
            ...state,
            groupzuord: { ...state.groupzuord, isFetching: true }
          }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
    case NEW_GROUPZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          groupzuord: {
            ...state.groupzuord,
            [action.data.group_id]: [
              ...(state.groupzuord[action.data.group_id] || []),
              action.data
            ]
          }
        }
      }
      return state

    case DELETE_GROUPZUORD:
      if (action.status == SUCCESS) {
        // console.log(state.groupzuord[action.group_id])
        let nState = [...state.groupzuord[action.group_id]]
        nState = nState.filter((r) => r.id != action.id)
        return {
          ...state,
          groupzuord: {
            ...state.groupzuord,
            [action.group_id]: nState
          }
        }
      }
      return state
    case EDIT_GROUP_ZUORD:
      return {
        ...state,
        groupzuord: {
          ...state.groupzuord,
          [action.group_id]: {
            ...state.groupzuord[action.group_id],
            ...action.changes,
            __dirty: true
          }
        }
      }
    case LOAD_GROUPGROUP_ZUORD:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            groupgroupzuord: action.data,
            isLoading: false,
            error: null
          }
      }
      break

    case LOAD_GROUPGROUP_ZUORD_ID:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            groupgroupzuord: {
              ...state.groupgroupzuord,
              [action.group_id]: action.data
            },
            isLoading: false,
            error: null
          }
      }
      break
    case UPDATE_GROUPGROUP_ZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          groupgroupzuord: {
            ...state.groupgroupzuord,
            [action.group_id]: state.groupgroupzuord[action.group_id].map(
              (row) => {
                if (row.id == action.id) {
                  return action.data
                }
                return row
              }
            )
          }
        }
      }
      return state
    case EDIT_GROUPGROUP_ZUORD:
      // console.log(state, action)
      return {
        ...state,
        groupgroupzuord: {
          ...state.groupgroupzuord,
          [action.group_id]: state.groupgroupzuord[action.group_id].map(
            (row) => {
              if (row.id == action.id) {
                return { ...row, ...action.changes, __dirty: true }
              }
              return row
            }
          )
        }
      }

    case ADD_GROUPGROUP_ZUORD_ROW:
      // console.log(state, action)
      return {
        ...state,
        groupgroupzuord: {
          ...state.groupgroupzuord,
          [action.group_id]: [
            ...state.groupgroupzuord[action.group_id],
            {
              id: uuidv4(),
              __new: true,
              group_id: action.group_id
            }
          ]
        }
      }
    // case DELETE_GROUPGROUP_ZUORD_ROW:
    //   return {
    //     ...state,
    //     groupgroupzuord: [
    //       ...state.groupgroupzuord.map((row) => {
    //         if (row.id == action.id) {
    //           return { ...row, __deleted: true }
    //         }
    //         return row
    //       })
    //     ]
    //   }

    case DELETED_GROUPGROUP_ZUORD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          groupgroupzuord: {
            ...state.groupgroupzuord.filter((row) => {
              if (row.id == action.id) {
                return false
              }
              return true
            })
          }
        }
      }
      return state
    case DELETE_GROUPGROUP_ZUORD_ROW:
      return {
        ...state,
        groupgroupzuord: {
          ...state.groupgroupzuord,
          [action.group_id]: state.groupgroupzuord[action.group_id].map(
            (row) => {
              if (row.id == action.id) {
                return { ...row, __deleted: true }
              }
              return row
            }
          )
        }
      }
  }
  return state
}
