import {
  LOAD_FAVORITEN,
  SET_FAVORIT,
  UNSET_FAVORIT
} from "../actions/favoriten"
import { ERROR, STARTED, SUCCESS } from "app/state/constants"

export function Favoriten(state = [], action) {
  switch (action.type) {
    case LOAD_FAVORITEN:
      if (action.status == STARTED) {
        return []
      } else if (action.status == ERROR) {
        return []
      } else {
        return action.data
      }
    case SET_FAVORIT:
      if (action.status == SUCCESS) {
        return [...state, action.data]
      }
      return state
    case UNSET_FAVORIT:
      if (action.status == SUCCESS) {
        return [...state].filter(row => {
          return row.dienst_id !== action.data
        })
      }
  }
  return state
}
