import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"
import {
  LOAD_USERS,
  LOAD_ROLES,
  LOAD_USER_ROLES,
  ADD_USER_ROLE,
  REMOVE_USER_ROLE,
  EDIT_USER,
  SELECT_USER,
  SAVE_USER,
  NEW_USER,
  LOAD_VALUE_PERM,
  LOAD_USER_PERM,
  ADD_USER_PERM,
  REMOVE_USER_PERM,
  SET_USER_PERM_VALUE,
  SAVE_USER_PERMS,
  NEW_VALUES,
  LOAD_SPERRDATUM,
  ADD_SPERRDATUM_ROW,
  DELETE_SPERRDATUM_ROW,
  DELETED_SPERRDATUM_ROW,
  EDIT_SPERRDATUM,
  UPDATE_SPERRDATUM,
  LOAD_SPERRDATUMUSER,
  ADD_SPERRDATUMUSER_ROW,
  DELETE_SPERRDATUMUSER_ROW,
  DELETED_SPERRDATUMUSER_ROW,
  EDIT_SPERRDATUMUSER,
  UPDATE_SPERRDATUMUSER,
  LOAD_SPERRDAT,
  UPDATE_SPERRDAT,
  EDIT_SPERRDAT,
  LOAD_STARTDATUM,
  EDIT_STARTDATUM,
  UPDATE_STARTDATUM
} from "./actions"
// import {
//   SELECT_ROLE,
//   LOAD_ROLES_PERM,
//   ADD_ROLESPERM_ROW,
//   EDIT_ROLESPERM,
//   NEW_ROLE,
//   SAVE_ROLE
// } from "./role_actions"
import { arrayToObject } from "app/shared/util/conversion"
import Empfaenger from "./empfaenger_reducers"
import Vertretung from "./vertretung_reducers"
import EmpfaengerZuord from "./empfaengerzuord_reducers"
import Roles from "./role_reducers"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"
// import Group from "./group_reducers_alt"

const initialState = {
  selectedUser: null,
  users: {
    isFetching: false,
    error: null,
    data: {},
    allIds: []
  },
  roles: {
    selectedRole: null,
    isFetching: false,
    error: null,
    data: {},
    allIds: []
  },
  // group: {
  //   // selectedGroup: null,
  //   // isFetching: false,
  //   error: null,
  //   data: {},
  //   groupempf: { data: {} }
  //   // allIds: []
  // },
  userRoles: {},
  valuePerms: {},
  userPerms: {},
  rolesPerm: {
    data: {}
  },
  wegezeit: [],
  sperrdatum: [],
  sperrdatumuser: [],
  sperrdat: {},
  startdatum: {}
}

export function Security(state = initialState, action) {
  switch (action.type) {
    case LOAD_STARTDATUM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            startdatum: action.data
          }
      }
      break
    case EDIT_STARTDATUM:
      return {
        ...state,
        startdatum: {
          ...state.startdatum,
          tab_wert: action.changes,
          __dirty: true
        }
      }
    case UPDATE_STARTDATUM:
      return {
        ...state,
        startdatum: {
          ...state.startdatum,
          [action.tab_wert]: action.data
        }
      }
    case LOAD_SPERRDAT:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            sperrdat: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_SPERRDAT:
      return {
        ...state,
        sperrdat: {
          ...state.sperrdat,
          datum: action.changes,
          __dirty: true
          // {
          //   ...state.sperrdat[action.datum],
          //   // datum: action.datum,
          //   ...action.changes,
          //   __dirty: true
          // }
        }
      }
    case UPDATE_SPERRDAT:
      return {
        ...state,
        sperrdat: {
          ...state.sperrdat,
          [action.datum]: action.data
        }
      }
    case LOAD_SPERRDATUMUSER:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            sperrdatumuser: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_SPERRDATUMUSER:
      if (action.status == SUCCESS) {
        return {
          ...state,
          sperrdatumuser: state.sperrdatumuser.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_SPERRDATUMUSER:
      return {
        ...state,
        sperrdatumuser: state.sperrdatumuser.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case DELETED_SPERRDATUMUSER_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          sperrdatumuser: state.sperrdatumuser.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_SPERRDATUMUSER_ROW:
      return {
        ...state,
        sperrdatumuser: state.sperrdatumuser.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_SPERRDATUMUSER_ROW:
      return {
        ...state,
        sperrdatumuser: [
          ...state.sperrdatumuser,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            abr_kreis: 1,
            abt_id: " ",
            trm_id: "RRS",
            abr_kreis: action.abr_kreis,
            user_id: action.user_id,
            ...(action.values || {})
          }
        ]
      }
    case LOAD_SPERRDATUM:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            sperrdatum: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_SPERRDATUM:
      if (action.status == SUCCESS) {
        return {
          ...state,
          sperrdatum: state.sperrdatum.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_SPERRDATUM:
      return {
        ...state,
        sperrdatum: state.sperrdatum.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case DELETED_SPERRDATUM_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          sperrdatum: state.sperrdatum.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_SPERRDATUM_ROW:
      return {
        ...state,
        sperrdatum: state.sperrdatum.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_SPERRDATUM_ROW:
      return {
        ...state,
        sperrdatum: [
          ...state.sperrdatum,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            datum: Datetime.now().startOf("day").toISO(),
            abr_kreis: action.abr_kreis,
            ...(action.values || {})
          }
        ]
      }

    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.user_id
      }
    case NEW_USER:
      return {
        ...state,
        selectedUser: action.user_id,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [action.user_id]: {
              user_id: action.user_id,
              user_name: "",
              full_name: "",
              user_type: 1,
              user_pass: "PASS",
              email: "",
              int_id: null,
              __new: true,
              __dirty: true
            }
          },
          allIds: [...state.users.allIds, action.user_id]
        },
        userRoles: {
          ...state.userRoles,
          [action.user_id]: {
            isFetching: false,
            error: null,
            __dirty: false,
            roles: []
          }
        },
        userPerms: {
          ...state.userPerms,
          [action.user_id]: {
            isFetching: false,
            error: null,
            __dirty: false,
            permissions: []
          }
        }
      }
    case EDIT_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [action.user_id]: {
              ...state.users.data[action.user_id],
              ...action.changes,
              __dirty: true
            }
          }
        }
      }
    case SAVE_USER:
      var { [action.user_id]: deletedItem, ...rest } = state.users.data
      var wasSelected = state.selectedUser === action.user_id
      var selectedUser = state.selectedUser
      if (wasSelected) {
        selectedUser = (action.user && action.user.user_id) || null
      }
      if (action.user == null) {
        return {
          ...state,
          users: {
            ...state.users,
            data: {
              ...rest
            },
            allIds: [
              ...state.users.allIds.filter((id) => id !== action.user_id)
            ]
          },
          selectedUser: selectedUser
        }
      }
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...rest,
            [action.user.user_id]: action.user
          },
          allIds: [
            ...state.users.allIds.filter((id) => id !== action.user_id),
            action.user.user_id
          ]
        },
        selectedUser: selectedUser
      }

    case LOAD_USERS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            users: {
              ...state.users,
              isFetching: false,
              error: null,
              data: arrayToObject(action.data, "user_id"),
              allIds: action.data.map((user) => user.user_id)
            }
          }
        case ERROR:
          return {
            ...state,
            users: { ...state.users, isFetching: false, error: action.error }
          }
        case STARTED:
          return { ...state, users: { ...state.users, isFetching: true } }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
    case LOAD_ROLES:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            roles: {
              ...state.roles,
              isFetching: false,
              error: null,
              data: arrayToObject(action.data, "role_id"),
              allIds: action.data.map((role) => role.role_id)
            }
          }
        case ERROR:
          return {
            ...state,
            roles: { ...state.roles, isFetching: false, error: action.error }
          }
        case STARTED:
          return { ...state, roles: { ...state.roles, isFetching: true } }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
    case LOAD_USER_ROLES:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            userRoles: {
              ...state.userRoles,
              [action.user_id]: {
                ...state.userRoles[action.user_id],
                isFetching: false,
                error: null,
                __dirty: false,
                roles: action.data.map((role) => role.role_id)
              }
            }
          }
        case ERROR:
          return {
            ...state,
            userRoles: {
              ...state.userRoles,
              [action.user_id]: {
                ...state.userRoles[action.user_id],
                isFetching: false,
                __dirty: false,
                error: action.error
              }
            }
          }
        case STARTED:
          return {
            ...state,
            userRoles: {
              ...state.userRoles,
              [action.user_id]: {
                ...state.userRoles[action.user_id],
                isFetching: true,
                error: null,
                __dirty: false,
                roles: []
              }
            }
          }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
    case ADD_USER_ROLE:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          [action.user_id]: {
            ...state.userRoles[action.user_id],
            isFetching: true,
            error: null,
            __dirty: true,
            roles: [...state.userRoles[action.user_id].roles, action.role_id]
          }
        }
      }
    case REMOVE_USER_ROLE:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          [action.user_id]: {
            ...state.userRoles[action.user_id],
            isFetching: true,
            error: null,
            __dirty: true,
            roles: state.userRoles[action.user_id].roles.filter((role_id) => {
              return role_id !== action.role_id
            })
          }
        }
      }
    case LOAD_VALUE_PERM:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            valuePerms: arrayToObject(action.data, "id")
          }
      }
      break
    case ADD_USER_PERM:
      return {
        ...state,
        userPerms: {
          ...state.userPerms,
          [action.user_id]: {
            ...state.userPerms[action.user_id],
            isFetching: false,
            error: null,
            __dirty: true,
            permissions: [
              ...state.userPerms[action.user_id].permissions,
              { value_id: action.value_id, user_id: action.user_id, value: 0 }
            ]
          }
        }
      }
    case REMOVE_USER_PERM:
      return {
        ...state,
        userPerms: {
          ...state.userPerms,
          [action.user_id]: {
            ...state.userPerms[action.user_id],
            isFetching: false,
            error: null,
            __dirty: true,
            permissions: state.userPerms[action.user_id].permissions.filter(
              (perm) => {
                return perm.value_id !== action.value_id
              }
            )
          }
        }
      }
    case SET_USER_PERM_VALUE:
      return {
        ...state,
        userPerms: {
          ...state.userPerms,
          [action.user_id]: {
            ...state.userPerms[action.user_id],
            isFetching: false,
            error: null,
            __dirty: true,
            permissions: state.userPerms[action.user_id].permissions.map(
              (perm) => {
                if (perm.value_id !== action.value_id) {
                  return perm
                }
                return { ...perm, value_permission: action.value }
              }
            )
          }
        }
      }
    case NEW_VALUES:
      if (action.status == SUCCESS) {
        return {
          ...state,
          valuePerms: {
            ...state.valuePerms,
            [action.data.value_id]: {
              // ...(state.valuePerms[action.data.value_id] || {}),
              ...action.data
            }
          }
        }
      }
      return state
    case LOAD_USER_PERM:
    case SAVE_USER_PERMS:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            userPerms: {
              ...state.userPerms,
              [action.user_id]: {
                ...state.userPerms[action.user_id],
                isFetching: false,
                error: null,
                __dirty: false,
                permissions: action.data
              }
            }
          }
        case ERROR:
          return {
            ...state,
            userPerms: {
              ...state.userPerms,
              [action.user_id]: {
                ...state.userPerms[action.user_id],
                isFetching: false,
                error: action.error
              }
            }
          }
        case STARTED:
          return {
            ...state,
            userPerms: {
              ...state.userPerms,
              [action.user_id]: {
                ...state.userPerms[action.user_id],
                isFetching: true,
                error: null,
                __dirty: false
              }
            }
          }
        default:
          throw new Error(`Invalid Action Status ${action.status}`)
      }
  }
  return Roles(
    {
      ...state,
      empfaenger: Empfaenger(state.empfaenger, action),
      vertretung: Vertretung(state.vertretung, action),
      empfaengerzuord: EmpfaengerZuord(state.empfaengerzuord, action)
      // group: Group(state.group, action)
    },
    action
  )
  //return state
}
