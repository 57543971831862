import request from "superagent"

export const LOAD_ALLOWED_FEHLZEITEN = "upl/LOAD_ALLOWED_FEHLZEITEN"

export const LoadAllowedFehlzeiten = () => {
  return (dispatch) => {
    request.get("/api/urlaubsplanung/fehlzeiten").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_ALLOWED_FEHLZEITEN,
          data: response.body
        })
      }
    })
  }
}
