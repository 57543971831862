import { STARTED, SUCCESS } from "app/state/constants"
import request from "superagent"

export const LOAD_ZEITTAG = "ZEITTAG/LOAD_ZEITTAG"
export const LOAD_ZEITTAG_DEF = "ZEITTAG/LOAD_ZEITTAG_DEF"
export const LOAD_ZEITTAG_PROPS = "ZEITTAG/LOAD_ZEITTAG_PROPS"
export const EDIT_DP_SONMOD_TAG = "ZEITTAG/EDIT_DP_SONMOD_TAG"
export const LOAD_DP_SONMOD_TAG = "ZEITTAG/LOAD_DP_SONMOD_TAG"
export const ADD_DP_SONMOD_TAG = "ZEITTAG/ADD_DP_SONMOD_TAG"
export const REMOVE_DP_SONMOD_TAG = "ZEITTAG/REMOVE_DP_SONMOD_TAG"

export const LoadZeitTag = (id) => {
  return (dispatch, getState) => {
    request.get(`/api/zeittags/tag/${id}`).end((err, response) => {
      dispatch({ type: LOAD_ZEITTAG, id: id, data: response.body })
    })
  }
}

export const LoadZeitTagDef = () => {
  return (dispatch, getState) => {
    request.get("/api/zeittags/def").end((err, response) => {
      dispatch({ type: LOAD_ZEITTAG_DEF, data: response.body })
    })
  }
}

export const LoadZeitTagProps = (id) => {
  return (dispatch, getState) => {
    if (getState().ZeitTags.tagprops[id]) {
      return
    }
    dispatch({ type: LOAD_ZEITTAG_PROPS, status: STARTED, id: id })
    request.get(`/api/zeittags/props/${id}`).end((err, response) => {
      dispatch({
        type: LOAD_ZEITTAG_PROPS,
        status: SUCCESS,
        id: id,
        data: response.body
      })
    })
  }
}

export const LoadDpSonModTags = (id) => {
  return (dispatch, getState) => {
    request.get(`/api/zeittags/dpsonmod/${id}`).end((err, response) => {
      dispatch({ type: LOAD_DP_SONMOD_TAG, mod_id: id, data: response.body })
    })
  }
}

export const DeleteDpSonModTags = (id) => {
  return (dispatch, getState) => {
    request.delete(`/api/zeittags/dpsonmod/${id}`).end((err, response) => {
      //dispatch({ type: LOAD_DP_SONMOD_TAG, mod_id: id, data: response.body })
    })
  }
}

export const UpdateDpSonModTags = (data) => {
  return (dispatch, getState) => {
    request
      .put(`/api/zeittags/dpsonmod/`)
      .send(data)
      .end((err, response) => {
        dispatch(EditDpSonModTag(data.sonmod_id, data.id, response.body))
      })
  }
}

export const CreateDpSonModTags = (data) => {
  return (dispatch, getState) => {
    let { id, ...rest } = data
    request
      .post(`/api/zeittags/dpsonmod/`)
      .send(rest)
      .end((err, response) => {
        dispatch(EditDpSonModTag(data.sonmod_id, data.id, response.body))
      })
  }
}

export const EditDpSonModTag = (mod_id, id, data) => {
  return {
    type: EDIT_DP_SONMOD_TAG,
    id,
    mod_id,
    data: data
  }
}

export const AddDpSonModTag = (mod_id) => {
  return {
    type: ADD_DP_SONMOD_TAG,
    mod_id
  }
}

export const RemoveDpSonModTag = (mod_id, id) => {
  return {
    type: REMOVE_DP_SONMOD_TAG,
    id,
    mod_id
  }
}
