import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import {
  LOAD_NEGATIVERFASSUNG,
  ADD_NEGATIVERFASSUNG_ROW,
  DELETE_NEGATIVERFASSUNG_ROW,
  DELETED_NEGATIVERFASSUNG_ROW,
  EDIT_NEGATIVERFASSUNG,
  UPDATE_NEGATIVERFASSUNG,
  LOAD_URLAUBSANSPRUCH,
  ADD_URLAUBSANSPRUCH_ROW,
  DELETE_URLAUBSANSPRUCH_ROW,
  DELETED_URLAUBSANSPRUCH_ROW,
  EDIT_URLAUBSANSPRUCH,
  UPDATE_URLAUBSANSPRUCH,
  LOAD_FEHLZEITDEF,
  ADD_FEHLZEITDEF_ROW,
  DELETE_FEHLZEITDEF_ROW,
  DELETED_FEHLZEITDEF_ROW,
  EDIT_FEHLZEITDEF,
  UPDATE_FEHLZEITDEF,
  LOAD_FEIERTAGDEF,
  ADD_FEIERTAGDEF_ROW,
  DELETE_FEIERTAGDEF_ROW,
  DELETED_FEIERTAGDEF_ROW,
  EDIT_FEIERTAGDEF,
  UPDATE_FEIERTAGDEF,
  UPDATE_BEFEHLSTASTENDEF,
  LOAD_BEFEHLSTASTENDEF,
  EDIT_BEFEHLSTASTENDEF,
  UPDATE_BEFEHLSTASTENTEXT,
  LOAD_BEFEHLSTASTENTEXT,
  EDIT_BEFEHLSTASTENTEXT,
  LOAD_WEGEZEIT,
  ADD_WEGEZEIT_ROW,
  DELETE_WEGEZEIT_ROW,
  DELETED_WEGEZEIT_ROW,
  EDIT_WEGEZEIT,
  UPDATE_WEGEZEIT
} from "./actions"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"

import { v4 as uuidv4 } from "uuid"

export default function Verwaltung(
  state = {
    negativerfassung: [],
    urlaubsanspruch: [],
    fehlzeitdef: [],
    feiertagdef: [],
    befehlstastendef: [],
    befehlstastentext: []
  },
  action
) {
  switch (action.type) {
    case UPDATE_WEGEZEIT:
      if (action.status == SUCCESS) {
        return {
          ...state,
          wegezeit: state.wegezeit.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_WEGEZEIT:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            wegezeit: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_WEGEZEIT_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          wegezeit: state.wegezeit.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_WEGEZEIT_ROW:
      return {
        ...state,
        wegezeit: state.wegezeit.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_WEGEZEIT_ROW:
      return {
        ...state,
        wegezeit: [
          ...state.wegezeit,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            abr_kreis: action.abr_kreis,
            ab_datum: Datetime.now().startOf("day").toISO(),
            int_id: 0,
            ...(action.values || {})
          }
        ]
      }
    case EDIT_WEGEZEIT:
      return {
        ...state,
        wegezeit: state.wegezeit.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }

    case UPDATE_BEFEHLSTASTENTEXT:
      if (action.status == SUCCESS) {
        return {
          ...state,
          befehlstastentext: state.befehlstastentext.map((row) => {
            if (row.bef_tas == action.bef_tas) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_BEFEHLSTASTENTEXT:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            befehlstastentext: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_BEFEHLSTASTENTEXT:
      return {
        ...state,
        befehlstastentext: state.befehlstastentext.map((row) => {
          if (row.bef_tas == action.bef_tas) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case UPDATE_BEFEHLSTASTENDEF:
      if (action.status == SUCCESS) {
        return {
          ...state,
          befehlstastendef: state.befehlstastendef.map((row) => {
            if (row.bef_tas == action.bef_tas) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_BEFEHLSTASTENDEF:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            befehlstastendef: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_BEFEHLSTASTENDEF:
      return {
        ...state,
        befehlstastendef: state.befehlstastendef.map((row) => {
          if (row.bef_tas == action.bef_tas) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }

    case UPDATE_NEGATIVERFASSUNG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          negativerfassung: state.negativerfassung.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_NEGATIVERFASSUNG:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            negativerfassung: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_NEGATIVERFASSUNG_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          negativerfassung: state.negativerfassung.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_NEGATIVERFASSUNG_ROW:
      return {
        ...state,
        negativerfassung: state.negativerfassung.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_NEGATIVERFASSUNG_ROW:
      return {
        ...state,
        negativerfassung: [
          ...state.negativerfassung,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            abr_kreis: action.abr_kreis,
            von_dat: Datetime.now().startOf("day").toISO(),
            int_id: 0,
            ...(action.values || {})
          }
        ]
      }
    case EDIT_NEGATIVERFASSUNG:
      return {
        ...state,
        negativerfassung: state.negativerfassung.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case UPDATE_FEIERTAGDEF:
      if (action.status == SUCCESS) {
        return {
          ...state,
          feiertagdef: state.feiertagdef.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_FEIERTAGDEF:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            feiertagdef: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_FEIERTAGDEF_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          feiertagdef: state.feiertagdef.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_FEIERTAGDEF_ROW:
      return {
        ...state,
        feiertagdef: state.feiertagdef.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_FEIERTAGDEF_ROW:
      return {
        ...state,
        feiertagdef: [
          ...state.feiertagdef,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            abr_kreis: 0,
            kal_kz: " ",
            ...(action.values || {})
          }
        ]
      }
    case EDIT_FEIERTAGDEF:
      return {
        ...state,
        feiertagdef: state.feiertagdef.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case UPDATE_FEHLZEITDEF:
      if (action.status == SUCCESS) {
        return {
          ...state,
          fehlzeitdef: state.fehlzeitdef.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_FEHLZEITDEF:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            fehlzeitdef: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_FEHLZEITDEF_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          fehlzeitdef: state.fehlzeitdef.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_FEHLZEITDEF_ROW:
      return {
        ...state,
        fehlzeitdef: state.fehlzeitdef.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_FEHLZEITDEF_ROW:
      return {
        ...state,
        fehlzeitdef: [
          ...state.fehlzeitdef,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            abr_kreis: 0,
            kal_kz: " ",
            kz_567: 5,
            ...(action.values || {})
          }
        ]
      }
    case EDIT_FEHLZEITDEF:
      return {
        ...state,
        fehlzeitdef: state.fehlzeitdef.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case UPDATE_URLAUBSANSPRUCH:
      if (action.status == SUCCESS) {
        return {
          ...state,
          urlaubsanspruch: state.urlaubsanspruch.map((row) => {
            if (row.id == action.id) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case LOAD_URLAUBSANSPRUCH:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            urlaubsanspruch: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case DELETED_URLAUBSANSPRUCH_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          urlaubsanspruch: state.urlaubsanspruch.filter((row) => {
            if (row.id == action.id) {
              return false
            }
            return true
          })
        }
      }
      return state
    case DELETE_URLAUBSANSPRUCH_ROW:
      return {
        ...state,
        urlaubsanspruch: state.urlaubsanspruch.map((row) => {
          if (row.id == action.id) {
            return { ...row, __deleted: true }
          }
          return row
        })
      }
    case ADD_URLAUBSANSPRUCH_ROW:
      return {
        ...state,
        urlaubsanspruch: [
          ...state.urlaubsanspruch,
          {
            id: uuidv4(),
            __new: true,
            mand_id: 1,
            firm_id: 1,
            abr_kreis: action.abr_kreis,
            url_abalt: 0,
            url_grans: 0,
            bet_zug: 0,
            tar_grp: " ",
            ...(action.values || {})
          }
        ]
      }
    case EDIT_URLAUBSANSPRUCH:
      return {
        ...state,
        urlaubsanspruch: state.urlaubsanspruch.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
  }
  return state
}
