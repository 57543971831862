import request from "superagent"
import * as dateFns from "date-fns"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import Datetime from "../dateutil/index"
export const FETCH_DROPDOWN = "FETCH_DROPDOWN"
export const CLEAR_DROPDOWNS = "CLEAR_DROPDOWNS"

export function fetchDropDownSuccess(key, data) {
  return {
    type: FETCH_DROPDOWN,
    status: SUCCESS,
    key,
    data
  }
}

export function fetchDropDownError(key, error) {
  return {
    type: FETCH_DROPDOWN,
    status: ERROR,
    key,
    error,
    _handleInApp: true
  }
}

export function fetchDropDownStarted(key) {
  return {
    type: FETCH_DROPDOWN,
    status: STARTED,
    key
  }
}

export function ClearDropDowns() {
  return {
    type: CLEAR_DROPDOWNS
  }
}

export function fetchDropDown(
  key,
  {
    extra,
    year,
    month,
    day,
    useFilter,
    qkey,
    ressourceGroup,
    ressourceName,
    force
  }
) {
  return function (dispatch, getState) {
    let datum = new Date()
    if (year) {
      datum.setFullYear(year)
    }
    if (month) {
      datum.setMonth(parseInt(month) - 1)
    }
    if (day) {
      datum.setDate(day)
    } else {
      datum.setDate(1)
    }
    let dataKey =
      key +
      "/" +
      (extra || "") +
      (year || "") +
      (month || "") +
      (day || "") +
      (useFilter ? "[filter]" : "")
    if (qkey) {
      dataKey = qkey
    }
    let data = getState().DropDown[dataKey]
    if (data && !force) {
      return
    }
    let userID = getState().currentUser.user.user_id
    dispatch(fetchDropDownStarted(dataKey))
    request
      .get("/api/dropdown")
      .query({
        name: key,
        value: extra,
        useFilter: useFilter ? "1" : "0",
        userID: useFilter ? userID : 0,
        ressourceGroup: ressourceGroup,
        ressourceName: ressourceName,
        datum: Datetime.fromJSDate(datum).toISO() // dateFns.format(datum, "YYYY-MM-DD") + "T00:00:00Z"
      })
      .end((err, response) => {
        if (err) {
          dispatch(fetchDropDownError(dataKey, err))
        } else {
          dispatch(fetchDropDownSuccess(dataKey, response.body))
        }
      })
  }
}

export function fetchOption(key, value) {
  return function (dispatch) {
    let dataKey = key + "/"
    dispatch(fetchDropDownStarted("FIND/" + dataKey))
    request
      .get("/api/find/options")
      .query({ name: key, value: value })
      .end((err, response) => {
        if (err) {
          dispatch(fetchDropDownError("FIND/" + dataKey, err))
        } else {
          dispatch(fetchDropDownSuccess("FIND/" + dataKey, response.body))
        }
      })
  }
}
