import Datetime from "app/shared/dateutil/index"

export const getVorbelegung = (state) => {
  return state.Dpl.PlaceHolders
}

export const getVorbelegungIntIDDatum = (state, IntID, Datum) => {
  return getVorbelegung(state)[
    `${IntID}-${Datetime.fromISO(Datum).toFormat("yyyy-MM-dd")}`
  ]
}
