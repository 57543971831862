import {
  FETCH_PERMISSION,
  FETCH_VALUE_PERMISSION,
  FETCH_APP_PERMISSION,
  fetchPermissionStatus
} from "./permission_actions"

export function Permissions(state = {}, action) {
  switch (action.type) {
    case FETCH_PERMISSION:
      switch (action.status) {
        case fetchPermissionStatus.start:
          return {
            ...state,
            [action.group + "/" + action.name + "/" + action.int_id]: -100
          }
        case fetchPermissionStatus.success:
          return {
            ...state,
            [action.group +
            "/" +
            action.name +
            "/" +
            action.int_id]: action.permission
          }
      }
      break
    case FETCH_VALUE_PERMISSION:
      switch (action.status) {
        case fetchPermissionStatus.start:
          return {
            ...state,
            [action.group +
            "/" +
            action.name +
            "/" +
            action.valueName +
            "/" +
            action.value]: -100
          }
        case fetchPermissionStatus.success:
          return {
            ...state,
            [action.group +
            "/" +
            action.name +
            "/" +
            action.valueName +
            "/" +
            action.value]: action.permission
          }
      }
      break
    case FETCH_APP_PERMISSION:
      switch (action.status) {
        case fetchPermissionStatus.start:
          return { ...state, [action.group + "/" + action.name]: -100 }
        case fetchPermissionStatus.success:
          return {
            ...state,
            [action.group + "/" + action.name]: action.permission
          }
      }
  }
  return state
}
