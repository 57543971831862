import {SUCCESS, ERROR, STARTED} from "app/state/constants"
import {LOAD_DPL_SYZM, LOAD_RB_SYZM} from "../actions"

export function DpZord(state = {rb: [], dp: []}, action) {
  switch (action.type) {
    case LOAD_DPL_SYZM:
      if (action.status == SUCCESS) {
        return {...state, dp: (action.data || []).sort((a, b) => (a.dpl_symb > b.dpl_symb ? 1 : -1)) || []}
      } else if (action.status == ERROR) {
        return state
      } else if (action.status == STARTED) {
        return state
      }
      break
    case LOAD_RB_SYZM:
      if (action.status == SUCCESS) {
        return {...state, rb: (action.data || []).sort((a, b) => (a.rb_symb > b.rb_symb ? 1 : -1)) || []}
      } else if (action.status == ERROR) {
        return state
      } else if (action.status == STARTED) {
        return state
      }
  }
  return state
}
