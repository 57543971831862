import { createSelector } from "reselect"
import { Datum } from "./datum"
import includes from "lodash/includes"
import memoizeOne from "memoize-one"
import Datetime from "app/shared/dateutil/index"

export const Personen = (state) => {
  return state.Dpl.AllPers.pers
}

export const SortGroup = (state) => {
  return state.Dpl.AllPers.group
}

export const SortLimits = (state) => {
  return state.Dpl.Records.fzLimitByGroup || {}
}

export const AktivZeitraum = (Personen, Datum, (pers, datum) => {})

export const SortLimitsByGroup = createSelector(
  SortGroup,
  SortLimits,
  (group, limits) => {
    return limits[group] || {}
  }
)

export const SortLimitsByID = createSelector(SortLimitsByGroup, (limits) => {
  let results = {}
  for (let a of Object.values(limits)) {
    for (let l of a) {
      results[l.id] = l
    }
  }
  return results
})

export const GroupOrder = (state) => {
  return state.Dpl.AllPers.groupSortOrder || {}
}

export const Filter = (state) => {
  return state.Dpl.AllPers.filter
}

export const GetIntIDs = createSelector(Personen, (p) => {
  return Object.keys(p).map((v) => parseInt(v))
})

export const GetSortedIntIDs = createSelector(
  Personen,
  SortGroup,
  GroupOrder,
  Filter,
  Datum,
  (p, group, gorder, filter, datum) => {
    let intids = Object.keys(p).sort((a, b) => {
      let order = p[a].Order - p[b].Order
      if (order == 0) {
        order = (p[a].fam_name + p[a].vor_name).localeCompare(
          p[b].fam_name + p[b].vor_name
        )
      }
      return order
    })
    return memoizedFilterIntIDs(
      memoizedSortByGroup(
        intids,
        p,
        group,
        gorder,
        (datum && datum.date) || null
      ),
      filter,
      p
    )
  }
)

export function findGroup(datum, pers) {
  const d = Datetime.fromISO(datum)
  for (let aktiv of pers.aktivzeitraum || []) {
    if (d >= Datetime.fromISO(aktiv.von).startOf("day")) {
      if (d <= Datetime.fromISO(aktiv.bis).startOf("day")) {
        return aktiv.u_abt_id
      }
    }
  }
  return pers.u_abt_id
}

function sortByGroup(intids, pers, group, sortOrder, datum) {
  if (group === null || group === "") {
    return intids
  }
  if (!datum) {
    return intids
  }
  let startDate = Datetime.fromISO(datum).toJSDate()
  let order = sortOrder[group] || {}

  intids = intids.sort((a, b) => {
    let groupA
    let groupB
    if (group === "u_abt_id") {
      groupA = findGroup(startDate, pers[a])
      groupB = findGroup(startDate, pers[b])
    } else {
      groupA = pers[a][group]
      groupB = pers[b][group]
    }

    if (groupA == groupB) {
      let order = pers[a].Order - pers[b].Order
      if (order == 0) {
        order = (pers[a].fam_name + pers[a].vor_name).localeCompare(
          pers[b].fam_name + pers[b].vor_name
        )
      }
      return order
    } else {
      let orderA = order[groupA] || 9999
      let orderB = order[groupB] || 9999
      if (orderA != orderB) {
        return orderA < orderB ? -1 : 1
      }
      return groupA < groupB ? -1 : 1
    }
  })
  return intids
}

const memoizedSortByGroup = memoizeOne(sortByGroup)

function filterIntids(intids, filter, pers) {
  if (filter.name) {
    intids = intids.filter((id) => {
      var ma = pers[id]
      if (includes(ma.fam_name.toLowerCase(), filter.name.toLowerCase())) {
        return true
      }
      if (includes(ma.vor_name.toLowerCase(), filter.name.toLowerCase())) {
        return true
      }
      return false
    })
  }
  return intids
}

const memoizedFilterIntIDs = memoizeOne(filterIntids)
