import {
  USER_DID_LOGIN,
  USER_DID_LOGOUT,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  CHANGE_PASSWORD
} from "./actions"
import { SUCCESS, ERROR } from "app/state/constants"
let initialState = {
  user: {
    user_name: null,
    new_password_required: false,
    logged_in: "UNKNOWN"
  },
  error: null,
  isFetching: false
}

function userLogin(state, action) {
  switch (action.status) {
    case LOGIN_STARTED:
      return { ...state, user: action.user, error: null, isFetching: true }
    case LOGIN_ERROR:
      return {
        ...state,
        user: action.user,
        error: action.error.message,
        isFetching: false
      }
    case LOGIN_SUCCESS:
      return { ...state, user: action.user, error: null, isFetching: false }
    case ERROR:
      return { ...state, error: action.error || null }
    case SUCCESS:
      return { ...state, error: null, isFetching: false }
  }
}

export default function currentUser(state = initialState, action) {
  switch (action.type) {
    case USER_DID_LOGIN:
    case CHANGE_PASSWORD:
      return userLogin(state, action)
    case USER_DID_LOGOUT:
      return { ...state, user: { ...initialState.user, logged_in: false } }
    default:
      return state
  }
}
