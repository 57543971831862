import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import Datetime from "../../shared/dateutil/index"
import {
  SET_TPL_RECORD,
  AUTO_DPL_RUNNING,
  SET_WUNSCH_MODE,
  LOADED_GROUP_LIMITS,
  LOADED_WISH_INFO,
  EDIT_PLAN_TEXT,
  SAVE_PLAN_TEXT,
  SET_FZ_MANUAL_RED,
  LOAD_FZ_MANUAL_RED,
  SELECT_PLAN
} from "../actions"

import {
  LOAD_RECORDS,
  LOAD_WUENSCHE,
  LOAD_KALENDER,
  LOAD_PLAN_TEXTE,
  LOAD_TEXTE,
  SET_FEHLZEIT,
  SAVE_FEHLZEIT,
  SET_TPL_TEXT,
  SET_DIENST,
  CLEAN_DIRTY_RECORDS,
  LOAD_VORGABEN,
  EDIT_WUNSCH,
  SET_WUNSCH_DIENST,
  VALIDATE_FEHLZEIT,
  MARK_TPL_RECORD_DELETE,
  LOAD_ANWESENHEIT,
  SET_SONDERMODELL
} from "../actions/records"

import { DirtyRecords, VisibleRecords } from "../selectors/records"

import {
  arrayToArrayObjectFn,
  arrayToObject,
  arrayToObjectFn
} from "app/shared/util/conversion"

export function AutoDpl(state = { running: false }, action) {
  if (action.type == AUTO_DPL_RUNNING) {
    if (action.status == STARTED) {
      return { running: true }
    } else {
      return { running: true }
    }
  }
  return state
}

const defaultState = {
  byDienst: {},
  byID: {},
  anwesenheit: {},
  wuensche: {},
  kalender: {},
  texte: {},
  plantexte: {},
  byAnyFehlzeit: {},
  byIntID: {},
  fzLimitByGroup: {},
  fzManualRedByGroup: {},
  byFehlzeit: {},
  tagestexte: {},
  vorgaben: {},
  displayWunsch: "taet",
  wishInfo: {},
  loading: false,
  error: null
}

export function Records(state = defaultState, action, appState) {
  switch (action.type) {
    case SET_SONDERMODELL:
      let mkey = `${action.id}-dp-S`
      return {
        ...state,
        byID: {
          ...state.byID,
          [mkey]: { ...state.byID[mkey], mod_id: action.modID }
        }
      }
    case LOAD_RECORDS:
      return {
        ...state,
        byID: {
          ...VisibleRecords({ Dpl: appState }),
          ...arrayToObjectFn(action.data, (o) => {
            return `${o.id}-${o.dprb}-${o.art}`
          }),
          ...DirtyRecords({ Dpl: { Records: state } })
        }
      }
    case LOAD_ANWESENHEIT:
      return {
        ...state,
        anwesenheit: arrayToArrayObjectFn(action.data, (o) => {
          return `${o.int_id}-${Datetime.fromISO(o.datum).toISODate()}`
        })
      }
    case CLEAN_DIRTY_RECORDS:
      const dirtyRecs = DirtyRecords({ Dpl: { Records: state } })
      let byID = { ...state.byID }
      for (let v of Object.values(dirtyRecs)) {
        delete byID[`${v.id}-${v.dprb}-${v.art}`]
      }
      let wuensche = { ...state.wuensche }
      for (let v of Object.values(wuensche)) {
        if (v.__dirty || v.__new || v.__deleted) {
          delete wuensche[v.id]
        }
      }
      let ptexte = { ...state.texte }
      for (let v of Object.values(ptexte)) {
        if (v.__dirty || v.__new || v.__deleted) {
          delete ptexte[v.id]
        }
      }
      let pltexte = { ...state.plantexte }
      for (let v of Object.values(pltexte)) {
        if (v.__dirty || v.__new || v.__deleted) {
          delete pltexte[v.id]
        }
      }
      let kalender = { ...state.kalender }
      for (let [id, v] of Object.entries(kalender)) {
        if (v.__dirty || v.__new || v.__deleted) {
          delete kalender[id]
        }
      }
      return {
        ...state,
        byID: byID,
        wuensche: wuensche,
        texte: ptexte,
        kalender: kalender,
        plantexte: pltexte
      }
    case SELECT_PLAN:
      return defaultState
    // const dirtyRecs = DirtyRecords({ Dpl: { Records: state } })
    // let byID = { ...state.byID }
    // for (let v of Object.values(dirtyRecs)) {
    //   delete byID[`${v.id}-${v.dprb}-${v.art}`]
    // }
    // let wuensche = { ...state.wuensche }
    // for (let v of Object.values(wuensche)) {
    //   if (v.__dirty || v.__new || v.__deleted) {
    //     delete wuensche[v.id]
    //   }
    // }
    // let ptexte = { ...state.texte }
    // for (let v of Object.values(ptexte)) {
    //   if (v.__dirty || v.__new || v.__deleted) {
    //     delete ptexte[v.id]
    //   }
    // }
    // let pltexte = { ...state.plantexte }
    // for (let v of Object.values(pltexte)) {
    //   if (v.__dirty || v.__new || v.__deleted) {
    //     delete pltexte[v.id]
    //   }
    // }
    // let kalender = { ...state.kalender }
    // for (let [id, v] of Object.entries(kalender)) {
    //   if (v.__dirty || v.__new || v.__deleted) {
    //     delete kalender[id]
    //   }
    // }
    // return {
    //   ...state,
    //   byID: byID,
    //   wuensche: wuensche,
    //   texte: ptexte,
    //   kalender: kalender,
    //   plantexte: pltexte
    // }
    case LOAD_VORGABEN:
      // let mergedData = {}
      // for (let [k, v] of Object.entries(action.data)) {
      //   mergedData[k] = { ...(state[k] || {}), ...v }
      // }
      return { ...state, vorgaben: action.data }
    case SET_WUNSCH_DIENST:
    case EDIT_WUNSCH:
      let deleted =
        action.value == null &&
        action.dpl_symb == null &&
        action.rb_symb == null
      return {
        ...state,
        wuensche: {
          ...state.wuensche,
          [action.RecordID]: {
            ...(state.wuensche[action.RecordID] || {
              id: action.RecordID,
              int_id: action.int_id,
              datum: action.Datum,
              plan_id: action.PlanID,
              __new: true
            }),
            dienst_id: action.value,
            dpl_symb: action.dpl_symb,
            rb_symb: action.rb_symb,
            __dirty: true,
            __deleted: deleted
          }
        }
      }
    case SET_DIENST:
      return {
        ...state,
        byID: {
          ...state.byID,
          [`${action.RecordID}-${action.dprb}-${action.art}`]: {
            ...(state.byID[
              `${action.RecordID}-${action.dprb}-${action.art}`
            ] || {
              id: action.RecordID,
              plan_id: action.PlanID,
              datum: action.Datum,
              dprb: action.dprb,
              art: action.art,
              int_id: action.int_id,
              __new: true
            }),
            dienst_id: action.value,
            __dirty: true
          }
        }
      }
    case SET_TPL_RECORD:
      return {
        ...state,
        byID: {
          ...state.byID,
          [`${action.RecordID}-${action.dprb}-${action.art}`]: {
            ...(state.byID[
              `${action.RecordID}-${action.dprb}-${action.art}`
            ] || {
              id: action.RecordID,
              plan_id: action.PlanID,
              datum: action.Datum,
              dprb: action.dprb,
              art: action.art,
              dienst_id: action.DienstID,
              int_id: action.value,
              __new: true
            }),
            dpl_symb: action.dpl_symb,
            int_id: action.value,
            dienst_id: action.DienstID,
            __valid: action.valid,
            __dirty: true,
            __deleted: action.deleted
          }
        }
      }
    case SET_FEHLZEIT:
      return {
        ...state,
        kalender: {
          ...state.kalender,
          [`${action.int_id}-${action.datum.slice(0, 10)}`]: {
            ...(state.kalender[
              `${action.int_id}-${action.datum.slice(0, 10)}`
            ] || {
              int_id: action.int_id,
              datum: action.datum,
              __new: true
            }),
            v: action.v,
            n: action.n,
            __valid: action.valid,
            __mustValidate: action.mustValidate,
            dienst_id: action.dienst_id,
            __dirty: true
          }
        }
      }
    case SAVE_FEHLZEIT:
      return {
        ...state,
        kalender: {
          ...state.kalender,
          [`${action.fehlzeit.int_id}-${action.fehlzeit.datum.slice(0, 10)}`]: {
            ...(state.kalender[
              `${action.fehlzeit.int_id}-${action.fehlzeit.datum.slice(0, 10)}`
            ] || {
              int_id: action.fehlzeit.int_id,
              datum: action.fehlzeit.datum
            }),
            v: action.fehlzeit.v,
            n: action.fehlzeit.n,
            __valid: true,
            __mustValidate: false,
            __dirty: false,
            __deleted: false
          }
        }
      }
    case VALIDATE_FEHLZEIT:
      return {
        ...state,
        kalender: {
          ...state.kalender,
          [`${action.int_id}-${action.datum.slice(0, 10)}`]: {
            ...state.kalender[`${action.int_id}-${action.datum.slice(0, 10)}`],
            __mustValidate: 0,
            dienst_id: null,
            __dirty: true
          }
        }
      }
    case SET_TPL_TEXT:
      return {
        ...state,
        texte: {
          ...state.texte,
          [action.id]: {
            ...(state.texte[action.id] || {
              id: action.id,
              int_id: action.int_id,
              datum: action.datum,
              __new: true
            }),
            text: action.text,
            __dirty: true
          }
        }
      }
    case LOAD_WUENSCHE:
      return {
        ...state,
        wuensche: { ...state.wuensche, ...arrayToObject(action.data, "id") }
      }
    case LOAD_KALENDER:
      return {
        ...state,
        kalender: { ...state.kalender, ...action.data }
      }
    case LOAD_TEXTE:
      return {
        ...state,
        texte: { ...state.texte, ...arrayToObject(action.data, "id") }
      }
    case LOAD_PLAN_TEXTE:
      return {
        ...state,
        plantexte: {
          ...state.plantexte,
          ...arrayToObject(action.data, "id")
        }
      }

    case SET_WUNSCH_MODE:
      return { ...state, displayWunsch: action.mode }
    case SAVE_PLAN_TEXT:
      let texte = { ...state.tagestexte }
      for (let [k, t] of Object.entries(state.tagestexte)) {
        if (action.PlanID == t.plan_id && action.Datum == t.datum) {
          texte[k] = action.data
        }
      }
      return { ...state, tagestexte: texte }
    case EDIT_PLAN_TEXT:
      return {
        ...state,
        plantexte: {
          ...state.plantexte,
          [action.RecordID]: {
            ...(state.plantexte[action.RecordID] || {
              id: action.RecordID,
              plan_id: action.PlanID,
              datum: action.Datum,
              __new: true
            }),
            text: action.value,
            __dirty: true
          }
        }
      }
    case LOADED_WISH_INFO:
      if (action.status == STARTED) {
        return { ...state, wishInfo: { ...state.wishInfo, [action.key]: [] } }
      } else if (action.status == SUCCESS) {
        return {
          ...state,
          wishInfo: { ...state.wishInfo, [action.key]: action.data }
        }
      }
      return state
    case LOADED_GROUP_LIMITS:
      return { ...state, fzLimitByGroup: action.data }

    case AUTO_DPL_RUNNING:
      if (action.status == STARTED) {
        return { ...state, loading: true }
      }
      return { ...state, loading: false }
    case LOAD_FZ_MANUAL_RED:
      return {
        ...state,
        fzManualRedByGroup: { ...state.fzManualRedByGroup, ...action.data }
      }
    case MARK_TPL_RECORD_DELETE:
      let nState = {
        ...state,
        byID: {
          ...state.byID,
          [action.RecordID + "-dp-" + (action.kz || "S")]: {
            ...state.byID[action.RecordID + "-dp-" + (action.kz || "S")],
            __deleted: true
          }
        }
      }
      if (state.byID[action.RecordID + "-rb-S"]) {
        nState = {
          ...nState,
          byID: {
            ...nState.byID,
            [action.RecordID + "-rb-S"]: {
              ...nState.byID[action.RecordID + "-rb-S"],
              __deleted: true
            }
          }
        }
      }
      return nState
    case SET_FZ_MANUAL_RED:
      let key = `${Datetime.fromISO(action.datum).toISODate()}-${
        action.groupName
      }-${action.group}`
      let red = { ...state.fzManualRedByGroup }
      if (action.value == null) {
        delete red[key]
      } else {
        red = { ...red, [key]: action.value }
      }
      return { ...state, fzManualRedByGroup: red }
  }

  return state
}
