import { LOAD_MACROS, LOAD_RB_FOLGETAG } from "../actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { arrayToObject } from "app/shared/util/conversion"
const defaultState = { bySymb: {}, byFz: {} }
export function Macro(state = defaultState, action) {
  switch (action.type) {
    case LOAD_MACROS:
      if (action.status == STARTED) {
        return defaultState
      } else if (action.status == ERROR) {
        return defaultState
      } else if (action.status == SUCCESS) {
        return {
          bySymb: arrayToObject(action.data, "dpl_symb"),
          byFz: arrayToObject(action.data, "fehlz_id")
        }
      }
  }
  return state
}

export function RbFolgeTag(state = [], action) {
  switch (action.type) {
    case LOAD_RB_FOLGETAG:
      return action.data
  }
  return state
}
