import React from "react"
import { connect } from "react-redux"
import { OnEvent, SetEventProcessed } from "./actions"
import swal from "sweetalert"
var socket
const globalIgnored = {
  progress: true
}
export class WSHandler extends React.Component {
  constructor(props) {
    super(props)
    this.openSocket = this.openSocket.bind(this)
    this.closeSocket = this.closeSocket.bind(this)
  }
  componentDidMount() {
    if (this.props.loginStatus == true) {
      this.openSocket()
    }
  }
  componentWillUnmount() {
    this.closeSocket()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loginStatus != this.props.loginStatus) {
      if (this.props.loginStatus == true) {
        this.closeSocket()
        this.openSocket()
      } else {
        this.closeSocket()
      }
    }
    if (prevProps.events !== this.props.events) {
      for (let e of this.props.events) {
        if (!e.processed) {
          this.processEvent(e)
          this.props.SetEventProcessed(e)
        }
      }
    }
  }
  processEvent(e) {
    switch (e.type) {
      case "alert":
        swal({
          title: e.type,
          text: e.message,
          icon: e.severity
        })
        break
      case "notification":
        //alert("notification:" + e.message)
        //var notification = new Notification(e.message)
        if (!("Notification" in window)) {
          return
          //alert("This browser does not support desktop notification")
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
          var notification = new Notification(e.message)
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
          try {
            Notification.requestPermission().then((permission) => {
              if (permission == "granted") {
                new Notification(e.message)
              }
            })
          } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it
            // throws a TypeError. It takes a callback as the first argument
            // instead.
            if (error instanceof TypeError) {
              Notification.requestPermission((permission) => {
                if (permission == "granted") {
                  new Notification(e.message)
                }
              })
            } else {
              throw error
            }
          }
          //   Notification.requestPermission().then(function (permission) {
          //     // If the user accepts, let's create a notification
          //     if (permission === "granted") {
          //       var notification = new Notification(e.message)
          //     }
          //   })
        }

        break
      default:
      //console.log("Unandled Event", e)
    }
  }
  closeSocket() {
    if (socket) {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close()
      }
    }
  }

  openSocket() {
    var loc = window.location
    var scheme
    if (loc.protocol === "https:") {
      scheme = "wss://"
    } else {
      scheme = "ws://"
    }
    socket = new WebSocket(scheme + location.host + "/api/ws")
    socket.onmessage = (msg) => this.props.onEvent(JSON.parse(msg.data))
    socket.onopen = () => this.props.onEvent({ event: "OPENED" })
    socket.onclose = () => {
      //console.log("connection lost, reconnecting....")
      this.props.onEvent({ event: "CLOSED" })
      setTimeout(this.openSocket, 2500)
    }
    socket.onerror = function (evt) {
      if (socket.readyState == 3) {
        //Connection closed.
        //console.log("error, reconnecting....")
        setTimeout(this.openSocket, 500)
      }
    }
  }
  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.currentUser.user.logged_in,
    events: state.WSData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onEvent: (event) => {
      if (globalIgnored[event.type]) {
        return
      }
      //console.log(event)
      dispatch(OnEvent(event))
    },
    SetEventProcessed: (event) => {
      dispatch(SetEventProcessed(event))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WSHandler)
