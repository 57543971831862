import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import {
  LOAD_SALDOUMBUCHUNGENVONBIS,
  EDIT_SALDOUMBUCHUNG,
  ADD_SALDOUMBUCHUNG_ROW,
  DELETE_SALDOUMBUCHUNG,
  DELETED_SALDOUMBUCHUNG,
  UPDATE_SALDOUMBUCHUNG,
  LOAD_SALDENVORTRAG,
  EDIT_SALDENVORTRAG,
  UPDATE_SALDENVORTRAG,
  LOAD_SPEICHERVORTRAG,
  EDIT_SPEICHERVORTRAG,
  ADD_SPEICHERVORTRAG_ROW,
  DELETE_SPEICHERVORTRAG,
  DELETED_SPEICHERVORTRAG,
  UPDATE_SPEICHERVORTRAG,
  LOAD_MONATSSPEICHER,
  LOAD_SPEICHERINDIVZULAGE,
  EDIT_SPEICHERINDIVZULAGE,
  UPDATE_SPEICHERINDIVZULAGE,
  LOAD_DPSALDENVORTRAG,
  EDIT_DPSALDENVORTRAG
} from "./actions"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"

import { v4 as uuidv4 } from "uuid"

export function Speicher(
  state = {
    saldoumbuchung: [],
    saldenvortrag: [],
    speichervortrag: [],
    monatsspeicher: [],
    speicherindivzulage: [],
    dpsaldenvortrag: []
  },
  action
) {
  switch (action.type) {
    case UPDATE_SPEICHERINDIVZULAGE:
      if (action.status == SUCCESS) {
        return {
          ...state,
          speicherindivzulage: state.speicherindivzulage.map((row) => {
            if (
              row.id == action.data.id
              // &&
              // row.monat == action.data.monat
            ) {
              return action.data
            }
            return row
          })
        }
      }
      return state
    case EDIT_SPEICHERINDIVZULAGE:
      return {
        ...state,
        speicherindivzulage: state.speicherindivzulage.map((row) => {
          if (row.id == action.id) {
            return { ...row, ...action.changes, __dirty: true }
          }
          return row
        })
      }
    case LOAD_SPEICHERINDIVZULAGE:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            speicherindivzulage: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_MONATSSPEICHER:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            monatsspeicher: action.data || [],
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_SPEICHERVORTRAG:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            speichervortrag: {
              ...state.speichervortrag,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break

    case EDIT_SPEICHERVORTRAG:
      // console.log(state, action)
      return {
        ...state,
        speichervortrag: {
          ...state.speichervortrag,
          [action.int_id]: state.speichervortrag[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }

    case ADD_SPEICHERVORTRAG_ROW:
      return {
        ...state,
        speichervortrag: {
          ...state.speichervortrag,
          [action.int_id]: [
            {
              __new: true,
              id: uuidv4(),
              int_id: action.int_id,
              jahr: Datetime.fromISO(action.jahr).toISO()
              // ...(action.values || {})
            },
            ...(state.speichervortrag[action.int_id] || [])
          ]
        }
      }

    case DELETE_SPEICHERVORTRAG:
      return {
        ...state,
        speichervortrag: {
          ...state.speichervortrag,
          [action.int_id]: state.speichervortrag[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
    case DELETED_SPEICHERVORTRAG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          speichervortrag: {
            ...state.speichervortrag,
            [action.int_id]: state.speichervortrag[action.int_id].filter(
              (row) => {
                if (row.id == action.id) {
                  return false
                }
                return true
              }
            )
          }
        }
      }
      return state
    case UPDATE_SPEICHERVORTRAG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          speichervortrag: {
            ...state.speichervortrag,
            [action.int_id]: state.speichervortrag[action.int_id]
              .map((row) => {
                if (row.id == action.id) {
                  return action.data
                }
                return row
              })
              .filter((r) => r !== null)
          }
        }
      }
      return state

    case LOAD_DPSALDENVORTRAG:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            dpsaldenvortrag: {
              ...state.dpsaldenvortrag,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_DPSALDENVORTRAG:
      return {
        ...state,
        dpsaldenvortrag: {
          ...state.dpsaldenvortrag,
          [action.int_id]: {
            ...state.dpsaldenvortrag[action.int_id],
            ...action.changes,
            __dirty: true
          }
        }
      }

    case LOAD_SALDENVORTRAG:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            saldenvortrag: {
              ...state.saldenvortrag,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case EDIT_SALDENVORTRAG:
      return {
        ...state,
        saldenvortrag: {
          ...state.saldenvortrag,
          [action.int_id]: {
            ...state.saldenvortrag[action.int_id],
            ...action.changes,
            __dirty: true
          }
        }
      }
    case UPDATE_SALDENVORTRAG:
      // console.log(state)
      // console.log(action)
      if (action.status == SUCCESS) {
        return {
          ...state,
          saldenvortrag: {
            ...state.saldenvortrag,
            [action.int_id]: action.data
          }
        }
      }
      return state
    case LOAD_SALDOUMBUCHUNGENVONBIS:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            saldoumbuchung: {
              ...state.saldoumbuchung,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break

    case EDIT_SALDOUMBUCHUNG:
      // console.log(state, action)
      return {
        ...state,
        saldoumbuchung: {
          ...state.saldoumbuchung,
          [action.int_id]: state.saldoumbuchung[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }

    case ADD_SALDOUMBUCHUNG_ROW:
      return {
        ...state,
        saldoumbuchung: {
          ...state.saldoumbuchung,
          [action.int_id]: [
            {
              __new: true,
              id: uuidv4(),
              int_id: action.int_id,
              datum: Datetime.fromISO(action.jahr).toISO(),
              kz_uebg: "0"
              // ...(action.values || {})
            },
            ...(state.saldoumbuchung[action.int_id] || [])
          ]
        }
      }

    case DELETE_SALDOUMBUCHUNG:
      return {
        ...state,
        saldoumbuchung: {
          ...state.saldoumbuchung,
          [action.int_id]: state.saldoumbuchung[action.int_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
    case DELETED_SALDOUMBUCHUNG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          saldoumbuchung: {
            ...state.saldoumbuchung,
            [action.int_id]: state.saldoumbuchung[action.int_id].filter(
              (row) => {
                if (row.id == action.id) {
                  return false
                }
                return true
              }
            )
          }
        }
      }
      return state
    case UPDATE_SALDOUMBUCHUNG:
      if (action.status == SUCCESS) {
        return {
          ...state,
          saldoumbuchung: {
            ...state.saldoumbuchung,
            [action.int_id]: state.saldoumbuchung[action.int_id]
              .map((row) => {
                if (row.id == action.id) {
                  return action.data
                }
                return row
              })
              .filter((r) => r !== null)
          }
        }
      }
      return state
  }
  return state
}
