import { LOAD_TEMPV } from "../actions/tempv"

export default function TempV(state = {}, action) {
  switch (action.type) {
    case LOAD_TEMPV:
      return {
        ...state,
        [action.int_id]: action.data
      }
  }
  return state
}
