import { arrayToObjectFn } from "app/shared/util/conversion"
import {
  EDIT_BESETZUNG,
  EDIT_SHIFT,
  EDIT_VORGABEN,
  LOAD_VORGABEN,
  UPDATE_VORGABE,
  COPY_VORGABE,
  PASTE_VORGABE,
  EDIT_SONDER_BESETZUNG_DATUM,
  EDIT_SONDER_BESETZUNG
} from "./actions"
import { DefaultVorgabe } from "./selectors"

export const Vorgaben = (state = {}, action) => {
  switch (action.type) {
    case LOAD_VORGABEN:
    case EDIT_VORGABEN:
    case EDIT_BESETZUNG:
    case UPDATE_VORGABE:
    case EDIT_SHIFT:
    case PASTE_VORGABE:
    case EDIT_SONDER_BESETZUNG:
    case EDIT_SONDER_BESETZUNG_DATUM:
      return {
        ...state,
        [action.abt_id]: YearManager(state[action.abt_id], action)
      }
  }
  return state
}

export const VorgabenCopyPaste = (state = null, action) => {
  switch (action.type) {
    case COPY_VORGABE:
      return action.vorgabe
  }
  return state
}

const YearManager = (state = {}, action) => {
  switch (action.type) {
    case LOAD_VORGABEN:
      // Geladen wird auf JahresEbene
      return {
        ...state,
        [action.year]: arrayToObjectFn(action.data, (o) => o.quali_id)
      }
    case EDIT_VORGABEN:
    case EDIT_BESETZUNG:
    case UPDATE_VORGABE:
    case EDIT_SHIFT:
    case PASTE_VORGABE:
    case EDIT_SONDER_BESETZUNG:
    case EDIT_SONDER_BESETZUNG_DATUM:
      return {
        ...state,
        [action.year]: QualiManager(state[action.year], action)
      }
  }
  return state
}

const QualiManager = (state = {}, action) => {
  switch (action.type) {
    case EDIT_VORGABEN:
    case EDIT_BESETZUNG:
    case UPDATE_VORGABE:
    case EDIT_SHIFT:
    case EDIT_SONDER_BESETZUNG:
    case EDIT_SONDER_BESETZUNG_DATUM:
      return {
        ...state,
        [action.quali_id]: VorgabenManager(state[action.quali_id], action)
      }
    case PASTE_VORGABE:
      return {
        ...state,
        [action.quali_id]: {
          ...action.vorgabe,
          id: undefined,
          quali_id: action.quali_id,
          abt_id: action.abt_id,
          year: action.year,
          __dirty: true,
          shifts: arrayToObjectFn(
            Object.values(action.vorgabe.shifts).map((o) => ({
              ...o,
              id: undefined,
              quali_id: action.quali_id,
              abt_id: action.abt_id,
              year: action.year
            })),
            (o) => o.shift
          ),
          besetzung: action.vorgabe.besetzung.map((b) => ({
            ...b,
            id: undefined,
            quali_id: action.quali_id,
            abt_id: action.abt_id,
            year: action.year
          })),
          sonderbesetzung: []
        }
      }
  }
  return state
}

const VorgabenManager = (
  state = { besetzung: [], sonderbesetzung: [] },
  action
) => {
  switch (action.type) {
    case UPDATE_VORGABE:
      return action.data
    case EDIT_VORGABEN:
      return {
        ...DefaultVorgabe(action.abt_id, action.year, action.quali_id),
        ...state,
        ...action.changes,
        __dirty: true
      }
    case EDIT_BESETZUNG:
      return {
        ...DefaultVorgabe(action.abt_id, action.year, action.quali_id),
        ...state,
        __dirty: true,
        besetzung: BesetzungManager(state.besetzung, action)
      }
    case EDIT_SHIFT:
      return {
        ...DefaultVorgabe(action.abt_id, action.year, action.quali_id),
        ...state,
        __dirty: true,
        shifts: ShiftManager(state.shifts, action)
      }
    case EDIT_SONDER_BESETZUNG:
    case EDIT_SONDER_BESETZUNG_DATUM:
      return {
        ...DefaultVorgabe(action.abt_id, action.year, action.quali_id),
        ...state,
        __dirty: true,
        sonderbesetzung: SonderBesetzungManager(state.sonderbesetzung, action)
      }
  }
  return state
}

const ShiftManager = (state, action) => {
  switch (action.type) {
    case EDIT_SHIFT:
      return {
        ...state,
        [action.shift]: {
          ...(state[action.shift] || {
            year: action.year,
            quali_id: action.quali_id,
            sektion_id: action.sektion_id || 0,
            shift: action.shift,
            abt_id: action.abt_id
          }),
          shift_length: action.value
        }
      }
  }
  return state
}

const BesetzungManager = (state = [], action) => {
  switch (action.type) {
    case EDIT_BESETZUNG:
      // Prüfen ob Row Vorhanden - Sonst eine Anlegen
      const HasRow = state.find(
        (b) => b.shift == action.shift && b.weekday == action.weekday
      )
      if (HasRow) {
        return state.map((b) => {
          if (b.shift == action.shift && b.weekday == action.weekday) {
            return { ...b, koepfe: action.value }
          }
          return b
        })
      } else {
        return [
          ...state,
          {
            year: action.year,
            quali_id: action.quali_id,
            sektion_id: action.sektion_id || 0,
            shift: action.shift,
            abt_id: action.abt_id,
            weekday: action.weekday,
            koepfe: action.value
          }
        ]
      }
  }
}

const SonderBesetzungManager = (state = [], action) => {
  switch (action.type) {
    case EDIT_SONDER_BESETZUNG_DATUM:
      return state.map((b) => {
        if (b.datum == action.datum) {
          return { ...b, datum: action.newDatum }
        }
        return b
      })
    case EDIT_SONDER_BESETZUNG:
      // Prüfen ob Row Vorhanden - Sonst eine Anlegen
      const HasRow = state.find(
        (b) => b.shift == action.shift && b.datum == action.datum
      )
      if (HasRow) {
        return state.map((b) => {
          if (b.shift == action.shift && b.datum == action.datum) {
            return { ...b, koepfe: action.value }
          }
          return b
        })
      } else {
        return [
          ...state,
          {
            year: action.year,
            quali_id: action.quali_id,
            sektion_id: action.sektion_id || 0,
            shift: action.shift,
            abt_id: action.abt_id,
            datum: action.datum,
            koepfe: action.value
          }
        ]
      }
  }
}
