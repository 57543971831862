import { DateTime, Settings } from "luxon"

Settings.defaultLocale = "de"

const d1900 = new Date(Date.UTC(1900, 1, 1))
class Datetime {
  constructor(d) {
    this.luxonDate = d
  }
  static fromJSDate(d) {
    return new Datetime(DateTime.fromJSDate(d, { zone: "utc" }))
  }
  static fromISO(d) {
    return new Datetime(DateTime.fromISO(d, { zone: "utc" }))
  }
  static fromArray(arr) {
    return Datetime.fromJSDate(new Date(Date.UTC(...arr)))
  }
  static fromTime(v) {
    return new Datetime(DateTime.fromFormat(v, "HH:mm", { zone: "utc" }))
  }
  static fromDate(v) {
    return new Datetime(DateTime.fromFormat(v, "dd.MM.yyyy", { zone: "utc" }))
  }
  static fromValues(year, month, day, hour, minute, second) {
    return Datetime.fromJSDate(
      new Date(Date.UTC(year, month, day, hour || 0, minute || 0, second || 0))
    )
  }
  static fromFormat(d, format) {
    return new Datetime(DateTime.fromFormat(d, format, { zone: "utc" }))
  }
  static utc(year, month, day, hour, minute, second, millisecond, options) {
    return new Datetime(
      DateTime.utc(year, month, day, hour, minute, second, millisecond, options)
    )
  }
  static today() {
    return Datetime.now().startOf("day")
  }
  static now() {
    let d = new Date()
    return Datetime.utc(
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds()
    ) //DateTime.now()
  }
  static nowExact() {
    let d = new Date()
    return Datetime.utc(
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds()
    ) //DateTime.now()
  }
  static year() {
    return Datetime.now().year()
  }
  static month() {
    return Datetime.now().month()
  }
  static day() {
    return Datetime.now().day()
  }
  isValid = () => {
    return this.luxonDate.isValid
  }
  toJSDate = () => {
    if (!this.luxonDate.isValid) {
      return null
    }
    return this.luxonDate.toJSDate()
  }
  toISO = (opts = {}) => {
    if (!this.luxonDate.isValid) {
      return null
    }
    return this.luxonDate.toISO({
      suppressMilliseconds: true,
      ...opts,
      zone: "utc"
    })
  }

  startOf = (unit) => {
    return new Datetime(this.luxonDate.startOf(unit))
  }
  endOf = (unit) => {
    return new Datetime(this.luxonDate.endOf(unit).set({ millisecond: 0 }))
  }
  diff = (...params) => {
    return this.luxonDate.diff(...params)
  }
  toFormat = (format, opts) => {
    return this.luxonDate.toFormat(format, opts)
  }
  set = (params) => {
    return new Datetime(this.luxonDate.set(params))
  }

  toISODate = () => {
    return this.luxonDate.toFormat("yyyy-MM-dd")
  }
  toISO1900 = () => {
    let d2 = DateTime.fromJSDate(d1900)
    return d2
      .set({
        hour: this.luxonDate.hour(),
        minute: this.luxonDate.minute(),
        millisecond: 0
      })
      .toISO({ suppressMilliseconds: true, zone: "utc" })
  }
  formatMonthLong = () => {
    return this.luxonDate.toFormat("MMMM")
  }
  formatMonthShort = () => {
    return this.luxonDate.toFormat("MMM")
  }
  formatDayLong = () => {
    return this.luxonDate.toFormat("cccc")
  }
  formatDayShort = () => {
    return this.luxonDate.toFormat("ccc")
  }
  formatDayPadded = () => {
    return this.luxonDate.toFormat("dd")
  }
  formatDateShort = () => {
    if (!this.isValid()) {
      return ""
    }
    return this.luxonDate.toFormat("dd.MM.yyyy")
  }

  formatDateShortNoYear = () => {
    if (!this.isValid()) {
      return ""
    }
    return this.luxonDate.toFormat("dd.MM")
  }
  formatDateTimeShort = () => {
    return this.luxonDate.toFormat("dd.MM.yyyy HH:mm")
  }
  formatTimeShort = () => {
    return this.luxonDate.toFormat("HH:mm")
  }
  formatTimeLong = () => {
    return this.luxonDate.toFormat("HH:mm:ss")
  }
  valueOf = () => {
    return this.luxonDate.valueOf()
  }
  hour = () => {
    return this.luxonDate.hour
  }
  minute = () => {
    return this.luxonDate.minute
  }
  day = () => {
    return this.luxonDate.day
  }
  weekday = () => {
    return this.luxonDate.weekday
  }
  month = () => {
    return this.luxonDate.month
  }
  year = () => {
    return this.luxonDate.year
  }
  diffMinutes = (d2) => {
    return d2.luxonDate.diff(this.luxonDate, "minutes").as("minutes")
  }
  diffHours = (d2) => {
    return d2.luxonDate.diff(this.luxonDate, "hours").as("hours")
  }
  diffDays = (d2) => {
    return d2.luxonDate.diff(this.luxonDate, "days").as("days")
  }
  diffWeeks = (d2) => {
    return d2.luxonDate.diff(this.luxonDate, "days").as("days") / 7
  }
  diffMonths = (d2) => {
    return d2.luxonDate.diff(this.luxonDate, "months").as("months")
  }
  equals = (d2) => {
    return this.luxonDate.equals(d2.luxonDate)
  }
  plus = (params) => {
    return new Datetime(this.luxonDate.plus(params))
  }
}

export default Datetime
