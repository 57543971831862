import request from "superagent"

export const LOAD_FEIERTAGE = "upl/LOAD_FEIERTAGE"

export const LoadFeiertage = (von, bis) => {
  return (dispatch) => {
    return request
      .get(`/api/dpl/basis/feiertage/${von}/${bis}`)
      .then((response) => {
        dispatch({
          type: LOAD_FEIERTAGE,
          data: response.body
        })
      })
  }
}
