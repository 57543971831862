import request from "superagent"
export const LOAD_VERSION = "LOAD_VERSION"

export const LOAD_VERSION_STARTED = "LOAD_VERSION_STARTED"
export const LOAD_VERSION_SUCCESS = "LOAD_VERSION_SUCCESS"
export const LOAD_VERSION_ERROR = "LOAD_VERSION_ERROR"

export function loadVersionStarted() {
  return {
    type: LOAD_VERSION,
    status: LOAD_VERSION_STARTED
  }
}

export function loadVersionSuccess(version) {
  return {
    type: LOAD_VERSION,
    status: LOAD_VERSION_SUCCESS,
    version
  }
}

export function loadVersionError(error) {
  return {
    type: LOAD_VERSION,
    status: LOAD_VERSION_ERROR,
    error
  }
}

export function LoadVersion() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(loadVersionStarted())
      request.get("/api/version").end((error, response) => {
        if (error) {
          dispatch(loadVersionError(error))
          reject(error)
        } else {
          dispatch(loadVersionSuccess(response.body))
          resolve(response.body)
        }
      })
    })
  }
}
