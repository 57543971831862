import {
  ALTER_QUERY,
  CLEAR_QUERY,
  EXECUTE_QUERY,
  EXECUTE_QUERY_STARTED,
  EXECUTE_QUERY_SUCCESS,
  EXECUTE_QUERY_ERROR,
  FETCH_QUERY_RESULTS,
  FETCH_QUERY_SETTINGS,
  SET_QUERY_SELECTION,
  SET_QUERY_SELECTION_RANGE,
  QUERY_SELECTION_SELECT_ALL,
  QUERY_SELECTION_CLEAR_ALL,
  TOGGLE_QUERY_VISIBILITY,
  SET_AKTIVEN_MITARBEITER
} from "./query_actions"
import uniq from "lodash/uniq"

export function QuerySelection(state = {}, action) {
  switch (action.type) {
    case ALTER_QUERY:
      switch (action.key) {
        case "aktiv":
          return { ...state, ...{ aktiv: !state.aktiv } }
        case "fam_name":
        case "vor_name":
        case "pers_id":
          return {
            ...state,
            ...{ [action.key]: action.changes ? action.changes.value : null }
          }
        default:
          return {
            ...state,
            ...{
              [action.key]: action.changes.map((value) => {
                return value.value
              })
            }
          }
      }
    case EXECUTE_QUERY:
      switch (action.status) {
        case EXECUTE_QUERY_STARTED:
          return {
            ...state,
            ...{ didExecute: false, isFetching: true, error: null }
          }
        case EXECUTE_QUERY_SUCCESS:
          return {
            ...state,
            ...{ didExecute: true, isFetching: false, error: null }
          }
        case EXECUTE_QUERY_ERROR:
          return {
            ...state,
            ...{ didExecute: true, isFetching: false, error: action.error }
          }
      }
  }
  return state
}

export function Selected(state = [], action) {
  switch (action.type) {
    case SET_QUERY_SELECTION:
      switch (action.add) {
        case true:
          return [...state, action.int_id]
        default:
          return [action.int_id]
      }
    case SET_QUERY_SELECTION_RANGE:
      if (state.length == 0) {
        return [action.int_id]
      }
  }

  return state
}

const initialState = {
  QuerySelection: null,
  QuerySelectionDirty: null,
  Results: [],
  Selected: [],
  Aktiv: null,
  isShown: true
}

export function Query(state = initialState, action) {
  var selected
  switch (action.type) {
    case SET_AKTIVEN_MITARBEITER:
      return { ...state, Aktiv: action.int_id }
    case TOGGLE_QUERY_VISIBILITY:
      return { ...state, isShown: !state.isShown }
    case ALTER_QUERY:
      return {
        ...state,
        QuerySelectionDirty: QuerySelection(
          { ...state.QuerySelection, ...state.QuerySelectionDirty },
          action
        )
      }
    case EXECUTE_QUERY:
      return {
        ...state,
        QuerySelection: QuerySelection(
          { ...state.QuerySelection, ...state.QuerySelectionDirty },
          action
        ),
        QuerySelectionDirty: null
      }
    case CLEAR_QUERY:
      return { ...state, QuerySelection: {}, QuerySelectionDirty: null }
    case FETCH_QUERY_RESULTS:
      return { ...state, Results: action.results || [] }
    case FETCH_QUERY_SETTINGS:
      return { ...state, QuerySelection: action.results }
    case SET_QUERY_SELECTION:
      if (state.Selected.indexOf(action.int_id) == -1 || !action.add) {
        selected = Selected(state.Selected, action)
      } else {
        selected = [...state.Selected].filter((val) => {
          return val != action.int_id
        })
      }
      return {
        ...state,
        Selected: selected,
        Aktiv:
          (state.Aktiv &&
            selected.indexOf(state.Aktiv) !== -1 &&
            state.Aktiv) ||
          selected[0] ||
          null
      }
    case QUERY_SELECTION_SELECT_ALL:
      selected = state.Results.map((res) => {
        return res.int_id
      })
      return {
        ...state,
        Aktiv:
          (state.Aktiv &&
            selected.indexOf(state.Aktiv) !== -1 &&
            state.Aktiv) ||
          selected[0] ||
          null,
        Selected: selected
      }
    case QUERY_SELECTION_CLEAR_ALL:
      return {
        ...state,
        Selected: []
      }
    case SET_QUERY_SELECTION_RANGE:
      // Range ermitteln
      var indexes = uniq(
        state.Selected.map((val) => {
          return state.Results.findIndex((res) => {
            return val == res.int_id
          })
        })
      )
      var currentIndex = state.Results.findIndex((val) => {
        return action.int_id === val.int_id
      })
      var max = Math.max(...indexes, currentIndex)
      var min
      if (max === currentIndex) {
        min = Math.max(...indexes)
      } else {
        min = currentIndex
        max = Math.min(...indexes)
      }
      return {
        ...state,
        Selected: uniq([
          ...state.Results.slice(min, max + 1).map((val) => {
            return val.int_id
          }),
          ...state.Selected
        ])
      }
  }
  return state
}
