import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export const LOAD_TAGESTEXTEMONAT = "LOAD_TAGESTEXTEMONAT"
export const LOAD_TAGESTEXTDATUM = "LOAD_TAGESTEXTDATUM"
export const UPDATE_TAGESTEXT = "UPDATE_TAGESTEXT"
export const EDIT_TAGESTEXTE = "EDIT_TAGESTEXTE"
export const DELETE_TAGESTEXT = "DELETE_TAGESTEXT"
export const UPDATE_TAGESTEXTFP = "UPDATE_TAGESTEXTFP"
export const DELETE_TAGESTEXTFP = "DELETE_TAGESTEXTFP"

export function LoadTagesTexteMonatStarted(int_id, year, month) {
  return {
    type: LOAD_TAGESTEXTEMONAT,
    status: STARTED,
    int_id,
    year,
    month
  }
}
export function LoadTagesTexteMonatSuccess(int_id, year, month, data) {
  return {
    type: LOAD_TAGESTEXTEMONAT,
    status: SUCCESS,
    int_id,
    year,
    month,
    data
  }
}
export function LoadTagesTexteMonatError(int_id, year, month, error) {
  return {
    type: LOAD_TAGESTEXTEMONAT,
    status: ERROR,
    int_id,
    year,
    month,
    error
  }
}

export function LoadTagesTexteMonat(int_id, year, month) {
  return (dispatch) => {
    dispatch(LoadTagesTexteMonatStarted(int_id, year, month))
    request
      .get(`/api/p/${int_id}/tagestexte/${year}/${month}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadTagesTexteMonatError(int_id, year, month, err))
        } else {
          dispatch(
            LoadTagesTexteMonatSuccess(int_id, year, month, response.body)
          )
        }
      })
  }
}

export function LoadTagesTextDatumStarted(int_id, datum) {
  return {
    type: LOAD_TAGESTEXTDATUM,
    status: STARTED,
    int_id,
    datum
  }
}
export function LoadTagesTextDatumSuccess(int_id, datum, data) {
  return {
    type: LOAD_TAGESTEXTDATUM,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}
export function LoadTagesTextDatumError(int_id, datum, error) {
  return {
    type: LOAD_TAGESTEXTDATUM,
    status: ERROR,
    int_id,
    datum,
    error
  }
}

export function LoadTagesTextDatum(int_id, datum) {
  return (dispatch) => {
    dispatch(LoadTagesTextDatumStarted(int_id, datum))
    request.get(`/api/p/${int_id}/tagestext/${datum}`).end((err, response) => {
      if (err) {
        dispatch(LoadTagesTextDatumError(int_id, datum, err))
      } else {
        dispatch(LoadTagesTextDatumSuccess(int_id, datum, response.body))
      }
    })
  }
}

export function EditTagesTexte(int_id, datum, changes, isNew, isDeleted) {
  return {
    type: EDIT_TAGESTEXTE,
    int_id,
    datum,
    changes,
    isNew,
    isDeleted
  }
}

export function TagesTextUnsavedChanges(state) {
  for (let tagestexte of Object.values(state)) {
    for (let row of tagestexte) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function DeleteTagesTextSuccess(int_id, datum) {
  return {
    type: DELETE_TAGESTEXT,
    status: SUCCESS,
    int_id,
    datum
  }
}

export function DeleteTagesTextError(int_id, error) {
  return {
    type: DELETE_TAGESTEXT,
    status: ERROR,
    int_id,
    error
  }
}

export function DeleteTagesTextStarted(int_id, datum) {
  return {
    type: DELETE_TAGESTEXT,
    int_id,
    datum
  }
}

export function UpdateTagesTextStarted(int_id) {
  return {
    type: UPDATE_TAGESTEXT,
    status: STARTED,
    int_id
  }
}

export function UpdateTagesTextSuccess(int_id, datum, data) {
  return {
    type: UPDATE_TAGESTEXT,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}

export function UpdateTagesTextError(int_id, datum, error) {
  return {
    type: UPDATE_TAGESTEXT,
    status: ERROR,
    int_id,
    datum,
    error
  }
}

export function UpdateTagesText(int_id) {
  return (dispatch, getState) => {
    let data = getState().TagesTexte[int_id] || []
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        dispatch(DeleteTagesTextStarted(int_id, row.datum))
        request
          .delete(`/api/p/${int_id}/tagestext/${row.datum}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteTagesTextError(int_id, row.datum, err))
            } else {
              dispatch(DeleteTagesTextSuccess(int_id, row.datum, response.body))
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row
        dispatch(UpdateTagesTextStarted(int_id, row.datum))
        request
          .post(`/api/p/${int_id}/tagestext/${row.datum}`)
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateTagesTextError(int_id, row.datum, err))
            } else {
              dispatch(UpdateTagesTextSuccess(int_id, row.datum, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateTagesTextStarted(int_id))
        request
          .put(`/api/p/${int_id}/tagestext/${row.datum}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateTagesTextError(int_id, row.datum, err))
            } else {
              dispatch(UpdateTagesTextSuccess(int_id, row.datum, response.body))
            }
          })
      }
    }
  }
}

export function DeleteTagesTextFPSuccess(int_id, datum) {
  return {
    type: DELETE_TAGESTEXTFP,
    status: SUCCESS,
    int_id,
    datum
  }
}

export function UpdateTagesTextFPSuccess(int_id, datum, data) {
  return {
    type: UPDATE_TAGESTEXTFP,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}

export function UpdateTagesTextFP() {
  return (dispatch, getState) => {
    let data = getDirtyTagesTextFP(getState())
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/p/${row.int_id}/tagestext/${row.datum}`)
          .send(row)
          .end((err, response) => {
            dispatch(
              DeleteTagesTextFPSuccess(row.int_id, row.datum, response.body)
            )
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post(`/api/p/${row.int_id}/tagestext/${row.datum}`)
          .send(r)
          .end((err, response) => {
            dispatch(
              UpdateTagesTextFPSuccess(row.int_id, row.datum, response.body)
            )
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put(`/api/p/${row.int_id}/tagestext/${row.datum}`)
          .send(row)
          .end((err, response) => {
            dispatch(
              UpdateTagesTextFPSuccess(row.int_id, row.datum, response.body)
            )
          })
      }
    }
  }
}

function getDirtyTagesTextFP(state) {
  let data = state.TagesTexte
  let result = []
  for (let rows of Object.values(data)) {
    for (let row of rows) {
      if (row.__dirty || row.__new || row.__deleted) {
        result.push(row)
      }
    }
  }
  return result
}
