import React from "react"
import {
  CSSTransition,
  TransitionGroup as _TransitionGroup
} from "react-transition-group"
import { css } from "@emotion/css"

const visibleStyles = {
  opacity: 1
}

const hiddenStyles = {
  opacity: 0
}
export const TransitionGroup = ({ children, ...props }) => (
  <_TransitionGroup {...props}>{children}</_TransitionGroup>
)

export const Fade = ({ children, ...props }) => {
  return (
    <CSSTransition
      classNames={{
        appear: css(hiddenStyles),
        appearActive: css({
          ...visibleStyles,
          transition: "all 750ms"
        }),
        enter: css(hiddenStyles),
        enterActive: css({
          ...visibleStyles,
          transition: "all 750ms"
        }),
        enterDone: css({
          ...visibleStyles
        }),
        exit: css(visibleStyles),
        exitActive: css({
          ...hiddenStyles,
          transition: "all 750ms"
        }),
        exitDone: "my-done-exit"
      }}
      timeout={500}
      appear
      {...props}
    >
      {children}
    </CSSTransition>
  )
}
