import {
  LOAD_TAETIGKEITEN,
  LOAD_PLAN_TAETIGKEITEN,
  ADDED_TAETIGKEIT,
  REMOVED_TAETIGKEIT,
  LOAD_DIENST_ZUORDNUNG,
  ADD_TAETIGKEIT,
  CREATE_TAETIGKEIT,
  SAVED_TAETIGKEIT,
  EDIT_TAETIGKEITS_ZORD,
  DELETE_TAETIGKEITS_ZORD,
  CHANGE_SORT_ORDER,
  EDIT_TAETIGKEIT,
  LOAD_SWITCH_DIENST,
  REMOVE_TAETIGKEIT
} from "../actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { arrayToObject } from "app/shared/util/conversion"
import { v4 as uuidv4 } from "uuid"
import { arrayMove } from "@dnd-kit/sortable"

export function Taetigkeiten(
  state = {
    data: {},
    byPlan: {},
    dienstzuordnung: {},
    switchDienst: [],
    error: null,
    loading: false
  },
  action
) {
  switch (action.type) {
    case LOAD_TAETIGKEITEN:
      switch (action.status) {
        case STARTED:
          return {
            ...state,
            loading: true,
            error: null,
            byPlan: {
              ...state.byPlan,
              [action.PlanID]: TaetigkeitenByPlan(
                state.byPlan[action.PlanID],
                action
              )
            }
          }
        case ERROR:
          return {
            ...state,
            loading: false,
            error: action.err,
            byPlan: {
              ...state.byPlan,
              [action.PlanID]: TaetigkeitenByPlan(
                state.byPlan[action.PlanID],
                action
              )
            }
          }
        case SUCCESS:
          return {
            ...state,
            data: {
              ...state.data,
              ...arrayToObject(action.data || [], "id")
            },
            loading: false,
            error: null
          }
      }
      break
    case LOAD_SWITCH_DIENST:
      return { ...state, switchDienst: action.data }
    case EDIT_TAETIGKEIT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...state.data[action.id],
            ...action.changes,
            __dirty: true
          }
        }
      }
    case LOAD_PLAN_TAETIGKEITEN:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: true, error: null }
        case ERROR:
          return { ...state, loading: false, error: action.err }
        case SUCCESS:
          return {
            ...state,
            data: {
              ...state.data,
              ...arrayToObject(action.data || [], "id")
            },
            loading: false,
            error: null,
            byPlan: {
              ...state.byPlan,
              [action.PlanID]: TaetigkeitenByPlan(
                state.byPlan[action.PlanID],
                action
              )
            }
          }
      }
      break
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        byPlan: {
          ...state.byPlan,
          [action.PlanID]: {
            ...state.byPlan[action.PlanID],
            data: arrayMove(
              state.byPlan[action.PlanID].data,
              action.OldIndex,
              action.NewIndex
            )
          }
        }
      }
    case LOAD_DIENST_ZUORDNUNG:
      return {
        ...state,
        dienstzuordnung: {
          ...state.dienstzuordnung,
          [action.PlanID]: action.data
        }
      }
    case ADD_TAETIGKEIT: //Taetigkeitszuordnung ! TODO: Rename Action
      return {
        ...state,
        dienstzuordnung: {
          ...state.dienstzuordnung,
          [action.PlanID]: [
            ...(state.dienstzuordnung[action.PlanID] || []),
            {
              id: uuidv4(),
              plan_id: action.PlanID,
              dienst_id: null,
              ab_datum: "1900-01-01T00:00:00Z",
              bis_datum: null,
              __new: true
            }
          ]
        }
      }
    case EDIT_TAETIGKEITS_ZORD:
      return {
        ...state,
        dienstzuordnung: EditTaetigkeitsZord(state.dienstzuordnung, action)
      }
    case DELETE_TAETIGKEITS_ZORD:
      return {
        ...state,
        dienstzuordnung: DeleteTaetigkeitsZord(state.dienstzuordnung, action)
      }
    case REMOVED_TAETIGKEIT:
      return {
        ...state,
        dienstzuordnung: {
          ...state.dienstzuordnung,
          [action.PlanID]: (state.dienstzuordnung[action.PlanID] || []).filter(
            (row) => {
              if (row.id == action.ID) {
                return false
              }
              return true
            }
          )
        }
      }
    case REMOVE_TAETIGKEIT:
      return {
        ...state,
        dienstzuordnung: {
          ...state.dienstzuordnung,
          [action.PlanID]: (state.dienstzuordnung[action.PlanID] || []).map(
            (row) => {
              if (row.id == action.ID) {
                return { ...row, __deleted: true }
              }
              return row
            }
          )
        }
      }
    case ADDED_TAETIGKEIT:
      return {
        ...state,
        dienstzuordnung: {
          ...state.dienstzuordnung,
          [action.PlanID]: (state.dienstzuordnung[action.PlanID] || []).map(
            (row) => {
              if (row.id == action.ID) {
                return action.Data
              }
              return row
            }
          )
        }
      }
    case CREATE_TAETIGKEIT:
      var id = uuidv4()
      return {
        ...state,
        data: {
          ...state.data,
          [id]: { bezeichnung: action.Name, id: id, __new: true, wochen: 1 }
        }
      }
    case SAVED_TAETIGKEIT:
      var dat
      if (action.data === null) {
        dat = { ...state.data }
        delete dat[action.id]
        return { ...state, data: dat }
      } else if (action.data.id !== id) {
        dat = { ...state.data }
        delete dat[action.id]
        dat[action.data.id] = action.data
        return { ...state, data: dat }
      }
      return { ...state, data: { ...state.data, [action.id]: action.data } }
  }
  return state
}

function EditTaetigkeitsZord(state = {}, action) {
  let nstate = { ...state }
  for (let k of Object.keys(state)) {
    nstate[k] = state[k].map((row) => {
      if (row.id == action.ID) {
        return { ...row, ...action.changes, __dirty: true }
      }
      return row
    })
  }
  return nstate
}

function DeleteTaetigkeitsZord(state = {}, action) {
  let nstate = { ...state }
  for (let k of Object.keys(state)) {
    nstate[k] = state[k].map((row) => {
      if (row.id == action.ID) {
        return { ...row, __deleted: true }
      }
      return row
    })
  }
  return nstate
}

export function TaetigkeitenByPlan(
  state = { loading: false, error: null, data: [] },
  action
) {
  switch (action.type) {
    case LOAD_PLAN_TAETIGKEITEN:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: true, error: null, data: [] }
        case ERROR:
          return { ...state, loading: false, error: action.err, data: [] }
        case SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
            data: action.data
              .map((t) => {
                return t.id
              })
              .filter((v, i, arr) => {
                return arr.indexOf(v) === i
              })
          }
      }
      break

    //case ADD_TAETIGKEIT:
    //if(includes(state.removed, action.ID)){
    //return {...state, removed:[...state.removed.filter((val)=>{
    //return val !== action.ID
    //})]}

    //}else{
    //return {...state, data:[...state.added, action.ID]}
    //}

    // case REMOVE_TAETIGKEIT:
    //   return {
    //     ...state,
    //     data: [...state.data].filter((v) => v.id != action.ID)
    //   }

    //case ADDED_TAETIGKEIT:
    //return {...state, data:[...state.data, action.ID], added:[...state.added.filter((val)=>{
    //return val !== action.ID
    //})]}
    //case REMOVED_TAETIGKEIT:
    //return {...state, data:[...state.data].filter((val)=>{
    //return val !== action.ID
    //}), removed:[...state.removed].filter((val)=>{
    //return val !== action.ID
    //})}
  }
  return state
}

export function isTaetigkeitRemoved(ID, removed) {
  for (let rem of removed) {
    if (rem == ID) {
      return true
    }
  }
  return false
}

export function isTaetigkeitNew(ID, added) {
  for (let rem of added) {
    if (rem == ID) {
      return true
    }
  }
  return false
}
