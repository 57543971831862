import { LOADED_NO_COUNT_FZ } from "../actions/various"
import { CHECK_DPL } from "../actions/records"
export function NoCountFz(state = {}, action) {
  if (action.type == LOADED_NO_COUNT_FZ) {
    return action.value
  }
  return state
}

export function CheckDpl(state = [], action) {
  if (action.type == CHECK_DPL) {
    return action.data
  }
  return state
}
