import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"
import {
  MQUEUE_EMPFAENGERZUORD,
  ADD_MQUEUE_EMPFAENGERZUORD_ROW,
  UPDATE_MQUEUE_EMPFAENGERZUORD,
  EDIT_MQUEUE_EMPFAENGERZUORD,
  DELETE_MQUEUE_EMPFAENGERZUORD_ROW,
  DELETED_MQUEUE_EMPFAENGERZUORD_ROW
} from "./empfaengerzuord_actions"

export default function EmpfaengerZuord(state = { data: {} }, action) {
  switch (action.type) {
    case MQUEUE_EMPFAENGERZUORD:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            data: {
              ...state.data,
              [action.user_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case UPDATE_MQUEUE_EMPFAENGERZUORD:
      if (action.status == SUCCESS) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.user_id]: state.data[action.user_id].map((row) => {
              if (row.id == action.id) {
                return action.data
              }
              return row
            })
          }
        }
      }
      return state
    case EDIT_MQUEUE_EMPFAENGERZUORD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.user_id]: state.data[action.user_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, ...action.changes, __dirty: true }
            }
            return row
          })
        }
      }
    case ADD_MQUEUE_EMPFAENGERZUORD_ROW:
      return {
        ...state,
        data: {
          ...state.data,
          [action.user_id]: [
            ...state.data[action.user_id],
            {
              id: uuidv4(),
              __new: true,
              user_id: action.user_id
              // id: nextlnbr
            }
          ]
        }
      }
    case DELETED_MQUEUE_EMPFAENGERZUORD_ROW:
      if (action.status == SUCCESS) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.user_id]: state.data[action.user_id].filter((row) => {
              if (row.id == action.id) {
                return false
              }
              return true
            })
          }
        }
      }
      return state
    case DELETE_MQUEUE_EMPFAENGERZUORD_ROW:
      return {
        ...state,
        data: {
          ...state.data,
          [action.user_id]: state.data[action.user_id].map((row) => {
            if (row.id == action.id) {
              return { ...row, __deleted: true }
            }
            return row
          })
        }
      }
  }
  return state
}
