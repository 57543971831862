import PropTypes from "prop-types"
/**
 * @module app
 * @description Arion AppManager
 *              verantwortlich für Login Status
 */
import React, { lazy, Suspense } from "react"

import { default_theme, dark_theme } from "app/theme"
import ArionLogon from "app/shared/authentication/logon.js"
const ArionRouterLazy = lazy(() => import("./router/router.js"))
import { PageLoading } from "app/shared/ui/various.js"
import isInfo from "app/info/util"
import InitUser from "app/shared/authentication/initial_user"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import WSHandler from "app/ws/ws"
import { CheckLogin, Logout } from "./shared/authentication/actions.js"
import { LoadVersion } from "./shared/version/version_actions.js"
import { Provider } from "react-redux"
import Icon from "app/shared/ui/icons/icons"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import store from "./state"
import { ConduitProvider } from "app/shared/conduit"
import ReactTooltip from "react-tooltip"
import { UserContext } from "./usercontext"
import { ThemeProvider } from "@mui/material/styles"
import theme from "app/shared/ui/mui-theme"

const ArionRouter = () => {
  return (
    <Suspense fallback={<div />}>
      <ArionRouterLazy />
    </Suspense>
  )
}

require("date-fns/locale/de")
/**
 * @class ArionApp
 * @memberOf app
 * @description Top Level Component
 *              Falls eingeloggt rendert er die passenden
 *              Kind Komponenten ansonten die Logon Seite
 */
export class ArionApp extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    checkLogin: PropTypes.func,
    loadVersion: PropTypes.func,
    version: PropTypes.object,
    theme: PropTypes.object,
    autologoff: PropTypes.number
  }
  // static childContextTypes = {
  //   theme: PropTypes.object,
  //   isInfo: PropTypes.bool,
  //   user_id: PropTypes.number,
  //   int_id: PropTypes.number,
  //   user_type: PropTypes.string,
  //   version: PropTypes.object
  // }

  // getChildContext() {
  //   return {
  //     theme: this.props.theme || default_theme,
  //     isInfo: isInfo(),
  //     user_id: this.props.currentUser.user.user_id,
  //     int_id: this.props.currentUser.user.int_id,
  //     version: this.props.version
  //   }
  // }
  /**
   * Erstellt eine neue ArionApp
   * Wird von React aufgerufen - wenn es im Dom gerendert wird,
   * sollte wie alle React Componenten nicht direkt erstellt werden.
   * @example
   * reactDom.render(<ArionApp/>, document.getElementById("root"))
   * @param  {object} props erwartet keine props
   * @return {ArionApp Instanz}       die erstellte Instanz
   */
  constructor(props) {
    super(props)
    this.state = Object.assign({}, { language: "de" })
    this.autologoffTimer = null
    this.InitAutoLogoff = this.InitAutoLogoff.bind(this)
  }

  /**
   * Hört auf events des AuthenticationStore - und prüft den
   * aktuellen Loginstatus
   * @return {undefined}
   */
  componentDidMount() {
    this.props.checkLogin()
    this.props.loadVersion()
    this.InitAutoLogoff()
    window.addEventListener("click", this.InitAutoLogoff)
    window.addEventListener("mousemove", this.InitAutoLogoff)
    window.addEventListener("keydown", this.InitAutoLogoff)
  }

  componentDidUpdate(oldProps) {
    if (oldProps.autologoff !== this.props.autologoff) {
      this.InitAutoLogoff()
    }
  }

  InitAutoLogoff() {
    if (
      this.props.autologoff > 0 &&
      this.props.currentUser.user.user_type == "info"
    ) {
      if (this.autologoffTimer !== null) {
        clearTimeout(this.autologoffTimer)
      }
      if (this.notifyLogoffTimer) {
        clearTimeout(this.notifyLogoffTimer)
      }
      if (this.notifyInterval) {
        clearInterval(this.notifyInterval)
        this.setState({ notifyLogoutSoon: 0 })
      }
      this.notifyLogoffTimer = setTimeout(() => {
        let login_status = this.props.currentUser.user.logged_in
        if (login_status == true) {
          this.setState({ notifyLogoutSoon: 5 })
          this.notifyInterval = setInterval(() => {
            if (this.state.notifyLogoutSoon == 0) {
              clearInterval(this.notifyInterval)
            } else {
              this.setState({
                notifyLogoutSoon: this.state.notifyLogoutSoon - 1
              })
            }
          }, 1000)
        }
      }, (this.props.autologoff - 5) * 1000)
      this.autologoffTimer = setTimeout(() => {
        let login_status = this.props.currentUser.user.logged_in
        if (login_status == true) {
          this.props.logout()
        }
        this.InitAutoLogoff()
      }, this.props.autologoff * 1000)
    }
  }

  /**
   * Rendert den ArionApp Componenten
   * @return {String} das genertierte HTML
   */
  render() {
    let login_status = this.props.currentUser.user.logged_in
    let needs_new_password = this.props.currentUser.user.new_password_required
    if (login_status === "UNKNOWN") {
      return <PageLoading />
    } else if (login_status !== true || needs_new_password) {
      if (this.props.currentUser.user.needs_init === true) {
        return <InitUser />
      }
      return (
        <ArionLogon
          profile={this.props.currentUser.user}
          error={this.props.currentUser.error}
        />
      )
    } else if (login_status === true) {
      const context = {
        theme: this.props.theme || default_theme,
        isInfo: isInfo(),
        user_id: this.props.currentUser.user.user_id,
        int_id: this.props.currentUser.user.int_id,
        version: this.props.version
      }
      return (
        <div
          id="app_wrapper"
          css={{ backgroundColor: default_theme.window_background_color }}
        >
          <WSHandler />
          <UserContext.Provider value={context}>
            <ArionRouter version={this.props.version} />
          </UserContext.Provider>
          {this.state.notifyLogoutSoon ? (
            <div
              css={{
                position: "absolute",
                top: "calc(50% - 250px)",
                right: "calc(50% - 70px)",
                width: "230px",
                height: "70px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "2px 2px 2px #ccc",
                padding: "10px",
                fontSize: "18px",
                textAlign: "center",
                lineHeight: "50px",
                backgroundColor: "var(--panel-bg-color)"
              }}
            >
              <Icon icon="triangle-exclamation" css={{ color: "#900" }} />{" "}
              Logout in {this.state.notifyLogoutSoon}s!
            </div>
          ) : null}
          {/* <ReactTooltip
            type="dark"
            effect="solid"
            place="bottom"
            delayShow={550}
          /> */}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    version: state.Version,
    theme: default_theme,
    autologoff: parseInt(
      (state.currentUser &&
        state.currentUser.user &&
        state.currentUser.user.autologoff) ||
        0
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => {
      dispatch(CheckLogin())
    },
    loadVersion: () => {
      dispatch(LoadVersion())
    },
    logout: () => {
      dispatch(Logout())
    }
  }
}

import { connect } from "react-redux"

const CArionApp = connect(mapStateToProps, mapDispatchToProps)(ArionApp)

//const DnDArionApp = DndContext(HTML5Backend)(CArionApp)
//const AsyncMode = React.unstable_AsyncMode
export const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ConduitProvider>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={theme}>
              <CArionApp />
            </ThemeProvider>
          </DndProvider>
        </ConduitProvider>
      </Provider>{" "}
    </React.StrictMode>
  )
}
