import {
  LOAD_DEFINITIONEN,
  EDIT_DEFINITION,
  ADD_DEFINITION,
  UPDATE_DEFINITION,
  REMOVE_DEFINITION
} from "../actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"

export function Definitionen(state = {}, action) {
  switch (action.type) {
    case LOAD_DEFINITIONEN:
    case ADD_DEFINITION:
    case UPDATE_DEFINITION:
    case REMOVE_DEFINITION:
      return {
        ...state,
        [action.PlanID]: Definition(state[action.PlanID], action)
      }
    case EDIT_DEFINITION:
      var nState = { ...state }
      for (let [key, value] of Object.entries(state)) {
        nState[key] = Definition(value, action)
      }
      return nState
  }
  return state
}

export function Definition(
  state = { loading: false, data: [], error: null },
  action
) {
  switch (action.type) {
    case LOAD_DEFINITIONEN:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: false, data: [], error: null }
        case ERROR:
          return { ...state, loading: false, data: [], error: action.err }
        case SUCCESS:
          return { ...state, loading: false, data: action.data, error: null }
      }
      break
    case EDIT_DEFINITION:
    case REMOVE_DEFINITION:
      return {
        ...state,
        data: state.data.map((def) => {
          if (def.id === action.id) {
            return SingleDefinition(def, action)
          }
          return def
        })
      }
    case ADD_DEFINITION:
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: uuidv4(),
            plan_id: action.PlanID,
            ab_datum: "1900-01-01T00:00:00Z",
            bis_datum: null,
            pers_art: "pers",
            __new: true
          }
        ]
      }

    case UPDATE_DEFINITION:
      if (action.data === null) {
        return {
          ...state,
          data: [...state.data].filter((def) => {
            return def.id !== action.id
          })
        }
      }
      return {
        ...state,
        data: state.data.map((def) => {
          if (def.id == action.id) {
            return action.data
          }
          return def
        })
      }
  }
  return state
}

export function SingleDefinition(state = {}, action) {
  switch (action.type) {
    case EDIT_DEFINITION:
      return { ...state, ...action.changes, __dirty: true }
    case REMOVE_DEFINITION:
      return { ...state, __deleted: true }
  }
  return state
}
