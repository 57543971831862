import { LOAD_ATAB, SAVED_ATAB, UPDATED_ATAB } from "./actions"
import { STARTED, SUCCESS, ERROR } from "app/state/constants"

export function Atabs(state = { atabs: [], isFetching: false, error: null }, action) {
  switch (action.type) {
    case LOAD_ATAB:
      switch (action.status) {
        case STARTED:
          return { ...state, atabs: [], isFetching: true, error: null }
        case ERROR:
          return { ...state, isFetching: false, error: action.error }
        case SUCCESS:
          return { ...state, atabs: action.atabs, isFetching: false, error: null }
      }
      break
    case UPDATED_ATAB:
      return {
        ...state,
        atabs: [
          ...state.atabs.filter(atab => {
            return !(atab.tab_art === action.atab.tab_art && atab.tab_key === action.atab.tab_key)
          }),
          action.atab
        ]
      }
    case SAVED_ATAB:
      return { ...state, atabs: [...state.atabs, action.atab] }
  }
  return state
}
