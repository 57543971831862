import {
  LOAD_QUALI,
  SET_QUALI_MA,
  SET_QUALI_QUALI,
  SET_SELECTED_QUALI_ROW,
  EDIT_QUALI,
  ADD_QUALI,
  DELETE_QUALI,
  CLEAR_DIRTY_QUALIS,
  LOAD_QUALI_PERS,
  LOAD_QUALI_QUALI
} from "../actions/qualifikationen.js"
import { v4 as uuidv4 } from "uuid"
import { arrayToObject } from "app/shared/util/conversion"
import Datetime from "../../shared/dateutil/index.js"

const makeQualiDefaults = () => {
  return {
    lst_upd: Datetime.now().toISO(),
    int_id: null,
    quali_id: null,
    ab_datum: Datetime.now().startOf("day").toISO(),
    bis_dat: null,
    __new: true
  }
}

export const Qualifikationen = (
  state = {
    qualis: {},
    selectedMa: null,
    selectedQuali: null,
    selectedRow: null,
    pers: [],
    quali: []
  },
  action
) => {
  switch (action.type) {
    case SET_SELECTED_QUALI_ROW:
      return { ...state, selectedRow: action.value }
    case DELETE_QUALI:
      return {
        ...state,
        qualis: {
          ...state.qualis,
          [action.id]: { ...state.qualis[action.id], __deleted: true }
        }
      }
    case CLEAR_DIRTY_QUALIS:
      let qualis = state.qualis
      let newQualis = {}
      for (let row of Object.values(qualis)) {
        if (row.__deleted) {
          continue
        }
        if (row.__new) {
          continue
        }
        if (row.__dirty) {
          continue
        }
        newQualis[row.id] = row
      }
      return {
        ...state,
        qualis: newQualis
      }
    case EDIT_QUALI:
      return {
        ...state,
        qualis: {
          ...state.qualis,
          [action.id]: {
            ...state.qualis[action.id],
            ...action.changes,
            __dirty: true
          }
        }
      }
    case ADD_QUALI:
      const id = uuidv4()
      return {
        ...state,
        qualis: {
          ...state.qualis,
          [id]: { ...makeQualiDefaults(), ...action.defaults, id: id }
        }
      }
    case SET_QUALI_QUALI:
      return { ...state, selectedQuali: action.value }
    case SET_QUALI_MA:
      return { ...state, selectedMa: action.value }
    case LOAD_QUALI_PERS:
      return { ...state, pers: action.data }
    case LOAD_QUALI_QUALI:
      return { ...state, quali: action.data }
    case LOAD_QUALI:
      return {
        ...state,
        qualis: { ...state.qualis, ...arrayToObject(action.data, "id") }
      }
  }
  return state
}
