import {
  LOAD_PLAENE,
  CREATE_PLAN,
  DELETE_PLAN,
  UPDATE_PLAN,
  SELECT_PLAN,
  EDIT_PLAN,
  LOAD_DEFAULT_PLAN
} from "../actions"

import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export function Plaene(
  state = {
    data: [],
    defaultplan: null,
    selected: null,
    error: null,
    loading: false
  },
  action
) {
  switch (action.type) {
    case SELECT_PLAN:
      return { ...state, selected: action.id }
    case LOAD_DEFAULT_PLAN:
      return { ...state, defaultplan: action.data }
    case LOAD_PLAENE:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: true, error: null }
        case ERROR:
          return { ...state, loading: false, error: action.err }
        case SUCCESS:
          return { ...state, loading: false, error: null, data: action.data }
      }
      break
    case EDIT_PLAN:
      return {
        ...state,
        data: [...state.data].map((plan) => {
          if (plan.id == action.id) {
            return { ...plan, ...action.changes }
          }
          return plan
        })
      }
    case CREATE_PLAN:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: true, error: null }
        case ERROR:
          return { ...state, loading: false, error: action.err }
        case SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
            data: [...state.data, action.data]
          }
      }
      break
    case UPDATE_PLAN:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: true, error: null }
        case ERROR:
          return { ...state, loading: false, error: action.err }
        case SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
            data: [...state.data].map((plan) => {
              if (plan.id === action.data.id) {
                return action.data
              }
              return plan
            })
          }
      }
      break
    case DELETE_PLAN:
      switch (action.status) {
        case STARTED:
          return { ...state, loading: true, error: null }
        case ERROR:
          return { ...state, loading: false, error: action.err }
        case SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
            data: [...state.data].filter((plan) => {
              return plan.id !== action.id
            })
          }
      }
  }
  return state
}
