import request from "superagent"
import DateTime from "app/shared/dateutil"
import { SUCCESS, STARTED } from "app/state/constants"
export const LOAD_PLACEHOLDERS = "dpl/LOAD_PLACEHOLDERS"
import { CountSummen } from "./summen"

export function LoadPlaceHolders(von, bis) {
  return (dispatch, getState) => {
    let state = getState()
    let PlanID = state.Dpl.Plaene.selected
    if (PlanID == 0) {
      let intID = state.currentUser.user.int_id
      dispatch(LoadPlaceHoldersStarted(PlanID, von, bis))
      request
        .get(
          `/api/dpl/int_id/default/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${intID}`
        )
        .end((err, response) => {
          dispatch(LoadedPlaceHolders(PlanID, von, bis, response.body))
          dispatch(CountSummen())
        })
    } else {
      dispatch(LoadPlaceHoldersStarted(PlanID, von, bis))
      request
        .get(
          `/api/dpl/default/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
        )
        .end((err, response) => {
          dispatch(LoadedPlaceHolders(PlanID, von, bis, response.body))
          dispatch(CountSummen())
        })
    }
  }
}

export function LoadPlaceHoldersStarted(PlanID, von, bis) {
  return {
    type: LOAD_PLACEHOLDERS,
    status: STARTED,
    PlanID,
    von,
    bis
  }
}

export function LoadedPlaceHolders(PlanID, von, bis, data) {
  return {
    type: LOAD_PLACEHOLDERS,
    status: SUCCESS,
    PlanID,
    von,
    bis,
    data
  }
}
