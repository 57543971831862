import request from "superagent"
import { SUCCESS, STARTED } from "../../state/constants"
import Datetime from "app/shared/dateutil/index"
export const SPERRDATUM_CHECK = "SPERRDATUM_CHECK"
export const NOSPERRE = "NOSPERRE"

export function SperrDatumCheck(int_id, datum) {
  return (dispatch, getState) => {
    if (!int_id || !datum) {
      return
    }

    dispatch({
      type: SPERRDATUM_CHECK,
      status: STARTED,
      int_id,
      datum
    })
    request
      .get(
        `/api/security/sperrdatum/${int_id}/${Datetime.fromISO(
          datum
        ).toISODate()}`
      )
      .end((err, response) => {
        dispatch({
          type: SPERRDATUM_CHECK,
          status: SUCCESS,
          int_id,
          datum,
          data: response.body
        })
      })
  }
}

export function NoSperreCheck(int_id, user_id) {
  return (dispatch) => {
    request
      .get(`/api/security/nosperre/${int_id}/${user_id}`)
      .end((err, response) => {
        dispatch({
          type: NOSPERRE,
          status: SUCCESS,
          int_id,
          user_id,
          data: response.body
        })
      })
  }
}
