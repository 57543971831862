import {
  TOGGLE_MENU,
  TOGGLE_MENU_PINNED,
  LOAD_MENU,
  LOAD_MENU_STATUS_STARTED,
  LOAD_MENU_STATUS_ERROR,
  LOAD_MENU_STATUS_SUCCESS,
  TOGGLE_MENU_GROUP,
  LOAD_GROUPS
} from "./menu_actions"

export function Menu(
  state = {
    error: null,
    items: [],
    groups: [],
    visible: false,
    pinned: false,
    activeGroup: null
  },
  action
) {
  switch (action.type) {
    case LOAD_GROUPS:
      return { ...state, groups: action.data }
    case LOAD_MENU:
      switch (action.status) {
        case LOAD_MENU_STATUS_SUCCESS:
          return { ...state, error: action.error, items: action.menu }
        case LOAD_MENU_STATUS_STARTED:
          return { ...state, error: null, items: [] }
        case LOAD_MENU_STATUS_ERROR:
          return { ...state, error: action.error, items: [] }
      }
      break
    case TOGGLE_MENU:
      return { ...state, visible: !state.visible }
    case TOGGLE_MENU_PINNED:
      return { ...state, pinned: !state.pinned }
    case TOGGLE_MENU_GROUP:
      if (action.group === state.activeGroup) {
        return { ...state, activeGroup: null }
      }
      return { ...state, activeGroup: action.group }
  }
  return state
}
