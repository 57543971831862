import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { queryToString } from "app/shared/util"
export const LOAD_FEHLERPROTOKOLL = "LOAD_FEHLERPROTOKOLL"
//export const UPDATE_FEHLERPROTOKOLL = "UPDATE_FEHLERPROTOKOLL"
export const UPDATE_FEHLERPROTOKOLL = "UPDATE_FEHLERPROTOKOLL"
export const BUCHUNG_MESSAGE = "BUCHUNG_MESSAGE"

export function LoadFehlerprotokoll(int_id) {
  return (dispatch) => {
    dispatch({
      type: LOAD_FEHLERPROTOKOLL,
      status: STARTED,
      int_id
    })
    request.get(`/api/p/${int_id}/fehlerprotokoll`).end((err, response) => {
      if (err) {
        dispatch({
          type: LOAD_FEHLERPROTOKOLL,
          status: ERROR,
          int_id
        })
      } else {
        dispatch({
          type: LOAD_FEHLERPROTOKOLL,
          status: SUCCESS,
          int_id,
          data: response.body
        })
      }
    })
  }
}

export function LoadFehlerprotokollQuery(query) {
  return (dispatch) => {
    dispatch({
      type: LOAD_FEHLERPROTOKOLL,
      status: STARTED
    })
    request
      .get("/api/fehlerprotokoll/query")
      .query(queryToString(query))
      .end((err, response) => {
        if (err) {
          dispatch(
            dispatch({
              type: LOAD_FEHLERPROTOKOLL,
              status: ERROR
            })
          )
        } else {
          dispatch({
            type: LOAD_FEHLERPROTOKOLL,
            status: SUCCESS,
            data: response.body
          })
        }
      })
  }
}

export function UpdateFehlerProtokoll(id) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_FEHLERPROTOKOLL,
      status: STARTED,
      id
    })
    let fprot = getState().Fehlerprotokoll.data.find((f) => f.id == id)
    if (!fprot) {
      return
    }

    request
      .put(`/api/fehler/${id}/prot`)
      .send({ ...fprot, kz_wdh: "0" })
      .end((err, response) => {
        if (err) {
          dispatch({
            type: UPDATE_FEHLERPROTOKOLL,
            status: ERROR,
            id,
            error: err
          })
        } else {
          dispatch({
            type: UPDATE_FEHLERPROTOKOLL,
            status: SUCCESS,
            id,
            data: response.body
          })
        }
      })
  }
}

export const SetKernZeitOK = (id, int_id, datum) => {
  return (dispatch) => {
    request
      .post(`/api/p/${int_id}/kernzeit/${datum.slice(0, 10)}/ok`)
      .end(() => {
        dispatch(UpdateFehlerProtokoll(id))
      })
  }
}
