import request from "superagent"
import { getVonBis } from "./datum"
import Datetime from "../../shared/dateutil/index"
export const LOAD_TEMPV = "dpl/LOAD_TEMPV"

export default function LoadTempV(IntID) {
  return (dispatch, getState) => {
    if (!IntID) {
      return
    }
    let datum = getState().Dpl.Datum
    let [von, bis] = getVonBis(datum)
    let dispVon = Datetime.fromISO(datum.date).startOf("month")
    let dispBis = Datetime.fromISO(datum.bis).endOf("month")
    request
      .get(
        `/api/dpl/tempv/${dispVon.toISODate()}/${dispBis.toISODate()}/${IntID}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_TEMPV,
            int_id: IntID,
            von: dispVon.toJSDate(),
            bis: dispBis.toJSDate(),
            data: response.body
          })
        }
      })
  }
}

export function CreateTempV(TempV) {
  return (dispatch, getState) => {
    request
      .post("/api/dpl/tempv/")
      .send(TempV)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadTempV(TempV.int_id))
        }
      })
  }
}

export function UpdateTempV(TempV) {
  return (dispatch, getState) => {
    request
      .put("/api/dpl/tempv/")
      .send(TempV)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadTempV(TempV.int_id))
        }
      })
  }
}

export function DeleteTempV(TempV) {
  return (dispatch, getState) => {
    request.delete(`/api/dpl/tempv/${TempV.id}`).end((err, response) => {
      if (!err) {
        dispatch(LoadTempV(TempV.int_id))
      }
    })
  }
}
