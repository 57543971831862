export const default_theme = {
  /**
   * HinterGrund des Browser Fensters
   */
  window_background_color: "var(--main-bg-color)",

  /**
   * Ui Kontrastfarbe 1
   */
  ui_contrast_color_darker: "var(--panel-bg-color)",

  /**
   * UI Kontrastfarbe 2
   */
  ui_contrast_color_light: "var(--light-bg-color)",

  /**
   * Font Family
   */
  font_family_1: "var(--main-font), Arial",

  /**
   * Info Text Farbe 1
   */
  info_text_color_1: "#009",

  /**
   * Info Text Farbe 2
   */
  info_text_color_2: "#090",

  /**
   * Info Text Farbe 3
   */
  info_text_color_3: "#900",

  /**
   * Schrift Farbe 1
   */
  ui_font_color_light: "#999",

  /**
   * Schrift Farbe 2
   */
  ui_font_color_dark: "#000",

  /**
   * Input HintergrundFarbe
   */
  input_background_color_default: "var(--input-default-bg-color)",

  /**
   * Input Schriftfarbe
   */
  input_font_color_default: "#444",

  /**
   * Input Rahmen Farbe
   */
  input_border_color_hover: "#eef",

  /**
   * Input Rahmenfarbe focus
   */
  input_border_color_active: "#2cf",

  /**
   * Button Hintergrund Farbe
   */
  button_background_color_default: "#fefefe",

  /**
   * Button Schriftarbe
   */
  button_font_color_default: "#444",

  /**
   * Button Hintergrundfarbe Mouseover
   */
  button_background_color_default_hover: "#dedede",

  /**
   * Button Hintergrundfarbe Primary
   */
  button_background_color_primary: "#0df",

  /**
   * Button Schriftfarbe Primary
   */
  button_font_color_primary: "#fff",

  /**
   * Farbe für Wochentag Samstag
   */
  weekday_color_saturday: "#f38630",

  /**
   * Farbe für Wochentag Sonntag
   */
  weekday_color_sunday: "#FA6900",

  /**
   * Farbe für Feiertag
   */
  holiday_color_default: "#69D2E7",

  /**
   * Farbe für Hochfeiertag
   */
  holiday_color_high: "#A7DBD8",

  /**
   * Farbe für Schulferien
   */
  holiday_color_school: "#FAEE7B",

  /**
   * Standard RahmenFarbe
   */
  border_default: "1px solid #ccc",

  /**
   * Rahmenfarbe 2 (Leichte Rahmen)
   */
  border_light: "1px solid #eee",

  /**
   * Rahmenfarbe Aktiv
   */
  border_active_color: "rgba(0, 126, 255, 0.1)",

  /**
   * Standard Rahmentyp
   */
  border_default_style: "solid",

  /**
   * Standard Rahmendicke
   */
  border_default_width: "1px",

  /**
   * Standard Rahmenfarbe
   */
  border_default_color: "#ccc",

  /**
   * "Light" Rahmentyp
   */
  border_light_style: "solid",

  /**
   * "Light" Rahmendicke
   */
  border_light_width: "1px",

  /**
   * "Light" Rahmenfarbe
   */
  border_light_color: "#eee",

  /**
   * Standard Table Row Hintergrundfarbe
   */
  table_row_background_default: "#fff",

  /**
   * Zweite Table Row Hintergrundfarbe
   */
  table_row_background_highlight: "#f6f7f8",

  /**
   * Farbe für Rahmen in Tables
   */
  table_border_color: "#ccc",

  /**
   * Border Radius 1
   */
  border_radius_1: "3px",

  /**
   * Border Radius 2
   */
  border_radius_2: "5px",

  /**
   * Border Radius 3
   */
  border_radius_3: "0px"
}

/**
 * Dunkles Ui Theme
 * Keys wie bei
 * @type {Object}
 */
export const dark_theme = {
  window_background_color: "#000",
  ui_contrast_color_darker: "#333",
  ui_contrast_color_light: "#666",
  font_family_1: "Gill Sans, Arial",
  info_text_color_1: "#009",
  info_text_color_2: "#090",
  info_text_color_3: "#900",
  ui_font_color_light: "#f2f2f2",
  ui_font_color_dark: "#eee",
  label_color_light: "#999",
  input_background_color_default: "#999",
  input_font_color_default: "#f2f2f2",
  input_border_color_hover: "#ee7600",
  input_border_color_active: "#eaa159",
  button_background_color_default: "#444",
  button_font_color_default: "#f2f2f2",
  button_background_color_default_hover: "#dedede",
  button_background_color_primary: "#447",
  button_font_color_primary: "#fff",
  weekday_color_saturday: "#f38630",
  weekday_color_sunday: "#FA6900",
  holiday_color_default: "#69D2E7",
  holiday_color_high: "#A7DBD8",
  holiday_color_school: "#FAEE7B",
  border_default_style: "solid",
  border_default_width: "1px",
  border_default_color: "#777",
  border_light_style: "solid",
  border_light_width: "1px",
  border_light_color: "#eee",
  border_active_color: "#2c9",
  table_row_background_default: "#999",
  table_row_background_highlight: "#ccc",
  table_row_border_style: "none",
  table_border_color: "#ccc",
  border_radius_1: "0px",
  border_radius_2: "5px",
  border_radius_3: "0px"
}
