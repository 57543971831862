import {
  LOAD_DPL_PERS,
  LOAD_UNVERPLANT,
  LOAD_ALL_PERS,
  SET_SORT_ORDER,
  SET_DPL_FILTER,
  SET_DPL_GROUP,
  LOADED_GROUP_ORDER,
  SET_TPL_RECORD,
  TOGGLE_SHOW_ALL,
  SELECT_PLAN
} from "../actions"
import includes from "lodash/includes"
import { arrayMove } from "@dnd-kit/sortable"
import { GetSortedIntIDs } from "../selectors/pers"
import { arrayToObjectFn } from "app/shared/util/conversion"

function getNextShowAll(currentVal, nextVal) {
  if (currentVal == nextVal) {
    return 0
  }
  return nextVal
}

export function Pers(state = { showAll: false }, action) {
  switch (action.type) {
    case SET_TPL_RECORD:
      return { all: state.all, showAll: state.showAll }
    case LOAD_DPL_PERS:
      return {
        ...state,
        [`${action.PlanID}-${action.DienstID}-${action.Datum}`]: action.data
      }
    case TOGGLE_SHOW_ALL:
      return {
        ...state,
        showAll: getNextShowAll(state.showAll, action.data)
      }
    case LOAD_ALL_PERS:
      return { ...state, all: toDisplayArray(action.data) }
    case SELECT_PLAN:
      return { showAll: false }
  }
  return state
}

export function Unverplant(state = {}, action) {
  switch (action.type) {
    case LOAD_UNVERPLANT:
      return { ...state, ...action.data }
  }
  return state
}

function SortPers(pers, int_ids, OldIndex, NewIndex) {
  let nPers = {}
  int_ids = arrayMove(int_ids, OldIndex, NewIndex)
  nPers = int_ids.map((i, idx) => {
    return { ...pers[i], Order: idx }
  })
  return { ...pers, ...arrayToObjectFn(nPers, (p) => p.int_id + "") }
}

export function AllPers(
  state = {
    pers: {},
    intids: [],
    origintids: [],
    filter: {},
    byQuali: {},
    groupSortOrder: {},
    group: null
  },
  action
) {
  let filter
  switch (action.type) {
    case LOAD_ALL_PERS:
      return { ...state, pers: action.data.pers }

    case LOADED_GROUP_ORDER:
      return {
        ...state,
        groupSortOrder: action.data
      }
    case SET_DPL_FILTER:
      filter = { ...state.filter }
      if (action.ftype == "name") {
        filter.name = action.fval
      }
      return { ...state, filter: filter }
    case SET_SORT_ORDER:
      var int_ids = GetSortedIntIDs({ Dpl: { AllPers: state } })
      return {
        ...state,
        pers: SortPers(state.pers, int_ids, action.oldIndex, action.newIndex)
      }
    case SET_DPL_GROUP:
      return { ...state, group: action.group }
  }
  return state
}

function sortByGroup(intids, pers, group, sortOrder) {
  if (group === null) {
    return intids
  }
  let order = sortOrder[group] || {}
  intids = intids.sort((a, b) => {
    if (pers[a][group] == pers[b][group]) {
      return pers[a].fam_name.localeCompare(pers[b].fam_name)
    } else {
      let orderA = order[pers[a][group]] || 9999
      let orderB = order[pers[b][group]] || 9999
      if (orderA != orderB) {
        return orderA < orderB ? -1 : 1
      }
      return pers[a][group] < pers[b][group] ? -1 : 1
    }
  })
  return intids
}

function toDisplayArray(allPers) {
  let result = []
  for (let p of Object.values(allPers.pers)) {
    result.push({
      value: p.int_id,
      label: p.fam_name + ", " + p.vor_name,
      pers_id: p.pers_id,
      isTemp: true
    })
  }
  return result
}

function filterIntids(state) {
  let intids = [...state.origintids]
  let filter = { ...state.filter }
  if (filter.name) {
    intids = intids.filter((id) => {
      var ma = state.pers[id]
      if (includes(ma.fam_name.toLowerCase(), filter.name.toLowerCase())) {
        return true
      }
      if (includes(ma.vor_name.toLowerCase(), filter.name.toLowerCase())) {
        return true
      }
      return false
    })
  }
  return intids
}
