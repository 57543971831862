import { LOAD_KOMPETENZEN } from "./pis_actions"
import { LOAD_PFLICHTVERAN } from "./pis_actions"
import { LOAD_PFLICHTVERANALL } from "./pis_actions"
import { LOAD_MEDIZINPRODUKTE } from "./pis_actions"
import {
  LOAD_INNERBETRIEBLICHEFORTBILDUNG,
  LOAD_GEKLASTAMMAKTUELL
} from "./pis_actions"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export function Kompetenzen(
  state = {
    kompetenzen: {},
    pflichtveran: {},
    pflichtveranall: {},
    medizinprodukte: {},
    innerbetrieblichefortbildung: {},
    geklaaktuell: {}
  },
  action
) {
  switch (action.type) {
    case LOAD_GEKLASTAMMAKTUELL:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            geklaaktuell: {
              ...state.geklaaktuell,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_KOMPETENZEN:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            kompetenzen: {
              ...state.kompetenzen,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_PFLICHTVERAN:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pflichtveran: {
              ...state.pflichtveran,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_PFLICHTVERANALL:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            pflichtveranall: {
              ...state.pflichtveranall,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_MEDIZINPRODUKTE:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            medizinprodukte: {
              ...state.medizinprodukte,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
    case LOAD_INNERBETRIEBLICHEFORTBILDUNG:
      switch (action.status) {
        case STARTED:
          return { ...state, isLoading: true, error: null }
        case SUCCESS:
          return {
            ...state,
            innerbetrieblichefortbildung: {
              ...state.innerbetrieblichefortbildung,
              [action.int_id]: action.data
            },
            isLoading: false,
            error: null
          }
        case ERROR:
          return { ...state, isLoading: false, error: action.error }
      }
      break
  }

  return state
}
