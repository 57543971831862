import Datetime from "app/shared/dateutil/index"
import { arrayToObjectFn } from "app/shared/util/conversion"
import { queryToString } from "app/shared/util/urls"
import request from "superagent"
import { GetVonBis } from "./helpers"

export const LOAD_SPI = "upl/LOAD_SPI"
export const SELECT_SPI = "upl/SELECT_SPI"

var loadTimeOutID = null

const TransformSPI = (spi) => {
  let res = {}
  for (let [abt, abtv] of Object.entries(spi)) {
    res[abt] = {}
    for (let [q, qv] of Object.entries(abtv)) {
      res[abt][q] = {
        ...qv,
        data: arrayToObjectFn(qv.data, (o) => o.datum.slice(0, 10))
      }
    }
  }
  return res
}

export const LoadSPI = (loadCount, query) => {
  return (dispatch, getState) => {
    const { loadVon, setLoadedVon, setLoadedBis, count } = GetVonBis(
      getState(),
      loadCount
    )
    if (count <= 0) {
      return
    }
    clearTimeout(loadTimeOutID)
    request
      .get(
        `/api/urlaubsplanung/date/query/spi/D/${count}/${loadVon.toISODate()}T00:00:00Z`
      )
      .query(queryToString(query))
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_SPI,
            data: TransformSPI(response.body)
          })
        }
      })
  }
}

export const ReloadLoadSPI = (query) => {
  return (dispatch, getState) => {
    const state = getState()
    const loadingStatus = state.UPL.LoadingStatus
    const loadedVon = Datetime.fromISO(loadingStatus.von)
    const loadedBis = Datetime.fromISO(loadingStatus.bis)
    const count = loadedVon.diffDays(loadedBis)
    clearTimeout(loadTimeOutID)
    request
      .get(
        `/api/urlaubsplanung/date/query/spi/D/${count}/${loadedVon.toISODate()}T00:00:00Z`
      )
      .query(queryToString(query))
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_SPI,
            data: TransformSPI(response.body)
          })
        }
      })
  }
}

export const SelectSPI = (value) => {
  return {
    type: SELECT_SPI,
    value
  }
}

export const SaveManuellSPI = (spi) => {
  return (dispatch, getState) => {
    request
      .post("/api/urlaubsplanung/manuell/vorgaben")
      .send(spi)
      .end((err, response) => {
        if (!err) {
          dispatch(ReloadLoadSPI(getState().Query.QuerySelection))
        }
      })
  }
}

export const DeleteManuellSPI = (spi) => {
  return (dispatch, getState) => {
    request
      .delete("/api/urlaubsplanung/manuell/vorgaben")
      .send(spi)
      .end((err, response) => {
        if (!err) {
          dispatch(ReloadLoadSPI(getState().Query.QuerySelection))
        }
      })
  }
}

//    request
//.delete("/api/urlaubsplanung/manuell/vorgaben")
