import React from "react"

export const UserContext = React.createContext({
  theme: {},
  isInfo: false,
  user_id: 0,
  int_id: 0,
  user_type: "",
  version: { build_type: "" }
})
