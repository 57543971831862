import Datetime from "app/shared/dateutil/index"
import { GetPers } from "./pers"

export const Genehmigungen = (state) => state.UPL.Genehmigung

export const GenehmigungStatus = (state, datum, u_abt_id) => {
  const genehmigung = Genehmigungen(state)
  const d = Datetime.fromISO(datum)
  for (let g of genehmigung) {
    if (g.abt_id != u_abt_id) {
      continue
    }
    if (g.status == 2) {
      continue
    }
    if (
      Datetime.fromISO(g.ab_datum) <= d &&
      d <= Datetime.fromISO(g.bis_datum)
    ) {
      return g.status
    }
  }
  return -1
}

export const IsGenehmigungLocked = (state, datum, u_abt_id) => {
  const status = GenehmigungStatus(state, datum, u_abt_id)
  return status == 0 || status == 1
}

export const IsGenehmigungLockedByPers = (state, datum, int_id) => {
  const pers = GetPers(state, parseInt(int_id))
  const status = GenehmigungStatus(state, datum, pers.u_abt_id)
  return status == 0 || status == 1
}
