import request from "superagent"
import swal from "sweetalert"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export const LOAD_NEGATIVERFASSUNG = "LOAD_NEGATIVERFASSUNG"
export const ADD_NEGATIVERFASSUNG_ROW = "ADD_NEGATIVERFASSUNG_ROW"
export const DELETED_NEGATIVERFASSUNG_ROW = "DELETED_NEGATIVERFASSUNG_ROW"
export const DELETE_NEGATIVERFASSUNG_ROW = "DELETE_NEGATIVERFASSUNG_ROW"
export const EDIT_NEGATIVERFASSUNG = "EDIT_NEGATIVERFASSUNG"
export const UPDATE_NEGATIVERFASSUNG = "UPDATE_NEGATIVERFASSUNG"
export const LOAD_URLAUBSANSPRUCH = "LOAD_URLAUBSANSPRUCH"
export const ADD_URLAUBSANSPRUCH_ROW = "ADD_URLAUBSANSPRUCH_ROW"
export const DELETED_URLAUBSANSPRUCH_ROW = "DELETED_URLAUBSANSPRUCH_ROW"
export const DELETE_URLAUBSANSPRUCH_ROW = "DELETE_URLAUBSANSPRUCH_ROW"
export const EDIT_URLAUBSANSPRUCH = "EDIT_URLAUBSANSPRUCH"
export const UPDATE_URLAUBSANSPRUCH = "UPDATE_URLAUBSANSPRUCH"
export const LOAD_FEHLZEITDEF = "LOAD_FEHLZEITDEF"
export const ADD_FEHLZEITDEF_ROW = "ADD_FEHLZEITDEF_ROW"
export const DELETED_FEHLZEITDEF_ROW = "DELETED_FEHLZEITDEF_ROW"
export const DELETE_FEHLZEITDEF_ROW = "DELETE_FEHLZEITDEF_ROW"
export const EDIT_FEHLZEITDEF = "EDIT_FEHLZEITDEF"
export const UPDATE_FEHLZEITDEF = "UPDATE_FEHLZEITDEF"
export const LOAD_FEIERTAGDEF = "LOAD_FEIERTAGDEF"
export const ADD_FEIERTAGDEF_ROW = "ADD_FEIERTAGDEF_ROW"
export const DELETED_FEIERTAGDEF_ROW = "DELETED_FEIERTAGDEF_ROW"
export const DELETE_FEIERTAGDEF_ROW = "DELETE_FEIERTAGDEF_ROW"
export const EDIT_FEIERTAGDEF = "EDIT_FEIERTAGDEF"
export const UPDATE_FEIERTAGDEF = "UPDATE_FEIERTAGDEF"
export const LOAD_BEFEHLSTASTENDEF = "LOAD_BEFEHLSTASTENDEF"
export const EDIT_BEFEHLSTASTENDEF = "EDIT_BEFEHLSTASTENDEF"
export const UPDATE_BEFEHLSTASTENDEF = "UPDATE_BEFEHLSTASTENDEF"
export const LOAD_BEFEHLSTASTENTEXT = "LOAD_BEFEHLSTASTENTEXT"
export const EDIT_BEFEHLSTASTENTEXT = "EDIT_BEFEHLSTASTENTEXT"
export const UPDATE_BEFEHLSTASTENTEXT = "UPDATE_BEFEHLSTASTENTEXT"
export const LOAD_WEGEZEIT = "LOAD_WEGEZEIT"
export const ADD_WEGEZEIT_ROW = "ADD_WEGEZEIT_ROW"
export const DELETED_WEGEZEIT_ROW = "DELETED_WEGEZEIT_ROW"
export const DELETE_WEGEZEIT_ROW = "DELETE_WEGEZEIT_ROW"
export const EDIT_WEGEZEIT = "EDIT_WEGEZEIT"
export const UPDATE_WEGEZEIT = "UPDATE_WEGEZEIT"

function CheckWegezeit(wegezeit) {
  for (let row of wegezeit) {
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
    if (row.weg_zt == null || row.weg_zt == 0) {
      return "bitte Wegezeit angeben"
    }
  }
  return null
}

export function UpdateWegezeitSuccess(id, data) {
  return {
    type: UPDATE_WEGEZEIT,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateWegezeit() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.wegezeit || []
    if (data.length == 0) {
      return
    }
    var err = CheckWegezeit(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/wegezeit/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteWegezeitSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/wegezeit/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateWegezeitSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/wegezeit")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateWegezeitSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function WegezeitUnsavedChanges(state) {
  for (let wegezeit of Object.values(state)) {
    for (let row of wegezeit) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function EditWegezeit(abr_kreis, id, changes) {
  return {
    type: EDIT_WEGEZEIT,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteWegezeitSuccess(id) {
  return {
    type: DELETED_WEGEZEIT_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteWegezeitRow(id) {
  return {
    type: DELETE_WEGEZEIT_ROW,
    id
  }
}

export function AddWegezeitRow(abr_kreis) {
  return {
    type: ADD_WEGEZEIT_ROW,
    abr_kreis
  }
}

export function LoadWegezeitSuccess(data) {
  return {
    type: LOAD_WEGEZEIT,
    status: SUCCESS,
    data
  }
}

export function LoadWegezeit() {
  return (dispatch) => {
    request.get("/api/wegezeit").end((err, response) => {
      dispatch(LoadWegezeitSuccess(response.body))
    })
  }
}

export function LoadBefehlstastenTextSuccess(data) {
  return {
    type: LOAD_BEFEHLSTASTENTEXT,
    status: SUCCESS,
    data
  }
}
export function LoadBefehlstastenText() {
  return (dispatch) => {
    request.get("/api/befehlstastentext").end((err, response) => {
      dispatch(LoadBefehlstastenTextSuccess(response.body))
    })
  }
}
export function UpdateBefehlstastenTextSuccess(bef_tas, data) {
  return {
    type: UPDATE_BEFEHLSTASTENTEXT,
    status: SUCCESS,
    bef_tas,
    data
  }
}
export function UpdateBefehlstastenText() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.befehlstastentext || []
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__dirty && !row.__deleted) {
        request
          .put("/api/befehlstastentext")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateBefehlstastenTextSuccess(row.bef_tas, response.body))
          })
      }
    }
  }
}
export function EditBefehlstastenText(abr_kreis, bef_tas, changes) {
  return {
    type: EDIT_BEFEHLSTASTENTEXT,
    abr_kreis,
    bef_tas,
    changes
  }
}

export function UpdateBefehlstastenDefSuccess(bef_tas, data) {
  return {
    type: UPDATE_BEFEHLSTASTENDEF,
    status: SUCCESS,
    bef_tas,
    data
  }
}

export function UpdateBefehlstastenDef() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.befehlstastendef || []
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__dirty && !row.__deleted) {
        request
          .put("/api/befehlstastendef")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateBefehlstastenDefSuccess(row.bef_tas, response.body))
          })
      }
    }
  }
}

export function EditBefehlstastenDef(abr_kreis, bef_tas, changes) {
  return {
    type: EDIT_BEFEHLSTASTENDEF,
    abr_kreis,
    bef_tas,
    changes
  }
}

export function LoadBefehlstastenDefSuccess(data) {
  return {
    type: LOAD_BEFEHLSTASTENDEF,
    status: SUCCESS,
    data
  }
}

export function LoadBefehlstastenDef() {
  return (dispatch) => {
    request.get("/api/befehlstastendef").end((err, response) => {
      dispatch(LoadBefehlstastenDefSuccess(response.body))
    })
  }
}

function CheckFeiertagDef(feiertagdef) {
  for (let row of feiertagdef) {
    if (row.fehlz_id == null) {
      return "bitte Fehlzeit auswählen"
    }
  }
  return null
}

export function UpdateFeiertagDefSuccess(id, data) {
  return {
    type: UPDATE_FEIERTAGDEF,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateFeiertagDef() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.feiertagdef || []
    if (data.length == 0) {
      return
    }
    var err = CheckFeiertagDef(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/feiertagdef/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteFeiertagDefSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/feiertagdef/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateFeiertagDefSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/feiertagdef")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateFeiertagDefSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function FeiertagDefUnsavedChanges(state) {
  for (let feiertagdef of Object.values(state)) {
    for (let row of feiertagdef) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function EditFeiertagDef(abr_kreis, id, changes) {
  return {
    type: EDIT_FEIERTAGDEF,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteFeiertagDefSuccess(id) {
  return {
    type: DELETED_FEIERTAGDEF_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteFeiertagDefRow(id) {
  return {
    type: DELETE_FEIERTAGDEF_ROW,
    id
  }
}

export function AddFeiertagDefRow(abr_kreis) {
  return {
    type: ADD_FEIERTAGDEF_ROW,
    abr_kreis
  }
}

export function LoadFeiertagDefSuccess(data) {
  return {
    type: LOAD_FEIERTAGDEF,
    status: SUCCESS,
    data
  }
}

export function LoadFeiertagDef() {
  return (dispatch) => {
    request.get("/api/feiertagdef").end((err, response) => {
      dispatch(LoadFeiertagDefSuccess(response.body))
    })
  }
}

function CheckFehlzeitDef(fehlzeitdef) {
  for (let row of fehlzeitdef) {
    if (row.fehlz_id == null) {
      return "bitte Fehlzeit auswählen"
    }
  }
  return null
}

export function UpdateFehlzeitDefSuccess(id, data) {
  return {
    type: UPDATE_FEHLZEITDEF,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateFehlzeitDef() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.fehlzeitdef || []
    if (data.length == 0) {
      return
    }
    var err = CheckFehlzeitDef(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/fehlzeitdef/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteFehlzeitDefSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/fehlzeitdef/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateFehlzeitDefSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/fehlzeitdef")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateFehlzeitDefSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function FehlzeitDefUnsavedChanges(state) {
  for (let fehlzeitdef of Object.values(state)) {
    for (let row of fehlzeitdef) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function EditFehlzeitDef(abr_kreis, id, changes) {
  return {
    type: EDIT_FEHLZEITDEF,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteFehlzeitDefSuccess(id) {
  return {
    type: DELETED_FEHLZEITDEF_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteFehlzeitDefRow(id) {
  return {
    type: DELETE_FEHLZEITDEF_ROW,
    id
  }
}

export function AddFehlzeitDefRow(abr_kreis) {
  return {
    type: ADD_FEHLZEITDEF_ROW,
    abr_kreis
  }
}

export function LoadFehlzeitDefSuccess(data) {
  return {
    type: LOAD_FEHLZEITDEF,
    status: SUCCESS,
    data
  }
}

export function LoadFehlzeitDef() {
  return (dispatch) => {
    request.get("/api/fehlzeitdef").end((err, response) => {
      dispatch(LoadFehlzeitDefSuccess(response.body))
    })
  }
}

function CheckUrlaubsAnspruch(urlaubsanspruch) {
  for (let row of urlaubsanspruch) {
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
  }
  return null
}

export function UpdateUrlaubsAnspruchSuccess(id, data) {
  return {
    type: UPDATE_URLAUBSANSPRUCH,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateUrlaubsAnspruch() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.urlaubsanspruch || []
    if (data.length == 0) {
      return
    }
    var err = CheckUrlaubsAnspruch(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/urlaubsanspruch/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteUrlaubsAnspruchSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/urlaubsanspruch/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateUrlaubsAnspruchSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/urlaubsanspruch")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateUrlaubsAnspruchSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function UrlaubsAnspruchUnsavedChanges(state) {
  for (let urlaubsanspruch of Object.values(state)) {
    for (let row of urlaubsanspruch) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function EditUrlaubsAnspruch(abr_kreis, id, changes) {
  return {
    type: EDIT_URLAUBSANSPRUCH,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteUrlaubsAnspruchSuccess(id) {
  return {
    type: DELETED_URLAUBSANSPRUCH_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteUrlaubsAnspruchRow(id) {
  return {
    type: DELETE_URLAUBSANSPRUCH_ROW,
    id
  }
}

export function AddUrlaubsAnspruchRow(abr_kreis) {
  return {
    type: ADD_URLAUBSANSPRUCH_ROW,
    abr_kreis
  }
}

export function LoadUrlaubsAnspruchSuccess(data) {
  return {
    type: LOAD_URLAUBSANSPRUCH,
    status: SUCCESS,
    data
  }
}

export function LoadUrlaubsAnspruch() {
  return (dispatch) => {
    request.get("/api/urlaubsanspruch").end((err, response) => {
      dispatch(LoadUrlaubsAnspruchSuccess(response.body))
    })
  }
}

function CheckNegativErfassung(negativerfassung) {
  for (let row of negativerfassung) {
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
    if (row.int_id > 0 && row.g_key > " ") {
      return "bitte Gruppe oder Person auswählen"
    }
  }
  return null
}

export function UpdateNegativErfassungSuccess(id, data) {
  return {
    type: UPDATE_NEGATIVERFASSUNG,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateNegativErfassung() {
  return (dispatch, getState) => {
    let data = getState().Verwaltung.negativerfassung || []
    if (data.length == 0) {
      return
    }
    var err = CheckNegativErfassung(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/negativerfassung/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteNegativErfassungSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/negativerfassung/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateNegativErfassungSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/negativerfassung")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateNegativErfassungSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function NegativErfassungUnsavedChanges(state) {
  for (let negativerfassung of Object.values(state)) {
    for (let row of negativerfassung) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function EditNegativErfassung(abr_kreis, id, changes) {
  return {
    type: EDIT_NEGATIVERFASSUNG,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteNegativErfassungSuccess(id) {
  return {
    type: DELETED_NEGATIVERFASSUNG_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteNegativErfassungRow(id) {
  return {
    type: DELETE_NEGATIVERFASSUNG_ROW,
    id
  }
}

export function AddNegativErfassungRow(abr_kreis) {
  return {
    type: ADD_NEGATIVERFASSUNG_ROW,
    abr_kreis
  }
}

export function LoadNegativErfassungSuccess(data) {
  return {
    type: LOAD_NEGATIVERFASSUNG,
    status: SUCCESS,
    data
  }
}

export function LoadNegativErfassung() {
  return (dispatch) => {
    request.get("/api/negativerfassung").end((err, response) => {
      dispatch(LoadNegativErfassungSuccess(response.body))
    })
  }
}
