import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { v4 as uuidv4 } from "uuid"
import DateTime from "app/shared/dateutil"
import swal from "sweetalert"
import after from "lodash/after"
import { canSelect, getPlaceHoldersForIntIDColumn } from "./selection"
import { batchActions } from "redux-batched-actions"
export const LOAD_RECORDS_BY_PLAN = "dpl/LOAD_RECORDS_BY_PLAN"
export const LOAD_PREV_RECORDS_BY_PLAN = "dpl/LOAD_PREV_RECORDS_BY_PLAN"
export const LOAD_NEXT_RECORDS_BY_PLAN = "dpl/LOAD_NEXT_RECORDS_BY_PLAN"
export const SET_TPL_RECORD = "dpl/SET_TPL_RECORD"
export const SET_WUNSCH_DIENST = "dpl/SET_WUNSCH_DIENST"
export const EDIT_WUNSCH = "dpl/EDIT_WUNSCH"
export const SAVE_TPL_RECORD = "dpl/SAVE_TPL_RECORD"
export const SET_FEHLZEIT = "dpl/SET_FEHLZEIT"
export const SET_TPL_TEXT = "dpl/SET_TPL_TEXT"
export const MARK_TPL_RECORD_DELETE = "dpl/MARK_TPL_RECORD_DELETE"
export const SAVE_FEHLZEIT = "dpl/SAVE_FEHLZEIT"
export const SET_SONDERMODELL = "dpl/SET_SONDERMODELL"
import { CountSummen } from "./summen"
import { UpdateSalden, LoadSalden } from "./salden"
import { LoadUnverplant } from "./pers"
import { CanStartGenehmigung } from "./genehmigung"
import { arrayToObjectFn } from "app/shared/util/conversion"
import { FzDef } from "../selectors/fz"
import {
  IstRecordByID,
  PlanRecordByID,
  RbRecordByID,
  SollRecordByID,
  Zeitmodell
} from "../selectors/records"
import { Personen } from "../selectors/pers"
import {
  RecordsByIntIDDatum,
  TexteByIntIDDatum,
  WuenscheByIntIDDatum,
  PlanTexteByDatum,
  DirtyRecords,
  DirtyTexte,
  DirtyPlanTexte,
  DirtyKalender,
  DirtyWuensche,
  GetIssues,
  CANT_GENEHMIG
} from "../selectors/records"
import Datetime from "app/shared/dateutil/index"
import { getVorbelegungIntIDDatum } from "../selectors/vorbelegung"
import { GetPlanID } from "../selectors/plan"
import { GetFtg } from "../selectors/ftg"
export const LOAD_ANWESENHEIT = "dpl/LOAD_ANWESENHEIT"
export const AUTO_DPL_RUNNING = "dpl/AUTO_DPL_RUNNING"
export const SET_WUNSCH_MODE = "dpl/SET_WUNSCH_MODE"
export const LOADED_GROUP_LIMITS = "dpl/LOADED_GROUP_LIMITS"
export const LOADED_WISH_INFO = "dpl/LOADED_WISH_INFO"
export const EDIT_PLAN_TEXT = "dpl/EDIT_PLAN_TEXT"
export const SAVE_PLAN_TEXT = "dpl/SAVE_PLAN_TEXT"
export const SET_FZ_MANUAL_RED = "dpl/SET_FZ_MANUAL_RED"
export const LOAD_FZ_MANUAL_RED = "dpl/LOAD_FZ_MANUAL_RED"
export const SET_DIENST = "dpl/SET_DIENST"

export const LOAD_RECORDS = "dpl/LOAD_RECORDS"
export const LOAD_WUENSCHE = "dpl/LOAD_WUENSCHE"
export const LOAD_KALENDER = "dpl/LOAD_KALENDER"
export const LOAD_PLAN_TEXTE = "dpl/LOAD_PLAN_TEXTE"
export const LOAD_TEXTE = "dpl/LOAD_TEXTE"
export const CLEAN_DIRTY_RECORDS = "dpl/CLEAN_DIRTY_RECORDS"
export const LOAD_VORGABEN = "dpl/LOAD_VORGABEN"
export const RELOAD = "dpl/RELOAD"
export const VALIDATE_FEHLZEIT = "dpl/VALIDATE_FEHLZEIT"
export const CHECK_DPL = "dpl/CHECK_DPL"

export const SetSonderModell = (id, modID) => {
  return { type: SET_SONDERMODELL, id, modID }
}

export const Reload = (clean) => {
  return (dispatch) => {
    if (clean) {
      dispatch({ type: CLEAN_DIRTY_RECORDS })
    }
    dispatch({ type: RELOAD })
  }
}

export const ValidateFehlzeit = (int_id, datum) => {
  return { type: VALIDATE_FEHLZEIT, int_id, datum }
}

export const EditWunsch = (value) => {
  return (dispatch, getState) => {
    var PlanID = getState().Dpl.Plaene.selected
    var selection = getState().Dpl.Selection
    for (let key of Object.keys(selection)) {
      let [intid, year, month, day, kz] = key.split("-")
      if (kz == "W") {
        let datum = DateTime.utc(parseInt(year), parseInt(month), parseInt(day))

        let recordsByIntID = WuenscheByIntIDDatum(getState())

        let PrevRecord = recordsByIntID[`${intid}-${year}-${month}-${day}`]
        let RecordID = uuidv4()
        if (PrevRecord) {
          RecordID = PrevRecord.id
        }
        dispatch({
          type: EDIT_WUNSCH,
          RecordID,
          PlanID,
          dpl_symb: value,
          int_id: parseInt(intid),
          Datum: datum.toISO({ suppressMilliseconds: true })
        })
      }
    }
  }
}

export function ReplaceEmptySoll(value) {
  return (dispatch, getState) => {
    swal({
      title: "Tage mit noch vorhandener Vorbelegung in Sollzeile ersetzen?",
      text:
        "Es werden alle vorbelegten Felder durch " +
        value +
        " ersetzt.\nAlternativ geben Sie das Symbol ein das ersetzt werden soll.",
      icon: "warning",
      buttons: ["Abbrechen", "Ok!"],
      content: {
        element: "input",
        attributes: {
          placeholder: "Wählen Sie ein Symbol das ersetzt werden soll."
        }
      },
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete != null) {
        let p = Personen(getState())
        let datum = getState().Dpl.Datum
        let records = RecordsByIntIDDatum(getState())
        let PlanID = getState().Dpl.Plaene.selected
        let placeholders = getState().Dpl.PlaceHolders
        let actions = []
        const dispatchLater = (action) => {
          actions.push(action)
        }
        let today = DateTime.now().startOf("day")
        for (let pers of Object.keys(p)) {
          let start = DateTime.fromISO(datum.date).startOf("month")
          let end = DateTime.fromISO(datum.date).endOf("month")
          let current = start
          if (current < today) {
            current = today
          }
          while (!current > end) {
            let key = `${pers}-${DateTime.fromISO(current).toFormat(
              "yyyy-MM-dd"
            )}-dp-S`
            if (!records[key]) {
              if (willDelete != "") {
                let pkey = `${pers}-${DateTime.fromISO(current).toFormat(
                  "yyyy-MM-dd"
                )}`
                if (placeholders[pkey] != willDelete) {
                  current = current.plus({ days: 1 })
                  continue
                }
              }
              dispatchLater({
                type: SET_TPL_RECORD,
                Datum: current.toFormat("yyyy-MM-dd") + "T00:00:00Z",
                PlanID,
                isNew: true,
                dprb: "dp",
                art: "S",
                DienstID: null,
                dpl_symb: value,
                Tag: datum,
                Nbr: 1,
                RecordID: uuidv4(),
                value: parseInt(pers),
                valid: true
              })
            }
            current = current.plus({ days: 1 })
          }
        }
        swal("Ersetzen erfolgreich!", {
          icon: "success"
        })
        dispatch(batchActions(actions))
      }
    })
  }
}

export function EditPlanText(value) {
  return (dispatch, getState) => {
    var PlanID = getState().Dpl.Plaene.selected
    var selection = getState().Dpl.Selection
    for (let key of Object.keys(selection)) {
      let [intid, year, month, day, kz] = key.split("-") // eslint-disable-line
      if (kz !== "ht") {
        continue
      }
      let datum = DateTime.utc(parseInt(year), parseInt(month), parseInt(day))
      let texte = PlanTexteByDatum(getState())
      let t = texte[datum.toFormat("yyyy-MM-dd")]
      let RecordID
      if (t) {
        RecordID = t.id
      } else {
        RecordID = uuidv4()
      }
      dispatch({
        type: EDIT_PLAN_TEXT,
        RecordID,
        PlanID,
        Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
        value
      })
    }
  }
}

export const SetWunschDienst = (value) => {
  return (dispatch, getState) => {
    var selection = getState().Dpl.Selection
    var PlanID = getState().Dpl.Plaene.selected
    var tdef =
      (getState().Dpl.TaetigkeitsDefinitionen[parseInt(value)] &&
        getState().Dpl.TaetigkeitsDefinitionen[parseInt(value)].data) ||
      []
    var tbDef =
      (getState().Dpl.Taetigkeiten.data &&
        getState().Dpl.Taetigkeiten.data[value]) ||
      {}
    for (let key of Object.keys(selection)) {
      let [intid, year, month, day, kz] = key.split("-")
      if (kz == "W") {
        let datum = DateTime.utc(parseInt(year), parseInt(month), parseInt(day))

        let recordsByIntID = WuenscheByIntIDDatum(getState())

        let PrevRecord = recordsByIntID[`${intid}-${year}-${month}-${day}`]

        let RecordID
        if (!PrevRecord) {
          RecordID = uuidv4()
        } else {
          RecordID = PrevRecord.id
        }
        if (value == null) {
          dispatch({
            type: SET_WUNSCH_DIENST,
            Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
            PlanID,
            RecordID,
            value: null,
            int_id: parseInt(intid),
            dpl_symb: null,
            rb_symb: null
          })
          continue
        }

        let key = datum.toFormat("yyyy-MM-dd")
        let ftg = getState().Dpl.Datum.ftg[key]
        let DplDef = getDplDef(datum, ftg, "dp", tdef, tbDef)
        if (!DplDef) {
          swal("Keine Vorgabe für diese Tätigkeit an diesem Tag ")
          continue
        }

        const DplSymb = DplDef.dpl_symb

        let RbDef = getDplDef(datum, ftg, "rb", tdef, tbDef)
        let RbSymb = ""
        if (RbDef) {
          RbSymb = RbDef.dpl_symb
        }
        dispatch({
          type: SET_WUNSCH_DIENST,
          Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          PlanID,
          RecordID,
          value: value,
          int_id: parseInt(intid),
          dpl_symb: DplSymb,
          rb_symb: RbSymb
        })
      }
    }
  }
}

export const LoadVorgaben = () => {
  return { type: "NOOP" }
}

export const SaveRecords = () => {
  return (dispatch, getState) => {
    let state = getState()
    let issues = GetIssues(state).filter((i) => {
      return i.type !== CANT_GENEHMIG
    })

    if (issues.length > 0) {
      swal({
        title: "Ungültige Werte.",
        text: "Korrigieren Sie diese vor dem Speichern",
        icon: "warning",
        button: true,
        dangerMode: true
      })
      return
    }
    let missing = state.Dpl.CanStartGenehmigung.missing || {}
    let reloadGeneStatus = Object.keys(missing).length > 0
    let datum = state.Dpl.Datum.date
    let PlanID = state.Dpl.Plaene.selected
    let updateS = {}
    let dirtyRecords = DirtyRecords(state)
    let removeIDs = {}
    let recs = Object.values(dirtyRecords)
      .filter((r) => {
        if (r.__new && r.__deleted) {
          if (r.art == "S" && r.dprb == "dp") {
            removeIDs[r.id] = true
          }
          return false
        }
        return true
      })
      .filter((r) => {
        if (removeIDs[r.id]) {
          return false
        }
        return true
      })
      .map((r) => {
        updateS[`${r.datum.slice(0, 7)}-${r.int_id}`] = true
        if (
          (r.__new && r.id.length && r.id.length == 36) ||
          parseInt(r.id) < 0
        ) {
          return { ...r, id: 0, tempID: r.id + "" }
        }
        return r
      })
    let dirtyTexte = DirtyTexte(state)
    let ptexte = Object.values(dirtyTexte).map((r) => {
      if (r.__new && r.id.length && r.id.length == 36) {
        return { ...r, id: 0, tempID: r.id + "" }
      }
      return r
    })

    let dirtyPlanTexte = DirtyPlanTexte(state)
    let pltexte = Object.values(dirtyPlanTexte).map((r) => {
      if (r.__new && r.id.length && r.id.length == 36) {
        return { ...r, id: 0, tempID: r.id + "" }
      }
      return r
    })
    let dirtyKalender = DirtyKalender(state)
    Object.values(dirtyKalender).map((r) => {
      updateS[`${r.datum.slice(0, 7)}-${r.int_id}`] = true
    })
    let dirtyWuensche = DirtyWuensche(state)
    let wuensche = Object.values(dirtyWuensche).map((r) => {
      if (r.__new && r.id.length && r.id.length == 36) {
        return { ...r, id: 0, tempID: r.id + "" }
      }
      return r
    })
    let actions = []
    const dispatchLater = (action) => {
      actions.push(action)
    }
    Promise.all([
      request
        .post("/api/dpl/basis/records")
        .send(recs)
        .then((response) => {
          dispatchLater({ type: LOAD_RECORDS, data: response.body })
        })
        .catch((err) => {
          swal(
            "Beim sichern der Daten ist ein Fehler aufetreten",
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.message) ||
              (err && err.message) ||
              "Unbekannter Fehler"
          )
          dispatchLater({ type: LOAD_RECORDS, data: [] })
        }),
      request
        .post("/api/personalkalender/tage")
        .send(Object.values(dirtyKalender))
        .then((response) => {
          dispatchLater({
            type: LOAD_KALENDER,
            data: arrayToObjectFn(response.body, (k) => {
              return `${k.int_id}-${k.datum.slice(0, 10)}`
            })
          })
        })
        .catch((err) => {
          swal(
            "Beim sichern der Daten ist ein Fehler aufetreten",
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.message) ||
              (err && err.message) ||
              "Unbekannter Fehler"
          )
          dispatchLater({ type: LOAD_KALENDER, data: {} })
        }),
      request
        .post("/api/dpl/basis/wuensche")
        .send(wuensche)
        .then((response) => {
          dispatchLater({
            type: LOAD_WUENSCHE,
            data: response.body
          })
        })
        .catch((err) => {
          swal(
            "Beim sichern der Daten ist ein Fehler aufetreten",
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.message) ||
              (err && err.message) ||
              "Unbekannter Fehler"
          )
          dispatchLater({ type: LOAD_WUENSCHE, data: [] })
        }),
      request
        .post("/api/dpl/basis/perstexte")
        .send(ptexte)
        .then((response) => {
          dispatchLater({
            type: LOAD_TEXTE,
            data: response.body
          })
        })
        .catch((err) => {
          swal(
            "Beim sichern der Daten ist ein Fehler aufetreten",
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.message) ||
              (err && err.message) ||
              "Unbekannter Fehler"
          )
          dispatchLater({ type: LOAD_TEXTE, data: [] })
        }),
      request
        .post("/api/dpl/basis/texte")
        .send(pltexte)
        .then((response) => {
          dispatchLater({
            type: LOAD_PLAN_TEXTE,
            data: response.body
          })
        })
        .catch((err) => {
          swal(
            "Beim sichern der Daten ist ein Fehler aufetreten",
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.message) ||
              (err && err.message) ||
              "Unbekannter Fehler"
          )
          dispatchLater({ type: LOAD_PLAN_TEXTE, data: [] })
        })
    ]).then(() => {
      const d = DateTime.fromISO(datum)
      dispatch({ type: CLEAN_DIRTY_RECORDS })
      dispatch(batchActions(actions))
      if (reloadGeneStatus) {
        dispatch(CanStartGenehmigung(PlanID, d.year(), d.month()))
      }
      dispatch(LoadSalden(PlanID, d.year(), d.month()))
      dispatch(CheckDpl())
    })
  }
}

export const LoadAllData = (von, bis) => {
  return (dispatch, getState) => {
    let LoadID = uuidv4()
    let state = getState()
    let PlanID = state.Dpl.Plaene.selected
    if (PlanID == null) {
      return
    }
    //console.log(typeof von, bis)
    dispatch(CheckDpl())
    request
      .get(
        `/api/dpl/basis/anwesenheit/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .end((err, response) => {
        dispatch({
          type: LOAD_ANWESENHEIT,
          data: response.body || []
        })
      })
    return Promise.all([
      request
        .get(
          `/api/dpl/basis/records/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
        )
        .query({ qID: LoadID })
        .then((r) => r),

      request
        .get(
          `/api/dpl/basis/wuensche/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
        )
        .query({ qID: LoadID })
        .then((r) => r),
      request
        .get(
          `/api/dpl/basis/kalender/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
        )
        .query({ qID: LoadID })
        .then((r) => r),
      request
        .get(
          `/api/dpl/basis/perstexte/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
        )

        .query({ qID: LoadID })
        .then((r) => r),
      request
        .get(
          `/api/dpl/basis/texte/${DateTime.fromISO(von).toFormat(
            "yyyy-MM-dd"
          )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
        )

        .then((r) => r)
    ]).then(
      ([
        recordResponse,

        wunschResponse,
        kalenderResponse,
        textResponse,
        plantextResponse
      ]) => {
        dispatch(
          batchActions([
            {
              type: LOAD_RECORDS,
              data: recordResponse.body || []
            },

            {
              type: LOAD_WUENSCHE,
              data: wunschResponse.body || []
            },
            {
              type: LOAD_KALENDER,
              data: kalenderResponse.body || []
            },
            {
              type: LOAD_TEXTE,
              data: textResponse.body || []
            },
            {
              type: LOAD_PLAN_TEXTE,
              data: plantextResponse.body || []
            }
          ])
        )
      }
    )
  }
}

export const LoadRecords = (von, bis) => {
  return (dispatch, getState) => {
    let state = getState()
    let PlanID = state.Dpl.Plaene.selected
    if (PlanID == null) {
      return
    }

    let p1 = request
      .get(
        `/api/dpl/basis/records/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_RECORDS,
          data: response.body || []
        })
      })
    let p2 = request
      .get(
        `/api/dpl/basis/anwesenheit/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_ANWESENHEIT,
          data: response.body || []
        })
      })
    return Promise.all(p1, p2)
  }
}

export const LoadWuensche = (von, bis) => {
  return (dispatch, getState) => {
    let state = getState()

    let PlanID = state.Dpl.Plaene.selected
    if (PlanID == null) {
      return
    }

    return request
      .get(
        `/api/dpl/basis/wuensche/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_WUENSCHE,
          data: response.body || []
        })
      })
  }
}

export const LoadKalender = (von, bis) => {
  return (dispatch, getState) => {
    let state = getState()
    let datum = state.Dpl.Datum
    if (!datum.date || !datum.bis) {
      return
    }
    let PlanID = state.Dpl.Plaene.selected
    if (PlanID == null) {
      return
    }

    return request
      .get(
        `/api/dpl/basis/kalender/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_KALENDER,
          data: response.body || []
        })
      })
  }
}

export const LoadTexte = (von, bis) => {
  return (dispatch, getState) => {
    let state = getState()
    let datum = state.Dpl.Datum
    if (!datum.date || !datum.bis) {
      return
    }
    let PlanID = state.Dpl.Plaene.selected
    if (PlanID == null) {
      return
    }

    return request
      .get(
        `/api/dpl/basis/perstexte/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_TEXTE,
          data: response.body || []
        })
      })
  }
}

export const LoadPlanTexte = (von, bis) => {
  return (dispatch, getState) => {
    let state = getState()
    let datum = state.Dpl.Datum
    if (!datum.date || !datum.bis) {
      return
    }
    let PlanID = state.Dpl.Plaene.selected
    if (!PlanID) {
      return
    }

    return request
      .get(
        `/api/dpl/basis/texte/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .then((response) => {
        dispatch({
          type: LOAD_PLAN_TEXTE,
          data: response.body || []
        })
      })
  }
}

export function SetFzManualRed(groupName, group, datum, value) {
  return (dispatch, getState) => {
    let state = getState()
    let PlanID = state.Dpl.Plaene.selected
    ///dpl/set/manual/red/:PlanID/:GroupName/:GroupValue/:Datum
    request
      .post(
        `/api/dpl/set/manual/red/${PlanID}/${groupName}/${group}/${DateTime.fromISO(
          datum
        ).toISO({ suppressMilliseconds: true })}`
      )
      .send({ value: value })
      .end()
    dispatch({
      type: SET_FZ_MANUAL_RED,
      groupName,
      group,
      datum,
      value
    })
  }
}

export function LoadFzManualRed(von, bis) {
  return (dispatch, getState) => {
    let state = getState()
    let PlanID = state.Dpl.Plaene.selected
    return request
      .get(
        `/api/dpl/manual/red/${DateTime.fromISO(von).toFormat(
          "yyyy-MM-dd"
        )}/${DateTime.fromISO(bis).toFormat("yyyy-MM-dd")}/${PlanID}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_FZ_MANUAL_RED,
            data: response.body || {}
          })
        }
      })
  }
}

export function LoadGroupLimits(PlanID) {
  return (dispatch) => {
    request.get(`/api/dpl/group/limits/${PlanID}`).end((err, response) => {
      if (!err) {
        dispatch({
          type: LOADED_GROUP_LIMITS,
          data: response.body
        })
      }
    })
  }
}

export function LoadWishes(wType, wValue, Datum) {
  let df = DateTime.fromISO(Datum).toFormat("yyyy-MM-dd") //DateTime.fromISO(Datum).toFormat("yyyy-MM-dd")
  return (dispatch) => {
    dispatch({
      type: LOADED_WISH_INFO,
      status: STARTED,
      key: `${wType}-${wValue}-${df}`
    })
    request
      .get(`/api/dpl/wishes/${wType}/${wValue}/${df}`)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOADED_WISH_INFO,
            status: SUCCESS,
            key: `${wType}-${wValue}-${df}`,
            data: response.body
          })
        }
      })
  }
}

export function SetWunschMode(mode) {
  return {
    type: SET_WUNSCH_MODE,
    mode
  }
}

export function SavedFehlzeit(fehlzeit) {
  return {
    type: SAVE_FEHLZEIT,
    fehlzeit
  }
}

export function SaveFehlzeit(fehlzeit) {
  return (dispatch) => {
    request
      .get(
        `/api/dpl/check/absence/${fehlzeit.int_id}/${
          fehlzeit.datum
        }/${encodeURIComponent(fehlzeit.v || fehlzeit.n)}`
      )
      .end((err, response) => {
        if (response.status == 200) {
          dispatch(SaveFehlzeitAfterCheck(fehlzeit))
        } else if (response.status == 420) {
          swal({
            title: "Soll die Fehlzeit wirklich eingetragen werden?",
            text: "Es ist bereits ein Dienst geplant.",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then((willDelete) => {
            if (willDelete) {
              dispatch(SaveFehlzeitAfterCheck(fehlzeit))
            }
          })
        } else if (response.status == 421) {
          swal(
            "Fehlzeit konnte nicht gesichert werden!",
            "Der geplante Dienst ist für Fehlzeiten gesperrt.",
            "error"
          )
        }
      })
  }
}

export function CheckDpl() {
  return (dispatch, getState) => {
    let plan = getState().Dpl.Plaene.selected
    let datum = DateTime.fromISO(getState().Dpl.Datum.date)
    let year = datum.year()
    let month = datum.month()
    request
      .get(`/api/dpl/auto/check/${plan}/${year}/${month}`)
      .end((err, response) => {
        if (err) {
          return
        }
        dispatch({
          type: CHECK_DPL,
          data: response.body
        })
      })
  }
}

export function SaveFehlzeitAfterCheck(fehlzeit) {
  return (dispatch, getState) => {
    let plan = getState().Dpl.Plaene.selected
    let datum = Datetime.fromISO(getState().Dpl.Datum.date)
    if (fehlzeit.id === false) {
      fehlzeit.id = undefined
    }

    request
      .post(
        `/api/p/${fehlzeit.int_id}/personalkalendertag/${Datetime.fromISO(
          fehlzeit.datum
        ).toISO({ suppressMilliseconds: true })}`
      )
      .send(fehlzeit)
      .end((err) => {
        if (err) {
          dispatch({ type: ERROR, status: ERROR, err: err })
          return
        }
        if (!fehlzeit.id) {
          for (let r of Object.values(getState().Dpl.Records.byID)) {
            if (r.datum == fehlzeit.datum && r.int_id == fehlzeit.int_id) {
              let fz = { ...fehlzeit }
              fz.id = r.id
              fz.dienst_id = r.dienst_id
              dispatch(SavedFehlzeit(fz))
              dispatch(LoadUnverplant(plan, datum.toISO()))
              return
            }
          }
          if (!fehlzeit.id) {
            fehlzeit.id = uuidv4()
            dispatch(SavedFehlzeit(fehlzeit))
            dispatch(LoadUnverplant(plan, datum.toISO()))
            return
          }
        } else {
          for (let r of Object.values(getState().Dpl.Records.byID)) {
            if (r.datum == fehlzeit.datum && r.int_id == fehlzeit.int_id) {
              let fz = { ...fehlzeit }
              fz.id = r.id
              fz.dienst_id = r.dienst_id
              dispatch(SavedFehlzeit(fz))
              dispatch(LoadUnverplant(plan, datum.toISO()))
              return
            }
          }
        }
        dispatch(SavedFehlzeit(fehlzeit))
        dispatch(LoadUnverplant(plan, datum.toISO()))
      })
  }
}

export function DoAutoDpl(PlanID, Year, Month) {
  return (dispatch) => {
    dispatch({ type: AUTO_DPL_RUNNING, status: STARTED })
    request
      .get(`/api/dpl/auto/plan/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        if (err) {
          dispatch({
            type: AUTO_DPL_RUNNING,
            status: ERROR,
            err: err,
            _handleInApp: true
          })
          return
        }
        dispatch({ type: CLEAN_DIRTY_RECORDS })
        dispatch({ type: AUTO_DPL_RUNNING, status: SUCCESS })
        dispatch({
          type: LOAD_RECORDS,
          data: response.body.records
        })
        dispatch({
          type: LOAD_KALENDER,
          data: response.body.kalender
        })
        //dispatch(LoadRecordsByPlanSuccess(PlanID, Year, Month, response.body))
      })
  }
}

export function LoadAutoDpl(records, kalender) {
  return (dispatch, getState) => {
    // if (!getState().Dpl.AutoDpl.running) {
    //   return
    // }
    dispatch({ type: CLEAN_DIRTY_RECORDS })
    dispatch({
      type: LOAD_RECORDS,
      data: records
    })
    dispatch({
      type: LOAD_KALENDER,
      data: kalender
    })
  }
}

export function SaveTplRecordSuccess(id, record) {
  return {
    type: SAVE_TPL_RECORD,
    status: SUCCESS,
    id,
    record
  }
}

export function SaveTplRecordError(id, err) {
  return {
    type: SAVE_TPL_RECORD,
    status: ERROR,
    id,
    err
  }
}

export function SavedTagesText(PlanID, Datum, data) {
  return {
    type: SAVE_PLAN_TEXT,
    PlanID,
    Datum,
    data
  }
}

function WarnInvalidValues() {
  alert("Ungültige Werte - speichern nicht möglich")
}

export function SaveTplRecords() {
  return (dispatch, getState) => {
    let records = getState().Dpl.Records.byID
    let newFzs = []
    for (let r of Object.values(records)) {
      if (r.ist && (r.ist.__new || r.ist.__dirty) && r.ist.__valid) {
        if (
          r.ist.dpl_symb &&
          (r.ist.dpl_symb.startsWith("*") ||
            r.ist.dpl_symb.startsWith("+") ||
            r.ist.dpl_symb.startsWith(":"))
        ) {
          newFzs.push({
            int_id: r.int_id,
            datum: r.datum,
            v: r.ist.dpl_symb.slice(1),
            n: r.ist.dpl_symb.slice(1)
          })
        }
      }
    }
    if (newFzs.length == 0) {
      dispatch(SaveTplRecordsAfterCheck())
    } else {
      let datum = DateTime.utc(
        getState().Dpl.Datum.year,
        getState().Dpl.Datum.month,
        1
      )
      let plan = getState().Dpl.Plaene.selected
      request
        .post("/api/dpl/check/absence/")
        .send(newFzs)
        .end((err, response) => {
          if (err) {
            dispatch(LoadRecordsByPlan(plan, datum.year, datum.month + 1))
          } else {
            let status = 0
            for (let r of response.body) {
              if (r.status > status) {
                status = r.status
              }
            }
            if (status == 1) {
              swal({
                title: "Soll die Fehlzeit wirklich eingetragen werden?",
                text: "Hinweis aufgrund geplanter Tätigkeit.",
                icon: "warning",
                buttons: true,
                dangerMode: true
              }).then((willDelete) => {
                if (willDelete) {
                  dispatch(SaveTplRecordsAfterCheck())
                } else {
                  dispatch(LoadRecordsByPlan(plan, datum.year, datum.month + 1))
                }
              })
            } else if (status == 2) {
              swal(
                "Fehlzeit konnte nicht gesichert werden!",
                "Der geplante Dienst ist für Fehlzeiten gesperrt.",
                "error"
              )
              dispatch(LoadRecordsByPlan(plan, datum.year, datum.month + 1))
            } else {
              dispatch(SaveTplRecordsAfterCheck())
            }
          }
        })
    }
  }
}

export function SaveTplRecordsAfterCheck() {
  return (dispatch, getState) => {
    let records = getState().Dpl.Records.byID
    let plan = getState().Dpl.Plaene.selected
    let datum = DateTime.utc(
      getState().Dpl.Datum.year,
      getState().Dpl.Datum.month,
      1
    )
    let actions = []
    let dispatchLater = (action) => {
      dispatch(action)
      //actions.push(action)
    }
    let intidsForUpdate = {}
    let errors = false
    let reload = after(Object.keys(records).length, () => {
      dispatch(batchActions(actions))
      dispatch(LoadUnverplant(plan, datum))
      for (let intid of Object.keys(intidsForUpdate)) {
        dispatch(UpdateSalden(intid, datum.year, datum.month + 1))
      }
      dispatch(CheckDpl())
      if (errors) {
        if (plan) {
          dispatch(LoadRecordsByPlan(plan, datum.year, datum.month + 1))
        }
      }
    })
    let tagestexte = getState().Dpl.Records.tagestexte || {}
    for (let t of Object.values(tagestexte)) {
      if (t.__new) {
        request
          .post("/api/dpl/basis/tagestext")
          .send(t)
          .end((err, response) => {
            dispatchLater(SavedTagesText(t.plan_id, t.datum, response.body))
          })
      } else if (t.__dirty) {
        request
          .put(`/api/dpl/basis/tagestext/${t.id}`)
          .send(t)
          .end((err, response) => {
            dispatchLater(SavedTagesText(t.plan_id, t.datum, response.body))
          })
      }
    }
    for (let [k, v] of Object.entries(records)) {
      let int_id = v.int_id
      if (v.__new || (v.soll && v.soll.__new) || (v.id != v.aid && v.__dirty)) {
        if (v.__deleted) {
          dispatchLater(SaveTplRecordSuccess(k, null))
          reload()
          continue
        }
        let { id, ...record } = v
        if (record.soll) {
          if (record.soll.__valid === false) {
            WarnInvalidValues()
            return
          }
          delete record.soll.id
        }
        if (record.plan) {
          if (record.plan.__valid === false) {
            WarnInvalidValues()
            return
          }
          delete record.plan.id
        }
        if (record.ist) {
          if (record.ist.__valid === false) {
            WarnInvalidValues()
            return
          }
          delete record.ist.id
        }
        if (record.rsoll) {
          if (record.rsoll.__valid === false) {
            WarnInvalidValues()
            return
          }
          delete record.rsoll.id
        }
        if (record.wunsch) {
          if (record.wunsch.__valid === false) {
            WarnInvalidValues()
            return
          }
          if (!/^\d+$/.test(record.wunsch.id)) {
            delete record.wunsch.id
          }
        }
        request
          .post("/api/dpl/record")
          .send(record)
          .end((err, response) => {
            if (!err) {
              dispatchLater(SaveTplRecordSuccess(k, response.body))
            } else {
              dispatchLater(SaveTplRecordError(k, err))
              errors = true
            }
            intidsForUpdate[int_id] = true
            //dispatch(UpdateSalden(int_id, year, month))
            reload()
          })
      } else if (v.__deleted) {
        request
          .delete(`/api/dpl/record/${k}`)
          .send(v)
          .end((err, response) => {
            if (!err) {
              dispatchLater(SaveTplRecordSuccess(k, response.body))
            } else {
              dispatchLater(SaveTplRecordError(k, err))
              errors = true
            }

            intidsForUpdate[int_id] = true
            //dispatch(UpdateSalden(int_id, year, month))
            reload()
          })
      } else if (v.__dirty) {
        let record = v
        if (record.soll) {
          if (record.soll.__valid === false) {
            WarnInvalidValues()
            return
          }
        }
        if (record.plan) {
          if (record.plan.__valid === false) {
            WarnInvalidValues()
            return
          }
        }
        if (record.ist) {
          if (record.ist.__valid === false) {
            WarnInvalidValues()
            return
          }
        }
        if (record.rsoll) {
          if (record.rsoll.__valid === false) {
            WarnInvalidValues()
            return
          }
        }
        if (record.wunsch) {
          if (record.wunsch.__valid === false) {
            WarnInvalidValues()
            return
          }
          if (!/^\d+$/.test(record.wunsch.id)) {
            delete record.wunsch.id
          }
        }

        request
          .put(`/api/dpl/record/${k}`)
          .send(v)
          .end((err, response) => {
            if (!err) {
              dispatchLater(SaveTplRecordSuccess(k, response.body))
            } else {
              dispatchLater(SaveTplRecordError(k, err))
              errors = true
            }

            intidsForUpdate[int_id] = true
            //dispatch(UpdateSalden(int_id, year, month))
            reload()
          })
      } else {
        reload()
      }
    }
  }
}

export function MarkTplRecordDelete(RecordID) {
  return (dispatch, getState) => {
    let record = getState().Dpl.Records.byID[RecordID + "-dp-S"]
    if (!record) {
      return
    }
    let kz = "S"
    let planRecord = getState().Dpl.Records.byID[RecordID + "-dp-P"]
    if (planRecord) {
      record = planRecord
      kz = "P"
    }
    let rbRecord = getState().Dpl.Records.byID[RecordID + "-rb-S"]
    var tdef =
      (getState().Dpl.TaetigkeitsDefinitionen[parseInt(record.dienst_id)] &&
        getState().Dpl.TaetigkeitsDefinitionen[parseInt(record.dienst_id)]
          .data) ||
      []
    var tbDef =
      (getState().Dpl.Taetigkeiten.data &&
        getState().Dpl.Taetigkeiten.data[record.dienst_id]) ||
      {}
    let datum = DateTime.fromISO(record.datum)
    let key = datum.toFormat("yyyy-MM-dd")
    let ftg = getState().Dpl.Datum.ftg[key]
    let DplDef = getDplDef(datum, ftg, "dp", tdef, tbDef)
    if (DplDef && DplDef.use_existing) {
      dispatch(SetDienst(null, record.int_id, record.datum))
      if (rbRecord) {
        dispatch({
          type: SET_TPL_RECORD,
          Datum: rbRecord.datum,
          PlanID: rbRecord.PlanID,
          isNew: false,
          dprb: "rb",
          art: "S",
          DienstID: null,
          dpl_symb: "",
          Tag: datum,
          Nbr: 0,
          RecordID,
          value: parseInt(rbRecord.intid),
          valid: true
        })
      }
    } else {
      dispatch(
        ApplyRbFolgeTag(
          "",
          DateTime.fromISO(record.datum).toJSDate(),
          record.plan_id,
          DateTime.fromISO(record.datum).toJSDate(),
          null,
          record.int_id,
          record,
          dispatch,
          getState,
          record.id
        )
      )
    }
    dispatch({
      type: MARK_TPL_RECORD_DELETE,
      RecordID,
      kz
    })
  }
}

export function SetTplRecord2(DienstID, Tag, Nbr, RecordID, value) {
  return (dispatch, getState) => {
    let actions = []
    const dispatchLater = (action) => {
      dispatch(action)
    }
    let state = getState()
    let selection = getState().Dpl.TplSelection
    let replaceMode = getState().Dpl.UI.replaceMode
    let recordsByIntID = RecordsByIntIDDatum(getState())
    var tdef =
      (getState().Dpl.TaetigkeitsDefinitionen[parseInt(DienstID)] &&
        getState().Dpl.TaetigkeitsDefinitionen[parseInt(DienstID)].data) ||
      []
    var tbDef =
      (getState().Dpl.Taetigkeiten.data &&
        getState().Dpl.Taetigkeiten.data[DienstID]) ||
      {}
    let recordID = RecordID
    for (let key of Object.keys(selection)) {
      let [dienst_id, year, month, day, nbr] = key.split("-")
      dienst_id = parseInt(dienst_id)
      nbr = parseInt(nbr)
      // let record = (records[`${dienst_id}-${year}-${month}-${day}-dp-S`] || [])[
      //   Nbr
      // ]
      let datum = `${year}-${month}-${day}T00:00:00Z`
      let ftg =
        state.Dpl.Datum.ftg[DateTime.fromISO(datum).toFormat("yyyy-MM-dd")]
      // let recordID = (record && record.id) || undefined
      let DplDef = getDplDef(DateTime.fromISO(datum), ftg, "dp", tdef, tbDef)
      if (!DplDef) {
        swal("Keine Vorgabe für diese Tätigkeit an diesem Tag ")
        continue
      }
      let wrecords =
        recordsByIntID[`${value}-${year}-${month}-${day}-dp-S`] || []
      wrecords = wrecords
        .filter((r) => !r.__deleted)
        .map((r) => {
          if (r.__new) {
            return { ...r, id: 0, tempID: r.id + "" }
          }
          return r
        })
      if (wrecords.length > 0) {
        if (DplDef.use_existing) {
          SetTplRecordPlain(
            dienst_id,
            datum,
            nbr,
            wrecords[0].id,
            value,
            state,
            dispatchLater
          )
          continue
        } else {
          request
            .post("/api/dpl/check/perm/replace")
            .send(wrecords)
            .end((err, response) => {
              wrecords = response.body
              if (replaceMode == "replace") {
                if (wrecords.length > 1) {
                  AskWichRecordReplace(
                    dispatch,
                    getState,
                    wrecords,
                    dienst_id,
                    datum,
                    nbr,
                    value
                  )
                  return
                }
              } else if (replaceMode == "ask") {
                let text = ""
                let replaceVisible = true

                if (wrecords.length == 1) {
                  let r = wrecords[0]
                  recordID = r.id
                  if (recordID == 0) {
                    recordID = r.tempID
                  }
                  if (r.dienst_id) {
                    text =
                      " ( " +
                      getState().DropDown["dienst_id/"].byKey[r.dienst_id]
                        .label +
                      " )"
                  } else if (r.soll && r.soll.dpl_symb) {
                    text = " ( Symbol: " + r.soll.dpl_symb + " )"
                  } else if (r.dpl_symb) {
                    text = " ( Symbol: " + r.dpl_symb + " )"
                  }
                } else if (wrecords.length == 0) {
                  replaceVisible = false
                }

                swal({
                  title: "Bisherigen Eintrag ersetzen?",
                  text: `Es gibt bereits Einträge für diesen Tag/Person${text}. Wie möchten Sie fortfahren?`,
                  icon: "warning",
                  buttons: {
                    doNew: {
                      text: "Zusätzlich eintragen",
                      value: false,
                      visible: true,
                      className: "",
                      closeModal: true
                    },
                    replace: {
                      text: "Ersetzen",
                      value: true,
                      visible: replaceVisible,
                      className: "",
                      closeModal: true
                    },
                    cancel: {
                      text: "Abbrechen",
                      value: null,
                      visible: true,
                      className: "",
                      closeModal: true
                    }
                  },
                  dangerMode: true
                }).then((doReplace) => {
                  if (doReplace == null) {
                    return
                  }
                  if (doReplace == true) {
                    if (wrecords.length > 1) {
                      AskWichRecordReplace(
                        dispatch,
                        getState,
                        wrecords,
                        dienst_id,
                        datum,
                        nbr,
                        value
                      )
                    } else {
                      let intRecID = +recordID
                      if (isNaN(intRecID)) {
                        intRecID = recordID
                      }
                      SetTplRecordPlain(
                        dienst_id,
                        datum,
                        null,
                        intRecID,
                        value,
                        state,
                        dispatch
                      )
                    }
                  } else {
                    SetTplRecordPlain(
                      dienst_id,
                      datum,
                      null,
                      null,
                      value,
                      state,
                      dispatch
                    )
                  }
                })
                return
              } else {
                recordID = null
              }
            })
          continue
        }
      }
      let intRecID = +recordID
      if (isNaN(intRecID)) {
        intRecID = recordID
      }
      SetTplRecordPlain(
        dienst_id,
        datum,
        nbr,
        intRecID,
        value,
        state,
        dispatchLater
      )
    }

    dispatch(batchActions(actions))
  }
}

//export function SetTplRecord

export function SetTplRecord(DienstID, Datum, Nbr, RecordID, value) {
  return (dispatch, getState) => {
    let actions = []
    const dispatchLater = (action) => {
      dispatch(action)
    }
    let state = getState()
    let replaceMode = getState().Dpl.UI.replaceMode
    let recordsByIntID = RecordsByIntIDDatum(getState())
    var tdef =
      (getState().Dpl.TaetigkeitsDefinitionen[parseInt(DienstID)] &&
        getState().Dpl.TaetigkeitsDefinitionen[parseInt(DienstID)].data) ||
      []
    var tbDef =
      (getState().Dpl.Taetigkeiten.data &&
        getState().Dpl.Taetigkeiten.data[DienstID]) ||
      {}
    let recordID = RecordID
    const datum = `${Datum}T00:00:00Z`
    const nbr = Nbr
    const dienst_id = DienstID
    // for (let key of Object.keys(selection)) {
    //   let [dienst_id, year, month, day, nbr] = key.split("-")
    //   dienst_id = parseInt(dienst_id)
    //   nbr = parseInt(nbr)
    //   // let record = (records[`${dienst_id}-${year}-${month}-${day}-dp-S`] || [])[
    //   //   Nbr
    //   // ]
    //let datum = `${year}-${month}-${day}T00:00:00Z`
    const ftg = state.Dpl.Datum.ftg[Datum]
    // let recordID = (record && record.id) || undefined
    let DplDef = getDplDef(Datetime.fromISO(datum), ftg, "dp", tdef, tbDef)
    if (!DplDef) {
      swal("Keine Vorgabe für diese Tätigkeit an diesem Tag 1")
      return
    }
    let wrecords = recordsByIntID[`${value}-${Datum}-dp-S`] || []
    wrecords = wrecords
      .filter((r) => !r.__deleted)
      .map((r) => {
        if (r.__new) {
          return { ...r, id: 0, tempID: r.id + "" }
        }
        return r
      })
    if (wrecords.length > 0) {
      if (DplDef.use_existing) {
        SetTplRecordPlain(
          dienst_id,
          datum,
          nbr,
          wrecords[0].id,
          value,
          state,
          dispatchLater
        )
        return
      } else {
        request
          .post("/api/dpl/check/perm/replace")
          .send(wrecords)
          .end((err, response) => {
            wrecords = response.body
            if (replaceMode == "replace") {
              if (wrecords.length > 1) {
                AskWichRecordReplace(
                  dispatch,
                  getState,
                  wrecords,
                  dienst_id,
                  datum,
                  nbr,
                  value
                )
                return
              }
            } else if (replaceMode == "ask") {
              let text = ""
              let replaceVisible = true

              if (wrecords.length == 1) {
                let r = wrecords[0]
                recordID = r.id
                if (recordID == 0) {
                  recordID = r.tempID
                }
                if (r.dienst_id) {
                  text =
                    " ( " +
                    getState().DropDown["dienst_id/"].byKey[r.dienst_id].label +
                    " )"
                } else if (r.soll && r.soll.dpl_symb) {
                  text = " ( Symbol: " + r.soll.dpl_symb + " )"
                } else if (r.dpl_symb) {
                  text = " ( Symbol: " + r.dpl_symb + " )"
                }
              } else if (wrecords.length == 0) {
                replaceVisible = false
              }

              swal({
                title: "Bisherigen Eintrag ersetzen?",
                text: `Es gibt bereits Einträge für diesen Tag/Person${text}. Wie möchten Sie fortfahren?`,
                icon: "warning",
                buttons: {
                  doNew: {
                    text: "Zusätzlich eintragen",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true
                  },
                  replace: {
                    text: "Ersetzen",
                    value: true,
                    visible: replaceVisible,
                    className: "",
                    closeModal: true
                  },
                  cancel: {
                    text: "Abbrechen",
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                },
                dangerMode: true
              }).then((doReplace) => {
                if (doReplace == null) {
                  return
                }
                if (doReplace == true) {
                  if (wrecords.length > 1) {
                    AskWichRecordReplace(
                      dispatch,
                      getState,
                      wrecords,
                      dienst_id,
                      datum,
                      nbr,
                      value
                    )
                  } else {
                    let intRecID = +recordID
                    if (isNaN(intRecID)) {
                      intRecID = recordID
                    }
                    SetTplRecordPlain(
                      dienst_id,
                      datum,
                      null,
                      intRecID,
                      value,
                      state,
                      dispatch
                    )
                  }
                } else {
                  SetTplRecordPlain(
                    dienst_id,
                    datum,
                    null,
                    null,
                    value,
                    state,
                    dispatch
                  )
                }
              })
              return
            } else {
              recordID = null
            }
          })
        return
      }
    }
    let intRecID = +recordID
    if (isNaN(intRecID)) {
      intRecID = recordID
    }
    SetTplRecordPlain(
      dienst_id,
      datum,
      nbr,
      intRecID,
      value,
      state,
      dispatchLater
    )
    dispatch(batchActions(actions))
  }
}

function AskWichRecordReplace(
  dispatch,
  getState,
  records,
  dienst_id,
  day,
  nbr,
  value
) {
  let buttons = records.map((r) => {
    let o = {
      value: r.id || r.tempID,
      visible: true,
      className: "",
      closeModal: true
    }
    if (r.dienst_id) {
      o.text = getState().DropDown["dienst_id/"].byKey[r.dienst_id].label
    } else if (r.dpl_symb) {
      o.text = "Symbol: " + r.dpl_symb
    }
    return o
  })
  let btnObj = {}
  for (let b of buttons) {
    btnObj[b.value] = b
  }
  swal({
    title: "Welchen Eintrag ersetzen?",
    text: "Es gibt mehrere Einträge für diesen Tag. Welchen möchten Sie ersetzen?",
    icon: "warning",
    buttons: btnObj,
    dangerMode: true
  }).then((val) => {
    let intRecID = +val
    if (isNaN(intRecID)) {
      intRecID = val
    }
    dispatch(
      SetTplRecordPlain(
        dienst_id,
        day,
        null,
        intRecID,
        value,
        getState(),
        dispatch
      )
    )
  })
}

export function SetWunsch(Row, Column) {
  return (dispatch, getState) => {
    if (!canSelect(getState(), Row, Column, "W")) {
      return
    }
    let IntID = parseInt(getState().Dpl.AllPers.intids[Row])
    var { year, month } = getState().Dpl.Datum
    var start = DateTime.utc(year, month, 1).plus({ month: -1 })
    let datum = start.plus({ day: Column })
    var current = DateTime.utc(year, month, 1)
    let Tag = datum.diff(current, "days") + 1
    let recordID =
      getState().Dpl.Records.byIntID[IntID] &&
      getState().Dpl.Records.byIntID[IntID][Tag] &&
      getState().Dpl.Records.byIntID[IntID][Tag][0]
    if (!recordID) {
      return
    }
    let record = getState().Dpl.Records.byID[recordID]
    if (!record) {
      return
    }
    let wunsch = record.wunsch
    if (!wunsch) {
      return
    }
    if (record.soll && record.soll.dpl_symb) {
      return
    }
    if (wunsch.dienst_id) {
      dispatch(
        SetTplRecordPlain(
          wunsch.dienst_id,
          Tag,
          0,
          recordID,
          IntID,
          getState(),
          dispatch
        )
      )
    } else {
      dispatch({
        type: SET_TPL_RECORD,
        Datum: record.datum,
        PlanID: getState().Dpl.Plaene.selected,
        isNew: true,
        art: "W",
        dpl_symb: wunsch.dpl_symb,
        DienstID: null,
        Tag,
        Nbr: 0,
        RecordID: recordID,
        value: IntID,
        valid: true
      })
    }
  }
}

const FindVorgabe = (vorgaben, datum, ftg, art) => {
  let wtg = datum.toFormat("ccc").slice(0, 2)
  for (let d of vorgaben) {
    if (datum < DateTime.fromISO(d.ab_datum)) {
      continue
    }
    if (d.bis_datum !== null && datum > DateTime.fromISO(d.bis_datum)) {
      continue
    }
    if (d.art != art) {
      continue
    }
    if (d.wtg != ftg) {
      continue
    }
    return d
  }
  if (ftg == "FF" || ftg == "VF") {
    ftg = "F"
    for (let d of vorgaben) {
      if (datum < DateTime.fromISO(d.ab_datum)) {
        continue
      }
      if (d.bis_datum !== null && datum > DateTime.fromISO(d.bis_datum)) {
        continue
      }
      if (d.art != art) {
        continue
      }
      if (d.wtg != ftg) {
        continue
      }
      return d
    }
  }
  for (let d of vorgaben) {
    if (datum < DateTime.fromISO(d.ab_datum)) {
      continue
    }
    if (d.bis_datum !== null && datum > DateTime.fromISO(d.bis_datum)) {
      continue
    }
    if (d.art != art) {
      continue
    }
    if (d.wtg != wtg) {
      continue
    }
    return d
  }
  for (let d of vorgaben) {
    if (datum < DateTime.fromISO(d.ab_datum)) {
      continue
    }
    if (d.bis_datum !== null && datum > DateTime.fromISO(d.bis_datum)) {
      continue
    }
    if (d.art != art) {
      continue
    }
    if (d.wtg != "MF") {
      continue
    }
    if (wtgMap[wtg] > 5) {
      continue
    }
    return d
  }
  for (let d of vorgaben) {
    if (datum < DateTime.fromISO(d.ab_datum)) {
      continue
    }
    if (d.bis_datum !== null && datum > DateTime.fromISO(d.bis_datum)) {
      continue
    }
    if (d.art != art) {
      continue
    }
    if (d.wtg != "SS") {
      continue
    }
    if (wtgMap[wtg] <= 5) {
      continue
    }
    return d
  }
  for (let d of vorgaben) {
    if (datum < DateTime.fromISO(d.ab_datum)) {
      continue
    }
    if (d.bis_datum !== null && datum > DateTime.fromISO(d.bis_datum)) {
      continue
    }
    if (d.art != art) {
      continue
    }
    if (d.wtg != "A") {
      continue
    }

    return d
  }
}

export function SetTplRecordPlain(
  DienstID,
  Tag,
  Nbr,
  RecordID,
  value,
  state,
  dispatch
) {
  let isNew = false
  if (!RecordID) {
    RecordID = uuidv4()
    isNew = true
  }
  let mom = DateTime.fromISO(Tag)
  let ftg = GetFtg(state, Tag) //Datum.ftg[mom.toFormat("yyyy-MM-dd")]
  let dt = mom.toISO()
  let vorbelegung = getVorbelegungIntIDDatum(state, value, Tag)

  let def =
    (state.Dpl.TaetigkeitsDefinitionen[DienstID] &&
      state.Dpl.TaetigkeitsDefinitionen[DienstID].data) ||
    []

  let dpl_symb = null
  let PlanID = GetPlanID(state) //state.Dpl.Plaene.selected
  let art = null
  let record = SollRecordByID(state, RecordID) //state.Dpl.Records.byID[RecordID + "-dp-S"]
  let planRecord = PlanRecordByID(state, RecordID)
  let istRecord = IstRecordByID(state, RecordID)
  if (planRecord) {
    record = planRecord
  }
  let rbrecord = RbRecordByID(state, RecordID)
  let kz = "S"
  if (record) {
    dispatch(
      ApplyRbFolgeTag(
        "",
        record.datum,
        record.plan_id,
        DateTime.fromISO(record.datum).toJSDate(),
        null,
        record.int_id,
        record,
        dispatch,
        () => state,
        record.id
      )
    )

    if (istRecord) {
      kz = "I"
    } else if (planRecord) {
      kz = "P"
    }
  }

  let dpDef = FindVorgabe(def, mom, ftg, "dp")
  let rbDef = FindVorgabe(def, mom, ftg, "rb")
  if (rbDef) {
    art = "S"
    dispatch(
      ApplyRbFolgeTag(
        rbDef.dpl_symb,
        DateTime.fromISO(dt).toJSDate(),
        PlanID,
        Tag,
        Nbr,
        value,
        record,
        dispatch,
        () => state
      )
    )
    if (rbDef.use_preset) {
      dpl_symb = vorbelegung
    } else {
      dpl_symb = rbDef.dpl_symb
    }
    if (rbDef.use_existing && record) {
      dpl_symb = record.dpl_symb
    }
    dispatch({
      type: SET_TPL_RECORD,
      Datum: dt,
      PlanID,
      isNew,
      art,
      dprb: rbDef.art,
      dpl_symb,
      DienstID,
      Tag,
      Nbr,
      RecordID,
      value,
      valid: true
    })
  }
  if (dpDef) {
    art = "S"
    dispatch(
      ApplyRbFolgeTag(
        dpDef.dpl_symb,
        DateTime.fromISO(dt).toJSDate(),
        PlanID,
        Tag,
        Nbr,
        value,
        record,
        dispatch,
        () => state
      )
    )
    if (dpDef.use_preset) {
      dpl_symb = vorbelegung
    } else {
      dpl_symb = dpDef.dpl_symb
    }
    if (dpDef.use_existing && record) {
      dpl_symb = record.dpl_symb
    }
    dispatch({
      type: SET_TPL_RECORD,
      Datum: dt,
      PlanID,
      isNew,
      art,
      dprb: dpDef.art,
      dpl_symb,
      DienstID,
      Tag,
      Nbr,
      RecordID,
      value,
      valid: true
    })
  }

  if (!rbDef && rbrecord) {
    dispatch({
      type: SET_TPL_RECORD,
      Datum: dt,
      PlanID,
      isNew: false,
      deleted: true,
      art: "S",
      dprb: "rb",
      dpl_symb: "",
      DienstID,
      Tag,
      Nbr,
      RecordID,
      value,
      valid: true
    })
    dispatch(
      ApplyRbFolgeTag(
        "",
        DateTime.fromISO(dt).toJSDate(),
        PlanID,
        Tag,
        Nbr,
        value,
        record,
        dispatch,
        () => state
      )
    )
  }

  return {}
}

function IsValidSymbol(state, PlanID, DienstID, kz, symbol, datum) {
  if (kz == "B") {
    for (let z of state.rb) {
      if (
        datum &&
        !DateTime.fromISO(datum) > DateTime.fromISO(z.ab_datum) &&
        !DateTime.fromISO(datum).equals(DateTime.fromISO(z.ab_datum))
      ) {
        continue
      }
      if (
        datum &&
        z.bis_dat !== null &&
        !DateTime.fromISO(datum) < DateTime.fromISO(z.bis_dat) &&
        !DateTime.fromISO(datum).equals(DateTime.fromISO(z.bis_dat))
      ) {
        continue
      }
      if (
        z.rb_symb == symbol &&
        (z.PlanID == null || z.PlanID == PlanID) &&
        (z.DienstID == null) | (z.DienstID == DienstID)
      ) {
        return true
      }
    }
  } else if (kz == "S" || kz == "P" || kz == "I" || kz == "W") {
    for (let z of state.dp) {
      if (
        datum &&
        !DateTime.fromISO(datum) > DateTime.fromISO(z.ab_datum) &&
        !DateTime.fromISO(datum).equals(DateTime.fromISO(z.ab_datum))
      ) {
        continue
      }
      if (
        datum &&
        z.bis_dat !== null &&
        !DateTime.fromISO(datum) < DateTime.fromISO(z.bis_dat) &&
        !DateTime.fromISO(datum).equals(DateTime.fromISO(z.bis_dat))
      ) {
        continue
      }
      if (
        z.dpl_symb == symbol &&
        (z.PlanID == null || z.PlanID == PlanID) &&
        (z.DienstID == null) | (z.DienstID == DienstID)
      ) {
        return true
      }
    }
  }
  return false
}

function differenceInWeeks(a, b) {
  let weekDiff = a.diffMonths(b)
  return parseInt(weekDiff)
}

const wtgMap = {
  Mo: 1,
  Di: 2,
  Mi: 3,
  Do: 4,
  Fr: 5,
  Sa: 6,
  So: 7
}
function getDplDef(datum, ftg, art, tdefs, tbDef) {
  let wochen = tbDef.wochen || 1

  let diffWochen = differenceInWeeks(DateTime.fromISO(tbDef.ab_datum), datum)
  let currentWoche = (diffWochen % wochen) + 1
  let wd = datum.toFormat("ccc").slice(0, 2)
  let filtered = tdefs.filter((row) => {
    if (row.art !== art) {
      return false
    }
    if (row.woche !== currentWoche) {
      return false
    }
    if (datum < DateTime.fromISO(row.ab_datum)) {
      return false
    }
    if (row.bis_datum !== null && datum > DateTime.fromISO(row.bis_datum)) {
      return false
    }
    if (ftg) {
      if (row.wtg != ftg) {
        return false
      }
    } else {
      if (row.wtg != wd) {
        return false
      }
    }
    return true
  })

  if (filtered.length == 0) {
    filtered = tdefs.filter((row) => {
      console.log(wd, row.wtg)
      if (row.art !== art) {
        return false
      }
      if (datum < DateTime.fromISO(row.ab_datum)) {
        return false
      }
      if (row.bis_datum !== null && datum > DateTime.fromISO(row.bis_dat)) {
        return false
      }
      if (ftg) {
        if (row.wtg != ftg) {
          return false
        }
      } else {
        if (wtgMap[wd] > 5 && row.wtg == "MF") {
          return false
        }
      }
      return true
    })
  }
  if (filtered.length == 0) {
    filtered = tdefs.filter((row) => {
      if (row.art !== art) {
        return false
      }
      if (datum < DateTime.fromISO(row.ab_datum)) {
        return false
      }
      if (row.bis_datum !== null && datum > DateTime.fromISO(row.bis_dat)) {
        return false
      }
      if (ftg) {
        if (row.wtg != ftg) {
          return false
        }
      } else {
        if (wtgMap[wd] <= 5 && row.wtg == "SS") {
          return false
        }
      }
      return true
    })
  }
  if (filtered.length == 0) {
    filtered = tdefs.filter((row) => {
      if (row.art !== art) {
        return false
      }
      if (datum < DateTime.fromISO(row.ab_datum)) {
        return false
      }
      if (row.bis_datum !== null && datum > DateTime.fromISO(row.bis_dat)) {
        return false
      }
      if (ftg) {
        if (row.wtg != ftg) {
          return false
        }
      } else {
        if (row.wtg != "A") {
          return false
        }
      }
      return true
    })
  }
  if (filtered.length == 0 && ftg == "FF") {
    ftg = "F"
    filtered = tdefs.filter((row) => {
      if (row.art !== art) {
        return false
      }
      if (datum < DateTime.fromISO(row.ab_datum)) {
        return false
      }
      if (row.bis_datum !== null && datum > DateTime.fromISO(row.bis_dat)) {
        return false
      }
      if (ftg) {
        if (row.wtg != ftg) {
          return false
        }
      } else {
        if (row.wtg != wd) {
          return false
        }
      }
      return true
    })
  }
  if (filtered.length == 0 && (ftg == "F" || ftg == "VF")) {
    filtered = tdefs.filter((row) => {
      if (row.art !== art) {
        return false
      }
      if (datum < DateTime.fromISO(row.ab_datum)) {
        return false
      }
      if (row.bis_datum !== null && datum > DateTime.fromISO(row.bis_dat)) {
        return false
      }
      if (row.wtg != wd) {
        return false
      }
      return true
    })
  }
  return (filtered && filtered[0]) || null
}

export function SetDienst(value, int_id, datum) {
  return (dispatch, getState) => {
    var selection = getState().Dpl.Selection
    var PlanID = getState().Dpl.Plaene.selected
    //let ownIntID = getState().currentUser.user.int_id
    var allowed = getState().Permissions[`dpl/plan/plan_id/${PlanID}`]
    if (allowed < 2) {
      return
    }
    //var allowedFehlzeiten = getState().Fehlzeiten.allowed

    var tdef =
      (getState().Dpl.TaetigkeitsDefinitionen[parseInt(value)] &&
        getState().Dpl.TaetigkeitsDefinitionen[parseInt(value)].data) ||
      []
    var tbDef =
      (getState().Dpl.Taetigkeiten.data &&
        getState().Dpl.Taetigkeiten.data[value]) ||
      {}
    if (int_id && datum) {
      selection = {
        [`${int_id}-${DateTime.fromISO(datum).toFormat("yyyy-MM-dd")}-Ta`]: true
      }
    }
    for (let key of Object.keys(selection)) {
      let [intid, year, month, day, kz] = key.split("-")
      if (kz == "Ta") {
        let Nbr =
          getState().Dpl.SelectionNum[`${intid}-${year}-${month}-${day}`] || 0
        let datum = DateTime.utc(parseInt(year), parseInt(month), parseInt(day))

        let recordsByIntID = RecordsByIntIDDatum(getState())

        let SollRecord =
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`] &&
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`][Nbr]
        let IstRecord =
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-I`] &&
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-I`][Nbr]
        let PlanRecord =
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`] &&
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`][Nbr]
        let kz = "S"
        if (PlanRecord && PlanRecord.dpl_symb) {
          kz = "P"
        }
        if (IstRecord && IstRecord.dpl_symb) {
          kz = "I"
        }
        let RecordID
        if (!SollRecord) {
          let key = datum.toFormat("yyyy-MM-dd")
          let ftg = getState().Dpl.Datum.ftg[key]
          let DplDef = getDplDef(datum, ftg, "dp", tdef, tbDef)
          if (!DplDef) {
            swal("Keine Vorgabe für diese Tätigkeit an diesem Tag ")
            continue
          }
          RecordID = uuidv4()
          dispatch({
            type: SET_TPL_RECORD,
            Datum: datum.toISO({ suppressMilliseconds: true }),
            PlanID,
            isNew: true,
            dprb: "dp",
            art: "S",
            dpl_symb: DplDef.dpl_symb,
            Tag: datum,
            Nbr,
            RecordID,
            value: parseInt(intid),
            valid: true
          })
          dispatch({
            type: SET_DIENST,
            art: "S",
            dprb: "dp",
            RecordID,
            value: value,
            int_id: parseInt(intid)
          })
          let RbDef = getDplDef(datum, ftg, "rb", tdef, tbDef)
          if (RbDef) {
            dispatch({
              type: SET_TPL_RECORD,
              Datum: datum.toISO({ suppressMilliseconds: true }),
              PlanID,
              isNew: true,
              dprb: "rb",
              art: "S",
              dpl_symb: RbDef.dpl_symb,
              Tag: datum,
              Nbr,
              RecordID,
              value: parseInt(intid),
              valid: true
            })
            let tomorrow = datum.plus({ day: 1 })
            let kal =
              getState().Dpl.Records.kalender[
                `${intid}-${tomorrow.toFormat("yyyy-MM-dd")}`
              ]
            if (!kal || (!kal.v && !kal.n)) {
              let rbFolgeTag = getState().Dpl.RbFolgeTag
              let filtered = rbFolgeTag.filter((v) => {
                return v.rb_symb == RbDef.dpl_symb
              })
              if (filtered.length > 0) {
                let value = filtered[0]
                let wd = tomorrow.weekday()
                if (value.kz_fzss == "1" || (wd != 6 && wd != 7)) {
                  let fz = value.kz_fol_tg
                  let fzV = fz
                  let fzN = fz
                  if (value.kz_vng == "V") {
                    //prefix = ":"
                    fzN = null
                  } else if (value.kz_vng == "N") {
                    //prefix = "+"
                    fzV = null
                  }
                  if (fz && fz.trim()) {
                    dispatch({
                      type: SET_FEHLZEIT,
                      int_id: parseInt(intid),
                      datum: tomorrow.toISO({ suppressMilliseconds: true }),
                      valid: true,
                      v: fzV,
                      n: fzN
                    })
                  }
                }
              }
            } else {
              let rbRecord = getState().Dpl.Records.byID[`${RecordID}-rb-S`]
              if (rbRecord) {
                //let value = rbRecord.dpl_symb
                let rbFolgeTag = getState().Dpl.RbFolgeTag
                let filtered = rbFolgeTag.filter((v) => {
                  return v.rb_symb == RbDef.dpl_symb
                })
                if (filtered.length > 0) {
                  let value = filtered[0]
                  let wd = tomorrow.weekday()
                  if (value.kz_fzss == "1" || (wd != 6 && wd != 7)) {
                    let fz = value.kz_fol_tg
                    if (fz == kal.v || fz == kal.n) {
                      dispatch({
                        type: SET_FEHLZEIT,
                        int_id: parseInt(intid),
                        datum: tomorrow.toFormat("yyyy-MM-dd") + "T00:00:00Z",
                        valid: true,
                        v: null,
                        n: null
                      })
                    }
                  }
                }
              }
            }
            dispatch({
              type: SET_DIENST,
              art: "S",
              dprb: "rb",
              RecordID,
              value: value,
              int_id: parseInt(intid)
            })
          }
        } else {
          dispatch({
            type: SET_DIENST,
            art: kz,
            dprb: "dp",
            RecordID: SollRecord.id,
            value: value,
            int_id: parseInt(intid)
          })
        }
      }
    }
  }
}

export function EditDpl(value, int_id, datum, kz) {
  return (dispatch, getState) => {
    let state = getState()
    var selection = state.Dpl.Selection
    var PlanID = state.Dpl.Plaene.selected
    let ownIntID = state.currentUser.user.int_id
    var allowed = state.Permissions[`dpl/plan/plan_id/${PlanID}`]
    if (allowed < 2) {
      return
    }
    var allowedFehlzeiten = state.Fehlzeiten.allowed
    var actions = []
    var postActions = []
    const dispatchLater = (action) => {
      actions.push(action)
    }
    const dispatchLater2 = (action) => {
      postActions.push(action)
    }

    if (int_id && datum) {
      if (!kz) {
        kz = "S"
      }
      selection = {
        [`${int_id}-${DateTime.fromISO(datum).toFormat(
          "yyyy-MM-dd"
        )}-${kz}`]: true
      }
    }
    let symbolState = state.Dpl.DpZord
    for (let key of Object.keys(selection)) {
      let type = "dp"
      let [intid, year, month, day, kz] = key.split("-")
      let valid = IsValidSymbol(
        symbolState,
        PlanID,
        null,
        kz,
        value,
        `${year}-${month}-${day}T00:00:00Z`
      )
      if (kz == "B") {
        type = "rb"
        kz = "S"
      }
      let Nbr = state.Dpl.SelectionNum[`${intid}-${year}-${month}-${day}`] || 0
      if (kz == "w" && intid != ownIntID) {
        continue
      }
      if (
        kz == "w" &&
        state.Dpl.Records.displayWunsch == "taet" &&
        type !== "symb"
      ) {
        kz = "wta"
      }
      let datum = DateTime.utc(parseInt(year), parseInt(month), parseInt(day))
      //let Tag = datum.diff(current, "days") + 1
      let placeHolder = getPlaceHoldersForIntIDColumn(
        state,
        intid,
        datum.toFormat("yyyy-MM-dd")
      )
      let recordsByIntID = RecordsByIntIDDatum(state)
      let Record =
        recordsByIntID[`${intid}-${year}-${month}-${day}-${type}-${kz}`] &&
        recordsByIntID[`${intid}-${year}-${month}-${day}-${type}-${kz}`][Nbr]
      let SollRecord =
        recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`] &&
        recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`][Nbr]
      let PlanRecords =
        (recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`] &&
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`]) ||
        []
      let PlanRecord = PlanRecords[Nbr]
      let IstRecords =
        (recordsByIntID[`${intid}-${year}-${month}-${day}-dp-I`] &&
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-I`]) ||
        []
      let SollRecords =
        (recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`] &&
          recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`]) ||
        []

      let RecordID = ""
      let DienstID = null
      let isNew = true
      if (Record) {
        RecordID = Record.id
        DienstID = Record.dienst_id
        if (Record.__deleted) {
          DienstID = SollRecord.dienst_id
        }
        isNew = false
      } else {
        if (SollRecord) {
          RecordID = SollRecord.id
          if (kz !== "S" && PlanRecord && !PlanRecord.__deleted) {
            DienstID = PlanRecord.dienst_id
          } else {
            DienstID = SollRecord.dienst_id
          }
        } else {
          RecordID = uuidv4()
        }

        Record = {}
      }

      let macro = null
      let removeMacro = null
      if (value == "") {
        valid = true
      }
      if (type === "dp" && kz != "I") {
        macro = state.Dpl.Macro.bySymb[value]
      } else if (kz === "I") {
        macro = state.Dpl.Macro.byFz[value]
      }
      // Kalender ?
      if (
        kz === "I" &&
        (value.startsWith("*") ||
          value.startsWith(":") ||
          value.startsWith("+"))
      ) {
        let v = null
        let n = null
        let fz = value.slice(1)
        let mustValidate = 0
        let validateDienstID = null
        if (value.startsWith("*")) {
          v = fz
          n = fz
        } else if (value.startsWith(":")) {
          v = fz
        } else if (value.startsWith("+")) {
          n = fz
        }
        if (allowedFehlzeiten[fz] || fz == "") {
          valid = true
        } else {
          valid = false
          continue
        }

        if (fz == "") {
          let prevKal =
            state.Dpl.Records.kalender[`${intid}-${datum.toISODate()}`]

          let pFz = (prevKal && (prevKal.v || prevKal.n)) || ""
          if (pFz == "" || allowedFehlzeiten[pFz]) {
            valid = true
          } else {
            valid = false
            continue
          }
        }
        if (fz == "") {
          let prevKal =
            state.Dpl.Records.kalender[
              `${intid}-${datum.toFormat("yyyy-MM-dd")}`
            ]
          let pFz = (prevKal && (prevKal.v || prevKal.n)) || ""
          if (allowedFehlzeiten[pFz]) {
            valid = true
          } else {
            valid = false
          }
        }
        let wd = datum.weekday
        let key = datum.toFormat("yyyy-MM-dd")
        let ftg = getState().Dpl.Datum.ftg[key]
        let skipMacro = false
        if (fz != "") {
          let fzDef = FzDef(getState(), intid, fz) //getState().Dpl.FehlzeitDefinitionen[fz]
          if (
            fz != "" &&
            ftg &&
            ftg != "VF" &&
            ftg != "S" &&
            fzDef.feit_ubs != "1"
          ) {
            v = null
            n = null
            skipMacro = true
          }
          if (fz != "" && wd > fzDef.kz_567) {
            v = null
            n = null
            skipMacro = true
          }
          if (fzDef.kz_sol == "1") {
            if (SollRecord && SollRecord.dpl_symb && !SollRecord.__deleted) {
              let dpSymb = SollRecord.dpl_symb
              if (PlanRecord && PlanRecord.dpl_symb && !PlanRecord.__deleted) {
                dpSymb = PlanRecord.dpl_symb
              }
              let zm = Zeitmodell(getState(), dpSymb, SollRecord.datum)
              if (zm && zm.soll_vva == "1") {
                let pers = getState().Dpl.AllPers.pers[intid]
                let vva = pers.vva
                let vvaKey = wdNamesVVa[wd]
                let vvaVal = vva[vvaKey]
                if (vvaVal == null || vvaVal == "0") {
                  v = null
                  n = null
                  skipMacro = true
                }
              } else if (zm && zm.soll_vva == "2") {
                let pers = getState().Dpl.AllPers.pers[intid]
                let vva = pers.vva
                let vvaVal = vva.arb_zt_tgl
                if (vvaVal == null || vvaVal == "0") {
                  v = null
                  n = null
                  skipMacro = true
                }
              } else {
                if ((zm && zm.sol_min == "0") || (zm && zm.sol_min == null)) {
                  v = null
                  n = null
                  skipMacro = true
                }
              }
            } else {
              let soll = getState().Dpl.Sollzeit[intid][key]
              if (!soll || soll.sol_min == "0" || soll.sol_min == null) {
                v = null
                n = null
                skipMacro = true
              }
            }
          } else if (fzDef.kz_sol == "2") {
            let k = `${year}-${month}-${day}`
            let soll = getState().Dpl.VVA[intid][k]
            console.log(soll)
            if (
              !soll ||
              soll.sol_min == "0" ||
              soll.sol_min == null ||
              parseFloat(soll.sol_min) == 0.0
            ) {
              v = null
              n = null
              skipMacro = true
            }
          }
        }
        let shouldValidate = state.Dpl.AbsenceCheck.fehlzeiten[fz]
        if (shouldValidate) {
          for (let r of [...IstRecords, ...PlanRecords, ...SollRecords]) {
            if (state.Dpl.AbsenceCheck.dienste[r.dienst_id]) {
              mustValidate = shouldValidate
              validateDienstID = r.dienst_id
            }
          }
        }

        dispatchLater({
          type: SET_FEHLZEIT,
          int_id: parseInt(intid),
          datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          valid: valid,
          mustValidate,
          dienst_id: validateDienstID,
          v,
          n
        })
        if (!skipMacro) {
          ProcessMacrosRemove(
            removeMacro,
            intid,
            PlanID,
            datum,
            DienstID,
            kz,
            RecordID,
            getState,
            dispatchLater2
          )
          ProcessMacros(
            macro,
            intid,
            PlanID,
            datum,
            DienstID,
            kz,
            RecordID,
            value,
            getState,
            dispatchLater2
          )
          // dispatchLaterPost(() => {
          //   dispatch(
          //     ProcessMacrosRemove(
          //       removeMacro,
          //       intid,
          //       PlanID,
          //       datum,
          //       DienstID,
          //       kz,
          //       RecordID
          //     )
          //   )
          // })
          // dispatchLaterPost(() => {
          //   dispatch(
          //     ProcessMacros(
          //       macro,
          //       intid,
          //       PlanID,
          //       datum,
          //       DienstID,
          //       kz,
          //       RecordID,
          //       value
          //     )
          //   )
          // })
        }
        continue
      } else if (kz === "I" && value == "") {
        // Wichtig - Kalender löschen
        // prüfen ob ein kalender da war - wenn ja v und n auf null
        // setzen - vorzeichen wird gesteuert über blank / null in v und n
        let prevKal =
          state.Dpl.Records.kalender[`${intid}-${datum.toFormat("yyyy-MM-dd")}`]

        let pFz = (prevKal && (prevKal.v || prevKal.n)) || ""
        if (pFz == "" || allowedFehlzeiten[pFz]) {
          valid = true
        } else {
          valid = false
          continue
        }
        if (prevKal && (prevKal.v !== null || prevKal.n !== null)) {
          // dispatchLaterPost(() => {
          //   dispatch(
          //     ProcessMacrosRemove(
          //       removeMacro,
          //       intid,
          //       PlanID,
          //       datum,
          //       DienstID,
          //       kz,
          //       RecordID
          //     )
          //   )
          // })
          ProcessMacrosRemove(
            removeMacro,
            intid,
            PlanID,
            datum,
            DienstID,
            kz,
            RecordID,
            getState,
            dispatchLater2
          )

          dispatchLater({
            type: SET_FEHLZEIT,
            int_id: parseInt(intid),
            datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
            v: null,
            n: null
          })
          continue
        }
      }
      if (kz == "Te") {
        const texteByIntID = TexteByIntIDDatum(state)
        let text = texteByIntID[`${intid}-${datum.toFormat("yyyy-MM-dd")}`]
        let textID = null
        if (text) {
          textID = text.id
        } else {
          textID = uuidv4()
        }
        dispatchLater({
          id: textID,
          type: SET_TPL_TEXT,
          int_id: parseInt(intid),
          datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          text: value
        })
        continue
      }
      if (kz == "S" && type == "rb") {
        let tomorrow = datum.plus({ day: 1 })
        let kal =
          state.Dpl.Records.kalender[
            `${intid}-${tomorrow.toFormat("yyyy-MM-dd")}`
          ]
        //if (!kal || (!kal.v && !kal.n)) {
        let rbFolgeTag = state.Dpl.RbFolgeTag
        let filtered = rbFolgeTag.filter((v) => {
          return v.rb_symb == value
        })
        if (filtered.length > 0) {
          let value = filtered[0]
          let wd = tomorrow.weekday()
          if (value.kz_fzss == "1" || (wd != 6 && wd != 7)) {
            let fz = value.kz_fol_tg
            let fzV = fz
            let fzN = fz
            if (value.kz_vng == "V") {
              //prefix = ":"
              fzN = null
            } else if (value.kz_vng == "N") {
              //prefix = "+"
              fzV = null
            }
            if (fz && fz.trim()) {
              dispatchLater({
                type: SET_FEHLZEIT,
                int_id: parseInt(intid),
                datum: tomorrow.toFormat("yyyy-MM-dd") + "T00:00:00Z",
                valid: true,
                v: fzV,
                n: fzN
              })
            }
          }
        } else {
          let rbRecord = state.Dpl.Records.byID[`${RecordID}-rb-S`]
          if (rbRecord) {
            let value = rbRecord.dpl_symb
            let rbFolgeTag = state.Dpl.RbFolgeTag
            let filtered = rbFolgeTag.filter((v) => {
              return v.rb_symb == value
            })
            if (filtered.length > 0) {
              let value = filtered[0]

              let wd = tomorrow.weekday()
              if (value.kz_fzss == "1" || (wd != 6 && wd != 7)) {
                let fz = value.kz_fol_tg
                if (fz == kal.v || fz == kal.n) {
                  dispatchLater({
                    type: SET_FEHLZEIT,
                    int_id: parseInt(intid),
                    datum: tomorrow.toFormat("yyyy-MM-dd") + "T00:00:00Z",
                    valid: true,
                    v: null,
                    n: null
                  })
                }
              }
            }
          }
        }
      }
      if (kz !== "S" || type !== "dp") {
        if (!SollRecord || !SollRecord.dpl_symb) {
          if (!placeHolder) {
            continue
          }
          dispatchLater({
            type: SET_TPL_RECORD,
            Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
            PlanID,
            isNew: isNew,
            dprb: "dp",
            art: "S",
            DienstID: DienstID,
            dpl_symb: placeHolder,
            Tag: datum,
            Nbr,
            RecordID,
            value: parseInt(intid),
            valid: true
          })
        }
      }
      let deleted = false
      if (value == "") {
        deleted = true
      }
      // Normaler set
      dispatchLater({
        type: SET_TPL_RECORD,
        Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
        PlanID,
        isNew: isNew,
        dprb: type,
        art: kz.toUpperCase(),
        DienstID: DienstID,
        dpl_symb: value,
        Tag: datum,
        Nbr,
        RecordID,
        value: parseInt(intid),
        valid: valid,
        deleted
      })
      if (type == "dp") {
        dispatchLater({
          type: SET_DIENST,
          art: kz,
          dprb: "dp",
          RecordID,
          value: DienstID,
          int_id: parseInt(intid)
        })
      }
      ProcessMacrosRemove(
        removeMacro,
        intid,
        PlanID,
        datum,
        DienstID,
        kz,
        RecordID,
        getState,
        dispatchLater2
      )
      ProcessMacros(
        macro,
        intid,
        PlanID,
        datum,
        DienstID,
        kz,
        RecordID,
        value,
        getState,
        dispatchLater2
      )
      //dispatchLater(ProcessMacros())
      // dispatchLaterPost(() => {
      //   dispatch(
      //     ProcessMacrosRemove(
      //       removeMacro,
      //       intid,
      //       PlanID,
      //       datum,
      //       DienstID,
      //       kz,
      //       RecordID
      //     )
      //   )
      // })
      // dispatchLaterPost(() => {
      //   dispatch(
      //     ProcessMacros(
      //       macro,
      //       intid,
      //       PlanID,
      //       datum,
      //       DienstID,
      //       kz,
      //       RecordID,
      //       value
      //     )
      //   )
      // })
    }
    dispatch(batchActions(actions))
    dispatch(batchActions(postActions))
    // for (let a of postActions) {
    //   a()
    // }
  }
}

const wdNamesVVa = {
  1: "arb_zt_mo",
  2: "arb_zt_di",
  3: "arb_zt_mi",
  4: "arb_zt_do",
  5: "arb_zt_fr",
  6: "arb_zt_sa",
  7: "arb_zt_so"
}

function ProcessMacros2(
  macro,
  intid,
  PlanID,
  datum,
  DienstID,
  kz,
  RecordID,
  value
) {
  return (dispatch, getState) => {
    let state = getState()
    let recordsByIntID = RecordsByIntIDDatum(state)
    let year = datum.year
    let month = ("0" + (datum.month + 1)).slice(-2)
    let day = ("0" + datum.day).slice(-2)
    let Nbr = 0
    let SollRecord =
      recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`] &&
      recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`][Nbr]
    let PlanRecord =
      recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`] &&
      recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`][Nbr]
    let Kalender =
      state.Dpl.Records.kalender[`${intid}-${year}-${month}-${day}`]
    if (macro) {
      if (kz === "I") {
        if (SollRecord && SollRecord.dpl_symb) {
        } else {
          dispatch({
            type: SET_TPL_RECORD,
            Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
            PlanID,
            isNew: true,
            dprb: "dp",
            art: "S",
            DienstID: DienstID,
            dpl_symb: macro.dpl_symb,
            Tag: datum,
            Nbr: 0,
            RecordID,
            value: parseInt(intid),
            valid: true
          })
        }
      }
      if (macro.dienst_id !== undefined) {
        dispatch({
          type: SET_DIENST,
          art: "S",
          dprb: "dp",
          RecordID,
          value: macro.dienst_id,
          int_id: parseInt(intid)
        })
      }
      if (macro.fehlz_id !== null) {
        let wd = datum.weekday()
        let key = datum.toFormat("yyyy-MM-dd")
        let ftg = getState().Dpl.Datum.ftg[key]
        let skipMacro = false
        let fz = macro.fehlz_id.slice(1)
        let fzDef = FzDef(getState(), intid, fz) //getState().Dpl.FehlzeitDefinitionen[fz]
        if (
          fz != "" &&
          ftg &&
          ftg != "VF" &&
          ftg != "S" &&
          fzDef.feit_ubs != "1"
        ) {
          skipMacro = true
        }
        if (fz != "" && wd > fzDef.kz_567) {
          skipMacro = true
        }
        if (fzDef.kz_sol == "1") {
          let rec = SollRecord
          let planSymb = null
          if (PlanRecord && PlanRecord.dpl_symb && !PlanRecord.__deleted) {
            rec = PlanRecord
          }
          if (kz == "P" && value != "") {
            planSymb = value
          }
          if (rec && rec.dpl_symb && !rec.__deleted) {
            let dpSymb = rec.dpl_symb
            let zm = Zeitmodell(getState(), planSymb || dpSymb, rec.datum)
            if (zm && zm.soll_vva == "1") {
              let pers = getState().Dpl.AllPers.pers[intid]
              let vva = pers.vva
              let vvaKey = wdNamesVVa[wd]
              let vvaVal = vva[vvaKey]
              if (vvaVal == null || vvaVal == "0") {
                skipMacro = true
              }
            } else if (zm && zm.soll_vva == "2") {
              let pers = getState().Dpl.AllPers.pers[intid]
              let vva = pers.vva
              let vvaVal = vva.arb_zt_tgl
              if (vvaVal == null || vvaVal == "0") {
                skipMacro = true
              }
            } else {
              if ((zm && zm.sol_min == "0") || (zm && zm.sol_min == null)) {
                skipMacro = true
              }
            }
          }
        } else if (fzDef.kz_sol == "2") {
          let soll = getState().Dpl.VVA[intid][key]
          if (!soll || soll.sol_min == "0" || soll.sol_min == null) {
            skipMacro = true
          }
        }
        if ((!Kalender || !Kalender.v || !Kalender.n) && !skipMacro) {
          let v = null
          let n = null
          if (macro.fehlz_id.startsWith("*")) {
            v = fz
            n = fz
          } else if (macro.fehlz_id.startsWith(":")) {
            v = fz
          } else if (macro.fehlz_id.startsWith("+")) {
            n = fz
          }
          dispatch({
            type: SET_FEHLZEIT,
            int_id: parseInt(intid),
            datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
            v,
            n
          })
        }
      }

      if (macro.rb !== null) {
        dispatch({
          type: SET_TPL_RECORD,
          Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          PlanID,
          isNew: true,
          art: "S",
          dprb: "rb",
          dpl_symb: macro.rb,
          Tag: datum,
          Nbr: 0,
          RecordID,
          value: parseInt(intid),
          valid: true,
          deleted: macro.rb == ""
        })
      }
      if (macro.ftext_id !== null) {
        const texteByIntID = TexteByIntIDDatum(state)
        let text = texteByIntID[`${intid}-${datum.toFormat("yyyy-MM-dd")}`]
        let textID = null
        if (text) {
          textID = text.id
        } else {
          textID = uuidv4()
        }
        let dd = state.DropDown["dpl_freitext/"].byKey[macro.ftext_id].label
        dispatch({
          id: textID,
          type: SET_TPL_TEXT,
          int_id: parseInt(intid),
          datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          text: dd
        })
      }
    }
  }
}

function ProcessMacros(
  macro,
  intid,
  PlanID,
  datum,
  DienstID,
  kz,
  RecordID,
  value,
  getState,
  dispatch
) {
  let state = getState()
  let recordsByIntID = RecordsByIntIDDatum(state)
  let year = datum.year()
  let month = ("0" + datum.month()).slice(-2)
  let day = ("0" + datum.day()).slice(-2)
  let Nbr = 0
  let SollRecord =
    recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`] &&
    recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`][Nbr]

  let PlanRecord =
    recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`] &&
    recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`][Nbr]
  let Kalender = state.Dpl.Records.kalender[`${intid}-${year}-${month}-${day}`]
  if (macro) {
    if (kz === "I") {
      if (!SollRecord || !SollRecord.dpl_symb) {
        dispatch({
          type: SET_TPL_RECORD,
          Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          PlanID,
          isNew: true,
          dprb: "dp",
          art: "S",
          DienstID: DienstID,
          dpl_symb: macro.dpl_symb,
          Tag: datum,
          Nbr: 0,
          RecordID,
          value: parseInt(intid),
          valid: true
        })
      }
    }
    if (macro.dienst_id !== undefined) {
      dispatch({
        type: SET_DIENST,
        art: "S",
        dprb: "dp",
        RecordID,
        value: macro.dienst_id,
        int_id: parseInt(intid)
      })
    }
    if (macro.fehlz_id !== null) {
      let wd = datum.weekday()
      let key = datum.toFormat("yyyy-MM-dd")
      let ftg = getState().Dpl.Datum.ftg[key]
      let skipMacro = false
      let fz = macro.fehlz_id.slice(1)
      let fzDef = FzDef(getState(), intid, fz) //getState().Dpl.FehlzeitDefinitionen[fz]
      if (
        fz != "" &&
        ftg &&
        ftg != "VF" &&
        ftg != "S" &&
        fzDef.feit_ubs != "1"
      ) {
        skipMacro = true
      }
      if (fz != "" && wd > fzDef.kz_567) {
        skipMacro = true
      }
      if (fzDef.kz_sol == "1") {
        let rec = SollRecord
        let planSymb = null
        if (PlanRecord && PlanRecord.dpl_symb && !PlanRecord.__deleted) {
          rec = PlanRecord
        }
        if (kz == "P" && value != "") {
          planSymb = value
        }
        if (rec && rec.dpl_symb && !rec.__deleted) {
          let dpSymb = rec.dpl_symb
          let zm = Zeitmodell(getState(), planSymb || dpSymb, rec.datum)
          if (zm && zm.soll_vva == "1") {
            let pers = getState().Dpl.AllPers.pers[intid]
            let vva = pers.vva
            let vvaKey = wdNamesVVa[wd]
            let vvaVal = vva[vvaKey]
            if (vvaVal == null || vvaVal == "0") {
              skipMacro = true
            }
          } else if (zm && zm.soll_vva == "2") {
            let pers = getState().Dpl.AllPers.pers[intid]
            let vva = pers.vva
            let vvaVal = vva.arb_zt_tgl
            if (vvaVal == null || vvaVal == "0") {
              skipMacro = true
            }
          } else {
            if ((zm && zm.sol_min == "0") || (zm && zm.sol_min == null)) {
              skipMacro = true
            }
          }
        }
      } else if (fzDef.kz_sol == "2") {
        let soll = getState().Dpl.VVA[intid][key]
        if (!soll || soll.sol_min == "0" || soll.sol_min == null) {
          skipMacro = true
        }
      }
      if (
        (!Kalender || !Kalender.v || !Kalender.n || Kalender.__new) &&
        !skipMacro
      ) {
        let v = null
        let n = null
        if (macro.fehlz_id.startsWith("*")) {
          v = fz
          n = fz
        } else if (macro.fehlz_id.startsWith(":")) {
          v = fz
        } else if (macro.fehlz_id.startsWith("+")) {
          n = fz
        }
        dispatch({
          type: SET_FEHLZEIT,
          int_id: parseInt(intid),
          datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          v,
          n
        })
      }
    }

    if (macro.rb !== null) {
      dispatch({
        type: SET_TPL_RECORD,
        Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
        PlanID,
        isNew: true,
        art: "S",
        dprb: "rb",
        dpl_symb: macro.rb,
        Tag: datum,
        Nbr: 0,
        RecordID,
        value: parseInt(intid),
        valid: true,
        deleted: macro.rb == ""
      })
    }
    if (macro.ftext_id !== null) {
      const texteByIntID = TexteByIntIDDatum(state)
      let text = texteByIntID[`${intid}-${datum.toFormat("yyyy-MM-dd")}`]
      let textID = null
      if (text) {
        textID = text.id
      } else {
        textID = uuidv4()
      }
      let dd = state.DropDown["dpl_freitext/"].byKey[macro.ftext_id].label
      dispatch({
        id: textID,
        type: SET_TPL_TEXT,
        int_id: parseInt(intid),
        datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
        text: dd
      })
    }
  }
}

function ProcessMacrosRemove(
  macro,
  intid,
  PlanID,
  datum,
  DienstID,
  kz,
  RecordID,
  getState,
  dispatch
) {
  let state = getState()
  let recordsByIntID = RecordsByIntIDDatum(state)
  let year = datum.year
  let month = ("0" + (datum.month + 1)).slice(-2)
  let day = ("0" + datum.day).slice(-2)
  let Nbr = 0
  let SollRecord =
    recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`] &&
    recordsByIntID[`${intid}-${year}-${month}-${day}-dp-S`][Nbr]
  let PlanRecords =
    (recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`] &&
      recordsByIntID[`${intid}-${year}-${month}-${day}-dp-P`]) ||
    []
  let PlanRecord = PlanRecords[Nbr]

  let Kalender = state.Dpl.Records.kalender[`${intid}-${year}-${month}-${day}`]
  if (macro) {
    if (kz === "I") {
      if (SollRecord && SollRecord.dpl_symb == macro.dpl_symb) {
        dispatch({
          type: SET_TPL_RECORD,
          Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          PlanID,
          isNew: true,
          dprb: "dp",
          art: "S",
          DienstID: null,
          dpl_symb: "",
          Tag: datum,
          Nbr: 0,
          RecordID,
          value: parseInt(intid),
          valid: true,
          deleted: true
        })
      } else if (PlanRecord && PlanRecord.dpl_symb == macro.dpl_symb) {
        dispatch({
          type: SET_TPL_RECORD,
          Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          PlanID,
          isNew: true,
          dprb: "dp",
          art: "P",
          DienstID: null,
          dpl_symb: "",
          Tag: datum,
          Nbr: 0,
          RecordID,
          value: parseInt(intid),
          valid: true,
          deleted: true
        })
      }
    }

    if (macro.fehlz_id !== null) {
      if (Kalender && "*" + Kalender.v == macro.fehlz_id) {
        dispatch({
          type: SET_FEHLZEIT,
          int_id: parseInt(intid),
          datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
          v: null,
          n: null
        })
      }
    }

    // if (macro.rb !== null) {
    //   dispatch({
    //     type: SET_TPL_RECORD,
    //     Datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
    //     PlanID,
    //     isNew: true,
    //     art: "S",
    //     dprb: "rb",
    //     dpl_symb: "",
    //     Tag: datum,
    //     Nbr: 0,
    //     RecordID,
    //     value: parseInt(intid),
    //     valid: true,
    //     deleted: macro.rb == ""
    //   })
    // }
    // if (macro.ftext_id !== null) {
    //   dispatch({
    //     id: null,
    //     type: SET_TPL_TEXT,
    //     int_id: parseInt(intid),
    //     datum: datum.toFormat("yyyy-MM-dd") + "T00:00:00Z",
    //     text: ""
    //   })
    // }
  }
}

function ApplyRbFolgeTag(
  value,
  datum,
  PlanID,
  Tag,
  Nbr,
  intid,
  tag,
  dispatch2,
  getState2,
  PrevRecID
) {
  return (dispatch, getState) => {
    let today = DateTime.fromISO(datum).toFormat("yyyy-MM-dd")
    let tomorrow = DateTime.fromISO(datum)
      .plus({ day: 1 })
      .toFormat("yyyy-MM-dd")
    let kalenderByIntID = getState().Dpl.Records.kalender
    let kal = kalenderByIntID[`${intid}-${tomorrow}`]
    let rbFolgeTag = getState().Dpl.RbFolgeTag
    let filtered = rbFolgeTag.filter((v) => {
      return v.rb_symb == value
    })
    //console.log(filtered, value)
    if (filtered.length > 0) {
      if (kal && (kal.v || kal.n)) {
        return
      }
      let wd = DateTime.fromISO(datum).plus({ day: 1 }).weekday
      let value = filtered[0]
      if (value.kz_fzss == "1" || (wd != 6 && wd != 7)) {
        let fz = value.kz_fol_tg
        let fzV = fz
        let fzN = fz
        if (value.kz_vng == "V") {
          fzN = ""
        } else if (value.kz_vng == "N") {
          fzV = ""
        }

        dispatch({
          type: SET_FEHLZEIT,
          int_id: parseInt(intid),
          datum: tomorrow + "T00:00:00Z",
          valid: true,
          v: fzV,
          n: fzN
        })
      }
    } else {
      let recordsByIntID = RecordsByIntIDDatum(getState())
      let previous = recordsByIntID[`${intid}-${today}-rb-S`] //tag && tag.rsoll && tag.rsoll.dpl_symb

      if (previous && previous.length) {
        if (PrevRecID) {
          previous = previous.filter((r) => {
            return r.id == PrevRecID
          })
        }
        filtered = rbFolgeTag.filter((v) => {
          return v.rb_symb == previous[0].dpl_symb
        })
        value = filtered[0]
        if (filtered.length > 0) {
          // let RemoveRecordID =
          //   getState().Dpl.Records.byIntID[intid] &&
          //   getState().Dpl.Records.byIntID[intid][Tag + 1] &&
          //   getState().Dpl.Records.byIntID[intid][Tag + 1][0]
          // let rec = getState().Dpl.Records.byID[RemoveRecordID]
          // let fz = value.kz_fol_tg
          // let prefix = "*"
          //
          // if (value.kz_vng == "V") {
          //   prefix = ":"
          // } else if (value.kz_vng == "N") {
          //   prefix = "+"
          // }
          //
          let wd = DateTime.fromISO(datum).plus({ day: 1 }).weekday
          if (value.kz_fzss == "1" || (wd != 6 && wd != 7)) {
            if (kal && (kal.v == value.kz_fol_tg || kal.n == value.kz_fol_tg)) {
              dispatch({
                type: SET_FEHLZEIT,
                int_id: parseInt(intid),
                datum: tomorrow + "T00:00:00Z",
                valid: true,
                v: null,
                n: null
              })
            }
          }
        }
      }
    }
  }
}

function LoadRecordsByPlanSuccess(PlanID, Year, Month, data) {
  return {
    type: LOAD_RECORDS_BY_PLAN,
    status: SUCCESS,
    PlanID,
    data,
    Year,
    Month
  }
}

function LoadRecordsByPlanError(PlanID, Year, Month, err) {
  return {
    type: LOAD_RECORDS_BY_PLAN,
    status: ERROR,
    PlanID,
    err,
    Year,
    Month
  }
}

function LoadRecordsByPlanStarted(PlanID, Year, Month) {
  return {
    type: LOAD_RECORDS_BY_PLAN,
    status: STARTED,
    PlanID,
    Year,
    Month
  }
}

export function LoadRecordsByPlan(PlanID, Year, Month) {
  return (dispatch, getState) => {
    dispatch(LoadRecordsByPlanStarted(PlanID, Year, Month))
    request
      .get(`/api/dpl/plan/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        let { year, month } = getState().Dpl.Datum
        if (year !== Year || month !== Month) {
          return
        }
        if (err) {
          dispatch(LoadRecordsByPlanError(PlanID, Year, Month, err))
        } else {
          dispatch(LoadRecordsByPlanSuccess(PlanID, Year, Month, response.body))
        }
        setTimeout(() => {
          dispatch(CountSummen())
        }, 0)
      })
  }
}

export function LoadRecordsByIntID(IntID, Year, Month) {
  return (dispatch, getState) => {
    dispatch(LoadRecordsByPlanStarted(0, Year, Month))
    request
      .get(`/api/dpl/info/records/${IntID}/${Year}/${Month}`)
      .end((err, response) => {
        let { year, month } = getState().Dpl.Datum
        if (year !== Year || month !== Month) {
          return
        }
        if (err) {
          dispatch(LoadRecordsByPlanError(0, Year, Month, err))
        } else {
          dispatch(LoadRecordsByPlanSuccess(0, Year, Month, response.body))
        }
        setTimeout(() => {
          dispatch(CountSummen())
        }, 0)
      })
  }
}

export function LoadRecordsByPlan2(PlanID, Year, Month) {
  return (dispatch, getState) => {
    dispatch(LoadRecordsByPlanStarted(PlanID, Year, Month))
    request
      .get(`/api/dpl/records/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        let { year, month } = getState().Dpl.Datum
        if (year !== Year || month !== Month) {
          return
        }
        if (err) {
          dispatch(LoadRecordsByPlanError(PlanID, Year, Month, err))
        } else {
          dispatch(LoadRecordsByPlanSuccess(PlanID, Year, Month, response.body))
        }
        setTimeout(() => {
          dispatch(CountSummen())
        }, 0)
      })
  }
}

function LoadPrevRecordsByPlanSuccess(PlanID, Year, Month, data) {
  return {
    type: LOAD_PREV_RECORDS_BY_PLAN,
    status: SUCCESS,
    PlanID,
    data,
    Year,
    Month
  }
}

function LoadPrevRecordsByPlanError(PlanID, Year, Month, err) {
  return {
    type: LOAD_PREV_RECORDS_BY_PLAN,
    status: ERROR,
    PlanID,
    err,
    Year,
    Month
  }
}

function LoadPrevRecordsByPlanStarted(PlanID, Year, Month) {
  return {
    type: LOAD_PREV_RECORDS_BY_PLAN,
    status: STARTED,
    PlanID,
    Year,
    Month
  }
}

export function LoadPrevRecordsByPlan(PlanID, Year, Month) {
  let m = DateTime.utc(Year, Month, 1).plus({ month: -1 })
  Year = m.year
  Month = m.month
  return (dispatch) => {
    dispatch(LoadPrevRecordsByPlanStarted(PlanID, Year, Month))
    request
      .get(`/api/dpl/records/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadPrevRecordsByPlanError(PlanID, Year, Month, err))
        } else {
          dispatch(
            LoadPrevRecordsByPlanSuccess(PlanID, Year, Month, response.body)
          )
        }

        setTimeout(() => {
          dispatch(CountSummen())
        }, 0)
      })
  }
}

function LoadNextRecordsByPlanSuccess(PlanID, Year, Month, data) {
  return {
    type: LOAD_NEXT_RECORDS_BY_PLAN,
    status: SUCCESS,
    PlanID,
    data,
    Year,
    Month
  }
}

function LoadNextRecordsByPlanError(PlanID, Year, Month, err) {
  return {
    type: LOAD_NEXT_RECORDS_BY_PLAN,
    status: ERROR,
    PlanID,
    err,
    Year,
    Month
  }
}

function LoadNextRecordsByPlanStarted(PlanID, Year, Month) {
  return {
    type: LOAD_NEXT_RECORDS_BY_PLAN,
    status: STARTED,
    PlanID,
    Year,
    Month
  }
}

export function LoadNextRecordsByPlan(PlanID, Year, Month) {
  let m = DateTime.utc(Year, Month, 1).plus({ month: 1 })
  Year = m.year
  Month = m.month
  return (dispatch) => {
    dispatch(LoadNextRecordsByPlanStarted(PlanID, Year, Month))
    request
      .get(`/api/dpl/records/${PlanID}/${Year}/${Month}`)
      .end((err, response) => {
        if (err) {
          dispatch(LoadNextRecordsByPlanError(PlanID, Year, Month, err))
        } else {
          dispatch(
            LoadNextRecordsByPlanSuccess(PlanID, Year, Month, response.body)
          )
        }

        setTimeout(() => {
          dispatch(CountSummen())
        }, 0)
      })
  }
}
