import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import request from "superagent"
import swal from "sweetalert"
export const MQUEUE_EMPFAENGER = "security/MQUEUE_EMPFAENGER"
export const ADD_MQUEUE_EMPFAENGER_ROW = "security/ADD_MQUEUE_EMPFAENGER_ROW"
export const INSERT_MQUEUE_EMPFAENGER = "security/INSERT_MQUEUE_EMPFAENGER"
export const EDIT_MQUEUE_EMPFAENGER = "security/EDIT_MQUEUE_EMPFAENGER"
export const DELETE_MQUEUE_EMPFAENGER_ROW =
  "security/DELETE_MQUEUE_EMPFAENGER_ROW"
export const DELETED_MQUEUE_EMPFAENGER_ROW =
  "security/DELETED_MQUEUE_EMPFAENGER_ROW"
export const UPDATE_MQUEUE_EMPFAENGER = "security/UPDATE_MQUEUE_EMPFAENGER"

export function MQueueEmpfaengerStarted(user_id) {
  return {
    type: MQUEUE_EMPFAENGER,
    status: STARTED,
    user_id
  }
}
export function MQueueEmpfaengerSuccess(user_id, data) {
  return {
    type: MQUEUE_EMPFAENGER,
    status: SUCCESS,
    user_id,
    data
  }
}
export function MQueueEmpfaengerError(user_id, error) {
  return {
    type: MQUEUE_EMPFAENGER,
    status: ERROR,
    user_id,
    error
  }
}

export function MQueueEmpfaenger(user_id) {
  return dispatch => {
    dispatch(MQueueEmpfaengerStarted(user_id))
    request
      .get(`/api/security/mqueue/${user_id}/empfaenger`)
      .end((err, response) => {
        if (err) {
          dispatch(MQueueEmpfaengerError(user_id, err))
        } else {
          dispatch(MQueueEmpfaengerSuccess(user_id, response.body))
        }
      })
  }
}

export function EditMQueueEmpfaenger(user_id, id, changes) {
  return {
    type: EDIT_MQUEUE_EMPFAENGER,
    user_id,
    id,
    changes
  }
}

export function AddMQueueEmpfaengerRow(user_id) {
  return {
    type: ADD_MQUEUE_EMPFAENGER_ROW,
    user_id
  }
}

export function DeleteMQueueEmpfaengerStarted(user_id) {
  return {
    type: DELETED_MQUEUE_EMPFAENGER_ROW,
    status: STARTED,
    user_id
  }
}
export function DeleteMQueueEmpfaengerSuccess(user_id, id) {
  return {
    type: DELETED_MQUEUE_EMPFAENGER_ROW,
    status: SUCCESS,
    user_id,
    id
  }
}
export function DeleteMQueueEmpfaengerError(user_id, error) {
  return {
    type: DELETED_MQUEUE_EMPFAENGER_ROW,
    status: ERROR,
    user_id,
    error
  }
}

export function DeleteMQueueEmpfaengerRow(user_id, id) {
  return {
    type: DELETE_MQUEUE_EMPFAENGER_ROW,
    user_id,
    id
  }
}

export function InsertMQueueEmpfaengerStarted(user_id) {
  return {
    type: INSERT_MQUEUE_EMPFAENGER,
    status: STARTED,
    user_id
  }
}
export function InsertMQueueEmpfaengerSuccess(user_id, data) {
  return {
    type: INSERT_MQUEUE_EMPFAENGER,
    status: SUCCESS,
    user_id,
    data
  }
}
export function InsertMQueueEmpfaengerError(user_id, error) {
  return {
    type: INSERT_MQUEUE_EMPFAENGER,
    status: ERROR,
    user_id,
    error
  }
}

function CheckMQueueEmpfaenger(empfaenger) {
  for (let row of empfaenger) {
    if (row.int_id == null) {
      return "bitte Mitarbeiter auswählen"
    }
  }
  return null
}

export function UpdateMQueueEmpfaengerStarted(user_id) {
  return {
    type: UPDATE_MQUEUE_EMPFAENGER,
    status: STARTED,
    user_id
  }
}

export function UpdateMQueueEmpfaengerSuccess(user_id, id, data) {
  return {
    type: UPDATE_MQUEUE_EMPFAENGER,
    status: SUCCESS,
    id,
    user_id,
    data
  }
}

export function UpdateMQueueEmpfaengerError(user_id, id, error) {
  return {
    type: UPDATE_MQUEUE_EMPFAENGER,
    status: ERROR,
    id,
    user_id,
    error
  }
}

export function UpdateMQueueEmpfaenger(user_id) {
  return (dispatch, getState) => {
    let data = getState().Security.empfaenger.data[user_id] || []

    if (data.length == 0) {
      return
    }
    var err = CheckMQueueEmpfaenger(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Personalstammdaten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        dispatch(DeleteMQueueEmpfaengerStarted(user_id, row.id))
        request
          .delete(`/api/security/mqueue/${user_id}/empfaenger/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteMQueueEmpfaengerError(user_id, row.id, err))
            } else {
              dispatch(
                DeleteMQueueEmpfaengerSuccess(user_id, row.id, response.body)
              )
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row

        dispatch(UpdateMQueueEmpfaengerStarted(user_id))
        request
          .post(`/api/security/mqueue/${user_id}/empfaenger`)
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateMQueueEmpfaengerError(user_id, id, err))
            } else {
              dispatch(
                UpdateMQueueEmpfaengerSuccess(user_id, id, response.body)
              )
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateMQueueEmpfaengerStarted(user_id))
        request
          .put(`/api/security/mqueue/${user_id}/empfaenger`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateMQueueEmpfaengerError(user_id, row.id, err))
            } else {
              dispatch(
                UpdateMQueueEmpfaengerSuccess(user_id, row.id, response.body)
              )
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
  }
}

export function EmpfaengerUnsavedChanges(state) {
  for (let empfaenger of Object.values(state)) {
    for (let row of empfaenger) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}
