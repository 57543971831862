import request from "superagent"
import { queryToString } from "app/shared/util"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"
export const ALTER_QUERY = "ALTER_QUERY"
export const SET_AKTIVEN_MITARBEITER = "SET_AKTIVEN_MITARBEITER"
export const CLEAR_QUERY = "CLEAR_QUERY"
export const TOGGLE_QUERY_VISIBILITY = "TOGGLE_QUERY_VISIBILITY"
export const EXECUTE_QUERY = "EXECUTE_QUERY"
export const SET_QUERY_SELECTION = "SET_QUERY_SELECTION"
export const QUERY_SELECTION_CLEAR_ALL = "QUERY_SELECTION_CLEAR_ALL"
export const QUERY_SELECTION_SELECT_ALL = "QUERY_SELECTION_SELECT_ALL"
export const SET_QUERY_SELECTION_RANGE = "SET_QUERY_SELECTION_RANGE"
export const FETCH_QUERY_RESULTS = "FETCH_QUERY_RESULTS"
export const FETCH_QUERY_SETTINGS = "FETCH_QUERY_SETTINGS"
export const SAVE_QUERY_SETTINGS = "SAVE_QUERY_SETTINGS"

export const EXECUTE_QUERY_STARTED = "EXECUTE_QUERY_STARTED"
export const EXECUTE_QUERY_SUCCESS = "EXECUTE_QUERY_SUCCESS"
export const EXECUTE_QUERY_ERROR = "EXECUTE_QUERY_ERROR"

export function setAktivenMitarbeiter(int_id) {
  return {
    type: SET_AKTIVEN_MITARBEITER,
    int_id
  }
}

export function toggleQuery() {
  return {
    type: TOGGLE_QUERY_VISIBILITY
  }
}

export function clearQuery() {
  return {
    type: CLEAR_QUERY
  }
}

export function alterQuery(key, changes) {
  return {
    type: ALTER_QUERY,
    key: key,
    changes: changes
  }
}

export function executeQueryStarted(query) {
  return {
    type: EXECUTE_QUERY,
    status: EXECUTE_QUERY_STARTED,
    query
  }
}

export function saveCurrentQuery(query) {
  return {
    type: EXECUTE_QUERY,
    status: EXECUTE_QUERY_SUCCESS,
    query
  }
}

export function executeQuerySuccess(query) {
  return {
    type: EXECUTE_QUERY,
    status: EXECUTE_QUERY_SUCCESS,
    query
  }
}

export function executeQueryError(query, error) {
  return {
    type: EXECUTE_QUERY,
    status: EXECUTE_QUERY_ERROR,
    query,
    error
  }
}

export function fetchQueryResultsStarted() {
  return {
    type: FETCH_QUERY_RESULTS,
    status: EXECUTE_QUERY_STARTED
  }
}

export function fetchQueryResultsSuccess(results) {
  return {
    type: FETCH_QUERY_RESULTS,
    status: EXECUTE_QUERY_SUCCESS,
    results
  }
}

export function fetchQueryResultsError(error) {
  return {
    type: FETCH_QUERY_RESULTS,
    status: EXECUTE_QUERY_ERROR,
    error
  }
}

export function fetchQuerySettingsStarted() {
  return {
    type: FETCH_QUERY_SETTINGS,
    status: EXECUTE_QUERY_STARTED
  }
}

export function fetchQuerySettingsSuccess(results) {
  return {
    type: FETCH_QUERY_SETTINGS,
    status: EXECUTE_QUERY_SUCCESS,
    results
  }
}

export function fetchQuerySettingsError(error) {
  return {
    type: FETCH_QUERY_SETTINGS,
    status: EXECUTE_QUERY_ERROR,
    error
  }
}

export function setQueryResultsSelection(int_id, add = false) {
  return {
    type: SET_QUERY_SELECTION,
    add,
    int_id
  }
}

export function removeQueryResultsSelection() {
  return {
    type: QUERY_SELECTION_CLEAR_ALL
  }
}

export function setQueryResultsSelectAll() {
  return {
    type: QUERY_SELECTION_SELECT_ALL
  }
}

export function setQueryResultsSelectionRange(int_id) {
  return {
    type: SET_QUERY_SELECTION_RANGE,
    int_id
  }
}

export function executeQuery(query, ressourceGroup, ressourceName, datum) {
  return function (dispatch) {
    dispatch(executeQueryStarted(query))
    query["group"] = ressourceGroup
    query["name"] = ressourceName
    if (!datum) {
      datum = Datetime.today().toISO()
    }
    return new Promise((resolve, reject) => {
      request
        .get(`/api/query/${datum}`)
        .query(queryToString(query))
        .end((error, response) => {
          if (error) {
            dispatch(executeQueryError(query, error))
            reject(error)
          } else {
            dispatch(saveQuerySettings())
            dispatch(executeQuerySuccess(query))
            dispatch(fetchQueryResultsSuccess(response.body))
            resolve(response.body)
          }
        })
    })
  }
}

export function saveQuerySettingsSuccess() {
  return {
    type: SAVE_QUERY_SETTINGS,
    status: EXECUTE_QUERY_SUCCESS
  }
}

export function saveQuerySettingsError() {
  return {
    type: SAVE_QUERY_SETTINGS,
    status: EXECUTE_QUERY_ERROR
  }
}

export function saveQuerySettings(squery) {
  return function (dispatch, getState) {
    let query = squery || getState().Query.QuerySelection
    return request
      .post("/api/ui/settings/query")
      .send(query)
      .end((err) => {
        if (err) {
          dispatch(saveQuerySettingsError())
        } else {
          dispatch(saveQuerySettingsSuccess())
        }
      })
  }
}

export function fetchQuerySettings() {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      if (getState().Query.QuerySelection !== null) {
        resolve(getState().Query.QuerySelection)
        return
      }
      dispatch(fetchQuerySettingsStarted())

      request.get("/api/ui/settings/query").end((error, response) => {
        if (error) {
          dispatch(fetchQuerySettingsError(error))
          reject(error)
        } else {
          dispatch(fetchQuerySettingsSuccess(response.body))
          resolve(response.body)
        }
      })
    })
  }
}
