import Datetime from "app/shared/dateutil/index"
import { IsFeiertag } from "./feiertage"
import { createSelector } from "reselect"
import { GenehmigungStatus } from "./genehmigung"

export const AllowedFehlzeiten = (state) => {
  return state.UPL.Fehlzeiten
}

export const AllowedWunschFehlzeiten = createSelector(
  AllowedFehlzeiten,
  (fz) => {
    return Object.values(fz)
      .filter((f) => f.wunschzeile)
      .map((f) => f.fehlz_id)
  }
)

export const AllowedPlanFehlzeiten = createSelector(AllowedFehlzeiten, (fz) => {
  return Object.values(fz)
    .filter((f) => f.planzeile)
    .map((f) => f.fehlz_id)
})

const weekdays = ["", "mo", "di", "mi", "do", "fr", "sa", "so"]

export const CanEdit = (state, value, datum, kz) => {
  if (value == "") {
    return true
  }
  const fz = AllowedFehlzeiten(state)
  if (!fz[value]) {
    return false
  }
  if (kz == "W") {
    if (!fz[value].wunschzeile) {
      return false
    }
  } else {
    if (!fz[value].planzeile) {
      return false
    }
  }
  const d = Datetime.fromISO(datum)
  const wd = weekdays[d.weekday()]
  const isFtg = IsFeiertag(state, datum)
  if (isFtg && !fz[value].ftg) {
    return false
  } else if (!fz[value][wd]) {
    return false
  }
  return true
}
