import {
  LOAD_KENNZEICHNUNGEN,
  LOAD_GEKENNZEICHNET,
  REMOVE_GEKENNZEICHNET
} from "app/dpl/actions/kennzeichnungen"
import { arrayToArrayObjectFn } from "app/shared/util/conversion"

const Kennzeichnungen = (state = { available: [], set: {} }, action) => {
  let prevData
  switch (action.type) {
    case LOAD_KENNZEICHNUNGEN:
      return { ...state, available: action.data || [] }
    case LOAD_GEKENNZEICHNET:
      prevData = Object.values(state.set).reduce((prev, next) => {
        return [...prev, ...next]
      }, [])
      return {
        ...state,
        set: {
          ...state.set,
          ...arrayToArrayObjectFn(
            [...prevData, ...(action.data || [])],
            (o) => {
              return `${o.int_id}-${o.datum.slice(0, 10)}`
            }
          )
        }
      }
    case REMOVE_GEKENNZEICHNET:
      prevData = Object.values(state.set).reduce((prev, next) => {
        return [...prev, ...next]
      }, [])
      return {
        ...state,
        set: {
          ...arrayToArrayObjectFn(
            [...prevData].filter((v) => {
              if (
                v.id == action.id &&
                v.int_id == action.int_id &&
                v.datum == action.datum
              ) {
                return false
              }
              return true
            }),
            (o) => {
              return `${o.int_id}-${o.datum.slice(0, 10)}`
            }
          )
        }
      }
  }
  return state
}

export default Kennzeichnungen
