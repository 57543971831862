import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { GetCurrentUser } from "app/shared/authentication/selectors"

export const LOAD_FAVORITEN = "LOAD_FAVORITEN"
export const SET_FAVORIT = "SET_FAVORIT"
export const UNSET_FAVORIT = "UNSET_FAVORIT"

export const LoadFavoriten = (PlanID) => {
  return (dispatch, getState) => {
    let UserID = GetCurrentUser(getState()).user_id
    dispatch({ type: LOAD_FAVORITEN, status: STARTED })
    request
      .get(`/api/dpl/favoriten/${PlanID}/${UserID}`)
      .end((err, response) => {
        if (err) {
          dispatch({ type: LOAD_FAVORITEN, status: ERROR, error: err })
        } else {
          dispatch({
            type: LOAD_FAVORITEN,
            status: SUCCESS,
            data: response.body
          })
        }
      })
  }
}

export const SetFavorit = (DienstID) => {
  return (dispatch, getState) => {
    let UserID = GetCurrentUser(getState()).user_id
    let PlanID = getState().Dpl.Plaene.selected
    request
      .post(`/api/dpl/favoriten/${PlanID}/${UserID}/${DienstID}`)
      .end((err, response) => {
        if (err) {
          dispatch({ type: SET_FAVORIT, status: ERROR, error: err })
        } else {
          dispatch({
            type: SET_FAVORIT,
            status: SUCCESS,
            data: response.body
          })
        }
      })
  }
}

export const UnSetFavorit = (DienstID) => {
  return (dispatch, getState) => {
    let UserID = GetCurrentUser(getState()).user_id
    let PlanID = getState().Dpl.Plaene.selected
    request
      .delete(`/api/dpl/favoriten/${PlanID}/${UserID}/${DienstID}`)
      .end((err) => {
        if (err) {
          dispatch({ type: UNSET_FAVORIT, status: ERROR, error: err })
        } else {
          dispatch({
            type: UNSET_FAVORIT,
            status: SUCCESS,
            data: DienstID
          })
        }
      })
  }
}
