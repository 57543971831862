import request from "superagent"

export const LOAD_ACTIVE_BERECHTIGUNGEN = "upl/LOAD_ACTIVE_BERECHTIGUNGEN"
export const LOAD_STUFEN = "upl/LOAD_STUFEN"

export const LoadActiveBerechtigungen = () => {
  return (dispatch, getState) => {
    return request
      .get("/api/urlaubsplanung/activeberechtigungen")
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_ACTIVE_BERECHTIGUNGEN,
            data: response.body
          })
        }
      })
  }
}

export const LoadStufen = () => {
  return (dispatch, getState) => {
    return request.get("/api/urlaubsplanung/stufen").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_STUFEN,
          data: response.body
        })
      }
    })
  }
}

///api/urlaubsplanung/stufen
