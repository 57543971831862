import request from "superagent"
import Datetime, { Parse, ISO } from "app/shared/dateutil/index"
import swal from "sweetalert"
import { getDropDownValue } from "app/shared/dropdown/selectors"
import { v4 as uuidv4 } from "uuid"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_ZEITMODELL = "zeitmodell/LOAD_ZEITMODELL"
export const LOAD_TAGESMODELLEBEZ = "LOAD_TAGESMODELLEBEZ"
export const EDIT_TAGESMODELLTEXT = "EDIT_TAGESMODELLTEXT"
export const LOAD_TAGESMODELLE = "LOAD_TAGESMODELLE"
export const NEW_TAGESMODELL = "NEW_TAGESMODELL"
export const NEW_TAGESMODELLBEZ = "NEW_TAGESMODELLBEZ"
export const COPY_TAGESMODELL = "COPY_TAGESMODELL"
export const COPY_TAGESMODELLBEZ = "COPY_TAGESMODELLBEZ"
export const UPDATE_TAGESMODELLBEZ = "UPDATE_TAGESMODELLBEZ"
export const UPDATE_TAGESMODELL = "UPDATE_TAGESMODELL"
export const LOAD_WOCHENMODELL = "LOAD_WOCHENMODELL"
export const LOAD_WOCHENMODELLE_BEZ = "LOAD_WOCHENMODELLE_BEZ"
export const NEW_WOCHENMODELL = "NEW_WOCHENMODELL"
export const NEW_WOCHENMODELLBEZ = "NEW_WOCHENMODELLBEZ"
export const EDIT_WOCHENTEXT = "EDIT_WOCHENTEXT"
export const EDIT_SCHICHTTEXT = "EDIT_SCHICHTTEXT"
export const UPDATE_SCHICHTMODELLBEZ = "UPDATE_SCHICHTMODELLBEZ"
export const UPDATE_SCHICHTMODELL = "UPDATE_SCHICHTMODELL"
export const UPDATE_WOCHENMODELL = "UPDATE_WOCHENMODELL"
export const UPDATE_WOCHENMODELLBEZ = "UPDATE_WOCHENMODELLBEZ"
export const LOAD_SCHICHTMODELLE = "LOAD_SCHICHTMODELLE"
export const LOAD_SCHICHTMODELLE_BEZ = "LOAD_SCHICHTMODELLE_BEZ"
export const NEW_SCHICHTMODELLBEZ = "NEW_SCHICHTMODELLBEZ"
export const NEW_SCHICHTMODELL = "NEW_SCHICHTMODELL"
export const EDIT_TZM = "EDIT_TZM"
export const LOAD_GRUPPENMODELLZUORD = "LOAD_GRUPPENMODELLZUORD"
export const ADD_GRUPPENMODELLZUORD_ROW = "ADD_GRUPPENMODELLZUORD_ROW"
export const DELETED_GRUPPENMODELLZUORD_ROW = "DELETED_GRUPPENMODELLZUORD_ROW"
export const DELETE_GRUPPENMODELLZUORD_ROW = "DELETE_GRUPPENMODELLZUORD_ROW"
export const EDIT_GRUPPENMODELLZUORD = "EDIT_GRUPPENMODELLZUORD"
export const UPDATE_GRUPPENMODELLZUORD = "UPDATE_GRUPPENMODELLZUORD"
export const EDIT_TAGESMOD = "EDIT_TAGESMOD"
export const EDIT_WOCHENMOD = "EDIT_WOCHENMOD"
export const LOAD_SCHICHTTAUSCH = "LOAD_SCHICHTTAUSCH"
export const ADD_SCHICHTTAUSCH_ROW = "ADD_SCHICHTTAUSCH_ROW"
export const DELETED_SCHICHTTAUSCH_ROW = "DELETED_SCHICHTTAUSCH_ROW"
export const DELETE_SCHICHTTAUSCH_ROW = "DELETE_SCHICHTTAUSCH_ROW"
export const EDIT_SCHICHT = "EDIT_SCHICHT"
export const UPDATE_SCHICHTTAUSCH = "UPDATE_SCHICHTTAUSCH"
export const CHANGETZM_AB_DATUM = "CHANGETZM_AB_DATUM"
export const CREATETZM_NEW_AB_DATUM = "CREATETZM_NEW_AB_DATUM"
export const LOAD_SYMBOL_ZUORD = "LOAD_SYMBOL_ZUORD"
export const EDIT_SYMBOL_ZUORD = "EDIT_SYMBOL_ZUORD"
export const UPDATE_SYMBOL_ZUORD = "UPDATE_SYMBOL_ZUORD"
export const ADD_SYMBOLZUORD_ROW = "ADD_SYMBOLZUORD_ROW"
export const DELETED_SYMBOLZUORD_ROW = "DELETED_SYMBOLZUORD_ROW"
export const DELETE_SYMBOLZUORD_ROW = "DELETE_SYMBOLZUORD_ROW"
export const LOAD_RBSYMBOL_ZUORD = "LOAD_RBSYMBOL_ZUORD"
export const UPDATE_RBSYMBOL_ZUORD = "UPDATE_RBSYMBOL_ZUORD"
export const ADD_RBSYMBOLZUORD_ROW = "ADD_RBSYMBOLZUORD_ROW"
export const DELETED_RBSYMBOLZUORD_ROW = "DELETED_RBSYMBOLZUORD_ROW"
export const DELETE_RBSYMBOLZUORD_ROW = "DELETE_RBSYMBOLZUORD_ROW"
export const EDIT_RBSYMBOL_ZUORD = "EDIT_RBSYMBOL_ZUORD"
export const LOAD_RBTAGESMODELLEBEZ = "LOAD_RBTAGESMODELLEBEZ"
export const LOAD_RBTAGESMODELLE = "LOAD_RBTAGESMODELLE"
export const EDIT_RBTZM = "EDIT_RBTZM"
export const EDIT_RBTAGESMODELLTEXT = "EDIT_RBTAGESMODELLTEXT"
export const UPDATE_RBTAGESMODELLBEZ = "UPDATE_RBTAGESMODELLBEZ"
export const UPDATE_RBTAGESMODELL = "UPDATE_RBTAGESMODELL"
export const NEW_RBTAGESMODELL = "NEW_RBTAGESMODELL"
export const NEW_RBTAGESMODELLBEZ = "NEW_RBTAGESMODELLBEZ"
export const EDIT_RBTAGESMOD = "EDIT_RBTAGESMOD"
export const LOAD_RBVERTAGESMOD = "LOAD_RBVERTAGESMOD"
export const ADD_RBVERTAGESMOD_ROW = "ADD_RBVERTAGESMOD_ROW"
export const DELETED_RBVERTAGESMOD_ROW = "DELETED_RBVERTAGESMOD_ROW"
export const DELETE_RBVERTAGESMOD_ROW = "DELETE_RBVERTAGESMOD_ROW"
export const EDIT_RBVERTAGESMOD = "EDIT_RBVERTAGESMOD"
export const UPDATE_RBVERTAGESMOD = "UPDATE_RBVERTAGESMOD"
export const LOAD_SALDENBEREINIGUNG = "LOAD_SALDENBEREINIGUNG"
export const ADD_SALDENBEREINIGUNG_ROW = "ADD_SALDENBEREINIGUNG_ROW"
export const DELETED_SALDENBEREINIGUNG_ROW = "DELETED_SALDENBEREINIGUNG_ROW"
export const DELETE_SALDENBEREINIGUNG_ROW = "DELETE_SALDENBEREINIGUNG_ROW"
export const UPDATE_SALDENBEREINIGUNG = "UPDATE_SALDENBEREINIGUNG"
export const EDIT_SALDENBEREINIGUNG = "EDIT_SALDENBEREINIGUNG"
export const LOAD_VVA = "LOAD_VVA"
export const EDIT_VVA = "EDIT_VVA"
export const EDIT_VVA_TGL = "EDIT_VVA_TGL"
export const ADD_VVA_ROW = "ADD_VVA_ROW"
export const UPDATE_VVA = "UPDATE_VVA"
export const DELETE_VVA_ROW = "DELETE_VVA_ROW"
export const DELETED_VVA_ROW = "DELETED_VVA_ROW"
export const EDIT_VVAAB = "EDIT_VVAAB"
export const ADD_VVA_ROW_AB = "ADD_VVA_ROW_AB"
export const LOAD_TAGESMODZTUMBUCH = "LOAD_TAGESMODZTUMBUCH"
export const ADD_TAGESMODZTUMBUCH_ROW = "ADD_TAGESMODZTUMBUCH_ROW"
export const DELETED_TAGESMODZTUMBUCH_ROW = "DELETED_TAGESMODZTUMBUCH_ROW"
export const DELETE_TAGESMODZTUMBUCH_ROW = "DELETE_TAGESMODZTUMBUCH_ROW"
export const EDIT_TAGESMODZTUMBUCH = "EDIT_TAGESMODZTUMBUCH"
export const UPDATE_TAGESMODZTUMBUCH = "UPDATE_TAGESMODZTUMBUCH"

export function LoadTagesModZtUmbuchSuccess(tg_ztmod, data) {
  return {
    type: LOAD_TAGESMODZTUMBUCH,
    status: SUCCESS,
    tg_ztmod,
    data
  }
}

export function LoadTagesModZtUmbuch(tg_ztmod) {
  return (dispatch) => {
    request.get(`/api/tagesmodellztumbuch/${tg_ztmod}`).end((err, response) => {
      dispatch(LoadTagesModZtUmbuchSuccess(tg_ztmod, response.body))
    })
  }
}

function CheckTagesModZtUmbuch(tagesmodztumbuch) {
  for (let row of tagesmodztumbuch) {
    if (row.std_umb == null) {
      return "bitte Stunden angeben"
    }
    if (row.pk_kenn == null) {
      return "bitte Pauschalzeit angeben"
    }
    if (row.kz_wrk == null || row.kz_wrk == "") {
      return "bitte ADD, SUB, SET, SEToÜZ angeben"
    }
    if (
      row.t1 == null &&
      row.t2 == null &&
      row.t3 == null &&
      row.t4 == null &&
      row.t5 == null &&
      row.t6 == null &&
      row.t7 == null &&
      row.tf == null
    ) {
      return "bitte Tag der Aktion  auswählen"
    }
  }
  return null
}

export function EditTagesModZtUmbuch(tg_ztmod, id, changes) {
  return {
    type: EDIT_TAGESMODZTUMBUCH,
    tg_ztmod,
    id,
    changes
  }
}

export function DeleteTagesModZtUmbuchSuccess(tg_ztmod, id) {
  return {
    type: DELETED_TAGESMODZTUMBUCH_ROW,
    status: SUCCESS,
    tg_ztmod,
    id
  }
}

export function DeleteTagesModZtUmbuchRow(tg_ztmod, id) {
  return {
    type: DELETE_TAGESMODZTUMBUCH_ROW,
    tg_ztmod,
    id
  }
}

export function AddTagesModZtUmbuchRow(tg_ztmod) {
  return {
    type: ADD_TAGESMODZTUMBUCH_ROW,
    tg_ztmod
  }
}

export function UpdateTagesModZtUmbuchSuccess(id, data) {
  return {
    type: UPDATE_TAGESMODZTUMBUCH,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateTagesModZtUmbuch() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.tagesmodztumbuch || {}
    for (let rows of Object.values(data)) {
      var err = CheckTagesModZtUmbuch(rows)
      if (err !== null) {
        swal({
          title: "Hinweis zum Speichern der Daten",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        return
      }
      for (let row of rows) {
        if (row.__deleted && !row.__new) {
          request
            .delete(`/api/tagesmodellztumbuch/${row.id}`)
            .send(row)
            .end((err, response) => {
              dispatch(DeleteTagesModZtUmbuchSuccess(row.tg_ztmod, row.id))
            })
        } else if (row.__new) {
          let { id, ...r } = row
          request
            .post("/api/tagesmodellztumbuch/new")
            .send(r)
            .end((err, response) => {
              dispatch(UpdateTagesModZtUmbuchSuccess(row.id, response.body))
            })
        } else if (row.__dirty && !row.__deleted) {
          request
            .put("/api/tagesmodellztumbuch")
            .send(row)
            .end((err, response) => {
              dispatch(UpdateTagesModZtUmbuchSuccess(row.id, response.body))
            })
        }
      }
    }
  }
}

export function TagesModZtUmbuchUnsavedChanges(state) {
  for (let tagesmodztumbuch of Object.values(state)) {
    for (let row of tagesmodztumbuch) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function LoadVVaSuccess(data) {
  return {
    type: LOAD_VVA,
    status: SUCCESS,
    data
  }
}
export function LoadVVa() {
  return (dispatch) => {
    request.get("/api/vva").end((err, response) => {
      dispatch(LoadVVaSuccess(response.body))
    })
  }
}
export function EditVVa(abr_kreis, id, changes) {
  return {
    type: EDIT_VVA,
    abr_kreis,
    id,
    changes
  }
}
export function EditVVaTgl(abr_kreis, id, changes) {
  return {
    type: EDIT_VVA_TGL,
    abr_kreis,
    id,
    changes
  }
}
function GetVVaAb(state, id) {
  //for (let vvas of Object.values(state.Zeitmodell.vva)) {
  for (let vva of state.Zeitmodell.vva) {
    //for (let vva of vvas) {
    if (vva.id == id) {
      return vva
    }
    //}
  }
}

// export function EditVVaAb(id, ab_datum) {
export function EditVVaAb(abr_kreis, id, ab_datum) {
  return (dispatch, getState) => {
    let vva = GetVVaAb(getState(), id)
    console.log(abr_kreis)
    console.log(vva)
    console.log(vva.ab_datum)
    console.log(id)
    console.log(ab_datum)
    // Ab Datum in Vergangenheit verschieben ist nicht möglich!
    // if (Datetime.fromISO(vva.ab_datum) > Datetime.fromISO(ab_datum)) {
    //   return
    // }
    // Wenn Ab Datum größer ist als bisheriges Ab-Datum muss ein neuer satz erstellt werden
    if (Datetime.fromISO(vva.ab_datum) < Datetime.fromISO(ab_datum)) {
      let nVVa = { ...vva, bis_dat: ab_datum, id: uuidv4() }
      console.log(ab_datum)
      dispatch(AddVVARowAb(vva.abr_kreis, nVVa))
    }
    // Ab-Datum editieren
    dispatch({
      type: EDIT_VVAAB,
      // abr_kreis,
      id,
      ab_datum
    })
  }
}
export function AddVVARowAb(abr_kreis, ab_datum) {
  return {
    type: ADD_VVA_ROW_AB,
    abr_kreis,
    ab_datum
  }
}
export function AddVVARow(abr_kreis) {
  return {
    type: ADD_VVA_ROW,
    abr_kreis
  }
}
function CheckVVA(vva) {
  for (let row of vva) {
    // console.log(row)
    if (
      row.bis_dat &&
      Datetime.fromISO(row.bis_dat) < Datetime.fromISO(row.ab_datum)
    ) {
      return "Das Bis-Datum muss nach dem Ab-Datum liegen !"
    }
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
    if (row.tage_wo == null) {
      return "bitte Tagewoche angeben"
    }
    if (row.tage_wo > 7) {
      return "bitte Tagewoche nicht mehr als 7 Tage angeben"
    }
    if (row.arb_zt_tgl > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_mo == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_di == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_mi == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_do == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_fr == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_sa == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_so == "NaN") {
      return "bitte mindestens 0 Stunden angeben"
    }
    if (row.arb_zt_mo > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_di > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_mi > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_do > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_fr > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_sa > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
    if (row.arb_zt_so > 24) {
      return "bitte nicht mehr als 24 Stunden angeben"
    }
  }
  return null
}

export function UpdateVVASuccess(id, data) {
  return {
    type: UPDATE_VVA,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateVVA() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.vva || []

    if (data.length == 0) {
      return
    }
    var err = CheckVVA(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/vva/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteVVASuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/vva")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateVVASuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/vva")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateVVASuccess(row.id, response.body))
          })
      }
    }
  }
}

export function VVAUnsavedChanges(state) {
  for (let vva of Object.values(state)) {
    for (let row of vva) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function DeleteVVASuccess(id) {
  return {
    type: DELETED_VVA_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteVVARow(id) {
  return {
    type: DELETE_VVA_ROW,
    id
  }
}

function CheckRBSymbolZuord(rbsymbolzuord) {
  for (let row of rbsymbolzuord) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    if (row.plan_id == null) {
      return "bitte Plan auswählen"
    }
    if (row.rb_symb == null) {
      return "bitte RB-Symbol auswählen"
    }
    if (row.tg_ztmod == null) {
      return "bitte Zeitmodell auswählen"
    }
    if (row.bezeichnung == null || row.bezeichnung == " ") {
      return "bitte Bezeichnung angeben"
    }
  }
  return null
}
export function AddRBSymbolZuordRow(rb_symb) {
  return {
    type: ADD_RBSYMBOLZUORD_ROW,
    rb_symb
  }
}
export function DeleteRBSymbolZuordRow(id) {
  return {
    type: DELETE_RBSYMBOLZUORD_ROW,
    id
  }
}
export function DeleteRBSymbolZuordSuccess(id) {
  return {
    type: DELETED_RBSYMBOLZUORD_ROW,
    status: SUCCESS,
    id
  }
}
export function UpdateRBSymbolZuordSuccess(id, data) {
  return {
    type: UPDATE_RBSYMBOL_ZUORD,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateRBSymbolZuord() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.rbsymbolzuord || []

    if (data.length == 0) {
      return
    }
    var err = CheckRBSymbolZuord(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/rbsymbolzuord/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteRBSymbolZuordSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/rbsymbolzuord")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateRBSymbolZuordSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/rbsymbolzuord")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateRBSymbolZuordSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function RBSymbolZuordSuccess(data) {
  return {
    type: LOAD_RBSYMBOL_ZUORD,
    status: SUCCESS,
    data
  }
}
export function LoadRBSymbolZuord() {
  return (dispatch) => {
    request.get("/api/rbsymbolzuord").end((err, response) => {
      dispatch(RBSymbolZuordSuccess(response.body))
    })
  }
}
export function EditRBSymb(plan_id, id, changes) {
  return {
    type: EDIT_RBSYMBOL_ZUORD,
    plan_id,
    id,
    changes
  }
}

function CheckSymbolZuord(dplsymbzuord) {
  for (let row of dplsymbzuord) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    // if (row.plan_id == null) {
    //   return "bitte Plan auswählen"
    // }
    if (row.dpl_symb == null) {
      return "bitte Dienstplan-Symbol auswählen"
    }
    if (row.tg_ztmod == null) {
      return "bitte Zeitmodell auswählen"
    }
    if (row.bezeichnung == null || row.bezeichnung == " ") {
      return "bitte Bezeichnung angeben"
    }
  }
  return null
}
export function AddSymbolZuordRow(dpl_symb) {
  return {
    type: ADD_SYMBOLZUORD_ROW,
    dpl_symb
  }
}
export function DeleteSymbolZuordRow(id) {
  return {
    type: DELETE_SYMBOLZUORD_ROW,
    id
  }
}
export function DeleteSymbolZuordSuccess(id) {
  return {
    type: DELETED_SYMBOLZUORD_ROW,
    status: SUCCESS,
    id
  }
}
export function UpdateSymbolZuordSuccess(id, data) {
  return {
    type: UPDATE_SYMBOL_ZUORD,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSymbolZuord() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.dplsymbolzuord || []

    if (data.length == 0) {
      return
    }
    var err = CheckSymbolZuord(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/dplsymbolzuord/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteSymbolZuordSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/dplsymbolzuord")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateSymbolZuordSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/dplsymbolzuord")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateSymbolZuordSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function DplSymbolZuordSuccess(data) {
  return {
    type: LOAD_SYMBOL_ZUORD,
    status: SUCCESS,
    data
  }
}
export function LoadDplSymbolZuord() {
  return (dispatch) => {
    request.get("/api/dplsymbolzuord").end((err, response) => {
      dispatch(DplSymbolZuordSuccess(response.body))
    })
  }
}

export function EditSymb(plan_id, id, changes) {
  return {
    type: EDIT_SYMBOL_ZUORD,
    plan_id,
    id,
    changes
  }
}

export function EditWochenmod(zm_var, id, changes) {
  return {
    type: EDIT_WOCHENMOD,
    zm_var,
    id,
    changes
  }
}
export function EditSchichtText(zm_var, id, changes) {
  return {
    type: EDIT_SCHICHTTEXT,
    zm_var,
    id,
    changes
  }
}
export function EditWochenText(zm_wo, id, changes) {
  return {
    type: EDIT_WOCHENTEXT,
    zm_wo,
    id,
    changes
  }
}
export function EditTagesmodellText(tg_ztmod, id, changes) {
  return {
    type: EDIT_TAGESMODELLTEXT,
    tg_ztmod,
    id,
    changes
  }
}
export function EditRBTagesmodellText(tg_ztmod, id, changes) {
  return {
    type: EDIT_RBTAGESMODELLTEXT,
    tg_ztmod,
    id,
    changes
  }
}
export function ChangeAbDatumPlain(Datum) {
  return {
    type: CHANGETZM_AB_DATUM,
    Datum
  }
}

export function createNewAbDatum(Datum, zm_tg) {
  return {
    type: CREATETZM_NEW_AB_DATUM,
    Datum,
    zm_tg
  }
}

export function ChangeAbDatum(tg_ztmod, ab_datum, new_ab_datum) {
  return {
    type: CREATETZM_NEW_AB_DATUM,
    tg_ztmod,
    ab_datum,
    new_ab_datum
  }
}

export function UpdateSchichtmodellStarted() {
  return {
    type: UPDATE_SCHICHTMODELL,
    status: STARTED
  }
}

export function UpdateSchichtmodellSuccess(id, data) {
  return {
    type: UPDATE_SCHICHTMODELL,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSchichtmodellError(id, error) {
  return {
    type: UPDATE_SCHICHTMODELL,
    status: ERROR,
    id,
    error
  }
}

export function UpdateSchichtmodell() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.schichtmod || {}
    for (let rows of Object.values(data)) {
      for (let row of rows) {
        // if (row.__new) {
        //   let { id, ...r } = row
        //   dispatch(UpdateSchichtmodellStarted())
        //   request
        //     .post(`/api/schichtmodelle/new`)
        //     .send(r)
        //     .end((err, response) => {
        //       if (err) {
        //         dispatch(UpdateSchichtmodellError(row.id, err))
        //       } else {
        //         dispatch(UpdateSchichtmodellSuccess(row.id, response.body))
        //       }
        //     })
        // } else
        if (row.__dirty && !row.__deleted) {
          dispatch(UpdateSchichtmodellStarted())
          request
            .put("/api/schichtmodell")
            .send(row)
            .end((err, response) => {
              if (err) {
                dispatch(UpdateSchichtmodellError(row.zm_var, err))
              } else {
                dispatch(UpdateSchichtmodellSuccess(row.zm_var, response.body))
              }
            })
        } else {
          // TODO
          //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
        }
      }
    }
  }
}

export function UpdateSchichtmodellBezStarted() {
  return {
    type: UPDATE_SCHICHTMODELLBEZ,
    status: STARTED
  }
}

export function UpdateSchichtmodellBezSuccess(id, data) {
  return {
    type: UPDATE_SCHICHTMODELLBEZ,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSchichtmodellBezError(id, error) {
  return {
    type: UPDATE_SCHICHTMODELLBEZ,
    status: ERROR,
    id,
    error
  }
}

export function UpdateSchichtmodellBez() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.schichtmodbez || {}
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__new) {
        let { id, ...r } = row
        dispatch(UpdateSchichtmodellBezStarted())
        request
          .post("/api/schichtmodellebez/new")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateSchichtmodellBezError(row.id, err))
            } else {
              dispatch(UpdateSchichtmodellBezSuccess(row.id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateSchichtmodellBezStarted())
        request
          .put("/api/schichtmodellebez")
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateSchichtmodellBezError(row.id, err))
            } else {
              dispatch(UpdateSchichtmodellBezSuccess(row.id, response.body))
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
    // }
  }
}

export function UpdateWochenmodellStarted() {
  return {
    type: UPDATE_WOCHENMODELL,
    status: STARTED
  }
}

export function UpdateWochenmodellSuccess(id, data) {
  return {
    type: UPDATE_WOCHENMODELL,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateWochenmodellError(id, error) {
  return {
    type: UPDATE_WOCHENMODELL,
    status: ERROR,
    id,
    error
  }
}

export function UpdateWochenmodell() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.wochenmodell || {}
    for (let rows of Object.values(data)) {
      for (let row of rows) {
        if (row.__dirty && !row.__deleted) {
          dispatch(UpdateWochenmodellStarted())
          request
            .put("/api/wochenmodell")
            .send(row)
            .end((err, response) => {
              if (err) {
                dispatch(UpdateWochenmodellError(row.zm_wo, err))
              } else {
                dispatch(UpdateWochenmodellSuccess(row.zm_wo, response.body))
              }
            })
        } else {
          // TODO
          //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
        }
      }
    }
    let ftgData = getState().Zeitmodell.wochenmodellftg || {}
    for (let row of Object.values(ftgData)) {
      if (row.__new && !row.__deleted) {
        dispatch(CreateWochenmodellFeiertag(row))
      } else if (row.__deleted) {
        dispatch(DeleteWochenmodellFeiertag(row))
      } else if (row.__dirty) {
        dispatch(UpdateWochenmodellFeiertag(row))
      }
    }
  }
}

export function UpdateWochenmodellBezStarted() {
  return {
    type: UPDATE_WOCHENMODELLBEZ,
    status: STARTED
  }
}

export function UpdateWochenmodellBezSuccess(id, data) {
  return {
    type: UPDATE_WOCHENMODELLBEZ,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateWochenmodellBezError(id, error) {
  return {
    type: UPDATE_WOCHENMODELLBEZ,
    status: ERROR,
    id,
    error
  }
}

export function UpdateWochenmodellBez() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.wochenmodbez || {}
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__new) {
        let { id, ...r } = row
        dispatch(UpdateWochenmodellBezStarted())
        request
          .post("/api/wochenmodellebez/new")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateWochenmodellBezError(row.id, err))
            } else {
              dispatch(UpdateWochenmodellBezSuccess(row.id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateWochenmodellBezStarted())
        request
          .put("/api/wochenmodellebez")
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateWochenmodellBezError(row.id, err))
            } else {
              dispatch(UpdateWochenmodellBezSuccess(row.id, response.body))
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
    // }
  }
}

export function UpdateTagesmodellBezStarted() {
  return {
    type: UPDATE_TAGESMODELLBEZ,
    status: STARTED
  }
}

export function UpdateTagesmodellBezSuccess(id, data) {
  return {
    type: UPDATE_TAGESMODELLBEZ,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateTagesmodellBezError(id, error) {
  return {
    type: UPDATE_TAGESMODELLBEZ,
    status: ERROR,
    id,
    error
  }
}

export function UpdateTagesmodellBez() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.tagesmodellebez || {}
    // for (let rows of Object.values(data)) {
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      // for (let row of rows) {
      if (row.__new) {
        let { id, ...r } = row
        dispatch(UpdateTagesmodellBezStarted())
        request
          .post("/api/tagesmodellebez/new")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateTagesmodellBezError(row.id, err))
            } else {
              dispatch(UpdateTagesmodellBezSuccess(row.id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateTagesmodellBezStarted())
        request
          .put("/api/tagesmodellebez")
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateTagesmodellBezError(row.id, err))
            } else {
              dispatch(UpdateTagesmodellBezSuccess(row.id, response.body))
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
    // }
  }
}

function CheckTagesmodell(rows) {
  for (let row of rows) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    // wenn das Ab-Datum manuell überschrieben wird muss dies geprüft werden
    if (
      row.__dirty &&
      Datetime.fromISO(row.ab_datum) > Datetime.fromISO(row.bis_dat)
    ) {
      return "ein neues Ab-Datum darf nur mit dem date-picker eingefügt werden !"
    }
    if (row.tg_ztmod == "AF") {
      return "das Modell Arbeitsfrei kann nicht geändert werden !"
    }
    // von - bis fix/glzt + Nachtschicht prüfen
    if (
      (Datetime.fromISO(row.fixz_von) > Datetime.fromISO(row.fixz_bis) &&
        row.kz_ns == "0") ||
      (Datetime.fromISO(row.glzt_von) > Datetime.fromISO(row.glzt_bis) &&
        row.kz_ns == "0")
    ) {
      return "bitte Nachtschichtkennzeichen angeben wenn Beginn vor dem Ende der Arbeitszeit liegt"
    }
    // Kernzeit
    if (
      (Datetime.fromISO(row.glzt_von) > Datetime.fromISO(row.krnzt_von) ||
        Datetime.fromISO(row.glzt_bis) < Datetime.fromISO(row.krnzt_bis)) &&
      row.kz_ns == "0"
    ) {
      return "Kernzeit darf nicht außerhalb der Gleitzeit liegen"
    }
    if (
      Datetime.fromISO(row.krnzt_von) > Datetime.fromISO(row.krnzt_bis) &&
      row.kz_ns == "0"
    ) {
      return "von-Kernzeit darf nicht nach bis-Kernzeit liegen"
    }
    // Fixe Zeit
    if (
      (row.fixz_von != null && row.fixz_bis == null) ||
      (row.fixz_bis != null && row.fixz_von == null)
    ) {
      return "bitte von- und bis-Uhrzeit Fixzeit angeben"
    }
    // Gleitzeit - Kernzeit
    if (
      (row.glzt_von != null && row.glzt_bis == null) ||
      (row.glzt_bis != null && row.glzt_von == null)
    ) {
      return "bitte von- und bis Uhrzeiten Gleitzeit angeben"
    }
    if (
      (row.krnzt_von != null && row.glzt_von == null) ||
      (row.krnzt_bis != null && row.glzt_bis == null)
    ) {
      return "bei Kernzeit bitte Gleitzeit angeben"
    }
    if (
      (row.krnzt_von != null && row.krnzt_bis == null) ||
      (row.krnzt_bis != null && row.krnzt_von == null)
    ) {
      return "bei von- und bis- Kernzeit angeben"
    }
    //Pause von vor bis
    if (
      (Datetime.fromISO(row.pau_1_von) > Datetime.fromISO(row.pau_1_bis) &&
        row.kz_ns == "0") ||
      (Datetime.fromISO(row.pau_2_von) > Datetime.fromISO(row.pau_2_bis) &&
        row.kz_ns == "0") ||
      (Datetime.fromISO(row.pau_3_von) > Datetime.fromISO(row.pau_3_bis) &&
        row.kz_ns == "0")
    ) {
      return "Pause von-Uhrzeit muss kleiner bis-Uhrzeit sein"
    }
    // Pause von gleich bis
    if (
      Datetime.fromISO(row.pau_1_von).equals(Datetime.fromISO(row.pau_1_bis)) ||
      Datetime.fromISO(row.pau_2_von).equals(Datetime.fromISO(row.pau_2_bis)) ||
      Datetime.fromISO(row.pau_3_von).equals(Datetime.fromISO(row.pau_3_bis))
    ) {
      return "Pause von-Uhrzeit kann nicht gleich bis-Uhrzeit sein"
    }
    // Pausenkennzeichen fehlt
    if (
      (Datetime.fromISO(row.pau_1_von) >
        Datetime.fromISO("1900-01-01T00:00:00") &&
        row.pau_1_kz == null) ||
      (Datetime.fromISO(row.pau_2_von) >
        Datetime.fromISO("1900-01-01T00:00:00") &&
        row.pau_2_kz == null) ||
      (Datetime.fromISO(row.pau_3_von) >
        Datetime.fromISO("1900-01-01T00:00:00") &&
        row.pau_3_kz == null)
    ) {
      return "bitte Pausenkennzeichen auswählen"
    }
    // wenn Pausenkennzeichen ausgewählt wurde Uhrzeit fehlt
    if (
      ((row.pau_1_von == null || row.pau_1_bis == null) && row.pau_1_kz > "") ||
      ((row.pau_2_von == null || row.pau_2_bis == null) && row.pau_2_kz > "") ||
      ((row.pau_3_von == null || row.pau_3_bis == null) && row.pau_3_kz > "")
    ) {
      return "bitte Pausenrahmen / Uhrzeit angeben"
    }
    // wenn Pausenkennzeichen ausgewählt + Minuten fehlen
    if (
      (row.pau_1_min == null && row.pau_1_kz > "") ||
      (row.pau_2_min == null && row.pau_2_kz > "") ||
      (row.pau_3_min == null && row.pau_3_kz > "")
    ) {
      return "Pausenminuten müssen angegeben werden"
    }
    // Pause innerhalb der Arbeitszeit fix/glz
    // if (
    //   (Datetime.fromISO(row.pau_1_von) < Datetime.fromISO(row.fixz_von) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_1_bis) < Datetime.fromISO(row.fixz_bis) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_2_von) < Datetime.fromISO(row.fixz_von) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_2_bis) < Datetime.fromISO(row.fixz_bis) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_3_von) < Datetime.fromISO(row.fixz_von) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_3_bis) < Datetime.fromISO(row.fixz_bis) &&
    //     row.kz_ns == "0")
    // ) {
    //   return "Pausen müssen innerhalb Arbeitszeit liegen"
    // }
    // if (
    //   (Datetime.fromISO(row.pau_1_von) < Datetime.fromISO(row.glzt_von) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_1_bis) > Datetime.fromISO(row.glzt_bis) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_2_von) < Datetime.fromISO(row.glzt_von) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_2_bis) > Datetime.fromISO(row.glzt_bis) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_3_von) < Datetime.fromISO(row.glzt_von) &&
    //     row.kz_ns == "0") ||
    //   (Datetime.fromISO(row.pau_3_bis) > Datetime.fromISO(row.glzt_bis) &&
    //     row.kz_ns == "0")
    // ) {
    //   return "Pausen müssen innerhalb Arbeitszeit liegen"
    // }
  }
  return null
}

export function UpdateTagesmodellSuccess(id, data) {
  return {
    type: UPDATE_TAGESMODELL,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateTagesmodell() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.tagesmodell || {}
    for (let rows of Object.values(data)) {
      var err = CheckTagesmodell(rows)
      if (err !== null) {
        swal({
          title: "Hinweis zum Speichern der Daten",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        // return
        continue
      }
      for (let row of rows) {
        // für neue Sätze mit ändern vom ab-Datum
        if (row.__new) {
          let { id, ...r } = row
          request
            .post("/api/tagesmodell/new")
            .send(r)
            .end((err, response) => {
              dispatch(UpdateTagesmodellSuccess(row.id, response.body))
            })
        } else if (row.__dirty && !row.__deleted) {
          request
            .put("/api/tagesmodell")
            .send(row)
            .end((err, response) => {
              dispatch(UpdateTagesmodellSuccess(row.tg_ztmod, response.body))
            })
        }
      }
    }
  }
}

function CheckSchichtTausch(schichttausch) {
  for (let row of schichttausch) {
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
  }
  return null
}

export function UpdateSchichtTauschStarted() {
  return {
    type: UPDATE_SCHICHTTAUSCH,
    status: STARTED
  }
}

export function UpdateSchichtTauschSuccess(id, data) {
  return {
    type: UPDATE_SCHICHTTAUSCH,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSchichtTauschError(id, error) {
  return {
    type: UPDATE_SCHICHTTAUSCH,
    status: ERROR,
    id,
    error
  }
}

export function UpdateSchichtTausch() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.schichttausch || []

    if (data.length == 0) {
      return
    }
    var err = CheckSchichtTausch(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        dispatch(DeleteSchichtTauschStarted(row.id))
        request
          .delete(`/api/schichttausch/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteSchichtTauschError(row.id, err))
            } else {
              dispatch(DeleteSchichtTauschSuccess(row.id, response.body))
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row

        dispatch(UpdateSchichtTauschStarted())
        request
          .post("/api/schichttausch")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateSchichtTauschError(row.id, err))
            } else {
              dispatch(UpdateSchichtTauschSuccess(row.id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateSchichtTauschStarted())
        request
          .put("/api/schichttausch")
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateSchichtTauschError(row.id, err))
            } else {
              dispatch(UpdateSchichtTauschSuccess(row.id, response.body))
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
  }
}

export function SchichtTauschUnsavedChanges(state) {
  for (let schichttausch of Object.values(state)) {
    for (let row of schichttausch) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export function EditSchicht(abr_kreis, id, changes) {
  return {
    type: EDIT_SCHICHT,
    abr_kreis,
    id,
    changes
  }
}

export function DeleteSchichtTauschStarted() {
  return {
    type: DELETED_SCHICHTTAUSCH_ROW,
    status: STARTED
  }
}
export function DeleteSchichtTauschSuccess(id) {
  return {
    type: DELETED_SCHICHTTAUSCH_ROW,
    status: SUCCESS,
    id
  }
}
export function DeleteSchichtTauschError(error) {
  return {
    type: DELETED_SCHICHTTAUSCH_ROW,
    status: ERROR,
    error
  }
}

export function DeleteSchichtTauschRow(id) {
  return {
    type: DELETE_SCHICHTTAUSCH_ROW,
    id
  }
}

export function AddSchichtTauschRow(zm_tg) {
  return {
    type: ADD_SCHICHTTAUSCH_ROW,
    zm_tg
  }
}

export function LoadSchichtTauschStarted() {
  return {
    type: LOAD_SCHICHTTAUSCH,
    status: STARTED
  }
}
export function LoadSchichtTauschSuccess(data) {
  return {
    type: LOAD_SCHICHTTAUSCH,
    status: SUCCESS,
    data
  }
}
export function LoadSchichtTauschError(error) {
  return {
    type: LOAD_SCHICHTTAUSCH,
    status: ERROR,
    error
  }
}
export function LoadSchichtTausch() {
  return (dispatch) => {
    dispatch(LoadSchichtTauschStarted())
    request.get("/api/schichttausch").end((err, response) => {
      if (err) {
        dispatch(LoadSchichtTauschError(err))
      } else {
        dispatch(LoadSchichtTauschSuccess(response.body))
      }
    })
  }
}

function CheckGruppenModellZuord(gruppenmodellzuord) {
  for (let row of gruppenmodellzuord) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
    if (row.kz_ztmod == null) {
      return "bitte ZM-Kennzeichen auswählen"
    }
    if (row.zm_tg == null && row.kz_ztmod != "D") {
      return "bitte Zeitmodell auswählen"
    }
  }
  return null
}

export function UpdateGruppenModellZuordStarted() {
  return {
    type: UPDATE_GRUPPENMODELLZUORD,
    status: STARTED
  }
}

export function UpdateGruppenModellZuordSuccess(id, data) {
  return {
    type: UPDATE_GRUPPENMODELLZUORD,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateGruppenModellZuordError(id, error) {
  return {
    type: UPDATE_GRUPPENMODELLZUORD,
    status: ERROR,
    id,
    error
  }
}

export function UpdateGruppenModellZuord() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.gruppenmodellzuord || []

    if (data.length == 0) {
      return
    }
    var err = CheckGruppenModellZuord(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        dispatch(DeleteGruppenModellZuordStarted(row.id))
        request
          .delete(`/api/gruppenmodellzuord/${row.id}`)
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(DeleteGruppenModellZuordError(row.id, err))
            } else {
              dispatch(DeleteGruppenModellZuordSuccess(row.id, response.body))
            }
          })
      } else if (row.__new) {
        let { id, ...r } = row

        dispatch(UpdateGruppenModellZuordStarted())
        request
          .post("/api/gruppenmodellzuord")
          .send(r)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateGruppenModellZuordError(row.id, err))
            } else {
              dispatch(UpdateGruppenModellZuordSuccess(row.id, response.body))
            }
          })
      } else if (row.__dirty && !row.__deleted) {
        dispatch(UpdateGruppenModellZuordStarted())
        request
          .put("/api/gruppenmodellzuord")
          .send(row)
          .end((err, response) => {
            if (err) {
              dispatch(UpdateGruppenModellZuordError(row.id, err))
            } else {
              dispatch(UpdateGruppenModellZuordSuccess(row.id, response.body))
            }
          })
      } else {
        // TODO
        //dispatch(UpdateMQueueEmpfaengerSuccess(user_id, null))
      }
    }
  }
}

export function EditTagesmod(tg_ztmod, id, changes) {
  return (dispatch, getState) => {
    let valid = true
    if (changes.tg_ztmod) {
      valid =
        getDropDownValue(getState(), "tg_ztmod/", changes.tg_ztmod) !== null
    }
    dispatch({
      type: EDIT_TAGESMOD,
      tg_ztmod,
      id,
      changes,
      valid
    })
  }
}
export function EditGruppen(abr_kreis, id, changes) {
  return {
    type: EDIT_GRUPPENMODELLZUORD,
    abr_kreis,
    id,
    changes
  }
}
export function DeleteGruppenModellZuordStarted() {
  return {
    type: DELETED_GRUPPENMODELLZUORD_ROW,
    status: STARTED
  }
}
export function DeleteGruppenModellZuordSuccess(id) {
  return {
    type: DELETED_GRUPPENMODELLZUORD_ROW,
    status: SUCCESS,
    id
  }
}
export function DeleteGruppenModellZuordError(error) {
  return {
    type: DELETED_GRUPPENMODELLZUORD_ROW,
    status: ERROR,
    error
  }
}

export function DeleteGruppenModellZuordRow(id) {
  return {
    type: DELETE_GRUPPENMODELLZUORD_ROW,
    id
  }
}

export function AddGruppenModellZuordRow(zm_tg) {
  return {
    type: ADD_GRUPPENMODELLZUORD_ROW,
    zm_tg
  }
}

export function LoadGruppenModellZuordStarted() {
  return {
    type: LOAD_GRUPPENMODELLZUORD,
    status: STARTED
  }
}
export function LoadGruppenModellZuordSuccess(data) {
  return {
    type: LOAD_GRUPPENMODELLZUORD,
    status: SUCCESS,
    data
  }
}
export function LoadGruppenModellZuordError(error) {
  return {
    type: LOAD_GRUPPENMODELLZUORD,
    status: ERROR,
    error
  }
}
export function LoadGruppenModellZuord() {
  return (dispatch) => {
    dispatch(LoadGruppenModellZuordStarted())
    request.get("/api/gruppenmodellzuord").end((err, response) => {
      if (err) {
        dispatch(LoadGruppenModellZuordError(err))
      } else {
        dispatch(LoadGruppenModellZuordSuccess(response.body))
      }
    })
  }
}

export function EditTzm(tg_ztmod, ab_datum, changes) {
  return {
    type: EDIT_TZM,
    tg_ztmod,
    ab_datum,
    changes
  }
}

export function LoadSchichtModelleStarted(zm_var) {
  return {
    type: LOAD_SCHICHTMODELLE,
    status: STARTED,
    zm_var
  }
}
export function LoadSchichtModelleSuccess(zm_var, data) {
  return {
    type: LOAD_SCHICHTMODELLE,
    status: SUCCESS,
    zm_var,
    data
  }
}
export function LoadSchichtModelleError(zm_var, error) {
  return {
    type: LOAD_SCHICHTMODELLE,
    status: ERROR,
    zm_var,
    error
  }
}

export function LoadSchichtModelle(zm_var) {
  return (dispatch) => {
    dispatch(LoadSchichtModelleStarted(zm_var))
    request.get(`/api/schichtmodelle/${zm_var}`).end((err, response) => {
      if (err) {
        dispatch(LoadSchichtModelleError(zm_var, err))
      } else {
        dispatch(LoadSchichtModelleSuccess(zm_var, response.body))
      }
    })
  }
}

export function LoadSchichtModelleBezStarted() {
  return {
    type: LOAD_SCHICHTMODELLE_BEZ,
    status: STARTED
  }
}
export function LoadSchichtModelleBezSuccess(data) {
  return {
    type: LOAD_SCHICHTMODELLE_BEZ,
    status: SUCCESS,
    data
  }
}
export function LoadSchichtModelleBezError(error) {
  return {
    type: LOAD_SCHICHTMODELLE_BEZ,
    status: ERROR,
    error
  }
}

export function LoadSchichtModelleBez() {
  return (dispatch) => {
    dispatch(LoadSchichtModelleBezStarted())
    request.get("/api/schichtmodellebez").end((err, response) => {
      if (err) {
        dispatch(LoadSchichtModelleBezError(err))
      } else {
        dispatch(LoadSchichtModelleBezSuccess(response.body))
      }
    })
  }
}

export function LoadWochenModelleBezStarted() {
  return {
    type: LOAD_WOCHENMODELLE_BEZ,
    status: STARTED
  }
}
export function LoadWochenModelleBezSuccess(data) {
  return {
    type: LOAD_WOCHENMODELLE_BEZ,
    status: SUCCESS,
    data
  }
}
export function LoadWochenModelleBezError(error) {
  return {
    type: LOAD_WOCHENMODELLE_BEZ,
    status: ERROR,
    error
  }
}

export function LoadWochenModelleBez() {
  return (dispatch) => {
    dispatch(LoadWochenModelleBezStarted())
    request.get("/api/wochenmodellebez").end((err, response) => {
      if (err) {
        dispatch(LoadWochenModelleBezError(err))
      } else {
        dispatch(LoadWochenModelleBezSuccess(response.body))
      }
    })
  }
}

export function LoadWochenModellStarted(zm_wo) {
  return {
    type: LOAD_WOCHENMODELL,
    status: STARTED,
    zm_wo
  }
}
export function LoadWochenModellSuccess(zm_wo, data) {
  return {
    type: LOAD_WOCHENMODELL,
    status: SUCCESS,
    zm_wo,
    data
  }
}
export function LoadWochenModellError(zm_wo, error) {
  return {
    type: LOAD_WOCHENMODELL,
    status: ERROR,
    zm_wo,
    error
  }
}

export function LoadWochenModell(zm_wo) {
  return (dispatch) => {
    dispatch(LoadWochenModellStarted(zm_wo))
    request.get(`/api/wochenmodell/${zm_wo}`).end((err, response) => {
      if (err) {
        dispatch(LoadWochenModellError(zm_wo, err))
      } else {
        dispatch(LoadWochenModellSuccess(zm_wo, response.body))
      }
    })
  }
}

export function LoadTagesModelleBezStarted() {
  return {
    type: LOAD_TAGESMODELLEBEZ,
    status: STARTED
  }
}
export function LoadTagesModelleBezSuccess(data) {
  return {
    type: LOAD_TAGESMODELLEBEZ,
    status: SUCCESS,
    data
  }
}
export function LoadTagesModelleBezError(error) {
  return {
    type: LOAD_TAGESMODELLEBEZ,
    status: ERROR,
    error
  }
}

export function LoadTagesModelleBez() {
  return (dispatch) => {
    dispatch(LoadTagesModelleBezStarted())
    request.get("/api/tagesmodellebez").end((err, response) => {
      if (err) {
        dispatch(LoadTagesModelleBezError(err))
      } else {
        dispatch(LoadTagesModelleBezSuccess(response.body))
      }
    })
  }
}

export function LoadTagesModelleStarted(tg_ztmod) {
  return {
    type: LOAD_TAGESMODELLE,
    status: STARTED,
    tg_ztmod
  }
}
export function LoadTagesModelleSuccess(tg_ztmod, data) {
  return {
    type: LOAD_TAGESMODELLE,
    status: SUCCESS,
    tg_ztmod,
    data
  }
}
export function LoadTagesModelleError(tg_ztmod, error) {
  return {
    type: LOAD_TAGESMODELLE,
    status: ERROR,
    tg_ztmod,
    error
  }
}

export function LoadTagesModelle(tg_ztmod) {
  return (dispatch) => {
    dispatch(LoadTagesModelleStarted(tg_ztmod))
    request.get(`/api/tagesmodell/${tg_ztmod}`).end((err, response) => {
      if (err) {
        dispatch(LoadTagesModelleError(tg_ztmod, err))
      } else {
        dispatch(LoadTagesModelleSuccess(tg_ztmod, response.body))
      }
    })
  }
}

export function LoadZeitmodellStarted(int_id, datum) {
  return {
    type: LOAD_ZEITMODELL,
    status: STARTED,
    int_id,
    datum
  }
}

export function LoadZeitmodellSuccess(int_id, datum, data) {
  return {
    type: LOAD_ZEITMODELL,
    status: SUCCESS,
    int_id,
    datum,
    data
  }
}
export function LoadZeitmodellError(int_id, datum, error) {
  return {
    type: LOAD_ZEITMODELL,
    status: ERROR,
    int_id,
    datum,
    error,
    _handleInApp: true
  }
}
export function LoadZeitmodell(int_id, datum) {
  return (dispatch) => {
    dispatch(LoadZeitmodellStarted(int_id, datum))
    request.get(`/api/p/${int_id}/zeitmodell/${datum}`).end((err, response) => {
      if (err) {
        dispatch(LoadZeitmodellError(int_id, datum, err))
      } else {
        dispatch(LoadZeitmodellSuccess(int_id, datum, response.body))
      }
    })
  }
}

export function NewWochenmodellBezError(error) {
  return {
    type: NEW_WOCHENMODELLBEZ,
    status: ERROR,
    error
  }
}
export function NewWochenmodellBezSuccess(data) {
  return {
    type: NEW_WOCHENMODELLBEZ,
    status: SUCCESS,
    data
  }
}
export function NewWochenmodellBez(data) {
  return (dispatch) => {
    request
      .post("/api/wochenmodellebez/new")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewWochenmodellBezError(err))
        } else {
          dispatch(NewWochenmodellBezSuccess(response.body))
        }
      })
  }
}

export function NewWochenmodellError(error) {
  return {
    type: NEW_WOCHENMODELL,
    status: ERROR,
    error
  }
}
export function NewWochenmodellSuccess(zm_wo, data) {
  return {
    type: NEW_WOCHENMODELL,
    status: SUCCESS,
    zm_wo,
    data
  }
}
export function NewWochenmodell(data) {
  return (dispatch) => {
    request
      .post("/api/wochenmodelle/new")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewWochenmodellError(err))
        } else {
          dispatch(NewWochenmodellSuccess(data.zm_wo, response.body))
        }
      })
  }
}

const feldNamen = {
  bezeichn: "Bezeichnung",
  zm_wo: "Wochenmodell",
  tg_ztmod: "Tagesmodell",
  tg_ztmod1: "Tagesmodell",
  tg_ztmod2: "Tagesmodell",
  tg_ztmod3: "Tagesmodell",
  tg_ztmod4: "Tagesmodell",
  tg_ztmod5: "Tagesmodell",
  tg_ztmod6: "Tagesmodell",
  tg_ztmod7: "Tagesmodell"
}

const defaultPflichtFelder = {
  bezeichn: true,
  zm_wo: true,
  tg_ztmod: true
}

export function CheckWochenModNeuPages(felder, data) {
  for (let feld of felder) {
    if (defaultPflichtFelder[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldNamen[feld] || feld
        return `Bitte ${feldName} angeben !`
      }
    }
  }
  if (data.zm_wo.length > 5) {
    return "Wochenmodell nicht mehr 5 Zeichen erlaubt !"
  }
  if (data.bezeichn.length > 30) {
    return "Bezeichnung nicht mehr 30 Zeichen erlaubt !"
  }
  // if (data.beze_kurz.length > 10) {
  //   return "Kurzbezeichnung nicht mehr 10 Zeichen erlaubt !"
  // }
  return null
}

const feldSchNamen = {
  bezeichn: "Bezeichnung",
  // beze_kurz: "Kurzbezeichnung",
  zm_var: "Schichtmodell",
  schi_woc: "Anzahl Schichten",
  ab_datum: "Ab Datum"
}
const defaultPflichtSchFelder = {
  bezeichn: true,
  // beze_kurz: true,
  zm_var: true,
  schi_woc: true,
  ab_datum: true
}
export function CheckSchichtModNeuPages(felder, data) {
  for (let feld of felder) {
    if (defaultPflichtSchFelder[feld]) {
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldSchNamen[feld] || feld
        return `Bitte ${feldName} angeben !`
      }
    }
  }
  if (
    (data.schi_woc && parseInt(data.schi_woc) > 50) ||
    (data.schi_woc && parseInt(data.schi_woc) < 2)
  ) {
    return "Nicht mehr als 50 und weniger als 2 Schichten erlaubt!"
  }
  if (data.zm_var.length > 5) {
    return "Schichtmodell nicht mehr 5 Zeichen erlaubt !"
  }
  if (data.bezeichn.length > 30) {
    return "Bezeichnung nicht mehr 30 Zeichen erlaubt !"
  }
  return null
}

export function NewTagesmodellBezError(error) {
  return {
    type: NEW_TAGESMODELLBEZ,
    status: ERROR,
    error
  }
}
export function NewTagesmodellBezSuccess(data) {
  return {
    type: NEW_TAGESMODELLBEZ,
    status: SUCCESS,
    data
  }
}
export function NewTagesmodellBez(data) {
  return (dispatch) => {
    request
      .post("/api/tagesmodellebez/new")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewTagesmodellBezError(err))
        } else {
          dispatch(NewTagesmodellBezSuccess(response.body))
        }
      })
  }
}

export function CopyTagesmodell(data) {
  return (dispatch) => {
    request
      .post("/api/tagesmodell/copy")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewTagesmodellError(err))
        } else {
          dispatch(NewTagesmodellSuccess(data.tg_ztmod, response.body))
        }
      })
  }
}

export function NewTagesmodellError(error) {
  return {
    type: NEW_TAGESMODELL,
    status: ERROR,
    error
  }
}
export function NewTagesmodellSuccess(tg_ztmod, data) {
  return {
    type: NEW_TAGESMODELL,
    status: SUCCESS,
    tg_ztmod,
    data
  }
}
export function NewTagesmodell(data) {
  return (dispatch) => {
    request
      .post("/api/tagesmodell/new")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewTagesmodellError(err))
        } else {
          dispatch(NewTagesmodellSuccess(data.tg_ztmod, response.body))
        }
      })
  }
}

const feldTZMNamen = {
  // tg_ztmod_new: "neues Tagesmodell",
  tg_ztmod: "Tagesmodell",
  ab_datum: "Ab Datum",
  bezeichn: "Bezeichnung"
}

const defaultTZMPflichtFelder = {
  // tg_ztmod_new: true,
  tg_ztmod: true,
  ab_datum: true,
  bezeichn: true
}

export function CheckTagesModNeuPages(felder, data) {
  for (let feld of felder) {
    // console.log(feld)
    if (defaultTZMPflichtFelder[feld]) {
      // console.log(data)
      // console.log(data.tg_ztmod_new, data.tg_ztmod)
      if (data[feld] == null || data[feld] == "") {
        let feldName = feldTZMNamen[feld] || feld
        return `Bitte ${feldName} angeben !`
      }
    }
  }
  // if (data.tg_ztmod.length > 0) {
  //   return "Tagesmodell schon mal angelegt bitte ändern  !"
  // }
  if (data.tg_ztmod.length > 5) {
    return "Tagesmodell nicht mehr 5 Zeichen erlaubt !"
  }
  if (data.bezeichn.length > 30) {
    return "Bezeichnung nicht mehr 30 Zeichen erlaubt !"
  }
  return null
}

export function NewRBTagesmodellBezSuccess(data) {
  return {
    type: NEW_RBTAGESMODELLBEZ,
    status: SUCCESS,
    data
  }
}
export function NewRBTagesmodellBez(data) {
  return (dispatch) => {
    request
      .post("/api/rbtagesmodellebez/new")
      .send(data)
      .end((err, response) => {
        dispatch(NewRBTagesmodellBezSuccess(response.body))
      })
  }
}

export function CopyRBTagesmodell(data) {
  return (dispatch) => {
    request
      .post("/api/rbtagesmodell/copy")
      .send(data)
      .end((err, response) => {
        dispatch(NewRBTagesmodellSuccess(data.tg_ztmod, response.body))
      })
  }
}

export function NewRBTagesmodellSuccess(tg_ztmod, data) {
  return {
    type: NEW_RBTAGESMODELL,
    status: SUCCESS,
    tg_ztmod,
    data
  }
}
export function NewRBTagesmodell(data) {
  return (dispatch) => {
    request
      .post("/api/rbtagesmodell/new")
      .send(data)
      .end((err, response) => {
        dispatch(NewRBTagesmodellSuccess(data.tg_ztmod, response.body))
      })
  }
}

export function NewSchichtmodellBezError(error) {
  return {
    type: NEW_SCHICHTMODELLBEZ,
    status: ERROR,
    error
  }
}
export function NewSchichtmodellBezSuccess(data) {
  return {
    type: NEW_SCHICHTMODELLBEZ,
    status: SUCCESS,
    data
  }
}
export function NewSchichtmodellBez(data) {
  return (dispatch) => {
    request
      .post("/api/schichtmodellebez/new")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewSchichtmodellBezError(err))
        } else {
          dispatch(NewSchichtmodellBezSuccess(response.body))
        }
      })
  }
}

export function NewSchichtmodellError(error) {
  return {
    type: NEW_SCHICHTMODELL,
    status: ERROR,
    error
  }
}
export function NewSchichtmodellSuccess(zm_var, data) {
  return {
    type: NEW_SCHICHTMODELL,
    status: SUCCESS,
    zm_var,
    data
  }
}
export function NewSchichtmodell(data) {
  return (dispatch) => {
    request
      .post("/api/schichtmodelle/new")
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch(NewSchichtmodellError(err))
        } else {
          dispatch(NewSchichtmodellSuccess(data.zm_var, response.body))
        }
      })
  }
}

export function LoadRBTagesModelleBezSuccess(data) {
  return {
    type: LOAD_RBTAGESMODELLEBEZ,
    status: SUCCESS,
    data
  }
}
export function LoadRBTagesModelleBez() {
  return (dispatch) => {
    request.get("/api/rbtagesmodellebez").end((err, response) => {
      dispatch(LoadRBTagesModelleBezSuccess(response.body))
    })
  }
}

export function LoadRBTagesModelleSuccess(tg_ztmod, data) {
  return {
    type: LOAD_RBTAGESMODELLE,
    status: SUCCESS,
    tg_ztmod,
    data
  }
}
export function LoadRBTagesModelle(tg_ztmod) {
  return (dispatch) => {
    request.get(`/api/rbtagesmodell/${tg_ztmod}`).end((err, response) => {
      dispatch(LoadRBTagesModelleSuccess(tg_ztmod, response.body))
    })
  }
}
export function EditRBTzm(tg_ztmod, ab_datum, changes) {
  return {
    type: EDIT_RBTZM,
    tg_ztmod,
    ab_datum,
    changes
  }
}

export function UpdateRBTagesmodellBezSuccess(id, data) {
  return {
    type: UPDATE_RBTAGESMODELLBEZ,
    status: SUCCESS,
    id,
    data
  }
}
export function UpdateRBTagesmodellBez() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.rbtagesmodellebez || {}
    if (data.length == 0) {
      return
    }
    for (let row of data) {
      if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/rbtagesmodellebez/new")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateRBTagesmodellSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/rbtagesmodellebez")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateRBTagesmodellBezSuccess(row.id, response.body))
          })
      }
    }
  }
}

function CheckRBTagesmodell(rows) {
  for (let row of rows) {
    if (!row.__new && !row.__dirty) {
      continue
    }
    // wenn das Ab-Datum manuell überschrieben wird muss dies geprüft werden
    if (
      row.__dirty &&
      Datetime.fromISO(row.ab_datum) > Datetime.fromISO(row.bis_dat)
    ) {
      return "ein neues Ab-Datum darf nur mit dem date-picker eingefügt werden !"
    }
    if (
      Datetime.fromISO(row.fixz_von) > Datetime.fromISO(row.fixz_bis) &&
      row.kz_ns == "0"
    ) {
      return "bitte Nachtschichtkennzeichen angeben wenn Beginn vor dem Ende der Arbeitszeit liegt"
    }
    // Fixe Zeit
    if (
      (row.fixz_von != null && row.fixz_bis == null) ||
      (row.fixz_bis != null && row.fixz_von == null)
    ) {
      return "bitte von- und bis-Uhrzeit Fixzeit angeben"
    }
  }
  return null
}

export function UpdateRBTagesmodellSuccess(id, data) {
  return {
    type: UPDATE_RBTAGESMODELL,
    status: SUCCESS,
    id,
    data
  }
}
export function UpdateRBTagesmodell() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.rbtagesmodell || {}
    for (let rows of Object.values(data)) {
      var err = CheckRBTagesmodell(rows)
      if (err !== null) {
        swal({
          title: "Hinweis zum Speichern der Daten",
          text: err,
          type: "warning",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok!",
          closeOnConfirm: false
        })
        // return
        continue
      }
      for (let row of rows) {
        if (row.__new) {
          let { id, ...r } = row
          request
            .post("/api/rbtagesmodell/new")
            .send(r)
            .end((err, response) => {
              dispatch(UpdateRBTagesmodellSuccess(row.id, response.body))
            })
        } else if (row.__dirty && !row.__deleted) {
          request
            .put("/api/rbtagesmodell")
            .send(row)
            .end((err, response) => {
              dispatch(UpdateRBTagesmodellSuccess(row.tg_ztmod, response.body))
            })
        }
      }
    }
  }
}

export function EditRBTagesmod(tg_ztmod, id, changes) {
  return (dispatch, getState) => {
    let valid = true
    if (changes.tg_ztmod) {
      valid =
        getDropDownValue(getState(), "rbtg_ztmod/", changes.tg_ztmod) !== null
    }
    dispatch({
      type: EDIT_RBTAGESMOD,
      tg_ztmod,
      id,
      changes,
      valid
    })
  }
}

export function EditRBVerTagesmodelle(tg_ztmod, id, changes) {
  return {
    type: EDIT_RBVERTAGESMOD,
    tg_ztmod,
    id,
    changes
  }
}
export function DeleteRBVerTagesmodelleSuccess(id) {
  return {
    type: DELETED_RBVERTAGESMOD_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteRBVerTagesmodelleRow(id) {
  return {
    type: DELETE_RBVERTAGESMOD_ROW,
    id
  }
}

export function AddRBVerTagesmodelleRow(tg_ztmod) {
  return {
    type: ADD_RBVERTAGESMOD_ROW,
    tg_ztmod
  }
}

export function LoadRBVerTagesmodelleSuccess(data) {
  return {
    type: LOAD_RBVERTAGESMOD,
    status: SUCCESS,
    data
  }
}
export function LoadRBVerTagesmodlle() {
  return (dispatch) => {
    request.get("/api/rbvertagesmodelle").end((err, response) => {
      dispatch(LoadRBVerTagesmodelleSuccess(response.body))
    })
  }
}
function CheckRBVerTagesmodlle(rbvertagesmodelle) {
  for (let row of rbvertagesmodelle) {
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
    if (row.tg_ztmod == null) {
      return "bitte RB-Tagesmodell auswählen"
    }
  }
  return null
}
export function UpdateRBVerTagesmodelleSuccess(id, data) {
  return {
    type: UPDATE_RBVERTAGESMOD,
    status: SUCCESS,
    id,
    data
  }
}
export function UpdateRBVerTagesmodelle() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.rbvertagesmodelle || []

    if (data.length == 0) {
      return
    }
    var err = CheckRBVerTagesmodlle(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/rbvertagesmodelle/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteRBVerTagesmodelleSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post("/api/rbvertagesmodelle")
          .send(r)
          .end((err, response) => {
            dispatch(UpdateRBVerTagesmodelleSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put("/api/rbvertagesmodelle")
          .send(row)
          .end((err, response) => {
            dispatch(UpdateRBVerTagesmodelleSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function EditSaldenBereinigung(id, changes) {
  return {
    type: EDIT_SALDENBEREINIGUNG,
    id,
    changes
  }
}
export function DeleteSaldenBereinigungSuccess(id) {
  return {
    type: DELETED_SALDENBEREINIGUNG_ROW,
    status: SUCCESS,
    id
  }
}

export function DeleteSaldenBereinigungRow(id) {
  return {
    type: DELETE_SALDENBEREINIGUNG_ROW,
    id
  }
}

export function AddSaldenBereinigungRow(zm_tg) {
  return {
    type: ADD_SALDENBEREINIGUNG_ROW,
    zm_tg
  }
}
export function LoadSaldenBereinigungSuccess(data) {
  return {
    type: LOAD_SALDENBEREINIGUNG,
    status: SUCCESS,
    data
  }
}

export function LoadSaldenBereinigung() {
  return (dispatch) => {
    request.get(`/api/saldenbereinigung`).end((err, response) => {
      dispatch(LoadSaldenBereinigungSuccess(response.body))
    })
  }
}

function CheckSaldenBereinigung(saldenbereinigung) {
  for (let row of saldenbereinigung) {
    if (row.abr_kreis == null) {
      return "bitte Abrechnungskreis auswählen"
    }
    // if (row.g_key > " " && row.int_id > 0) {
    //   return "bitte Auswahl oder Mitarbeiter auswählen"
    // }
  }
  return null
}

export function UpdateSaldenBereinigungSuccess(id, data) {
  return {
    type: UPDATE_SALDENBEREINIGUNG,
    status: SUCCESS,
    id,
    data
  }
}

export function UpdateSaldenBereinigung() {
  return (dispatch, getState) => {
    let data = getState().Zeitmodell.saldenbereinigung || []

    if (data.length == 0) {
      return
    }
    var err = CheckSaldenBereinigung(data)
    if (err !== null) {
      swal({
        title: "Hinweis zum Speichern der Daten",
        text: err,
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Ok!",
        closeOnConfirm: false
      })
      return
    }
    for (let row of data) {
      if (row.__deleted && !row.__new) {
        request
          .delete(`/api/saldenbereinigung/${row.id}`)
          .send(row)
          .end((err, response) => {
            dispatch(DeleteSaldenBereinigungSuccess(row.id, response.body))
          })
      } else if (row.__new) {
        let { id, ...r } = row
        request
          .post(`/api/saldenbereinigung`)
          .send(r)
          .end((err, response) => {
            dispatch(UpdateSaldenBereinigungSuccess(row.id, response.body))
          })
      } else if (row.__dirty && !row.__deleted) {
        request
          .put(`/api/saldenbereinigung`)
          .send(row)
          .end((err, response) => {
            dispatch(UpdateSaldenBereinigungSuccess(row.id, response.body))
          })
      }
    }
  }
}

export function SaldenBereinigungUnsavedChanges(state) {
  for (let saldenbereinigung of Object.values(state)) {
    for (let row of saldenbereinigung) {
      if (row.__new || row.__dirty || row.__deleted) {
        return true
      }
    }
  }
  return false
}

export const LOAD_WOCHENMODELL_FEIERTAG = "LOAD_WOCHENMODELL_FEIERTAG"
export const DELETE_WOCHENMODELL_FEIERTAG = "DELETE_WOCHENMODELL_FEIERTAG"
export const EDIT_WOCHENMODELL_FEIERTAG = "EDIT_WOCHENMODELL_FEIERTAG"
export const LoadWochenmodellFeiertag = (zmwo) => {
  return (dispatch) => {
    request.get(`/api/wochenmodellfeiertag/${zmwo}`).end((err, response) => {
      if (err) {
        return
      }
      dispatch({
        type: LOAD_WOCHENMODELL_FEIERTAG,
        status: SUCCESS,
        zmwo,
        data: response.body
      })
    })
  }
}

export const EditWochenmodellFeiertag = (zmwo, changes) => {
  return {
    type: EDIT_WOCHENMODELL_FEIERTAG,
    zmwo,
    changes
  }
}

export const UpdateWochenmodellFeiertag = (modell) => {
  return (dispatch) => {
    request
      .put("/api/wochenmodellfeiertag/")
      .send(modell)
      .end((err, response) => {
        dispatch({
          type: LOAD_WOCHENMODELL_FEIERTAG,
          status: SUCCESS,
          zm_wo: modell.zm_wo,
          data: response.body
        })
      })
  }
}

export const CreateWochenmodellFeiertag = (modell) => {
  return (dispatch) => {
    request
      .post("/api/wochenmodellfeiertag/new")
      .send(modell)
      .end((err, response) => {
        dispatch({
          type: LOAD_WOCHENMODELL_FEIERTAG,
          status: SUCCESS,
          zm_wo: modell.zm_wo,
          data: response.body
        })
      })
  }
}

export const DeleteWochenmodellFeiertag = (modell) => {
  return (dispatch) => {
    request
      .delete(`/api/wochenmodellfeiertag/${modell.id}`)
      .end((err, response) => {
        dispatch({
          type: DELETE_WOCHENMODELL_FEIERTAG,
          status: SUCCESS,
          zm_wo: modell.zm_wo,
          data: response.body
        })
      })
  }
}
