import request from "superagent"
import DateTime from "app/shared/dateutil"
import { SUCCESS } from "app/state/constants"
import { ChangeDplUISettings } from "./ui"

export const LOAD_DPL_PERS = "dpl/LOAD_DPL_PERS"
export const LOAD_UNVERPLANT = "dpl/LOAD_UNVERPLANT"
export const LOAD_ALL_PERS = "dpl/LOAD_ALL_PERS"

export const SET_SORT_ORDER = "dpl/SET_SORT_ORDER"
export const SET_DPL_FILTER = "dpl/SET_DPL_FILTER"
export const SET_DPL_GROUP = "dpl/SET_DPL_GROUP"
export const LOADED_GROUP_ORDER = "dpl/LOADED_GROUP_ORDER"

export const TOGGLE_SHOW_ALL = "dpl/TOGGLE_SHOW_ALL"

import { GetSortedIntIDs } from "../selectors/pers"

export function ToggleShowAll(t) {
  return {
    type: TOGGLE_SHOW_ALL,
    data: t
  }
}

export function SetDplFilter(ftype, fval) {
  return {
    type: SET_DPL_FILTER,
    ftype,
    fval
  }
}

export function SetDplGroupBy(group, silent) {
  return (dispatch, getState) => {
    let prevgroup = getState().Dpl.AllPers.group
    if (prevgroup == group) {
      if (silent) {
        return
      }
      group = null
    }
    if (silent !== true) {
      dispatch(ChangeDplUISettings("group_by", group))
    }
    dispatch({
      type: SET_DPL_GROUP,
      group
    })
  }
}

export function LoadGroupOrder(PlanID) {
  return (dispatch) => {
    request.get(`/api/dpl/group/order/${PlanID}`).end((err, response) => {
      if (!err) {
        dispatch({
          type: LOADED_GROUP_ORDER,
          data: response.body
        })
      }
    })
  }
}

export function SetSortOrderLocal(oldIndex, newIndex) {
  return {
    type: SET_SORT_ORDER,
    oldIndex,
    newIndex
  }
}

export function SetSortOrder(oldIndex, newIndex) {
  return (dispatch, getState) => {
    dispatch(SetSortOrderLocal(oldIndex, newIndex))
    var data = GetSortedIntIDs(getState())
    var PlanID = getState().Dpl.Plaene.selected

    request
      .post(`/api/dpl/basis/sortorder/${PlanID}`)
      .send(data)
      .end(() => {})
  }
}

export function LoadAllPersSuccess(PlanID, Datum, data) {
  return {
    type: LOAD_ALL_PERS,
    PlanID,
    data
  }
}

export function LoadAllPers(PlanID, Datum, BisDat) {
  return (dispatch) => {
    request
      .get(
        `/api/dpl/basis/allpers/${PlanID}/${DateTime.fromISO(Datum).toISO({
          suppressMilliseconds: true
        })}/${DateTime.fromISO(BisDat).toISO({
          suppressMilliseconds: true
        })}`
      )
      .end((err, response) => {
        dispatch(LoadAllPersSuccess(PlanID, Datum, response.body))
      })
  }
}

export function LoadOnePers(IntID, Datum) {
  return (dispatch) => {
    request.get(`/api/dpl/basis/onepers/${IntID}`).end((err, response) => {
      dispatch(LoadAllPersSuccess(0, Datum, response.body))
    })
  }
}

export function LoadPersSuccess(PlanID, DienstID, Datum, data) {
  return {
    type: LOAD_DPL_PERS,
    status: SUCCESS,
    PlanID,
    DienstID,
    Datum,
    data
  }
}

export function LoadDplPers(PlanID, DienstID, Datum) {
  return (dispatch, getState) => {
    let records = getState().Dpl.Records.byID
    let data = {}
    for (let r of Object.values(records)) {
      let record = { ...r }
      if (!r.__deleted) {
        if (r.__new || r.aid == 0) {
          delete record.id
        }
        data[record.int_id] = [...(data[record.int_id] || []), record]
      }
    }
    request
      .post(
        `/api/dpl/pers/${PlanID}/${DienstID}/${DateTime.fromISO(Datum, {
          zone: "utc"
        }).toISO({
          suppressMilliseconds: true
        })}`
      )
      .send(data)
      .end((err, response) => {
        if (!err) {
          dispatch(LoadPersSuccess(PlanID, DienstID, Datum, response.body))
        }
      })
  }
}

export function LoadUnverplantSuccess(PlanID, Datum, data) {
  return {
    type: LOAD_UNVERPLANT,
    status: SUCCESS,
    PlanID,
    Datum,
    data
  }
}

export function LoadUnverplant(PlanID, Datum) {
  return (dispatch) => {
    if (PlanID == null || PlanID == 0) {
      return
    }

    request
      .get(
        `/api/dpl/basis/unverplant/${PlanID}/${DateTime.fromISO(Datum).toISO({
          suppressMilliseconds: true
        })}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch(LoadUnverplantSuccess(PlanID, Datum, response.body))
        }
      })
  }
}
