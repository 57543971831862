import request from "superagent"
import swal from "sweetalert"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
import { UpdateDefinitionen } from "./definitionen"
import { LoadAll } from "./datum"
export const LOAD_PLAENE = "dpl/LOAD_PLAENE"
export const CREATE_PLAN = "dpl/SAVE_PLAN"
export const DELETE_PLAN = "dpl/DELETE_PLAN"
export const UPDATE_PLAN = "dpl/UPDATE_PLAN"
export const SELECT_PLAN = "dpl/SELECT_PLAN"
export const EDIT_PLAN = "dpl/EDIT_PLAN"
export const LOAD_DEFAULT_PLAN = "dpl/LOAD_DEFAULT_PLAN"

export function EditPlan(id, changes) {
  return {
    type: EDIT_PLAN,
    id,
    changes
  }
}

export function SelectPlan(id) {
  return (dispatch) => {
    dispatch({
      type: SELECT_PLAN,
      id
    })
    dispatch(LoadAll())
  }
}

export function LoadPlaeneSuccess(data) {
  return {
    type: LOAD_PLAENE,
    status: SUCCESS,
    data
  }
}

export function LoadPlaeneError(err) {
  return {
    type: LOAD_PLAENE,
    status: ERROR,
    err
  }
}

export function LoadPlaeneStarted() {
  return {
    type: LOAD_PLAENE,
    status: STARTED
  }
}

export const LoadDefaultPlan = () => {
  return (dispatch) => {
    request.get("/api/dpl/basis/defaultplan").end((err, response) => {
      if (!err) {
        dispatch({ type: LOAD_DEFAULT_PLAN, data: response.body })
      }
    })
  }
}

export function LoadPlaene() {
  return (dispatch) => {
    dispatch(LoadPlaeneStarted())
    request.get("/api/dpl/basis/plan").end((err, response) => {
      if (err) {
        dispatch(LoadPlaeneError(err))
      } else {
        dispatch(LoadPlaeneSuccess(response.body || []))
      }
    })
  }
}

export function CreatePlanSuccess(data) {
  return {
    type: CREATE_PLAN,
    status: SUCCESS,
    data
  }
}

export function CreatePlanError(err) {
  return {
    type: CREATE_PLAN,
    status: ERROR,
    err
  }
}

export function CreatePlanStarted() {
  return {
    type: CREATE_PLAN,
    status: STARTED
  }
}

export function CreatePlan(plan) {
  return (dispatch) => {
    dispatch(CreatePlanStarted())
    request
      .post("/api/dpl/basis/plan")
      .send(plan)
      .end((err, response) => {
        if (err) {
          dispatch(CreatePlanError(err))
        } else {
          dispatch(CreatePlanSuccess(response.body))
        }
      })
  }
}

export function DeletePlanSuccess(id) {
  return {
    type: DELETE_PLAN,
    status: SUCCESS,
    id
  }
}

export function DeletePlanError(err) {
  return {
    type: DELETE_PLAN,
    status: ERROR,
    err
  }
}

export function DeletePlanStarted() {
  return {
    type: DELETE_PLAN,
    status: STARTED
  }
}

export function DeletePlan(plan) {
  return (dispatch) => {
    dispatch(DeletePlanStarted())
    request.delete(`/api/dpl/basis/plan/${plan.id}`).end((err) => {
      if (err) {
        swal("Löschen des Plans fehlgeschlagen")
        dispatch(DeletePlanError(err))
      } else {
        dispatch(DeletePlanSuccess(plan.id))
      }
    })
  }
}

export function UpdatePlanSuccess(data) {
  return {
    type: UPDATE_PLAN,
    status: SUCCESS,
    data
  }
}

export function UpdatePlanError(err) {
  return {
    type: UPDATE_PLAN,
    status: ERROR,
    err
  }
}

export function UpdatePlanStarted() {
  return {
    type: UPDATE_PLAN,
    status: STARTED
  }
}

export function UpdatePlan(plan) {
  return (dispatch) => {
    dispatch(UpdatePlanStarted())
    request
      .put(`/api/dpl/basis/plan/${plan.id}`)
      .send(plan)
      .end((err, response) => {
        if (err) {
          dispatch(UpdatePlanError(err))
        } else {
          dispatch(UpdateDefinitionen(plan.id))
          dispatch(UpdatePlanSuccess(response.body))
        }
      })
  }
}
