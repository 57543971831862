import request from "superagent"
export const LOAD_QUALI = "dpl/LOAD_QUALI"
export const SET_QUALI_MA = "dpl/SET_QUALI_MA"
export const SET_QUALI_QUALI = "dpl/SET_QUAL_QUALI"
export const EDIT_QUALI = "dpl/EDIT_MA_QUALI"
export const ADD_QUALI = "dpl/ADD_MA_QUALI"
export const DELETE_QUALI = "dpl/DELETE_MA_QUALI"
export const SET_SELECTED_QUALI_ROW = "dpl/SET_SELECTED_QUALI_ROW"
export const CLEAR_DIRTY_QUALIS = "dpl/CLEAR_DIRTY_QUALIS"
export const LOAD_QUALI_PERS = "dpl/LOAD_QUALI_PERS"
export const LOAD_QUALI_QUALI = "dpl/LOAD_QUALI_QUALI"

export const LoadQualiPers = () => {
  return dispatch => {
    request.get("/api/dpl/quali/pers").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_QUALI_PERS,
          data: response.body || []
        })
      }
    })
  }
}

export const LoadQualiQuali = () => {
  return dispatch => {
    request.get("/api/dpl/quali/quali").end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_QUALI_QUALI,
          data: response.body || []
        })
      }
    })
  }
}

export const EditQuali = (id, changes) => {
  return {
    type: EDIT_QUALI,
    id,
    changes
  }
}
export const Clear = () => {
  return {
    type: CLEAR_DIRTY_QUALIS
  }
}
export const AddQuali = defaults => {
  return {
    type: ADD_QUALI,
    defaults
  }
}

export const AddMaQuali = () => {
  return (dispatch, getState) => {
    const selectedMa = parseInt(getState().Dpl.Qualifikationen.selectedMa)
    if (!selectedMa) {
      return
    }
    dispatch(AddQuali({ int_id: selectedMa }))
  }
}

export const AddQualiQuali = () => {
  return (dispatch, getState) => {
    const selectedQuali = parseInt(getState().Dpl.Qualifikationen.selectedQuali)
    if (!selectedQuali) {
      return
    }
    dispatch(AddQuali({ quali_id: selectedQuali }))
  }
}

export const DeleteQuali = id => {
  return {
    type: DELETE_QUALI,
    id
  }
}

export const DeleteSelectedQuali = () => {
  return (dispatch, getState) => {
    const selected = getState().Dpl.Qualifikationen.selectedRow
    if (!selected) {
      return
    }
    dispatch(DeleteQuali(selected))
  }
}

export const SetQualiMa = int_id => {
  return {
    type: SET_QUALI_MA,
    value: int_id
  }
}
export const SetQualiQuali = quali_id => {
  return {
    type: SET_QUALI_QUALI,
    value: quali_id
  }
}

export const SetSelectedQualiRow = id => {
  return {
    type: SET_SELECTED_QUALI_ROW,
    value: id
  }
}

export const LoadQualiIntID = int_id => {
  return dispatch => {
    request.get(`/api/qualifikation/int_id/${int_id}`).end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_QUALI,
          data: response.body
        })
      }
    })
  }
}

export const LoadQualiQualiID = quali_id => {
  return dispatch => {
    request.get(`/api/qualifikation/quali/${quali_id}`).end((err, response) => {
      if (!err) {
        dispatch({
          type: LOAD_QUALI,
          data: response.body
        })
      }
    })
  }
}

export const SaveQualis = () => {
  return (dispatch, getState) => {
    let state = getState()
    let qualis = state.Dpl.Qualifikationen.qualis
    for (let row of Object.values(qualis)) {
      if (row.__new && row.__deleted) {
        continue
      }
      if (row.__new) {
        request
          .post("/api/qualifikation")
          .send({ ...row, id: 0 })
          .end((err, response) => {
            if (!err) {
              dispatch({
                type: LOAD_QUALI,
                data: [response.body]
              })
            }
          })
      } else if (row.__deleted) {
        request
          .delete("/api/qualifikation")
          .send(row)
          .end((err, response) => {})
      } else if (row.__dirty) {
        request
          .put("/api/qualifikation")
          .send(row)
          .end((err, response) => {
            if (!err) {
              dispatch({
                type: LOAD_QUALI,
                data: [response.body]
              })
            }
          })
      }
    }
    dispatch(Clear())
  }
}
