import request from "superagent"
import DateTime from "app/shared/dateutil"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"

export const LOAD_MACROS = "dpl/LOAD_MACROS"
export const LOAD_RB_FOLGETAG = "dpl/LOAD_RB_FOLGETAG"

export function LoadMacrosSuccess(PlanID, data) {
  return {
    type: LOAD_MACROS,
    status: SUCCESS,
    PlanID,
    data
  }
}

export function LoadMacrosError(PlanID, err) {
  return {
    type: LOAD_MACROS,
    status: ERROR,
    PlanID,
    err
  }
}

export function LoadMacrosStarted(PlanID) {
  return {
    type: LOAD_MACROS,
    status: STARTED,
    PlanID
  }
}

export function LoadMacros(PlanID) {
  return (dispatch) => {
    dispatch(LoadMacrosStarted(PlanID))
    request.get(`/api/dpl/macros/${PlanID}`).end((err, response) => {
      if (err) {
        dispatch(LoadMacrosError(PlanID, err))
      } else {
        dispatch(LoadMacrosSuccess(PlanID, response.body))
      }
    })
  }
}

export function LoadRbFolgeTag(PlanID, datum) {
  return (dispatch) => {
    request

      .get(
        `/api/rb/fzfolgetage/${PlanID}/${DateTime.fromISO(datum, {
          zone: "utc"
        }).toISO({ suppressMilliseconds: true })}`
      )
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: LOAD_RB_FOLGETAG,
            data: response.body
          })
        }
      })
  }
}
