import store from "../state"
import { createSelector } from "reselect"

export const PlanID = (state) => {
  return state.Dpl.Plaene.selected
}

export const GetPlanID = (state) => {
  return PlanID(state)
}

export const usePlanID = () => {
  return PlanID(store.getState())
}

export const AllPlaene = (state) => {
  return state.Dpl.Plaene.data
}

export const DefaultPlan = (state) => {
  return state.Dpl.Plaene.defaultplan
}

export const GetSelectedPlan = createSelector(
  PlanID,
  AllPlaene,
  DefaultPlan,
  (planID, allplaene, defaultplan) => {
    const p = allplaene.find((p) => p.id === planID)
    if (!p) {
      return defaultplan || { wish_dienst: 1 }
    }
    return p
  }
)
