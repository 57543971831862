export const SET_ROW_VISIBILITY = "upl/SET_ROW_VISIBILITY"
export const SET_SHOW_PLUS_MINUS = "upl/SET_SHOW_PLUS_MINUS"
export const SET_SHOW_SUM = "upl/SET_SHOW_SUM"

export const SetRowVisibility = (key, value) => {
  return {
    type: SET_ROW_VISIBILITY,
    key,
    value
  }
}

export const SetShowSum = () => {
  return {
    type: SET_SHOW_SUM
  }
}

export const SetShowPlusMinus = () => {
  return {
    type: SET_SHOW_PLUS_MINUS
  }
}
