import { combineReducers } from "redux"
import combineSectionReducers from "combine-section-reducers"
import { Plaene } from "./plan"
import { Definitionen } from "./definitionen"
import { Taetigkeiten } from "./taetigkeiten"
import { TaetigkeitsDefinitionen } from "./taetigkeitsdef"
import { Records, AutoDpl } from "./records"
import { Datum } from "./datum.js"
import { Pers, Unverplant, AllPers } from "./pers.js"
import { UI, DUI } from "./ui.js"
import { Quali } from "./quali.js"
import { Selection, TplSelection, SelectionNum } from "./selection.js"
import { DpZord } from "./zuordnung.js"
import { Salden } from "./salden.js"
import { Summen } from "./summen.js"
import { PlaceHolders } from "./placeholder.js"
import { Macro, RbFolgeTag } from "./dpl_macro.js"
import { Favoriten } from "./favoriten"
import { NoCountFz, CheckDpl } from "./various"
import History from "./history"
import TempV from "./tempv"
import {
  Genehmigung,
  CanStartGenehmigung,
  Stufen,
  Berechtigungen
} from "./genehmigung.js"
import Kennzeichnungen from "./kennzeichnungen"
import { Qualifikationen } from "./qualifikationen.js"
import AbsenceCheck from "./absencecheck"
import { Lock } from "./lock.js"
import { Rules } from "./rules.js"
import {
  FehlzeitDefinitionen,
  Sollzeit,
  VVA,
  AllowedFehlzeitenDienst
} from "./fehlzeiten"

export default combineSectionReducers({
  Plaene,
  Definitionen,
  Taetigkeiten,
  TaetigkeitsDefinitionen,
  Records,
  Datum,
  Pers,
  Unverplant,
  AllPers,
  UI,
  DUI,
  Quali,
  Selection,
  TplSelection,
  SelectionNum,
  DpZord,
  Salden,
  Summen,
  PlaceHolders,
  Macro,
  RbFolgeTag,
  Genehmigung,
  CanStartGenehmigung,
  Lock,
  Stufen,
  Berechtigungen,
  AutoDpl,
  Rules,
  Favoriten,
  AbsenceCheck,
  History,
  NoCountFz,
  CheckDpl,
  Qualifikationen,
  FehlzeitDefinitionen,
  Sollzeit,
  VVA,
  AllowedFehlzeitenDienst,
  TempV,
  Kennzeichnungen
})
