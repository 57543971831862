import { combineReducers } from "redux"
import currentUser from "app/shared/authentication/reducers"
import { Security } from "app/security/reducers"
import { SecurityGroup } from "app/securitygroup/reducers"
import { Query } from "app/shared/query/query_reducers"
import { Permissions } from "app/permissions/permission_reducers"
import { Menu } from "app/shared/menu/menu_reducers"
import { Version } from "app/shared/version"
import { DropDown } from "app/shared/dropdown/reducers"
import { Mitarbeiter } from "app/shared/mitarbeiter/reducers"
import { Atabs } from "app/settings/atab/reducers"
import AnwesenheitsUebersicht from "app/pzw/anwesenheitsuebersicht/state"
import Wfl from "app/workflow/state"
import {
  UrlaubsAnspruch,
  UrlaubsAnspruchFix,
  UrlaubsVerfall
} from "app/shared/urlaubsanspruch/reducers"
import { WSData } from "../ws/state"
import Dpl from "../dpl/state"
import { ERROR } from "./constants"
import {
  USER_DID_LOGOUT,
  LOGIN_SUCCESS
} from "app/shared/authentication/actions"
import swal from "sweetalert"
import Sperrdatum from "app/shared/sperrdatum/state"
import Zeitkonto from "app/pzw/zeitkonto/state"
import { Buchungen } from "app/info/buchungen/reducers"
import { enableBatching } from "redux-batched-actions"
import { RESET_STATE } from "./actions"
import { Fehlerprotokoll } from "app/pzw/fehlerprotokoll/reducers"
import { Personalstamm } from "app/pzw/personalstamm/state/reducers"
import { Speicher } from "app/pzw/speicher/reducers"
import { TagesTexte } from "app/pzw/tagestexte/reducers"
import Zeitmodell from "app/pzw/zeitmodell/reducers"
import ZeitTags from "app/pzw/zeitmodell/zeittag_reducers"
//import { Urlaubsplanung } from "app/pzw/urlaubsplanung_/reducers"
import UPL from "app/upl/state"
import Verwaltung from "app/pzw/verwaltung/reducers"
import { Fehlzeiten } from "app/pzw/kalender/reducers/fehlzeiten"
import { Kompetenzen } from "app/info/pis/pis_reducers"
import { SchichtWsZulage } from "app/info/sws/swszulage_reducers"
import { TelRufdienst } from "app/info/telrufdienst/telrufdienst_reducers"
//import { KalenderHistory } from "./kalender/reducers/kalhistory_reducers"
import KalenderHistory2 from "app/pzw/kalenderhistory/state"
import { Kalender } from "app/pzw/kalender/reducers/kalender_reducers"

const appReducer = combineReducers({
  currentUser,
  Security,
  Personalstamm,
  SecurityGroup,
  Zeitkonto,
  AnwesenheitsUebersicht,
  Fehlerprotokoll,
  Query,
  Permissions,
  Menu,
  Version,
  DropDown,
  Mitarbeiter,
  UrlaubsAnspruch,
  UrlaubsAnspruchFix,
  UrlaubsVerfall,
  Atabs,
  Dpl,
  UPL,
  Wfl,
  Sperrdatum,
  Buchungen,
  Speicher,
  TagesTexte,
  Zeitmodell,
  ZeitTags,
  //Urlaubsplanung,
  Fehlzeiten,
  Kompetenzen,
  // KalenderHistory,
  Kalender,
  KalenderHistory2,
  SchichtWsZulage,
  TelRufdienst,
  Verwaltung,
  WSData
})

const rootReducer = (state, action) => {
  if (action.type === USER_DID_LOGOUT && action.status === LOGIN_SUCCESS) {
    state = undefined
  }
  if (action.type === RESET_STATE) {
    state = { ...state, Pzw: undefined, Dpl: undefined }
  }
  if (action.status == ERROR && action._handleInApp === undefined) {
    ShowError(action)
    return state
  }

  return appReducer(state, action)
}

function ShowError(action) {
  swal("Fehler", ExtractErrorMessage(action), "warning")
}

function ExtractErrorMessage(action) {
  if (
    action.err &&
    action.err.response &&
    action.err.response.body &&
    action.err.response.body.message
  ) {
    return action.err.response.body.message
  }
  if (action.err && action.err.message) {
    return action.err.message
  }
  if (
    action.error &&
    action.error.response &&
    action.error.response.body &&
    action.error.response.body.message
  ) {
    return action.error.response.body.message
  }
  if (action.error && action.error.message) {
    return action.error.message
  }
  return "Unbekannter Fehler"
}

export default enableBatching(rootReducer)
