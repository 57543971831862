import request from "superagent"
import { SUCCESS, ERROR, STARTED } from "app/state/constants"
export const LOAD_FZ_DEF = "dpl/LOAD_FZ_DEF"
export const LOAD_SOLLZEIT = "dpl/LOAD_SOLLZEIT"
export const LOAD_VVA = "dpl/LOAD_VVA"
export const LOAD_ALLOWED_FZ_DIENST = "dpl/LOAD_ALLOWED_FZ_DIENST"

export function LoadAllowedFehlzeitDienst() {
  return (dispatch) => {
    request.get("/api/dpl/basis/fz/dienst").end((err, res) => {
      dispatch({
        type: LOAD_ALLOWED_FZ_DIENST,
        data: res.body
      })
    })
  }
}

export function LoadFzDef() {
  return (dispatch) => {
    request
      .get("/api/personalkalender/fehlzeiten/definition")
      .end((err, res) => {
        dispatch({
          type: LOAD_FZ_DEF,
          data: res.body
        })
      })
  }
}

export function LoadSollZeit(PlanID, von, bis) {
  return (dispatch) => {
    request
      .get(`/api/sollzeit/plan/${PlanID}/${von}/${bis}?skipDpl=1`)
      .end((err, res) => {
        dispatch({
          type: LOAD_SOLLZEIT,
          data: res.body
        })
      })
  }
}

export function LoadVVa(PlanID, von, bis) {
  return (dispatch) => {
    request
      .get(`/api/vva/plan/${PlanID}/${von}/${bis}?skipDpl=1`)
      .end((err, res) => {
        dispatch({
          type: LOAD_VVA,
          data: res.body
        })
      })
  }
}
